import { combineReducers } from 'redux';
import { currentUser } from './currentUser';
import { currentSession } from './currentSession';
import { userAuthenticators } from './userAuthenticators';
import { promotionSpots } from './promotionSpots';
import { selectedGifteeContent } from './selectedGifteeContent';
import { expiringMyGiftsConnection } from './expiringMyGiftsConnection';
import { selectedMyGifts } from './selectedMyGifts';
import { availableMyGiftsConnection } from './availableMyGiftsConnection';
import { unavailableMyGiftsConnection } from './unavailableMyGiftsConnection';
import { brandsConnection } from './brandsConnection';
import { nonRecommendedBrandsConnection } from './nonRecommendedBrandsConnection';
import { itemsConnection, itemsConnectionActions } from './itemsConnection';
import { recommendedBrandsConnection } from './recommendedBrandsConnection';
import { pointWallet } from './pointWallet';
import { pageError } from './pageError';
import { changePageActions } from './changePage';
import {
  itemSearchCondition,
  itemSearchConditionActions,
} from './itemSearchCondition';
import { itemDetail, itemDetailActions } from './itemDetail';
import {
  createPointExchangeInput,
  createPointExchangeInputActions,
} from './createPointExchangeInput';
import { exchangedGifts } from './exchangedGifts';
import { myGift } from './myGift';
import { brand, brandActions } from './brand';
import {
  displayOnlyExchangeableBrands,
  displayOnlyExchangeableBrandsActions,
} from './displayOnlyExchangeableBrands';
import { chargedPoint } from './chargedPoint';
import {
  pointChargeWithSerialCodeError,
  pointChargeWithSerialCodeErrorActions,
} from './pointChargeWithSerialCodeError';

export const appReducer = combineReducers({
  currentUser,
  currentSession,
  userAuthenticators,
  promotionSpots,
  selectedGifteeContent,
  expiringMyGiftsConnection,
  availableMyGiftsConnection,
  unavailableMyGiftsConnection,
  brandsConnection,
  nonRecommendedBrandsConnection,
  itemsConnection,
  recommendedBrandsConnection,
  itemSearchCondition,
  pointWallet,
  pageError,
  selectedMyGifts,
  itemDetail,
  createPointExchangeInput,
  exchangedGifts,
  myGift,
  brand,
  displayOnlyExchangeableBrands,
  chargedPoint,
  pointChargeWithSerialCodeError,
});

export const appActions = {
  ...changePageActions,
  ...itemsConnectionActions,
  ...itemSearchConditionActions,
  ...itemDetailActions,
  ...createPointExchangeInputActions,
  ...brandActions,
  ...displayOnlyExchangeableBrandsActions,
  ...pointChargeWithSerialCodeErrorActions,
};
