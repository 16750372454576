import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  CircularProgress,
  Theme,
  Grid,
} from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    progress: {
      margin: theme.spacing(2),
    },
  });
};

type Props = WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes }) => (
  <Grid container justifyContent="center" data-cy="partialLoader">
    <CircularProgress className={classes.progress} color="primary" />
  </Grid>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
