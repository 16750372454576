import React from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { POINT_SECTION_HEIGHT } from '/@/components/shared/headingWithPoints/Presenter';
import { FOOTER_HEIGHT } from '/@/components/templates/footer/Presenter';
import { HeadingWithPoints } from '/@/components/shared/headingWithPoints/Index';
import { PointItemContents } from '/@/components/shared/pointItemContents/Index';
import { CautionText } from '/@/components/shared/cautionText/Index';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';

const styles = (theme: Theme) =>
  createStyles({
    itemsContainer: {
      backgroundColor: theme.palette.background.hint,
      minHeight: `calc(100vh - ${HEADER_HEIGHT}px - ${POINT_SECTION_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
      padding: theme.spacing(2),
    },
    cautionTextContainer: {
      marginTop: theme.spacing(2),
    },
  });

type OwnProps = {
  headerTitle: string;
  isRestoredSearchConditionWithURLParams: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  headerTitle,
  isRestoredSearchConditionWithURLParams,
}) => (
  <MainLayout headerTitle={headerTitle}>
    <HeadingWithPoints benefitOfPointChargeModalVisible={false} />
    <div className={classes.itemsContainer}>
      {isRestoredSearchConditionWithURLParams ? (
        <PointItemContents />
      ) : (
        <PartialLoader />
      )}
      <div className={classes.cautionTextContainer}>
        <CautionText />
      </div>
    </div>
  </MainLayout>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
