import { createSelector } from 'reselect';
import { TopicValues } from '.';
import { RootState } from '/@/store';

export const selectName = (state: RootState): string =>
  state.page.inquiriesNewPage.name;

export const selectEmail = (state: RootState): string =>
  state.page.inquiriesNewPage.email;

export const selectTopic = (state: RootState): TopicValues | '' =>
  state.page.inquiriesNewPage.topic;

export const selectContent = (state: RootState): string =>
  state.page.inquiriesNewPage.content;

export const selectIsTermsAgreed = (state: RootState): boolean =>
  state.page.inquiriesNewPage.isTermsAgreed;

const selectIsNameEntered = createSelector(
  [selectName],
  (name): boolean => !!name,
);

const selectIsEmailEntered = createSelector(
  [selectEmail],
  (email): boolean => !!email,
);

const selectIsTopicSelected = createSelector(
  [selectTopic],
  (topic): boolean => !!topic,
);

const selectIsContentEntered = createSelector(
  [selectContent],
  (content): boolean => !!content,
);

export const selectIsReadyToSubmit = createSelector(
  [
    selectIsNameEntered,
    selectIsEmailEntered,
    selectIsTopicSelected,
    selectIsContentEntered,
    selectIsTermsAgreed,
  ],
  (
    isNameEntered,
    isEmailEntered,
    isTopicSelected,
    isContentEntered,
    isTermsAgreed,
  ): boolean => {
    return [
      isNameEntered,
      isEmailEntered,
      isTopicSelected,
      isContentEntered,
      isTermsAgreed,
    ].every(Boolean);
  },
);
