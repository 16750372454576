import { FetchResult, Observable } from '@apollo/client';
import { internalApiAppSubscriptionClient } from '/@/api/graphql/appApolloSubscription';
import { giftConvertToPointRequestSubscriptionGql } from './index.gql';
import {
  GiftConvertToPointRequestSubscription,
  GiftConvertToPointRequestSubscriptionVariables,
} from '../types';

type GiftConvertToPointRequestSubscriptionResponse =
  FetchResult<GiftConvertToPointRequestSubscription>;

const giftConvertToPointRequestSubscriptionObserver = (
  input: GiftConvertToPointRequestSubscriptionVariables,
): Observable<GiftConvertToPointRequestSubscriptionResponse> | undefined => {
  return internalApiAppSubscriptionClient()?.subscribe({
    query: giftConvertToPointRequestSubscriptionGql,
    variables: input,
  });
};

export { giftConvertToPointRequestSubscriptionObserver };

export type { GiftConvertToPointRequestSubscriptionResponse };
