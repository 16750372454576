import React from 'react';
import { RecommendedContents } from './recommendedContents/Index';
import { ExchangeableContents } from './exchangeableContents/Index';
import {
  Button,
  Checkbox,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { DisplayOnlyExchangeableBrandsState } from '/@/store/app/displayOnlyExchangeableBrands';

const styles = (theme: Theme) => {
  return createStyles({
    switchDisplayOnlyExchangeableContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginLeft: 'auto',
      padding: 0,
    },
    switchDisplayOnlyExchangeableText: {
      fontSize: '0.75rem',
      lineHeight: '0.75rem',
    },
    sectionTitle: {
      display: 'inline-block',
      fontSize: '0.75rem',
      lineHeight: '0.75rem',
      fontWeight: 'bold',
      '&:not(:first-child)': {
        marginTop: theme.spacing(3),
      },
    },
  });
};

type OwnProps = {
  isDisplayOnlyExchangeableBrands: DisplayOnlyExchangeableBrandsState;
  handleDisplayOnlyExchangeable: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  isDisplayOnlyExchangeableBrands,
  handleDisplayOnlyExchangeable,
}) => {
  return (
    <>
      <Button
        className={classes.switchDisplayOnlyExchangeableContainer}
        onClick={handleDisplayOnlyExchangeable}
      >
        <Typography
          variant="subtitle2"
          className={classes.switchDisplayOnlyExchangeableText}
        >
          交換可能なチケットに絞る
        </Typography>
        <Checkbox
          checked={isDisplayOnlyExchangeableBrands}
          data-cy="checkBox-displayOnlyExchangeable"
          color="primary"
          style={{ padding: 0, marginLeft: 4 }}
        />
      </Button>
      <Typography variant="subtitle1" className={classes.sectionTitle}>
        今交換するとお得なチケット
      </Typography>
      <RecommendedContents />
      <Typography variant="subtitle1" className={classes.sectionTitle}>
        チケット一覧
      </Typography>
      <ExchangeableContents />
    </>
  );
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
