import { gql } from '@apollo/client';
import { FRAGMENT_POINT_EXCHANGE } from '/@/api/graphql/internalApi/fragments/pointExchange.gql';
import { FRAGMENT_POINT_WALLET } from '/@/api/graphql/internalApi/fragments/pointWallet.gql';
import { FRAGMENT_ERRORS } from '/@/api/graphql/internalApi/fragments/errors.gql';

export const createPointExchangeGql = gql`
  ${FRAGMENT_POINT_EXCHANGE}
  ${FRAGMENT_POINT_WALLET}
  ${FRAGMENT_ERRORS}
  mutation CreatePointExchange(
    $clientMutationId: String
    $quantity: Int
    $pointAmount: Int!
    $requestUid: String!
    $itemUid: String!
    $pointBackCampaignUid: String
  ) {
    createPointExchange(
      input: {
        clientMutationId: $clientMutationId
        quantity: $quantity
        pointAmount: $pointAmount
        requestUid: $requestUid
        itemUid: $itemUid
        pointBackCampaignUid: $pointBackCampaignUid
      }
    ) {
      pointExchange {
        ...PointExchangeFields
        pointLog {
          status
        }
        pointBackCampaignApply {
          backPoint
          pointBackCampaign {
            discountPercentage
          }
        }
      }
      pointWallet {
        ...PointWalletFields
      }
      errors {
        ...ErrorsFields
      }
    }
  }
`;
