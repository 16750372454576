import { gql } from '@apollo/client';

export const getPromotionSpotsGql = gql`
  query GetPromotionSpots($name: PromotionSpotNameEnum!) {
    promotionSpots(name: $name) {
      name
      order
      promotionPublishment {
        promotion {
          name
          description
          promoter {
            imageUrl
            name
          }
          reward {
            imageUrl
            name
          }
          promotionTag {
            name
          }
        }
        url
      }
    }
  }
`;
