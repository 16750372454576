import { Reducer } from 'redux';
import type { RootAction } from '/@/store/actions';
import { getPointWalletTypes } from '/@/store/api/getPointWallet';
import { types as createPointExchangeTypes } from '/@/store/api/createPointExchange/types';
import { pointUpdatedSubscriptionTypes } from '/@/store/api/pointUpdatedSubscription';
import { types as createGiftConvertToPointTypes } from '/@/store/api/createGiftConvertToPoint/types';
import { createPointChargeWithSerialCodeTypes } from '../../api/createPointChargeWithSerialCode';

type PointWalletState = PointWallet | null;

type PointWallet = {
  point: number;
  nearestAvailableWithdrawableUntilAt?: string | null;
};

const initialState: PointWalletState = null;

const pointWallet: Reducer<PointWalletState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getPointWalletTypes.GET_POINT_WALLET_SUCCESS:
      return action.pointWallet;
    case createPointExchangeTypes.CREATE_POINT_EXCHANGE_SUCCESS:
      return action.pointWallet ?? state;
    case pointUpdatedSubscriptionTypes.POINT_UPDATED_SUBSCRIPTION_SUCCESS:
      return action.pointWallet;
    case createGiftConvertToPointTypes.CREATE_GIFT_CONVERT_TO_POINT_SUCCESS:
      return action.giftConvertToPointRequest?.pointWallet ?? state;
    case createPointChargeWithSerialCodeTypes.CREATE_POINT_CHARGE_WITH_SERIAL_CODE_SUCCESS:
      return (
        action.createPointChargeWithSerialCodeResponse.pointWallet ?? state
      );
    default:
      return state;
  }
};

export { pointWallet };
export type { PointWalletState };
