import { types } from './types';
import type { GetOauthSessionQuery } from '/@/api/graphql/publicApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getOauthSessionStart = () =>
  ({
    type: types.GET_OAUTH_SESSION_START,
  }) as const;

export const getOauthSessionSuccess = (
  oauthSession: GetOauthSessionQuery['oauthSession'],
) =>
  ({
    type: types.GET_OAUTH_SESSION_SUCCESS,
    oauthSession,
  }) as const;

export const getOauthSessionFailure = (error: ApiError) =>
  ({
    type: types.GET_OAUTH_SESSION_FAILURE,
    error,
  }) as const;
