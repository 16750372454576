import React from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/core';
import logo from '/@/assets/signin-hero-gift-wallet-logo.svg';

const styles = () => {
  return createStyles({
    logo: {
      width: '100%',
    },
  });
};

type OwnProps = Record<string, never>;

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes }) => (
  <div data-cy="signInPageHeroSection">
    <img src={logo} alt="gift wallet logo" className={classes.logo} />
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
