import React from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/core';
import { PageLoader } from '/@/components/shared/loader/pageLoader/Index';

const styles = () => createStyles({});

type OwnProps = Record<string, never>;

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = () => <PageLoader />;

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
