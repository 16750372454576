import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getInitialExpiringMyGiftsConnectionThunk } from '/@/store/api/getInitialExpiringMyGiftsConnection';
import { getContinuousExpiringMyGiftsConnectionThunk } from '/@/store/api/getContinuousExpiringMyGiftsConnection';
import { Presenter } from './Presenter';
import { useIntersectionObserver } from '/@/common/hooks/useIntersectionObserver';
import { STATUS } from '/@/store/api/constants';

const Container: React.VFC<Record<string, never>> = () => {
  const expiredMyGifts = useAppSelector(
    (state) => state.app.expiringMyGiftsConnection.expiringMyGifts,
  );
  const expiringMyGiftsConnectionpageInfo = useAppSelector(
    (state) => state.app.expiringMyGiftsConnection.pageInfo,
  );
  const getInitialExpiringMyGiftsConnectionStatus = useAppSelector(
    (state) => state.api.getInitialExpiringMyGiftsConnection.status,
  );
  const getContinuousExpiringMyGiftsConnectionStatus = useAppSelector(
    (state) => state.api.getContinuousExpiringMyGiftsConnection.status,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getInitialExpiringMyGiftsConnectionThunk()).catch(() => {});
  }, [dispatch]);

  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);

  useEffect(() => {
    startObserving(true);

    return () => {
      startObserving(false);
    };
  }, [startObserving]);

  useEffect(() => {
    if (
      isIntersect &&
      getContinuousExpiringMyGiftsConnectionStatus !== STATUS.LOADING &&
      expiringMyGiftsConnectionpageInfo?.hasNextPage
    ) {
      dispatch(
        getContinuousExpiringMyGiftsConnectionThunk(
          expiringMyGiftsConnectionpageInfo.endCursor,
        ),
      ).catch(() => {});
    }
  }, [
    dispatch,
    isIntersect,
    getContinuousExpiringMyGiftsConnectionStatus,
    expiredMyGifts,
    expiringMyGiftsConnectionpageInfo,
  ]);

  return (
    <Presenter
      expiredMyGifts={expiredMyGifts}
      infiniteScrollTarget={infiniteScrollTarget}
      getInitialExpiringMyGiftsConnectionStatus={
        getInitialExpiringMyGiftsConnectionStatus
      }
      getContinuousExpiringMyGiftsConnectionStatus={
        getContinuousExpiringMyGiftsConnectionStatus
      }
    />
  );
};

export { Container as ExpiringMyGifts };
