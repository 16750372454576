import React, { useCallback, useEffect } from 'react';
import { Presenter } from './Presenter';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { giftConvertToPointRequestSubscriptionThunk } from '/@/store/api/giftConvertToPointRequestSubscription';
import { useAppDispatch, useAppSelector } from '/@/store';
import {
  createGiftConvertToPointInitThunk,
  createGiftConvertToPointThunk,
} from '/@/store/api/createGiftConvertToPoint';
import createRequestUid from '/@/utils/createRequestUid';
import { initialiseGiftConvertToPointRequestSubscriptionStatus } from '/@/store/api/giftConvertToPointRequestSubscription/actions';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { STATUS } from '/@/store/api/constants';

type OwnProps = {
  handleClose: () => void;
  skipConfirm?: boolean;
};

const Container: React.VFC<OwnProps> = ({ handleClose, skipConfirm }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const requestUid = createRequestUid();
  const { pushClickEvent, pushPointChargeEvent } = useDataLayer();
  const createGiftConvertToPointStatus = useAppSelector(
    (state) => state.api.createGiftConvertToPoint.status,
  );
  const giftConvertToPointRequestSubscriptionStatus = useAppSelector(
    (state) => state.api.giftConvertToPointRequestSubscription.status,
  );
  const myGift = useAppSelector((state) => state.app.myGift);

  const cancelCharge = () => {
    pushClickEvent({
      clickItem: 'cancelChargeButton',
      customData: {
        clickItem: 'cancelChargeButton',
        itemName: myGift?.contentName,
        point: myGift?.remainingPoint,
      },
    });
    handleClose();
  };

  const handleSubmit = useCallback(() => {
    if (
      myGift?.urlCode &&
      myGift?.remainingPoint &&
      createGiftConvertToPointStatus === STATUS.IDLE
    ) {
      dispatch(
        createGiftConvertToPointThunk({
          myGiftUrlCode: myGift.urlCode,
          requestUid: requestUid,
          convertPointAmount: myGift.remainingPoint,
        }),
      ).then(() => {
        pushPointChargeEvent({
          itemName: myGift?.contentName,
          point: myGift?.remainingPoint,
          customData: {
            itemName: myGift?.contentName,
            point: myGift?.remainingPoint,
          },
        });
        dispatch(
          giftConvertToPointRequestSubscriptionThunk({
            requestUid: requestUid,
          }),
        );
      });
    }
  }, [
    dispatch,
    myGift,
    createGiftConvertToPointStatus,
    requestUid,
    pushPointChargeEvent,
  ]);

  useEffect(() => {
    if (myGift?.urlCode && myGift?.remainingPoint) {
      dispatch(createGiftConvertToPointInitThunk());
    }
  }, [dispatch, myGift]);

  useEffect(() => {
    if (skipConfirm === true && myGift?.remainingPoint) {
      handleSubmit();
    }
  }, [dispatch, myGift, skipConfirm, handleSubmit]);

  useEffect(() => {
    // MEMO: subscriptionにて移行成功通知取得時に画面遷移
    if (giftConvertToPointRequestSubscriptionStatus === STATUS.SUCCESS) {
      dispatch(initialiseGiftConvertToPointRequestSubscriptionStatus());
      navigate(PATHS.POINT_CHARGE_COMPLETION);
    } else if (giftConvertToPointRequestSubscriptionStatus === STATUS.FAILURE) {
      dispatch(initialiseGiftConvertToPointRequestSubscriptionStatus());
      handleClose();
      // MEMO:移行時エラーのalertMessageがページTOPに表示されるため
      window.scrollTo(0, 0);
    }
  }, [
    giftConvertToPointRequestSubscriptionStatus,
    dispatch,
    navigate,
    handleClose,
  ]);

  return myGift?.remainingPoint && !skipConfirm ? (
    <Presenter
      cancelCharge={cancelCharge}
      handleSubmit={handleSubmit}
      pointAmount={myGift.remainingPoint}
      isLoading={
        createGiftConvertToPointStatus === STATUS.LOADING ||
        giftConvertToPointRequestSubscriptionStatus === STATUS.LOADING
      }
    />
  ) : null;
};
export { Container as PointChargeModal };
