import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { createGiftConvertToPointGql } from './index.gql';
import {
  CreateGiftConvertToPointMutationVariables,
  CreateGiftConvertToPointMutation,
} from '../types';

type CreateGiftConvertToPointResponse =
  FetchResult<CreateGiftConvertToPointMutation>;

const createGiftConvertToPoint = (
  input: CreateGiftConvertToPointMutationVariables,
): Promise<CreateGiftConvertToPointResponse> => {
  return internalApiClient.mutate({
    mutation: createGiftConvertToPointGql,
    variables: input,
  });
};

export { createGiftConvertToPoint };

export type { CreateGiftConvertToPointResponse };
