import { createSelector } from 'reselect';
import { RootState } from '/@/store';
import type { PointLog } from './reducers';
import { formatToDateJa, formatToEndDateJa } from '/@/utils/formatDate';

type PointLogDetail = { key: string; value: string };
export type PointLogWithDetail = PointLog & {
  point: number;
  details: PointLogDetail[];
};

const pointLogs = (state: RootState) =>
  state.page.pointLogsPage.listPage.pointLogs;

const wrapWithDetail = (value: PointLog): PointLogWithDetail => {
  const details =
    value.statement.__typename === 'PointCharge'
      ? [
          {
            key: '獲得日',
            value: formatToDateJa(value.loggedAt),
          },
          {
            key: '有効期限',
            value: formatToEndDateJa(value.statement.withdrawableUntilAt),
          },
        ]
      : value.statement.__typename === 'PointExchange'
        ? [
            {
              key: '交換日',
              value: formatToDateJa(value.loggedAt),
            },
            {
              key: '交換商品',
              value: value.statement.myGifts
                .map((myGift) => myGift.contentName)
                .join(', '),
            },
          ]
        : value.statement.__typename === 'PointExpired'
          ? [
              {
                key: '失効日',
                value: formatToEndDateJa(value.loggedAt),
              },
            ]
          : [];
  const point =
    value.statement.__typename === 'PointCharge'
      ? value.statement.pointAmount
      : value.statement.__typename === 'PointExchange'
        ? value.statement.pointAmount * -1
        : value.statement.__typename === 'PointExpired'
          ? value.statement.expiredPoint * -1
          : 0;
  return {
    ...value,
    point,
    details,
  };
};

export const pointLogsWithDetail = createSelector(
  [pointLogs],
  (pointLogs): PointLogWithDetail[] =>
    pointLogs?.reduce((prev, current) => {
      return current ? [...prev, wrapWithDetail(current)] : prev;
    }, [] as PointLogWithDetail[]) || [],
);
