import { types } from './types';
import type { CreatePointChargeWithSerialCodeMutation } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const createPointChargeWithSerialCodeStart = () =>
  ({ type: types.CREATE_POINT_CHARGE_WITH_SERIAL_CODE_START }) as const;

export const createPointChargeWithSerialCodeSuccess = (
  createPointChargeWithSerialCodeResponse: CreatePointChargeWithSerialCodeMutation['createPointChargeWithSerialCode'],
) =>
  ({
    type: types.CREATE_POINT_CHARGE_WITH_SERIAL_CODE_SUCCESS,
    createPointChargeWithSerialCodeResponse,
  }) as const;

export const createPointChargeWithSerialCodeFailure = (error: ApiError) =>
  ({
    type: types.CREATE_POINT_CHARGE_WITH_SERIAL_CODE_FAILURE,
    error,
  }) as const;
