import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  AppBar,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Grid,
  Button,
  IconButton,
  TextField,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import closeIcon from '/@/assets/close-icon.svg';
import arrowRightIcon from '/@/assets/arrow_right_icon.svg';
import sliderIcon from '/@/assets/slider_icon.svg';
import { BrandCondition } from './brandCondition/Index';
import { ConditionOpenedType } from '.';
import { theme } from '/@/utils/theme';
import { ItemSearchConditionState } from '/@/store/app/itemSearchCondition/reducers';

const styles = (theme: Theme) => {
  return createStyles({
    appBar: {
      backgroundColor: theme.palette.background.default,
      border: 'none',
    },
    titleGrid: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1.5),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      height: HEADER_HEIGHT,
      minHeight: HEADER_HEIGHT,
    },
    arrowBackIcon: {
      padding: 0,
    },
    title: {
      fontWeight: 'bold',
    },
    searchMenuDrawer: {
      height: '100%',
      maxWidth: '400px',
      [theme.breakpoints.down('sm')]: {
        width: '87.5%',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: '75%',
      },
      [theme.breakpoints.up('md')]: {
        width: '40%',
      },
    },
    searchMenuHeader: {
      position: 'relative',
      padding: theme.spacing(1.5),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    searchMenuTitle: {
      color: theme.palette.common.black,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    backButton: {
      position: 'absolute',
      left: theme.spacing(2),
      top: theme.spacing(1),
      color: theme.palette.primary.main,
      minWidth: 'auto',
      minHeight: 'auto',
    },
    clearButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(1),
      color: theme.palette.primary.main,
      minWidth: 'auto',
      minHeight: 'auto',
    },
    closeIcon: {
      position: 'absolute',
      left: theme.spacing(-2),
      top: theme.spacing(2),
      height: '20px',
      width: '20px',
      transform: 'translateX(-50%)',
    },
    menuListItem: {
      padding: theme.spacing(1.5),
    },
    pointRangeInputs: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    menuListItemText: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
    arrowRightIcon: {
      height: '0.75rem',
    },
    searchButton: {
      fontWeight: 'bold',
      width: '90%',
      margin: 'auto',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1.5),
      borderRadius: theme.spacing(4),
    },
    pointInputField: {
      width: '4rem',
    },
  });
};

type OwnProps = {
  headerTitle: string;
  sideMenuTitle: string;
  isMenuOpened: boolean;
  handleToggleMenu: () => void;
  isConditionOpened: ConditionOpenedType;
  handleOpenCondition: (type: ConditionOpenedType) => void;
  handleNavigateBack: () => void;
  selectedBrandName: string | null;
  conditions: ItemSearchConditionState;
  setConditions: (conditions: ItemSearchConditionState) => void;
  searchItems: () => void;
  handleClearBrandCondition: () => void;
  handleClearCondition: () => void;
  setMinPointCondition: (minPoint: number) => void;
  setMaxPointCondition: (maxPoint: number) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  headerTitle,
  sideMenuTitle,
  isMenuOpened,
  handleToggleMenu,
  isConditionOpened,
  handleOpenCondition,
  handleNavigateBack,
  selectedBrandName,
  conditions,
  setConditions,
  searchItems,
  handleClearBrandCondition,
  handleClearCondition,
  setMinPointCondition,
  setMaxPointCondition,
}) => (
  <>
    <AppBar
      position="fixed"
      className={classes.appBar}
      variant="outlined"
      color="default"
      data-cy="header"
    >
      <Grid container alignItems="center" className={classes.titleGrid}>
        <IconButton
          color="secondary"
          onClick={handleNavigateBack}
          className={classes.arrowBackIcon}
          data-gtm-click="templates-header-seachHeader-handleNavigateBackButton"
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Typography className={classes.title}>{headerTitle}</Typography>
        <Button
          onClick={handleToggleMenu}
          style={{ minWidth: 'auto', padding: 0 }}
          data-gtm-click="templates-header-seachHeader-handleToggleMenuButton"
        >
          <img
            src={sliderIcon}
            alt="絞り込みメニュー"
            width="20"
            height="20"
            data-cy="sliderIcon"
          />
        </Button>
      </Grid>
    </AppBar>

    <SwipeableDrawer
      classes={{
        paper: classes.searchMenuDrawer,
      }}
      anchor="right"
      open={isMenuOpened}
      onOpen={() => {}} // Do nothing
      onClose={handleToggleMenu}
    >
      <img
        src={closeIcon}
        alt="閉じるボタン"
        data-cy="closeIcon"
        className={classes.closeIcon}
      />
      <div className={classes.searchMenuHeader}>
        {isConditionOpened === 'brand' && (
          <Button
            className={classes.backButton}
            onClick={() => handleOpenCondition(null)}
            data-gtm-click="templates-header-searchHeader-handleCloseBrandConditionButton"
          >
            <ArrowBackIosIcon />
          </Button>
        )}
        <Typography classes={{ root: classes.searchMenuTitle }}>
          {sideMenuTitle}
        </Typography>
        <Button
          className={classes.clearButton}
          onClick={
            isConditionOpened !== 'brand'
              ? handleClearCondition
              : handleClearBrandCondition
          }
          data-gtm-click={`templates-header-searchHeader-${isConditionOpened !== 'brand' ? 'handleClearCondition' : 'handleClearBrandCondition'}Button`}
        >
          クリア
        </Button>
      </div>
      {isConditionOpened === 'brand' ? (
        <BrandCondition
          handleClose={() => handleOpenCondition(null)}
          conditions={conditions}
          setConditions={setConditions}
        />
      ) : (
        <>
          <List
            data-cy="menuList"
            component="nav"
            aria-label="menu-list"
            style={{ paddingTop: 0 }}
          >
            <ListItem
              button
              className={classes.menuListItem}
              onClick={() => handleOpenCondition('brand')}
              data-gtm-click="templates-header-searchHeader-handleOpenBrandConditionButton"
            >
              <ListItemText
                primary="ブランド"
                classes={{ primary: classes.menuListItemText }}
              />
              <div
                style={{
                  color: selectedBrandName ? 'black' : theme.palette.grey.A200,
                }}
              >
                {selectedBrandName ?? '指定なし'}
                <img
                  src={arrowRightIcon}
                  alt="条件選択ボタン"
                  width="24"
                  height="12"
                  data-cy="arrowRightIcon"
                />
              </div>
            </ListItem>
            <Divider component="li" />
            <ListItem
              button
              className={classes.menuListItem}
              onClick={() => handleOpenCondition('point')}
            >
              <ListItemText
                primary="ポイント"
                classes={{ primary: classes.menuListItemText }}
              />
              <div className={classes.pointRangeInputs}>
                <TextField
                  type="number"
                  placeholder="100"
                  value={
                    conditions?.minPoint && conditions.minPoint > 0
                      ? conditions.minPoint
                      : ''
                  }
                  onChange={(e) => {
                    if (typeof Number(e.target.value) == 'number')
                      setMinPointCondition(Number(e.target.value));
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 999999,
                      style: { textAlign: 'right' },
                    },
                  }}
                  className={classes.pointInputField}
                />
                <Typography
                  style={{ display: 'inline-block', margin: 'auto 30px' }}
                >
                  {'-'}
                </Typography>
                <TextField
                  type="number"
                  placeholder="10000"
                  value={
                    conditions?.maxPoint && conditions.maxPoint > 0
                      ? conditions.maxPoint
                      : ''
                  }
                  onChange={(e) => {
                    if (typeof Number(e.target.value) == 'number')
                      setMaxPointCondition(Number(e.target.value));
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 999999,
                      style: { textAlign: 'right' },
                    },
                  }}
                  className={classes.pointInputField}
                />
              </div>
            </ListItem>
          </List>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            className={classes.searchButton}
            onClick={searchItems}
            disabled={
              !!(
                conditions?.minPoint &&
                conditions?.maxPoint &&
                conditions?.minPoint > conditions?.maxPoint
              )
            }
            data-gtm-click="templates-header-searchHeader-searchButton"
          >
            検索
          </Button>
        </>
      )}
    </SwipeableDrawer>
  </>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
