import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Grid,
  Typography,
  Theme,
} from '@material-ui/core';
import scrollDown from '/@/assets/signin-about-scroll-down.svg';
import decoration from '/@/assets/signin-about-decoration.svg';

const styles = (theme: Theme) => {
  return createStyles({
    scrollDownImage: {
      display: 'block',
      margin: 'auto',
    },
    decorationImage: {
      display: 'block',
      margin: 'auto',
      width: '85%',
      marginTop: theme.spacing(4),
    },
    description: {
      color: theme.palette.primary.dark,
      fontWeight: 'bold',
      marginTop: theme.spacing(4),
    },
  });
};

type OwnProps = Record<string, never>;

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes }) => (
  <div data-cy="signInPageAboutSection">
    <Grid container direction="column" alignContent="center">
      <Grid item xs>
        <img
          src={scrollDown}
          alt="scroll down"
          className={classes.scrollDownImage}
        />
      </Grid>
      <Grid item xs>
        <Typography
          variant="body2"
          align="center"
          className={classes.description}
        >
          gift walletは、 <br />
          せっかくのギフトを使い忘れなく、
          <br />
          しっかり活用するためのお財布サービスです。
        </Typography>
      </Grid>
      <Grid item xs>
        <img
          src={decoration}
          alt="decoration"
          className={classes.decorationImage}
        />
      </Grid>
    </Grid>
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
