import { gql } from '@apollo/client';
import { FRAGMENT_GIFTEE_CONTENT } from '/@/api/graphql/internalApi/fragments/gifteeContent.gql';

export const getGifteeContentGql = gql`
  ${FRAGMENT_GIFTEE_CONTENT}
  query GetGifteeContent($urlCode: ID, $url: String) {
    gifteeContent(urlCode: $urlCode, url: $url) {
      ...GifteeContentFields
    }
  }
`;
