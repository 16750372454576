import { GIFT_WALLET_FRONT_URI } from '/@/common/giftWalletFrontUri';

const GIFT_WALLET_API_URI = {
  DEVELOPMENT:
    process.env.VITE_GIFT_WALLET_API_ENDPOINT || 'http://localhost:3000',
  STAGING: 'https://stg.api.gift-wallet.app',
  PRODUCTION: 'https://api.gift-wallet.app',
} as const;

type GiftWalletApiUri =
  (typeof GIFT_WALLET_API_URI)[keyof typeof GIFT_WALLET_API_URI];

const getGiftWalletApiUri = (): GiftWalletApiUri => {
  const { hostname } = location;
  if (hostname === GIFT_WALLET_FRONT_URI.PRODUCTION) {
    return GIFT_WALLET_API_URI.PRODUCTION;
  } else if (hostname === GIFT_WALLET_FRONT_URI.STAGING) {
    return GIFT_WALLET_API_URI.STAGING;
  }
  return GIFT_WALLET_API_URI.DEVELOPMENT;
};

export { getGiftWalletApiUri };
