import { types } from './types';
import type { PromotionSpot } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getPromotionSpotsStart = () =>
  ({
    type: types.GET_PROMOTION_SPOTS_START,
  }) as const;

export const getPromotionSpotsSuccess = (
  promotionSpots: Array<PromotionSpot>,
) =>
  ({
    type: types.GET_PROMOTION_SPOTS_SUCCESS,
    promotionSpots,
  }) as const;

export const getPromotionSpotsFailure = (error: ApiError) =>
  ({
    type: types.GET_PROMOTION_SPOTS_FAILURE,
    error,
  }) as const;
