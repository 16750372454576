import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Button,
  Typography,
} from '@material-ui/core';
import QuestionIcon from '/@/assets/question_icon.svg';
import { BenefitOfPointChargeModal } from '../modal/Index';

const styles = (theme: Theme) => {
  return createStyles({
    benefitOfPointChargeModalButton: {
      textAlign: 'center',
      textDecoration: 'none',
      backgroundColor: theme.palette.grey[300],
      borderRadius: '1.375rem',
    },
    questionIcon: {
      width: '1rem',
      height: '1rem',
      marginRight: theme.spacing(0.5),
    },
    pointQuestionText: {
      color: theme.palette.grey[900],
      fontWeight: 'bold',
      fontSize: '0.75rem',
      lineHeight: '0.75rem',
    },
  });
};

type OwnProps = {
  isOpenModal: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  isOpenModal,
  handleOpenModal,
  handleCloseModal,
}) => (
  <>
    <Button
      onClick={handleOpenModal}
      disableElevation
      variant="contained"
      className={classes.benefitOfPointChargeModalButton}
      data-cy="benefitOfPointChargeModalButton"
      data-gtm-click="shared-benefitOfPointCharge-modalWithButton-openButton"
    >
      <img src={QuestionIcon} alt="question" className={classes.questionIcon} />
      <Typography className={classes.pointQuestionText}>
        ポイントとは？
      </Typography>
    </Button>
    {isOpenModal && (
      <BenefitOfPointChargeModal handleClose={handleCloseModal} />
    )}
  </>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
