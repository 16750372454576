import { types } from './types';
import type { GetPointLogsQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getPointLogsStart = () =>
  ({
    type: types.GET_POINT_LOGS_START,
  }) as const;

export const getPointLogsSuccess = (
  pointLogsResponse: GetPointLogsQuery['pointWallet']['pointLogs'],
) =>
  ({
    type: types.GET_POINT_LOGS_SUCCESS,
    pointLogsResponse,
  }) as const;

export const getPointLogsFailure = (error: ApiError) =>
  ({
    type: types.GET_POINT_LOGS_FAILURE,
    error,
  }) as const;
