import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import '/@/assets/font/font.css';
import clsx from 'clsx';

const styles = (theme: Theme) => {
  return createStyles({
    pointText: {
      fontSize: '0.625rem',
      lineHeight: '1.6',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    pointTextLg: {
      fontSize: '0.875rem',
    },
    pointTextXl: {
      fontSize: '1rem',
    },
    points: {
      fontFamily: 'mamelon',
      fontSize: '1.25rem',
      lineHeight: '1',
      fontWeight: 400,
    },
    pointsLg: {
      fontSize: '1.75rem',
    },
    pointsXl: {
      fontSize: '2rem',
    },
  });
};

type OwnProps = {
  points: number | null | undefined;
  fontSize: 'md' | 'lg' | 'xl';
  isMinus: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  points,
  fontSize,
  isMinus,
}) => (
  <Typography
    className={clsx(
      classes.pointText,
      fontSize === 'lg' && classes.pointTextLg,
      fontSize === 'xl' && classes.pointTextXl,
    )}
  >
    <span
      className={clsx(
        classes.points,
        fontSize === 'lg' && classes.pointsLg,
        fontSize === 'xl' && classes.pointsXl,
      )}
    >
      {typeof points === 'number'
        ? (isMinus ? '-' : '') + points.toLocaleString('ja-JP')
        : '-'}
    </span>
    {' ポイント'}
  </Typography>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
