import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { PointLog } from '/@/components/shared/pointLog/Index';
import { GroupedAvailablePointCharges } from '/@/store/page/pointLogsPage/availablePointChargesPage/selectors';
import { formatToEndDateJa } from '/@/utils/formatDate';

const styles = (theme: Theme) =>
  createStyles({
    groupedLabel: {
      backgroundColor: theme.palette.background.hint,
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(2),
    },
    groupedLabelText: {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
    card: { padding: theme.spacing(2) },
    cardContent: { minWidth: 0 },
    remainingPoint: {
      maxWidth: '100%',
      fontWeight: 'bold',
      overflow: 'hidden',
      lineHeight: '1.5em',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    myGiftExpiredAtSecondary: {
      maxWidth: '100%',
      fontWeight: 'bold',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: theme.palette.secondary.main,
    },
  });

type OwnProps = {
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  groupedAvailablePointChargesByMonth: GroupedAvailablePointCharges;
  getAvailablePointChargesStatus: Status;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  infiniteScrollTarget,
  groupedAvailablePointChargesByMonth,
  getAvailablePointChargesStatus,
}) => (
  <Grid data-cy="availablePointCharges">
    {getAvailablePointChargesStatus == STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <>
        {Object.entries(groupedAvailablePointChargesByMonth).map(
          ([month, availablePointCharges]) => (
            <React.Fragment key={month}>
              <Grid className={classes.groupedLabel}>
                <Typography
                  variant="caption"
                  className={classes.groupedLabelText}
                >
                  {month}
                </Typography>
              </Grid>
              <Divider />
              <Grid>
                {availablePointCharges.map((availablePointCharge, index) => {
                  const title = `${formatToEndDateJa(
                    availablePointCharge.withdrawableUntilAt,
                  )}まで有効`;
                  return (
                    <React.Fragment key={index}>
                      <PointLog
                        title={title}
                        point={availablePointCharge.remainingAmount}
                      />
                      <Divider />
                    </React.Fragment>
                  );
                })}
              </Grid>
            </React.Fragment>
          ),
        )}
      </>
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
