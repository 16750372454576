import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  Button,
  Divider,
} from '@material-ui/core';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { MyGift } from '/@/components/shared/myGift/Index';
import { GroupedAvailableMyGifts } from '/@/store/app/availableMyGiftsConnection/selectors';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';

const styles = (theme: Theme) =>
  createStyles({
    availableGiftsContainer: {
      padding: theme.spacing(0, 2),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.hint,
    },
    groupedLabelText: {
      display: 'inline-block',
      marginTop: theme.spacing(3),
      fontWeight: 'bold',
      lineHeight: '1',
      color: '#4E5B61',
    },
    myGiftCard: {
      marginTop: theme.spacing(2),
      borderRadius: theme.spacing(1.5),
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    },
    availableMyGiftsNotFoundContainer: {
      padding: theme.spacing(2),
    },
    availableMyGiftsNotFoundTextContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    availableMyGiftsNotFoundText: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    promotionsPageButton: {
      fontWeight: 'bold',
      borderRadius: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
    sortGrid: {
      padding: theme.spacing(2),
    },
    sortLabel: {
      paddingLeft: theme.spacing(2),
      color: theme.palette.secondary.dark,
      fontWeight: 'bold',
    },
  });

type OwnProps = {
  groupedAvailableMyGifts: GroupedAvailableMyGifts;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getInitialAvailableMyGiftsConnectionStatus: Status;
  getContinuousAvailableMyGiftsConnectionStatus: Status;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  groupedAvailableMyGifts,
  infiniteScrollTarget,
  getInitialAvailableMyGiftsConnectionStatus,
  getContinuousAvailableMyGiftsConnectionStatus,
}) => (
  <Grid data-cy="availableMyGifts">
    {getInitialAvailableMyGiftsConnectionStatus === STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <>
        {Object.keys(groupedAvailableMyGifts).length ? (
          <div className={classes.availableGiftsContainer}>
            {Object.entries(groupedAvailableMyGifts).map(([date, myGifts]) => (
              <React.Fragment key={date}>
                <Typography
                  variant="caption"
                  className={classes.groupedLabelText}
                >
                  {date}
                </Typography>
                {myGifts.map((myGift) => (
                  <div key={myGift.urlCode} className={classes.myGiftCard}>
                    <MyGift myGift={myGift} />
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        ) : (
          <Grid
            container
            justifyContent="center"
            direction="column"
            className={classes.availableMyGiftsNotFoundContainer}
          >
            <div className={classes.availableMyGiftsNotFoundTextContainer}>
              <Typography
                variant="body2"
                className={classes.availableMyGiftsNotFoundText}
              >
                現在ご利用可能なギフトはありません。
              </Typography>
              <Typography
                variant="body2"
                className={classes.availableMyGiftsNotFoundText}
              >
                以下のページからギフトをもらえます！
              </Typography>
            </div>
            <Divider />
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.promotionsPageButton}
              component={Link}
              to={PATHS.PROMOTIONS}
              data-cy="promotionsPageButton"
            >
              もらえるギフトを確認する
            </Button>
          </Grid>
        )}
      </>
    )}
    {getContinuousAvailableMyGiftsConnectionStatus === STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <></>
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
