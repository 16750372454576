const types = {
  GET_INITIAL_EXPIRING_MY_GIFTS_CONNECTION_START:
    'api/GET_INITIAL_EXPIRING_MY_GIFTS_CONNECTION_START',
  GET_INITIAL_EXPIRING_MY_GIFTS_CONNECTION_SUCCESS:
    'api/GET_INITIAL_EXPIRING_MY_GIFTS_CONNECTION_SUCCESS',
  GET_INITIAL_EXPIRING_MY_GIFTS_CONNECTION_FAILURE:
    'api/GET_INITIAL_EXPIRING_MY_GIFTS_CONNECTION_FAILURE',
} as const;

export { types };
