import { getAvailablePointCharges } from '/@/api/graphql/internalApi/getAvailablePointCharges';
import type { GetAvailablePointChargesResponse } from '/@/api/graphql/internalApi/getAvailablePointCharges';
import type { AppThunk } from '/@/store';
import {
  getAvailablePointChargesStart,
  getAvailablePointChargesSuccess,
  getAvailablePointChargesFailure,
} from './actions';
import { handleErrorThunk } from '../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getAvailablePointChargesThunk =
  (input?: { after?: string }): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getAvailablePointChargesStart());

    return getAvailablePointCharges(input)
      .then((response) =>
        dispatch(getAvailablePointChargesSuccessThunk(response)),
      )
      .catch((error) => dispatch(getAvailablePointChargesFailureThunk(error)));
  };

const getAvailablePointChargesSuccessThunk =
  (response: GetAvailablePointChargesResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { availablePointCharges } = response.data!.pointWallet;
    dispatch(getAvailablePointChargesSuccess(availablePointCharges));
    return Promise.resolve();
  };

const getAvailablePointChargesFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getAvailablePointChargesFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getAvailablePointChargesThunk };
