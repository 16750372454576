import { getBrandsConnection } from '/@/api/graphql/internalApi/getBrandsConnection/index';
import type { GetBrandsConnectionResponse } from '/@/api/graphql/internalApi/getBrandsConnection/index';
import type { AppThunk } from '/@/store';
import {
  getNonRecommendedBrandsConnectionStart,
  getNonRecommendedBrandsConnectionSuccess,
  getNonRecommendedBrandsConnectionFailure,
} from './actions';
import type { GetBrandsConnectionQueryVariables } from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getNonRecommendedBrandsConnectionThunk =
  (variables: GetBrandsConnectionQueryVariables): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getNonRecommendedBrandsConnectionStart());

    const nonRecommendedVariables = {
      ...variables,
      recommendEq: false,
    };

    return getBrandsConnection(nonRecommendedVariables)
      .then((response) =>
        dispatch(getNonRecommendedBrandsConnectionSuccessThunk(response)),
      )
      .catch((error) =>
        dispatch(getNonRecommendedBrandsConnectionFailureThunk(error)),
      );
  };

const getNonRecommendedBrandsConnectionSuccessThunk =
  (response: GetBrandsConnectionResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { brands } = response.data!;
    dispatch(getNonRecommendedBrandsConnectionSuccess(brands));
    return Promise.resolve();
  };

const getNonRecommendedBrandsConnectionFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getNonRecommendedBrandsConnectionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getNonRecommendedBrandsConnectionThunk };
