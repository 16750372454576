import type { AppThunk } from '/@/store';
import { ApolloError } from '@apollo/client';
import { updatePageError } from './actions';
import { ApolloClientErrors } from './constants';
import * as Sentry from '@sentry/browser';

export const handleApolloErrorThunk =
  (error: ApolloError): AppThunk<Promise<void>> =>
  (dispatch) => {
    if (error.networkError) {
      // apollo client の error.networkError の型に、statusCode がないため、ignore してます
      // ref: https://github.com/apollographql/apollo-client/blob/3d48f9fc7d07d8170c5b906b6e93bf0400aa2698/src/errors/ApolloError.ts#L39
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (error.networkError.statusCode === 401) {
        dispatch(
          updatePageError(
            ApolloClientErrors.AUTHENTICATION_ERROR.message,
            ApolloClientErrors.AUTHENTICATION_ERROR.status,
          ),
        );
      } else {
        dispatch(
          updatePageError(
            ApolloClientErrors.NETWORK_ERROR.message,
            ApolloClientErrors.NETWORK_ERROR.status,
          ),
        );
      }
    } else if (error.clientErrors.length) {
      dispatch(
        updatePageError(
          ApolloClientErrors.CLIENT_ERROR.message,
          ApolloClientErrors.CLIENT_ERROR.status,
        ),
      );
    } else if (
      error.graphQLErrors.some(
        (graphQLError) => graphQLError.extensions?.status === 404,
      )
    ) {
      dispatch(
        updatePageError(
          ApolloClientErrors.NOT_FOUND_ERROR.message,
          ApolloClientErrors.NOT_FOUND_ERROR.status,
        ),
      );
    } else if (
      error.graphQLErrors.some((graphQLError) => !graphQLError.extensions)
    ) {
      dispatch(
        updatePageError(
          ApolloClientErrors.UNEXPECTED_ERROR.message,
          ApolloClientErrors.UNEXPECTED_ERROR.status,
        ),
      );
      Sentry.captureException(error);
    } else {
      error.graphQLErrors.forEach((graphQLError, i) => {
        if (typeof graphQLError.extensions.errorId === 'string') {
          Sentry.setTag(`error_id_${i}`, graphQLError.extensions.errorId);
        }
      });
      Sentry.captureException(error);
      dispatch(
        updatePageError(
          ApolloClientErrors.UNEXPECTED_ERROR.message,
          ApolloClientErrors.UNEXPECTED_ERROR.status,
        ),
      );
    }
    return Promise.reject(error);
  };
