import { gql } from '@apollo/client';

export const getOauthSessionGql = gql`
  query GetOauthSession($state: String!) {
    oauthSession(state: $state) {
      giftSavingOption {
        url
        origin
        referer
        convertToPoint
      }
      destinationOption {
        name
      }
      pointSerialOption {
        serialCode
      }
    }
  }
`;
