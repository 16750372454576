import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { App } from '/@/components/AppContainer';
import { store } from '/@/store';
import { theme } from '/@/utils/theme';
import { initSentry } from '/@/utils/initSentry';

if (import.meta.env.PROD) {
  initSentry();
}
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  //  strict mode が有効だとコンポーネントの mount & unmount を 2回やるようになるのでコメントアウトする
  // ref: https://reactjs.org/blog/2022/03/29/react-v18.html#new-strict-mode-behaviors
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
  // </React.StrictMode>
);
