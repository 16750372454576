import { gql } from '@apollo/client';

export const FRAGMENT_FIXED_GIFT_CARD_TICKET = gql`
  fragment FixedGiftCardTicketFields on FixedGiftCardTicket {
    name
    imageUrl
    expiredAt
    url
  }
`;
