import React from 'react';
import { Presenter } from './Presenter';

type OwnProps = {
  children: React.ReactNode;
  handleClose: () => void;
};

const Container: React.VFC<OwnProps> = ({ children, handleClose }) => {
  return <Presenter handleClose={handleClose}>{children}</Presenter>;
};
export { Container as Modal };
