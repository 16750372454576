import { EXCHAGE_ERROR_PATTERN, ExchangeErrorPatternType } from './constants';
import { AlertMessageState } from './reducers';
import { types } from './types';

export const initialiseAlertMessage = () =>
  ({
    type: types.INITIALISE_ALERT_MESSAGE,
  }) as const;

export const setAlertMessage = (alertMessage: AlertMessageState) =>
  ({
    type: types.SET_ALERT_MESSAGE,
    alertMessage,
  }) as const;

export const setExchangeAlertMessage = (errorCode: ExchangeErrorPatternType) =>
  ({
    type: types.SET_EXCHANGE_ALERT_MESSAGE,
    errorPattern: EXCHAGE_ERROR_PATTERN[errorCode],
  }) as const;
