import { types } from './types';
import type { GetCurrentUserQuery } from '/@/api/graphql/internalApi/types';
import { ApiError } from '/@/api/graphql/client';

export const getCurrentUserStart = () =>
  ({
    type: types.GET_CURRENT_USER_START,
  }) as const;

export const getCurrentUserSuccess = (
  currentUser: GetCurrentUserQuery['currentUser'],
) =>
  ({
    type: types.GET_CURRENT_USER_SUCCESS,
    currentUser,
  }) as const;

export const getCurrentUserFailure = (error: ApiError) =>
  ({
    type: types.GET_CURRENT_USER_FAILURE,
    error,
  }) as const;
