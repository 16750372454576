import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Card,
} from '@material-ui/core';
import {
  MyGiftUsageStatus,
  MY_GIFT_USAGE_STATUS,
} from '/@/utils/calcMyGiftUsageStatus';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = (theme: Theme) => {
  const MY_GIFT_IMAGE_SIZE = 64;
  return createStyles({
    card: {
      padding: theme.spacing(2),
      boxShadow: 'none',
    },
    cardContent: { minWidth: 0 },
    pointChargeableText: {
      minWidth: '8.125rem',
      fontSize: '0.625rem',
      lineHeight: '0.625rem',
      fontWeight: 'bold',
      color: theme.palette.success.main,
      marginBottom: theme.spacing(1),
    },
    myGiftImage: {
      marginRight: theme.spacing(1.5),
      height: MY_GIFT_IMAGE_SIZE,
      width: MY_GIFT_IMAGE_SIZE,
      borderRadius: theme.spacing(),
      border: `1px solid ${theme.palette.grey[300]}`,
      objectFit: 'contain',
    },
    myGiftName: {
      maxWidth: '100%',
      fontWeight: 'bold',
      overflow: 'hidden',
      fontSize: '0.875rem',
      lineHeight: '1.3',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    myGiftExpiredAtSecondary: {
      marginTop: theme.spacing(1),
      maxWidth: '100%',
      fontWeight: 'bold',
      fontSize: '0.625rem',
      lineHeight: '1',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: theme.palette.secondary.main,
    },
    myGiftExpiredAtWarning: {
      maxWidth: '100%',
      fontWeight: 'bold',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: theme.palette.warning.main,
    },
    expiredCheckCircleIcon: {
      marginRight: theme.spacing(0.5),
      width: '14px',
      color: theme.palette.warning.main,
    },
    discardedCheckCircleIcon: {
      marginRight: theme.spacing(0.5),
      width: '14px',
      color: theme.palette.primary.main,
    },
  });
};

type OwnProps = {
  name: string;
  imageUrl: string;
  expirationText: string;
  usageStatus: MyGiftUsageStatus;
  pointMergeable: boolean;
  handleClick: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  name,
  imageUrl,
  expirationText,
  usageStatus,
  pointMergeable,
  handleClick,
}) => (
  <Card
    onClick={handleClick}
    className={classes.card}
    data-cy="myGiftSkelton"
    data-gtm-click="shared-myGift-myGiftSkelton-card"
  >
    <Grid container wrap="nowrap" direction="row">
      <img className={classes.myGiftImage} src={imageUrl} alt={name} />
      <Grid
        container
        justifyContent="center"
        direction="column"
        className={classes.cardContent}
      >
        {pointMergeable && (
          <Typography className={classes.pointChargeableText}>
            ポイントに移行可能なギフト
          </Typography>
        )}
        <Typography variant="subtitle1" className={classes.myGiftName}>
          {name}
        </Typography>
        <Grid container alignItems="center">
          {usageStatus === MY_GIFT_USAGE_STATUS.DISCARDED ? (
            <CheckCircleIcon
              classes={{
                root: classes.discardedCheckCircleIcon,
              }}
              data-cy="discardedCheckCircleIcon"
            />
          ) : usageStatus === MY_GIFT_USAGE_STATUS.EXPIRED ? (
            <CheckCircleIcon
              classes={{
                root: classes.expiredCheckCircleIcon,
              }}
              data-cy="expiredCheckCircleIcon"
            />
          ) : null}
          <Typography
            variant="caption"
            className={
              usageStatus === MY_GIFT_USAGE_STATUS.EXPIRING
                ? classes.myGiftExpiredAtWarning
                : classes.myGiftExpiredAtSecondary
            }
            data-cy="expirationText"
          >
            {expirationText}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Card>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
