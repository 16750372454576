import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Grid,
  Container,
  Typography,
} from '@material-ui/core';
import { HeroSection } from './heroSection/Index';
import { AboutSection } from './aboutSection/Index';
import { BenefitsOfConvertSection } from './benefitsOfConvertSection/Index';
import { HowToUseSection } from './howToUseSection/Index';
import {
  LoginButton,
  LoginOption,
} from '/@/components/shared/loginButton/Index';
import { Footer } from '/@/components/templates/footer/Index';
import { CautionText } from '../../shared/cautionText/Index';
import { PATHS } from '/@/routes/paths';
import { CampaignSection } from './campaignSection/Index';
import backgroundImage from '/@/assets/signin-hero-gift-wallet-background.png';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      padding: 0,
    },
    heroSection: {
      width: '100%',
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    campaignSection: {
      width: '100%',
      backgroundColor: '#E0F2F7',
    },
    usageSectionContainer: {
      width: '100%',
      backgroundColor: theme.palette.background.hint,
    },
    howToUseSectionContainer: {
      width: '100%',
    },
    loginContainer: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%',
      backgroundColor: theme.palette.background.contrast,
    },
    convertToPointNote: {
      color: '#4E5B61',
      display: 'inline-block',
      width: '100%',
      marginTop: theme.spacing(2),
      textAlign: 'center',
      fontSize: '0.75rem',
    },
    cautionTextContainer: {
      marginTop: theme.spacing(2),
    },
  });
};

type OwnProps = LoginOption;

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  url,
  origin,
  referer,
  convertToPoint,
  destination,
}) => (
  <Grid container direction="column" alignContent="center">
    <section className={classes.heroSection}>
      <Container maxWidth="sm" className={classes.container}>
        <HeroSection
          url={url}
          origin={origin}
          referer={referer}
          convertToPoint={convertToPoint}
          destination={destination}
        />
      </Container>
    </section>
    <section>
      <Container maxWidth="sm" className={classes.container}>
        <AboutSection />
      </Container>
    </section>
    <section className={classes.campaignSection}>
      <Container maxWidth="sm" className={classes.container}>
        <CampaignSection
          url={url}
          origin={origin}
          referer={referer}
          convertToPoint={convertToPoint}
          destination={destination}
        />
      </Container>
    </section>
    <section className={classes.usageSectionContainer}>
      <Container maxWidth="sm" className={classes.container}>
        <BenefitsOfConvertSection />
      </Container>
    </section>
    <section className={classes.howToUseSectionContainer}>
      <Container maxWidth="sm" className={classes.container}>
        <HowToUseSection />
      </Container>
    </section>
    <section className={classes.loginContainer}>
      <Container maxWidth="sm" className={classes.container}>
        <LoginButton
          url={url}
          origin={origin}
          referer={referer}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={null}
          flow={PATHS.LP_CONVERT_TO_POINT}
        >
          ギフトをポイントに移行する
        </LoginButton>
        <Typography variant="caption" className={classes.convertToPointNote}>
          ※ポイントは残高全てが移行されます。ご注意ください。
        </Typography>
        <div className={classes.cautionTextContainer}>
          <CautionText />
        </div>
      </Container>
    </section>
    <Footer />
  </Grid>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
