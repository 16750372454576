import { gql } from '@apollo/client';

export const FRAGMENT_POINT_EXCHANGE = gql`
  fragment PointExchangeFields on PointExchange {
    item {
      uid
      contentName
      brand {
        uid
        name
      }
    }
    myGifts {
      contentName
      expiredAt
      giftUrl
      imageUrl
    }
    pointAmount
  }
`;
