import { FetchResult } from '@apollo/client';
import { publicApiClient } from '/@/api/graphql/appApolloClient';
import { getSavingGifteeContentGql } from './index.gql';
import {
  GetGifteeContentQuery,
  GetGifteeContentQueryVariables,
} from '../types';

type GetSavingGifteeContentResponse = FetchResult<GetGifteeContentQuery>;

const getSavingGifteeContent = (
  variables: GetGifteeContentQueryVariables,
): Promise<GetSavingGifteeContentResponse> =>
  publicApiClient.query({
    query: getSavingGifteeContentGql,
    variables,
  });

export { getSavingGifteeContent };

export type { GetSavingGifteeContentResponse };
