import { Reducer } from 'redux';
import type { RootAction } from '/@/store/actions';
import { types } from './types';
import {
  POINT_CHARGE_WITH_SERIAL_CODE_ERROR_PATTERN,
  isPointChargeWithSerialCodeErrorPatternTypeMatch,
} from './constants';
import * as Sentry from '@sentry/browser';

type PointChargeWithSerialCodeErrorState =
  PointChargeWithSerialCodeError | null;

type PointChargeWithSerialCodeError = {
  errorMessage: string;
};

const pointChargeWithSerialCodeError: Reducer<
  PointChargeWithSerialCodeErrorState,
  RootAction
> = (state = null, action) => {
  switch (action.type) {
    case types.SET_POINT_CHARGE_WITH_SERIAL_CODE_ERROR: {
      if (isPointChargeWithSerialCodeErrorPatternTypeMatch(action.errorCode)) {
        return {
          errorMessage:
            POINT_CHARGE_WITH_SERIAL_CODE_ERROR_PATTERN[action.errorCode]
              .message,
        };
      } else {
        Sentry.captureException(
          `point charge with serialcode Error: ${action.errorCode}`,
        );
        return {
          errorMessage: 'エラーが発生しました。',
        };
      }
    }
    default:
      return state;
  }
};

export { pointChargeWithSerialCodeError };
export type { PointChargeWithSerialCodeErrorState };
