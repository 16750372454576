import React from 'react';
import { WithStyles, withStyles, createStyles, Grid } from '@material-ui/core';
import { Header } from '/@/components/templates/header/Index';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { Footer } from '/@/components/templates/footer/Index';
import { AlertMessage } from '/@/components/shared/alertMessage/Index';

const styles = () => {
  return createStyles({
    layoutContainer: {
      minHeight: '100vh',
    },
    childrenContainer: {
      flex: 1,
      maxWidth: '100%',
    },
    headerContainer: {
      marginTop: HEADER_HEIGHT,
    },
  });
};

type OwnProps = {
  children: React.ReactNode;
  headerTitle?: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, children, headerTitle }) => (
  <Grid
    container
    direction="column"
    className={classes.layoutContainer}
    data-cy="mainLayout"
  >
    <div className={classes.headerContainer}>
      <Header
        isMenuVisible={true}
        isLogoClickable={true}
        headerTitle={headerTitle}
      />
    </div>
    <div className={classes.childrenContainer}>
      <AlertMessage />
      {children}
    </div>
    <Footer />
  </Grid>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
