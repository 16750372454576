import { types } from './types';

export const initialiseDisplayOnlyExchangeableBrands = () =>
  ({
    type: types.INITIALISE_DISPLAY_ONLY_EXCHANGEABLE_BRANDS,
  }) as const;

export const switchDisplayOnlyExchangeableBrands = () =>
  ({
    type: types.SWITCH_DISPLAY_ONLY_EXCHANGEABLE_BRANDS,
  }) as const;
