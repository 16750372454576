import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Typography,
  Theme,
  Grid,
} from '@material-ui/core';
import highReturnBrandsImage from '/@/assets/signin-campaign-gift-wallet-high-return-brands.png';
import exchangeableBrandsImage from '/@/assets/signin-campaign-gift-wallet-exchangeable-brands.png';
import { LoginButton } from '/@/components/shared/loginButton/Index';
import { PATHS } from '/@/routes/paths';
import { DestinationEnum, OriginEnum } from '/@/api/graphql/publicApi/types';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      width: '100%',
      padding: theme.spacing(4, 3),
    },
    subTitle: {
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: '1',
      marginBottom: theme.spacing(0.5),
      textAlign: 'center',
    },
    mainTitle: {
      color: '#003F65',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: '1.3',
      textAlign: 'center',
    },
    mainText: {
      fontSize: '0.875rem',
      lineHeight: '1.4',
      textAlign: 'left',
      marginTop: theme.spacing(3),
    },
    listTitle: {
      fontSize: '0.625rem',
      fontWeight: 'bold',
      lineHeight: '1',
      color: '#4E5B61',
      marginTop: theme.spacing(3),
    },
    highReturnBrandsImage: {
      display: 'block',
      margin: 'auto',
      width: '100%',
      marginTop: theme.spacing(1.5),
    },
    exchangeableBrandsImage: {
      display: 'block',
      margin: 'auto',
      width: '100%',
      marginTop: theme.spacing(1.5),
    },
    loginButton: {
      marginTop: theme.spacing(2),
    },
    convertToPointNote: {
      color: '#4E5B61',
      display: 'inline-block',
      width: '100%',
      marginTop: theme.spacing(2),
      textAlign: 'center',
      fontSize: '0.75rem',
    },
  });
};

type OwnProps = {
  url: string | null;
  origin: OriginEnum | null;
  referer: string | null;
  convertToPoint: boolean | null;
  destination: DestinationEnum | null;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  url,
  origin,
  referer,
  convertToPoint,
  destination,
}) => (
  <div data-cy="signInPageCampaignSection" className={classes.container}>
    <Grid container direction="column" alignContent="center">
      <Grid item xs>
        <Typography variant="h3" className={classes.subTitle}>
          いまなら
        </Typography>
        <Typography variant="h1" className={classes.mainTitle}>
          最大10%のポイント還元
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="body1" className={classes.mainText}>
          gift wallet
          でギフトを交換すると、その場で還元されるおトクなギフトも多数。
          <br />
          今なら最大10%還元となるギフトも！
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="body1" className={classes.listTitle}>
          高還元ブランド例
        </Typography>
        <img
          src={highReturnBrandsImage}
          alt="highReturnBrandsImage"
          className={classes.highReturnBrandsImage}
        />
      </Grid>
      <Grid item xs>
        <Typography variant="body1" className={classes.listTitle}>
          その他交換可能なCPも多数！
        </Typography>
        <img
          src={exchangeableBrandsImage}
          alt="exchangeableBrandsImage"
          className={classes.exchangeableBrandsImage}
        />
      </Grid>
      <Grid item xs>
        <div className={classes.loginButton}>
          <LoginButton
            url={url}
            origin={origin}
            referer={referer}
            convertToPoint={convertToPoint}
            destination={destination}
            serialCode={null}
            flow={PATHS.LP_CONVERT_TO_POINT}
          >
            ギフトをポイントに移行する
          </LoginButton>
          <Typography variant="caption" className={classes.convertToPointNote}>
            ※ポイントは残高全てが移行されます。ご注意ください。
          </Typography>
        </div>
      </Grid>
    </Grid>
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
