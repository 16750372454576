import React from 'react';
import { Presenter } from './Presenter';
import { useDataLayer } from '/@/common/hooks/useDataLayer';

type OwnProps = {
  isLogoClickable: boolean;
  isMenuVisible: boolean;
  isMenuOpened: boolean;
  handleToggleMenu: () => void;
};

const Container: React.VFC<OwnProps> = ({
  isLogoClickable,
  isMenuVisible,
  isMenuOpened,
  handleToggleMenu,
}) => {
  const { pushClickEvent } = useDataLayer();

  const handleClickGlobalMenu = () => {
    pushClickEvent({
      clickItem: 'clickGlobalMenu',
      customData: {
        clickItem: 'clickGlobalMenu',
      },
    });
  };

  return (
    <Presenter
      isLogoClickable={isLogoClickable}
      isMenuVisible={isMenuVisible}
      isMenuOpened={isMenuOpened}
      handleToggleMenu={handleToggleMenu}
      handleClickGlobalMenu={handleClickGlobalMenu}
    />
  );
};

export { Container as GlobalHeader };
