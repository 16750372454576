import { Reducer } from 'redux';
import {
  GetBrandsConnectionQuery,
  BrandConnection,
} from '/@/api/graphql/internalApi/types';
import { getNonRecommendedBrandsConnectionTypes } from '/@/store/api/getNonRecommendedBrandsConnection/index';
import { BrandsConnectionBase } from '/@/store/app/shared/types';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';

type NonRecommendedBrandsState = NonRecommendedBrand[] | null;
type PageInfoState = BrandConnection['pageInfo'] | null;
type NonRecommendedBrandsConnectionState = {
  brands: NonRecommendedBrandsState;
  pageInfo: PageInfoState;
};
type NonRecommendedBrand = BrandsConnectionBase;

const initialState: NonRecommendedBrandsState = null;

const brands: Reducer<NonRecommendedBrandsState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getNonRecommendedBrandsConnectionTypes.GET_NONRECOMMENDED_BRANDS_CONNECTION_SUCCESS: {
      if (!action.brandsConnection?.nodes) return null;
      const filteredNodes = filterNodes(action.brandsConnection.nodes);

      if (state === null) {
        return filteredNodes;
      } else {
        const uniqueBrands = Array.from(
          new Map(
            [...state, ...filteredNodes].map((brand) => [brand.uid, brand]),
          ).values(),
        );
        return uniqueBrands;
      }
    }
    default:
      return state;
  }
};

const filterNodes = (
  nonRecommendedBrandsConnectionNodes: NonNullable<
    GetBrandsConnectionQuery['brands']['nodes']
  >,
): NonNullable<NonRecommendedBrandsState> =>
  nonRecommendedBrandsConnectionNodes.filter(
    (node) => !!node,
  ) as NonRecommendedBrand[];

const pageInfoInitialState: PageInfoState = null;

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getNonRecommendedBrandsConnectionTypes.GET_NONRECOMMENDED_BRANDS_CONNECTION_SUCCESS:
      return action.brandsConnection.pageInfo;
    default:
      return state;
  }
};

const nonRecommendedBrandsConnection = combineReducers({
  brands,
  pageInfo,
});

export { nonRecommendedBrandsConnection };
export type {
  NonRecommendedBrandsConnectionState,
  NonRecommendedBrand,
  NonRecommendedBrandsState,
};
