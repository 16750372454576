import React, { createContext, useContext } from 'react';
import { useDataLayer } from '/@/common/hooks/useDataLayer';

const DataLayerContext = createContext({});

export const DataLayerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dataLayerHook = useDataLayer();

  return (
    <DataLayerContext.Provider value={dataLayerHook}>
      {children}
    </DataLayerContext.Provider>
  );
};

export const useDataLayerContext = () => {
  return useContext(DataLayerContext);
};
