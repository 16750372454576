import { combineReducers } from 'redux';
import {
  inquiriesNewPage,
  inquiriesNewPageActions,
} from './inquiriesPage/newPage';
import { signInPage } from './signInPage';
import { oauthPage } from './oauthPage/';
import { pointLogsPage } from './pointLogsPage';
import {
  userProfileNewPage,
  userProfileNewPageActions,
} from './userPage/profilePage/newPage/';
import { myGiftsEditPageActions } from './giftsPage/editPage';

export const pageReducer = combineReducers({
  inquiriesNewPage,
  signInPage,
  oauthPage,
  pointLogsPage,
  userProfileNewPage,
});

export const pageActions = {
  ...inquiriesNewPageActions,
  ...userProfileNewPageActions,
  ...myGiftsEditPageActions,
};
