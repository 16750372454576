import { RootState } from '/@/store';
import { Status, STATUS } from '/@/store/api/constants';
import { createSelector } from 'reselect';

export const selectDiscardMyGiftsStatus = (state: RootState): Status => {
  return state.api.discardMyGifts.status;
};

export const selectIsDiscardMyGiftsLoading = createSelector(
  [selectDiscardMyGiftsStatus],
  (discardMyGiftsStatus): boolean => {
    return discardMyGiftsStatus === STATUS.LOADING;
  },
);

export const selectIsDiscardMyGiftsSuccess = createSelector(
  [selectDiscardMyGiftsStatus],
  (discardMyGiftsStatus): boolean => {
    return discardMyGiftsStatus === STATUS.SUCCESS;
  },
);
