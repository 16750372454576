const types = {
  INITIALISE_GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_STATUS:
    'api/INITIALISE_GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_STATUS',
  GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_START:
    'api/GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_START',
  GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_SUCCESS:
    'api/GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_SUCCESS',
  GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_FAILURE:
    'api/GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_FAILURE',
} as const;

export { types };
