import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import list from '/@/assets/signin-feature-list.svg';
import gift from '/@/assets/signin-feature-gift.svg';
import line from '/@/assets/signin-feature-line.svg';

const styles = (theme: Theme) => {
  return createStyles({
    sectionTitle: {
      fontWeight: 'bold',
      color: theme.palette.gifteeCoral.main,
    },
    sectionSubTitle: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&::before': {
        borderTop: '3px solid',
        borderColor: theme.palette.primary.light,
        content: '""',
        width: '12px',
        marginRight: theme.spacing(1),
      },
      '&::after': {
        borderTop: '3px solid',
        borderColor: theme.palette.primary.light,
        content: '""',
        width: '12px',
        marginLeft: theme.spacing(1),
      },
    },
    feature: {
      marginTop: theme.spacing(3),
    },
    listImageContainer: {
      marginLeft: 'auto',
      textAlign: 'right',
    },
    giftImageContainer: {
      marginRight: 'auto',
      textAlign: 'left',
    },
    lineImageContainer: {
      marginLeft: 'auto',
      textAlign: 'right',
    },
    listImage: {
      width: '80%',
    },
    giftImage: {
      width: '80%',
    },
    lineImage: {
      width: '80%',
    },
    featureText: {
      margin: theme.spacing(2),
      textAlign: 'center',
    },
    featureTitle: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    featureDescription: {
      display: 'inline-block',
      textAlign: 'left',
      fontWeight: 'normal',
      color: theme.palette.secondary.main,
    },
  });
};

type OwnProps = Record<string, never>;

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes }) => (
  <div data-cy="signInPageFeatureSection">
    <Grid container direction="row" justifyContent="center">
      <Grid item xs={12}>
        <Typography
          align="center"
          variant="h5"
          className={classes.sectionTitle}
        >
          FEATURE
        </Typography>
        <Typography
          align="center"
          variant="body2"
          className={classes.sectionSubTitle}
        >
          gift wallet でできること
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.feature}>
        <div className={classes.listImageContainer}>
          <img src={list} alt="list" className={classes.listImage} />
        </div>
        <div className={classes.featureText}>
          <Typography variant="body1" className={classes.featureTitle}>
            もらったギフトを一覧で管理できる
          </Typography>
          <Typography variant="caption" className={classes.featureDescription}>
            「マイギフト」ページから確認できるので、ギフトの期限切れ、使い忘れを減らせます。
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} className={classes.feature}>
        <div className={classes.giftImageContainer}>
          <img src={gift} alt="gift" className={classes.giftImage} />
        </div>
        <div className={classes.featureText}>
          <Typography variant="body1" className={classes.featureTitle}>
            保存したチケットをすぐに表示できる
          </Typography>
          <Typography variant="caption" className={classes.featureDescription}>
            gift walletのLINEアカウントを開いて、チケットをタップするだけ。
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} className={classes.feature}>
        <div className={classes.lineImageContainer}>
          <img src={line} alt="line" className={classes.lineImage} />
        </div>
        <div className={classes.featureText}>
          <Typography variant="body1" className={classes.featureTitle}>
            ギフトはLINEから確認できる
          </Typography>
          <Typography variant="caption" className={classes.featureDescription}>
            ギフトがgiftee walletに保存されると、LINEアカウントに通知されます。
          </Typography>
        </div>
      </Grid>
    </Grid>
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
