import { types } from './types';
import type { GetAvailablePointChargesQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getAvailablePointChargesStart = () =>
  ({
    type: types.GET_AVAILABLE_POINT_CHARGES_START,
  }) as const;

export const getAvailablePointChargesSuccess = (
  availablePointChargesResponse: GetAvailablePointChargesQuery['pointWallet']['availablePointCharges'],
) =>
  ({
    type: types.GET_AVAILABLE_POINT_CHARGES_SUCCESS,
    availablePointChargesResponse,
  }) as const;

export const getAvailablePointChargesFailure = (error: ApiError) =>
  ({
    type: types.GET_AVAILABLE_POINT_CHARGES_FAILURE,
    error,
  }) as const;
