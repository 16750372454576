import React from 'react';
import { Presenter } from './Presenter';
import { useAppSelector } from '/@/store';
import { selectIsGiftsEditPageButtonDisabled } from '/@/store/app/availableMyGiftsConnection/selectors';

const Container: React.VFC<Record<string, never>> = () => {
  const isGiftsEditPageButtonDisabled = useAppSelector(
    selectIsGiftsEditPageButtonDisabled,
  );
  return (
    <Presenter isGiftsEditPageButtonDisabled={isGiftsEditPageButtonDisabled} />
  );
};

export { Container as GiftsPage };
