import React from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/core';
import { GiftCardTicketBase } from '/@/store/app/shared/types';
import { FixedGiftCardTicket } from './fixedGiftCardTicket/Index';
import { ChoosableGiftCardTicket } from './choosableGiftCardTicket/Index';
import { typeGuards } from '/@/utils/typeGuards';

const styles = () => {
  return createStyles({});
};

export type GiftCardTicket = GiftCardTicketBase;

type OwnProps = {
  giftCardTicket: GiftCardTicket;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ giftCardTicket }) => {
  if (typeGuards.app.isFixedGiftCardTicket(giftCardTicket))
    return (
      <div data-cy={`giftCardTicket-${giftCardTicket.ticket.url}`}>
        <FixedGiftCardTicket fixedGiftCardTicket={giftCardTicket.ticket} />
      </div>
    );
  if (typeGuards.app.isChoosableGiftCardTicket(giftCardTicket))
    return (
      <div data-cy={`giftCardTicket-${giftCardTicket.ticket.url}`}>
        <ChoosableGiftCardTicket
          choosableGiftCardTicket={giftCardTicket.ticket}
        />
      </div>
    );
  return <></>;
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
