import { types } from './types';
import type { SaveGifteeContentMutation } from '/@/api/graphql/internalApi/types';
import { ApiError } from '/@/api/graphql/client';

export const saveGifteeContentStart = () =>
  ({
    type: types.SAVE_GIFTEE_CONTENT_START,
  }) as const;

export const saveGifteeContentSuccess = (
  gifteeContent: NonNullable<
    SaveGifteeContentMutation['saveGifteeContent']['gifteeContent']
  >,
) =>
  ({
    type: types.SAVE_GIFTEE_CONTENT_SUCCESS,
    gifteeContent,
  }) as const;

export const saveGifteeContentFailure = (error: ApiError) =>
  ({
    type: types.SAVE_GIFTEE_CONTENT_FAILURE,
    error,
  }) as const;
