import React, { useEffect } from 'react';
import { Presenter } from './Presenter';
import { pointUpdatedSubscriptionThunk } from '/@/store/api/pointUpdatedSubscription';
import { useAppDispatch } from '/@/store';

const Container: React.VFC<{
  children: React.ReactNode;
  headerTitle?: string;
}> = ({ children, headerTitle }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(pointUpdatedSubscriptionThunk());
  }, [dispatch]);

  return <Presenter headerTitle={headerTitle}>{children}</Presenter>;
};

export { Container as MainLayout };
