import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
} from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    heading: {
      padding: theme.spacing(3),
    },
    headingTitle: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    headingSubtitle: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      padding: theme.spacing(1),
    },
  });
};

type OwnProps = {
  title: string;
  subTitle: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, title, subTitle }) => (
  <Grid className={classes.heading}>
    <Typography align="center" variant="h4" className={classes.headingTitle}>
      {title}
    </Typography>
    <Typography
      align="center"
      variant="subtitle1"
      className={classes.headingSubtitle}
    >
      {subTitle}
    </Typography>
  </Grid>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
