import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getInitialAvailableMyGiftsConnectionThunk } from '/@/store/api/getInitialAvailableMyGiftsConnection';
import { getContinuousAvailableMyGiftsConnectionThunk } from '/@/store/api/getContinuousAvailableMyGiftsConnection';
import { Presenter } from './Presenter';
import { useIntersectionObserver } from '/@/common/hooks/useIntersectionObserver';
import { STATUS } from '/@/store/api/constants';
import { selectChangedAtGroupedAvailableMyGifts } from '/@/store/app/availableMyGiftsConnection/selectors';
import { MyGiftBase } from '/@/store/app/shared/types';
import { myGiftsEditPageActions } from '/@/store/page/giftsPage/editPage';

const Container: React.VFC<Record<string, never>> = () => {
  const groupedAvailableMyGifts = useAppSelector(
    selectChangedAtGroupedAvailableMyGifts,
  );
  const availableMyGiftsConnectionPageInfo = useAppSelector(
    (state) => state.app.availableMyGiftsConnection.pageInfo,
  );
  const getContinuousAvailableMyGiftsConnectionStatus = useAppSelector(
    (state) => state.api.getContinuousAvailableMyGiftsConnection.status,
  );
  const getInitialAvailableMyGiftsConnectionStatus = useAppSelector(
    (state) => state.api.getInitialAvailableMyGiftsConnection.status,
  );
  const selectedMyGifts = useAppSelector((state) => state.app.selectedMyGifts);

  const handleEditingMyGift = (myGift: MyGiftBase, checked: boolean) => {
    if (checked) {
      dispatch(myGiftsEditPageActions.addEditingMyGift(myGift));
    } else {
      dispatch(myGiftsEditPageActions.removeEditingMyGift(myGift));
    }
  };
  const isSelectedMyGift = (myGift: MyGiftBase) =>
    selectedMyGifts.some(
      (selectedMyGift) => selectedMyGift.urlCode === myGift.urlCode,
    );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getInitialAvailableMyGiftsConnectionThunk()).catch(() => {});
  }, [dispatch]);

  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);

  useEffect(() => {
    startObserving(true);

    return () => {
      startObserving(false);
    };
  }, [startObserving]);

  useEffect(() => {
    if (
      isIntersect &&
      getContinuousAvailableMyGiftsConnectionStatus !== STATUS.LOADING &&
      availableMyGiftsConnectionPageInfo?.hasNextPage
    ) {
      dispatch(
        getContinuousAvailableMyGiftsConnectionThunk(
          availableMyGiftsConnectionPageInfo.endCursor,
        ),
      ).catch(() => {});
    }
  }, [
    dispatch,
    isIntersect,
    getContinuousAvailableMyGiftsConnectionStatus,
    groupedAvailableMyGifts,
    availableMyGiftsConnectionPageInfo,
  ]);

  return (
    <Presenter
      groupedAvailableMyGifts={groupedAvailableMyGifts}
      infiniteScrollTarget={infiniteScrollTarget}
      getInitialAvailableMyGiftsConnectionStatus={
        getInitialAvailableMyGiftsConnectionStatus
      }
      getContinuousAvailableMyGiftsConnectionStatus={
        getContinuousAvailableMyGiftsConnectionStatus
      }
      handleEditingMyGift={handleEditingMyGift}
      isSelectedMyGift={isSelectedMyGift}
    />
  );
};

export { Container as AvailableMyGifts };
