import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import { LoadingButton } from '/@/components/shared/button/loadingButton/Index';
import { Modal } from '/@/components/shared/modal/Index';
import { Points } from '../../points/Index';

const styles = (theme: Theme) => {
  return createStyles({
    message: {
      marginBottom: theme.spacing(3),
    },
    messageText: {
      fontWeight: 'bold',
      fontSize: '1rem',
      lineHeight: '1.4rem',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    contents: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    pointSection: {
      '& > :not(:last-child)': {
        marginBottom: theme.spacing(1.5),
      },
      '& > :last-child': {
        marginBottom: theme.spacing(3),
      },
    },
    pointRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    pointTitle: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      color: theme.palette.grey[700],
    },
    exchangeSubmitButton: {
      marginBottom: theme.spacing(2),
      padding: '0.9375rem',
      fontWeight: 'bold',
      lineHeight: '1',
      borderRadius: '1.375rem',
      boxShadow: 'none',
    },
    cancelButton: {
      fontWeight: 'bold',
      borderRadius: '1.375rem',
      padding: '0.8125rem',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      lineHeight: '1',
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: 'none',
    },
  });
};

type OwnProps = {
  cancelExchange: () => void;
  handleSubmit: () => void;
  myPoints: number | undefined;
  pointAmount: number | undefined;
  createPointExchangeStatus: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  cancelExchange,
  handleSubmit,
  myPoints,
  pointAmount,
  createPointExchangeStatus,
}) => (
  <Modal handleClose={cancelExchange}>
    <Grid container direction="column">
      <Grid item className={classes.message}>
        <Typography className={classes.messageText}>
          ポイントを使用してギフトを交換します。
          <br />
          よろしいですか？
        </Typography>
      </Grid>
      <div className={classes.pointSection}>
        <Grid container alignItems="center" className={classes.pointRow}>
          <Typography className={classes.pointTitle}>使用ポイント</Typography>
          <Points points={pointAmount} fontSize="lg" isMinus={true} />
        </Grid>
        <Grid container alignItems="center" className={classes.pointRow}>
          <Typography className={classes.pointTitle}>
            使用後残りポイント
          </Typography>
          <Points
            points={
              pointAmount !== undefined &&
              myPoints !== undefined &&
              myPoints - pointAmount >= 0
                ? myPoints - pointAmount
                : null
            }
            fontSize="lg"
          />
        </Grid>
      </div>
      <LoadingButton
        className={classes.exchangeSubmitButton}
        variant="contained"
        color="primary"
        loading={createPointExchangeStatus === 'loading'}
        onClick={handleSubmit}
        disabled={
          pointAmount === undefined ||
          myPoints === undefined ||
          pointAmount > myPoints ||
          createPointExchangeStatus === 'loading'
        }
        data-gtm-click="shared-modal-itemExchangeModal-submitButton"
      >
        交換する
      </LoadingButton>
      <Button
        className={classes.cancelButton}
        variant="contained"
        onClick={cancelExchange}
        data-gtm-click="shared-modal-itemExchangeModal-cancelButton"
      >
        キャンセル
      </Button>
    </Grid>
  </Modal>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
