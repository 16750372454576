import { Reducer } from 'redux';
import { types } from './types';
import type { RootAction } from '/@/store/actions';
import { GIFTS_AVAILABILITY } from './constants';

type SelectedGiftsAvailabilityTabState =
  | typeof GIFTS_AVAILABILITY.AVAILABLE
  | typeof GIFTS_AVAILABILITY.UNAVAILABLE;

const initialState: SelectedGiftsAvailabilityTabState =
  GIFTS_AVAILABILITY.AVAILABLE;

const selectedGiftsAvailabilityTab: Reducer<
  SelectedGiftsAvailabilityTabState,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case types.SELECT_GIFTS_AVAILABILITY_TAB:
      return action.selectedGiftsAvailabilityTab;
    default:
      return state;
  }
};

export { selectedGiftsAvailabilityTab };
export type { SelectedGiftsAvailabilityTabState };
