import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Card,
  Divider,
} from '@material-ui/core';
import { GiftCard } from './giftCard/Index';
import { formatToDateJa } from '/@/utils/formatDate';
import { GiftCardGifteeContentBase } from '/@/store/app/shared/types';

export type GiftCardGifteeContent = GiftCardGifteeContentBase;

const styles = (theme: Theme) => {
  return createStyles({
    card: {
      border: 'none',
      borderRadius: theme.spacing(2),
    },
    content: {
      padding: theme.spacing(2),
    },
    contentExpiredAt: {
      color: theme.palette.secondary.main,
    },
    contentName: {
      overflowWrap: 'break-word',
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
    contentCount: {
      color: theme.palette.secondary.main,
    },
  });
};

type OwnProps = {
  giftCardGifteeContent: GiftCardGifteeContent;
  ticketsCountText: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  giftCardGifteeContent,
  ticketsCountText,
}) => (
  <Card
    data-cy="giftCardGifteeContent"
    variant="outlined"
    className={classes.card}
  >
    <Grid className={classes.content}>
      {giftCardGifteeContent.savedAt && (
        <Typography
          variant="caption"
          className={classes.contentExpiredAt}
          data-cy="giftCardGifteeContentSavedAt"
        >
          保存日時：{formatToDateJa(giftCardGifteeContent.savedAt)}
        </Typography>
      )}
      <Typography variant="body1" className={classes.contentName}>
        {giftCardGifteeContent.content.name}
      </Typography>
      <Typography variant="caption" className={classes.contentCount}>
        {ticketsCountText}
      </Typography>
    </Grid>
    <Divider />
    <GiftCard giftCard={giftCardGifteeContent.content} />
  </Card>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
