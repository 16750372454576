import { types } from './types';
import type { RefreshMyGiftMutation } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const refreshMyGiftStart = () =>
  ({
    type: types.REFRESH_MY_GIFT_START,
  }) as const;

export const refreshMyGiftSuccess = (
  myGift: RefreshMyGiftMutation['refreshMyGift']['myGift'],
) =>
  ({
    type: types.REFRESH_MY_GIFT_SUCCESS,
    myGift,
  }) as const;

export const refreshMyGiftFailure = (error: ApiError) =>
  ({
    type: types.REFRESH_MY_GIFT_FAILURE,
    error,
  }) as const;
