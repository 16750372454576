import { gql } from '@apollo/client';

export const FRAGMENT_GIFTEE_BOX = gql`
  fragment GifteeBoxFields on GifteeBox {
    urlCode
    url
    name
    imageUrl
    initialPoint
    expiredAt
  }
`;
