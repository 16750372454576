import { gql } from '@apollo/client';

// TODO: Update query(add currentPointBackCampaign)
export const getItemsConnectionGql = gql`
  query GetItemsConnection(
    $after: String
    $before: String
    $brandUidEq: String
    $first: Int
    $last: Int
    $withPointsGteq: Int
    $withPointsLteq: Int
    $sortConditions: [ItemType_SortConditionInputType!]
  ) {
    items(
      after: $after
      before: $before
      brandUidEq: $brandUidEq
      first: $first
      last: $last
      withPointsGteq: $withPointsGteq
      withPointsLteq: $withPointsLteq
      sortConditions: $sortConditions
    ) {
      nodes {
        uid
        brand {
          name
        }
        contentName
        contentImageUrl
        readableExpiration
        currentItemPointPhase {
          point
          startAt
          endAt
          variable {
            maxPoint
            minPoint
            step
          }
        }
        currentPointBackCampaign {
          beginAt
          description
          discountPercentage
          endAt
          normalDiscountPercentage
          uid
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
