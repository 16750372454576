import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import { LoadingButton } from '/@/components/shared/button/loadingButton/Index';
import { Modal } from '/@/components/shared/modal/Index';
import { Points } from '../../points/Index';

const styles = (theme: Theme) => {
  return createStyles({
    messageText: {
      fontWeight: 'bold',
      fontSize: '1rem',
      lineHeight: '1.3375rem',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    pointTitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: '0.75rem',
      fontWeight: 'bold',
      color: theme.palette.grey[700],
    },
    pointNote: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      textAlign: 'left',
      fontSize: '0.75rem',
    },
    exchangeSubmitButton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: '0.9375rem',
      fontWeight: 'bold',
      lineHeight: '1',
      borderRadius: '1.375rem',
      boxShadow: 'none',
    },
    cancelButton: {
      fontWeight: 'bold',
      borderRadius: '1.375rem',
      padding: '0.8125rem',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      lineHeight: '1',
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: 'none',
    },
  });
};

type OwnProps = {
  cancelCharge: () => void;
  handleSubmit: () => void;
  pointAmount: number | undefined;
  isLoading: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  cancelCharge,
  handleSubmit,
  pointAmount,
  isLoading,
}) => (
  <Modal handleClose={cancelCharge} data-cy="pointChargeModal">
    <Grid container direction="column" justifyContent="center">
      <Typography className={classes.messageText}>
        選択したギフトを
        <br />
        ポイント移行しますか？
      </Typography>
      <Typography className={classes.pointTitle}>移行されるポイント</Typography>
      <Points points={pointAmount} fontSize="lg" />
      <Typography className={classes.pointNote}>
        ※ポイント移行したギフトは元に戻せません。
      </Typography>
      <LoadingButton
        className={classes.exchangeSubmitButton}
        variant="contained"
        color="primary"
        loading={isLoading}
        onClick={handleSubmit}
        disabled={!pointAmount || isLoading}
        data-gtm-click="shared-modal-pointChargeModal-submitButton"
      >
        移行する
      </LoadingButton>
      <Button
        className={classes.cancelButton}
        variant="contained"
        onClick={cancelCharge}
        data-gtm-click="shared-modal-pointChargeModal-cancelButton"
      >
        キャンセル
      </Button>
    </Grid>
  </Modal>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
