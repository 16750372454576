import React, { useEffect } from 'react';
import { useAuth, AUTHENTICATION_STATUS } from '/@/common/hooks/useAuth';
import { PATHS } from '/@/routes/paths';
import { useNavigate } from 'react-router';

type OwnProps = { children: React.ReactNode };

const AuthenticatedGuard: React.VFC<OwnProps> = ({ children }) => {
  const authenticationStatus = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticationStatus === AUTHENTICATION_STATUS.UNAUTHENTICATED) {
      navigate(PATHS.SIGN_IN);
    }
  }, [authenticationStatus, navigate]);

  return (
    <>
      {authenticationStatus === AUTHENTICATION_STATUS.AUTHENTICATED && children}
    </>
  );
};

export { AuthenticatedGuard };
