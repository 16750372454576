import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import { getLineBotFriendUrl } from '/@/common/lineBotFriendUrl';

const styles = (theme: Theme) => {
  return createStyles({
    addFriendButton: {
      fontWeight: 'bold',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderRadius: theme.spacing(4),
      textTransform: 'none',
      width: '100%',
    },
    mainMessage: {
      color: theme.palette.warning.main,
      fontWeight: 'bold',
    },
    subMessage: {
      color: theme.palette.secondary.dark,
    },
  });
};

type OwnProps = {
  message: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, message }) => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    direction="column"
    data-cy="friendRequest"
  >
    <Typography variant="body2" align="center" className={classes.mainMessage}>
      {message}
    </Typography>
    <Button
      variant="contained"
      color="primary"
      disableElevation
      className={classes.addFriendButton}
      href={getLineBotFriendUrl()}
    >
      gift walletを友だち追加する
    </Button>
    <Typography variant="caption" align="center" className={classes.subMessage}>
      友だち追加すると、以下のギフトを保存できます
    </Typography>
  </Grid>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
