import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Card,
  Typography,
  Grid,
  IconButton,
  Button,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { formatToDateJa } from '/@/utils/formatDate';
import type { PointWalletState } from '/@/store/app/pointWallet';
import { Points } from '/@/components/shared/points/Index';
import clsx from 'clsx';

export type PointWallet = NonNullable<PointWalletState>;

const styles = (theme: Theme) =>
  createStyles({
    mainCard: {
      borderRadius: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.1)',
    },
    pointDisplayArea: {
      height: '2rem',
    },
    // 表示調整用
    loader: {
      marginTop: '-1rem',
      transform: 'scale(0.5)',
    },
    pointAvailablePeriodContent: {
      color: theme.palette.grey[700],
      marginTop: theme.spacing(2),
      fontSize: '0.75rem',
      fontWeight: 'bold',
    },
    pointAvailablePeriod: {
      marginLeft: '0.5rem',
      fontSize: '0.875rem',
    },
    pointBrandsPageButton: {
      width: '100%',
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
      padding: theme.spacing(1, 4),
      borderRadius: theme.spacing(4),
    },
    disabledPointBrandsPageButton: {
      color: theme.palette.common.white + ' !important', // MEMO: MUIのデフォルトの色を上書きするため
    },
    pointLogsButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '2rem',
      width: '2rem',
    },
    pointPointLogsPageButton: {
      backgroundColor: theme.palette.grey[100],
      height: '70%',
      width: '70%',
    },
    arrowForwardIosIcon: {
      height: '0.75rem',
      width: '0.75rem',
    },
  });

type OwnProps = {
  pointWallet: PointWallet | null;
  getPointWalletStatus: Status;
  handleNavigatePointBrands: () => void;
  handleNavigatePointLogs: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  pointWallet,
  getPointWalletStatus,
  handleNavigatePointBrands,
  handleNavigatePointLogs,
}) => (
  <Card className={classes.mainCard}>
    <Grid container justifyContent="space-between">
      <Grid item className={classes.pointDisplayArea}>
        {getPointWalletStatus === STATUS.LOADING ? (
          <div className={classes.loader}>
            <PartialLoader />
          </div>
        ) : (
          <Grid container direction="row" alignItems="baseline">
            <Points points={pointWallet?.point} fontSize="xl" />
          </Grid>
        )}
      </Grid>
      <Grid item className={classes.pointLogsButtonWrapper}>
        <IconButton
          color="secondary"
          onClick={handleNavigatePointLogs}
          className={classes.pointPointLogsPageButton}
          data-cy="handleNavigatePointLogsButton"
          data-gtm-click="homePage-pointSection-pointCard-handleNavigatePointLogsButton"
        >
          <ArrowForwardIosIcon className={classes.arrowForwardIosIcon} />
        </IconButton>
      </Grid>
    </Grid>
    {pointWallet?.point !== undefined && pointWallet.point > 0 && (
      <Typography
        variant="body2"
        className={classes.pointAvailablePeriodContent}
      >
        直近のポイント有効期限
        <span className={classes.pointAvailablePeriod}>
          {pointWallet?.nearestAvailableWithdrawableUntilAt
            ? formatToDateJa(pointWallet.nearestAvailableWithdrawableUntilAt)
            : '-'}
        </span>
      </Typography>
    )}
    <Button
      variant="contained"
      disableElevation
      color="primary"
      onClick={handleNavigatePointBrands}
      className={clsx(
        classes.pointBrandsPageButton,
        !pointWallet?.point && classes.disabledPointBrandsPageButton,
      )}
      disabled={!pointWallet?.point}
      data-cy="handleNavigatePointBrandsButton"
      data-gtm-click="homePage-pointSection-pointCard-handleNavigatePointBrandsButton"
    >
      ポイントを使う
    </Button>
  </Card>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
