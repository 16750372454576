import { gql } from '@apollo/client';
export const FRAGMENT_MY_GIFT = gql`
  fragment MyGiftFields on MyGift {
    urlCode
    contentName
    imageUrl
    giftUrl
    giftType
    pointMergeable
    remainingPoint
    savedAt
    changedAt
    discardedAt
    expiredAt
    unavailableAt
  }
`;
