import React from 'react';
import { Presenter } from './Presenter';
import { PageErrorState } from '/@/store/app/pageError';

type OwnProps = {
  error: PageErrorState;
};

const Container: React.VFC<OwnProps> = ({ error }) => {
  return <Presenter error={error} />;
};

export { Container as ErrorPage };
