import { MyGiftBase } from '/@/store/app/shared/types';
import dayjs from 'dayjs';

export const MY_GIFT_USAGE_STATUS = {
  AVAILABLE: 'AVAILABLE',
  EXPIRED: 'EXPIRED',
  EXPIRING: 'EXPIRING',
  DISCARDED: 'DISCARDED',
} as const;

export type MyGiftUsageStatus =
  (typeof MY_GIFT_USAGE_STATUS)[keyof typeof MY_GIFT_USAGE_STATUS];

export const calcMyGiftUsageStatus = (
  myGift: MyGiftBase,
): MyGiftUsageStatus => {
  if (isDiscarded(myGift)) {
    return MY_GIFT_USAGE_STATUS.DISCARDED;
  } else if (isExpiring(myGift)) {
    return MY_GIFT_USAGE_STATUS.EXPIRING;
  } else if (isExpired(myGift)) {
    return MY_GIFT_USAGE_STATUS.EXPIRED;
  } else {
    return MY_GIFT_USAGE_STATUS.AVAILABLE;
  }
};

const isDiscarded = (myGift: MyGiftBase): boolean => !!myGift.discardedAt;
const isExpiring = (myGift: MyGiftBase): boolean =>
  dayjs(myGift.expiredAt).isBefore(dayjs().add(1, 'week')) &&
  dayjs(myGift.expiredAt).isAfter(dayjs());
const isExpired = (myGift: MyGiftBase): boolean =>
  dayjs(myGift.expiredAt).isBefore(dayjs());
