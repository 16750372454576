import React, { useState, useEffect } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch } from '/@/store/hooks';
import { getPointWalletThunk } from '/@/store/api/getPointWallet';

export type TAB_NAME = 'availablePointLogs' | 'logList';

const Container: React.VFC<Record<string, never>> = () => {
  const headerTitle = '所持ポイント情報';

  const dispatch = useAppDispatch();

  const [activeTabStatus, setActiveTabStatus] =
    useState<TAB_NAME>('availablePointLogs');

  useEffect(() => {
    dispatch(getPointWalletThunk()).catch(() => {});
  }, [dispatch]);

  return (
    <Presenter
      headerTitle={headerTitle}
      activeTabStatus={activeTabStatus}
      setActiveTabStatus={setActiveTabStatus}
    />
  );
};

export { Container as PointPointLogsPage };
