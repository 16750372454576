import { Reducer } from 'redux';
import type {
  GetMyGiftsConnectionQuery,
  MyGiftConnection,
} from '/@/api/graphql/internalApi/types';
import { getInitialUnavailableMyGiftsConnectionTypes } from '/@/store/api/getInitialUnavailableMyGiftsConnection';
import { getContinuousUnavailableMyGiftsConnectionTypes } from '/@/store/api/getContinuousUnavailableMyGiftsConnection';
import { MyGiftBase } from '/@/store/app/shared/types';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';

// TODO: State の型定義を自前で用意する
type UnavailableMyGiftsState = UnavailableMyGift[] | null;
type PageInfoState = MyGiftConnection['pageInfo'] | null;
type UnavailableMyGiftsConnectionState = {
  unavailableMyGifts: UnavailableMyGiftsState;
  pageInfo: PageInfoState;
};
type UnavailableMyGift = MyGiftBase;

const unavailableMyGiftsInitialState: UnavailableMyGiftsState = null;

const unavailableMyGifts: Reducer<UnavailableMyGiftsState, RootAction> = (
  state = unavailableMyGiftsInitialState,
  action,
) => {
  switch (action.type) {
    case getInitialUnavailableMyGiftsConnectionTypes.GET_INITIAL_UNAVAILABLE_MY_GIFTS_CONNECTION_SUCCESS:
      if (!action.unavailableMyGiftsConnection.nodes) return null;
      return filterNodes(action.unavailableMyGiftsConnection.nodes);
    case getContinuousUnavailableMyGiftsConnectionTypes.GET_CONTINUOUS_UNAVAILABLE_MY_GIFTS_CONNECTION_SUCCESS: {
      if (!action.unavailableMyGiftsConnection.nodes) return state;
      const filteredNodes = filterNodes(
        action.unavailableMyGiftsConnection.nodes,
      );

      if (state === null) {
        return filteredNodes;
      } else {
        const uniqueItems = Array.from(
          new Map(
            [...state, ...filteredNodes].map((item) => [item.urlCode, item]),
          ).values(),
        );
        return uniqueItems;
      }
    }
    default:
      return state;
  }
};

const filterNodes = (
  unavailableMyGiftsConnectionNodes: NonNullable<
    GetMyGiftsConnectionQuery['myGiftsConnection']['nodes']
  >,
): NonNullable<UnavailableMyGiftsState> =>
  unavailableMyGiftsConnectionNodes.filter(
    (node): node is UnavailableMyGift => !!node,
  );

const pageInfoInitialState: PageInfoState = null;

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getInitialUnavailableMyGiftsConnectionTypes.GET_INITIAL_UNAVAILABLE_MY_GIFTS_CONNECTION_SUCCESS:
    case getContinuousUnavailableMyGiftsConnectionTypes.GET_CONTINUOUS_UNAVAILABLE_MY_GIFTS_CONNECTION_SUCCESS:
      return action.unavailableMyGiftsConnection.pageInfo;
    default:
      return state;
  }
};

const unavailableMyGiftsConnection = combineReducers({
  unavailableMyGifts,
  pageInfo,
});

export { unavailableMyGiftsConnection };
export type {
  UnavailableMyGiftsConnectionState,
  UnavailableMyGift,
  UnavailableMyGiftsState,
};
