import { sendToGTM } from '@elgorditosalsero/react-gtm-hook';
import { useCallback, useEffect, useRef } from 'react';
import { useAppSelector } from '/@/store';
import { STATUS } from '/@/store/api/constants';

// actionTypeはGoogle Tag Managerのトリガーに使用するため、変更する場合はGTM側も変更する
const ACTIONTYPE = {
  PAGE_VIEW: 'pageView',
  CLICK: 'click',
  SEARCH_ITEMS: 'searchItems',
  POINT_EXCHANGE: 'pointExchange',
  POINT_CHARGE: 'pointCharge',
  REGISTER_PROFILE: 'registerProfile',
  CUSTOM_EVENT: 'costomEvent',
} as const;

type ActionType = (typeof ACTIONTYPE)[keyof typeof ACTIONTYPE];

type PageViewEventParams = {
  page: string;
  customData?: object;
};
type ClickEventParams = {
  clickItem: string;
  customData?: object;
};
type SearchItemsEventParams = {
  brandUid: string | null;
  brandName: string | null;
  minPoint: number | null;
  maxPoint: number | null;
  possessionPoints: number | null;
  customData?: object;
};
type PointExchangeEventParams = {
  point: number;
  itemUid: string;
  itemName: string;
  brandUid: string;
  brandName: string;
  customData?: object;
};
type PointChargeEventParams = {
  point: number;
  itemName: string;
  customData?: object;
};
type RegisterProfileEventParams = {
  gender?: string;
  birthday?: string; // 例: '1990/01/01'
  birthYear?: string; // 例: '1990'
  prefecture?: string;
  customData?: object;
};
// 上記以外のパターン用イベントhook用
type CustomEventParams = {
  eventName: string;
  customData?: object;
};

// Google Tag ManagerのdataLayerにデータを送信するためのカスタムフック
export const useDataLayer = () => {
  const currentUser = useAppSelector((state) => state.app.currentUser);
  const currentUserApiStatus = useAppSelector(
    (state) => state.api.getCurrentUser.status,
  );
  // ユーザー情報未取得時の再帰処理用に値を保持
  const currentUserRef = useRef(currentUser);
  const currentUserApiStatusRef = useRef(currentUserApiStatus);

  const pushToDataLayer = useCallback(
    (actionType: ActionType, data: object, authCheckDisable?: boolean) => {
      if (authCheckDisable) {
        sendToGTM({
          dataLayerName: 'dataLayer',
          data: {
            event: actionType,
            ...data,
          },
        });
      } else if (
        currentUserApiStatusRef.current === STATUS.LOADING ||
        (!currentUserRef.current &&
          currentUserApiStatusRef.current === STATUS.IDLE)
      ) {
        setTimeout(() => {
          pushToDataLayer(actionType, data);
        }, 1000);
      } else {
        sendToGTM({
          dataLayerName: 'dataLayer',
          data: {
            event: actionType,
            userId: currentUserRef.current?.uid ?? null,
            ...data,
          },
        });
      }
    },
    [],
  );

  useEffect(() => {
    currentUserRef.current = currentUser;
  }, [currentUser]);

  useEffect(() => {
    currentUserApiStatusRef.current = currentUserApiStatus;
  }, [currentUserApiStatus]);

  // 個別のイベントを送信する関数を定義
  // ※送信するデータを変更する場合はGTM側のデータレイヤー変数やタグ内のイベントパラメータも変更する
  const pushPageViewEvent = (
    params: PageViewEventParams,
    authCheckDisable?: boolean,
  ) => {
    pushToDataLayer(
      ACTIONTYPE.PAGE_VIEW,
      {
        page: params.page,
        customData: params.customData
          ? JSON.stringify(params.customData)
          : null,
      },
      authCheckDisable,
    );
  };

  const pushClickEvent = (
    params: ClickEventParams,
    authCheckDisable?: boolean,
  ) => {
    pushToDataLayer(
      ACTIONTYPE.CLICK,
      {
        clickItem: params.clickItem,
        customData: params.customData
          ? JSON.stringify(params.customData)
          : null,
      },
      authCheckDisable,
    );
  };

  const pushSearchItemsEvent = (
    params: SearchItemsEventParams,
    authCheckDisable?: boolean,
  ) => {
    pushToDataLayer(
      ACTIONTYPE.SEARCH_ITEMS,
      {
        ...params,
        customData: params.customData
          ? JSON.stringify(params.customData)
          : null,
      },
      authCheckDisable,
    );
  };

  const pushPointExchangeEvent = (
    params: PointExchangeEventParams,
    authCheckDisable?: boolean,
  ) => {
    pushToDataLayer(
      ACTIONTYPE.POINT_EXCHANGE,
      {
        ...params,
        customData: params.customData
          ? JSON.stringify(params.customData)
          : null,
      },
      authCheckDisable,
    );
  };

  const pushPointChargeEvent = (
    params: PointChargeEventParams,
    authCheckDisable?: boolean,
  ) => {
    pushToDataLayer(
      ACTIONTYPE.POINT_CHARGE,
      {
        ...params,
        customData: params.customData
          ? JSON.stringify(params.customData)
          : null,
      },
      authCheckDisable,
    );
  };
  const pushRegisterProfileEvent = (
    params: RegisterProfileEventParams,
    authCheckDisable?: boolean,
  ) => {
    pushToDataLayer(
      ACTIONTYPE.REGISTER_PROFILE,
      {
        ...params,
        customData: params.customData
          ? JSON.stringify(params.customData)
          : null,
      },
      authCheckDisable,
    );
  };
  // 上記以外のパターン用イベントhook
  const pushCustomEvent = (
    params: CustomEventParams,
    authCheckDisable?: boolean,
  ) => {
    pushToDataLayer(
      ACTIONTYPE.CUSTOM_EVENT,
      {
        ...params,
        customData: params.customData
          ? JSON.stringify(params.customData)
          : null,
      },
      authCheckDisable,
    );
  };

  return {
    pushPageViewEvent,
    pushClickEvent,
    pushSearchItemsEvent,
    pushPointExchangeEvent,
    pushPointChargeEvent,
    pushRegisterProfileEvent,
    pushCustomEvent,
  };
};
