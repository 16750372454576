import { gql } from '@apollo/client';
import { FRAGMENT_POINT_WALLET } from '/@/api/graphql/internalApi/fragments/pointWallet.gql';
import { FRAGMENT_ERRORS } from '/@/api/graphql/internalApi/fragments/errors.gql';

export const createGiftConvertToPointGql = gql`
  ${FRAGMENT_POINT_WALLET}
  ${FRAGMENT_ERRORS}
  mutation createGiftConvertToPoint(
    $clientMutationId: String
    $myGiftUrlCode: String!
    $requestUid: String!
    $convertPointAmount: Int!
  ) {
    createGiftConvertToPointRequest(
      input: {
        clientMutationId: $clientMutationId
        myGiftUrlCode: $myGiftUrlCode
        requestUid: $requestUid
        convertPointAmount: $convertPointAmount
      }
    ) {
      clientMutationId
      giftConvertToPointRequest {
        convertPointAmount
        pointWallet {
          ...PointWalletFields
        }
        requestUid
      }
      errors {
        ...ErrorsFields
      }
    }
  }
`;
