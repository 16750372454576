import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0C6993',
      dark: '#003F65',
      light: '#5097C4',
    },
    secondary: {
      main: '#6C7F87',
    },
    warning: {
      main: '#EB7152',
    },
    gifteeCoral: {
      main: '#F08B71',
    },
    background: {
      default: '#FFFFFF',
      hint: '#F6F7F8',
      contrast: '#E0F2F7',
      highContrast: '#5097C4',
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Helvetica Neue',
      'Arial',
      'Hiragino Kaku Gothic ProN',
      'Hiragino Sans',
      'Meiryo',
      'sans-serif',
    ].join(','),
  },
});
