import { Presenter } from './Presenter';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { OriginEnum } from '/@/api/graphql/publicApi/types';
import { getSavingGifteeContentThunk } from '/@/store/api/getSavingGifteeContent';

type Props = {
  url: string;
  origin: OriginEnum;
};

const Container: React.VFC<Props> = ({ url, origin }) => {
  const dispatch = useAppDispatch();

  const getSavingGifteeContentStatus = useAppSelector(
    (state) => state.api.getSavingGifteeContent.status,
  );

  const savingGifteeContent = useAppSelector(
    (state) => state.page.signInPage.savingGifteeContent,
  );

  useEffect(() => {
    dispatch(
      getSavingGifteeContentThunk({
        url,
        origin,
      }),
    ).catch(() => {});
  }, [dispatch, url, origin]);

  return (
    <Presenter
      getSavingGifteeContentStatus={getSavingGifteeContentStatus}
      savingGifteeContent={savingGifteeContent}
    />
  );
};

export { Container as SavingGift };
