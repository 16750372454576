import { saveGifteeContent } from '/@/api/graphql/internalApi/saveGifteeContent';
import type { SaveGifteeContentResponse } from '/@/api/graphql/internalApi/saveGifteeContent';
import type { AppThunk } from '/@/store';
import {
  saveGifteeContentStart,
  saveGifteeContentSuccess,
  saveGifteeContentFailure,
} from './actions';
import type { SaveGifteeContentMutationVariables } from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from './handleError';
import type { ApiError } from '/@/api/graphql/client';

const saveGifteeContentThunk =
  (input: SaveGifteeContentMutationVariables): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(saveGifteeContentStart());
    return saveGifteeContent(input)
      .then((response) => dispatch(saveGifteeContentSuccessThunk(response)))
      .catch((error) => dispatch(saveGifteeContentFailureThunk(error)));
  };

const saveGifteeContentSuccessThunk =
  (response: SaveGifteeContentResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { gifteeContent } = response.data!.saveGifteeContent;
    if (gifteeContent) dispatch(saveGifteeContentSuccess(gifteeContent));
    return Promise.resolve();
  };

const saveGifteeContentFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(saveGifteeContentFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { saveGifteeContentThunk };
