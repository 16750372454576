import { Reducer } from 'redux';
import {
  GetItemsConnectionQuery,
  ItemConnection,
} from '/@/api/graphql/internalApi/types';
import { getItemsConnectionTypes } from '/@/store/api/getItemsConnection/index';
import { ItemBase } from '/@/store/app/shared/types';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';
import { initialiseItemsConnectionTypes } from './index';

type ItemsState = Item[] | null;
type PageInfoState = ItemConnection['pageInfo'] | null;
type ItemsConnectionState = {
  items: ItemsState;
  pageInfo: PageInfoState;
};
type Item = ItemBase;

const initialState: ItemsState = null;

const items: Reducer<ItemsState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getItemsConnectionTypes.GET_ITEMS_CONNECTION_SUCCESS: {
      if (!action.itemsConnection?.nodes) return null;
      const filteredNodes = filterNodes(action.itemsConnection.nodes);

      if (state === null) {
        return filteredNodes;
      } else {
        const uniqueItems = Array.from(
          new Map(
            [...state, ...filteredNodes].map((item) => [item.uid, item]),
          ).values(),
        );
        return uniqueItems;
      }
    }
    case initialiseItemsConnectionTypes.INITIALISE_ITEMS_CONNECTION:
      return initialState;
    default:
      return state;
  }
};

const filterNodes = (
  itemsConnectionNodes: NonNullable<GetItemsConnectionQuery['items']['nodes']>,
): NonNullable<ItemsState> =>
  itemsConnectionNodes.filter((node) => !!node) as Item[];

const pageInfoInitialState: PageInfoState = null;

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getItemsConnectionTypes.GET_ITEMS_CONNECTION_SUCCESS:
      return action.itemsConnection.pageInfo;
    case initialiseItemsConnectionTypes.INITIALISE_ITEMS_CONNECTION:
      return pageInfoInitialState;
    default:
      return state;
  }
};

const itemsConnection = combineReducers({
  items,
  pageInfo,
});

export { itemsConnection };
export type { ItemsConnectionState, Item, ItemsState };
