import { updateTopic, updateContent } from './actions';
import type { AppDispatch } from '/@/store';

const resetInquiriesNewPageStatesReEnterNeededThunk =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch(updateTopic(''));
    dispatch(updateContent(''));
  };

export { resetInquiriesNewPageStatesReEnterNeededThunk };
