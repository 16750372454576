import { giftConvertToPointRequestSubscriptionObserver } from '/@/api/graphql/internalApi/giftConvertToPointRequestSubscription';
import type { AppThunk } from '/@/store';
import {
  giftConvertToPointRequestSubscriptionStart,
  giftConvertToPointRequestSubscriptionSuccess,
  giftConvertToPointRequestSubscriptionFailure,
} from './actions';
import { handleErrorThunk } from './handleError';
import type { ApiError } from '/@/api/graphql/client';
import {
  GiftConvertToPointRequestSubscription,
  GiftConvertToPointRequestSubscriptionVariables,
} from '/@/api/graphql/internalApi/types';
import { setAlertMessage } from '/@/store/ui/alertMessage/actions';
import { SEVERITY } from '/@/store/ui/alertMessage';

const giftConvertToPointRequestSubscriptionThunk =
  (
    input: GiftConvertToPointRequestSubscriptionVariables,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(giftConvertToPointRequestSubscriptionStart());
    const subscription = giftConvertToPointRequestSubscriptionObserver(
      input,
    )?.subscribe({
      next: (response) => {
        const { giftConvertToPointRequest } =
          response.data!.giftConvertToPointCompletion;
        if (giftConvertToPointRequest?.errorMessage) {
          // エラーメッセージをアラートで表示する
          dispatch(
            setAlertMessage({
              severity: SEVERITY.ERROR,
              message: giftConvertToPointRequest.errorMessage,
            }),
          );
          dispatch(
            giftConvertToPointRequestSubscriptionFailureThunk(
              giftConvertToPointRequest.errorMessage,
            ),
          );
        } else if (giftConvertToPointRequest) {
          dispatch(
            giftConvertToPointRequestSubscriptionSuccessThunk(
              giftConvertToPointRequest,
            ),
          );
        }
        subscription?.unsubscribe();
      },
      error: (error: ApiError) => {
        dispatch(giftConvertToPointRequestSubscriptionFailureThunk(error));
        subscription?.unsubscribe();
      },
    });
    return Promise.resolve();
  };

const giftConvertToPointRequestSubscriptionSuccessThunk =
  (
    response: GiftConvertToPointRequestSubscription['giftConvertToPointCompletion']['giftConvertToPointRequest'],
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    if (response) {
      dispatch(giftConvertToPointRequestSubscriptionSuccess(response));
    }
    return Promise.resolve();
  };

const giftConvertToPointRequestSubscriptionFailureThunk =
  (error: ApiError | string): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(giftConvertToPointRequestSubscriptionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { giftConvertToPointRequestSubscriptionThunk };
