import { gql } from '@apollo/client';
import { FRAGMENT_GIFTEE_BOX } from './gifteeBox.gql';
import { FRAGMENT_GIFT_CARD } from './giftCard.gql';

export const FRAGMENT_GIFTEE_CONTENT = gql`
  ${FRAGMENT_GIFTEE_BOX}
  ${FRAGMENT_GIFT_CARD}
  fragment GifteeContentFields on GifteeContent {
    urlCode
    savedAt
    myGifts {
      urlCode
    }
    content {
      __typename
      ... on GiftCard {
        ...GiftCardFields
      }
      ... on GifteeBox {
        ...GifteeBoxFields
      }
    }
  }
`;
