import React, { useState } from 'react';
import { Presenter } from './Presenter';

const Container: React.VFC<Record<string, never>> = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  return (
    <Presenter
      isOpenModal={isOpenModal}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
    />
  );
};
export { Container as BenefitOfPointChargeModalWithButton };
