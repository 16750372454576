import { Reducer, combineReducers } from 'redux';

import { types } from './types';
import { RootAction } from '/@/store/actions';

type InquiriesNewPage = {
  input: InquiriesNewPageInputState;
};

type InquiriesNewPageInputState = {
  name: string;
  email: string;
  topic: TopicValues | '';
  content: string;
  sessionToken?: string;
};

type TopicKeys = keyof typeof TOPICS;
type TopicValues = (typeof TOPICS)[TopicKeys];

const TOPICS = {
  CANNOT_SAVING_GIFT: 'ギフトを保存できない',
  UNKNOWN_GIFT_USAGE: 'ギフトの使い方がわからない',
  OTHER: 'その他',
} as const;

const name: Reducer<string, RootAction> = (state = '', action) => {
  switch (action.type) {
    case types.UPDATE_NAME:
      return action.payload;
    default:
      return state;
  }
};

const email: Reducer<string, RootAction> = (state = '', action) => {
  switch (action.type) {
    case types.UPDATE_EMAIL:
      return action.payload;
    default:
      return state;
  }
};

const topic: Reducer<TopicValues | '', RootAction> = (state = '', action) => {
  switch (action.type) {
    case types.UPDATE_TOPIC:
      return action.payload;
    default:
      return state;
  }
};

const content: Reducer<string, RootAction> = (state = '', action) => {
  switch (action.type) {
    case types.UPDATE_CONTENT:
      return action.payload;
    default:
      return state;
  }
};

const isTermsAgreed: Reducer<boolean, RootAction> = (state = false, action) => {
  switch (action.type) {
    case types.UPDATE_IS_TERMS_AGREED:
      return action.payload;
    default:
      return state;
  }
};

const inquiriesNewPage = combineReducers({
  name,
  email,
  topic,
  content,
  isTermsAgreed,
});

export type {
  InquiriesNewPage,
  InquiriesNewPageInputState,
  TopicKeys,
  TopicValues,
};
export { inquiriesNewPage, TOPICS };
