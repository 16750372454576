import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Modal,
  Box,
} from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    modal: {
      backgroundColor: 'transparent',
      textAlign: 'center',
    },
    box: {
      position: 'absolute',
      top: '50%',
      width: 'calc(100% - 32px)',
      maxWidth: '480px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(3),
      borderRadius: theme.spacing(1),
      alignItems: 'center',
    },
  });
};

type OwnProps = {
  children: React.ReactNode;
  handleClose: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, children, handleClose }) => (
  <Modal open={true} onClose={handleClose} className={classes.modal}>
    <Box className={classes.box}>{children}</Box>
  </Modal>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
