const types = {
  CREATE_GIFT_CONVERT_TO_POINT_INIT: 'api/CREATE_GIFT_CONVERT_TO_POINT_INIT',
  CREATE_GIFT_CONVERT_TO_POINT_START: 'api/CREATE_GIFT_CONVERT_TO_POINT_START',
  CREATE_GIFT_CONVERT_TO_POINT_SUCCESS:
    'api/CREATE_GIFT_CONVERT_TO_POINT_SUCCESS',
  CREATE_GIFT_CONVERT_TO_POINT_FAILURE:
    'api/CREATE_GIFT_CONVERT_TO_POINT_FAILURE',
} as const;

export { types };
