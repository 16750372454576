import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Box,
  Grid,
  Container,
  Typography,
} from '@material-ui/core';
import type { PointWalletState } from '/@/store/app/pointWallet';
import { PointCard } from './pointCard/Index';
import { MyGift } from '/@/components/shared/myGift/Index';
import type { MyGiftBase } from '/@/store/app/shared/types';

export type PointWallet = NonNullable<PointWalletState>;

const styles = (theme: Theme) =>
  createStyles({
    pointBox: {
      backgroundColor: '#F6F7F8',
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(0.5),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    pointBoxContainer: {
      padding: 0,
    },
    pointMergeableMyGiftsContainer: {
      padding: 0,
      marginTop: theme.spacing(2),
    },
    pointMergeableMyGiftsScrollContainer: {
      margin: theme.spacing(1, -0.5),
      marginBottom: 0,
      padding: theme.spacing(1, 0.5),
      width: 'auto',
      overflowX: 'auto',
      flexWrap: 'nowrap',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    myGiftContainer: {
      marginRight: theme.spacing(2),
      maxWidth: '90%',
      '&:last-child': {
        marginRight: 0,
      },
    },
    myGift: {
      overflow: 'hidden',
      borderRadius: theme.spacing(1.5),
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.1)',
    },
    caption: {
      fontWeight: 'bold',
      color: theme.palette.grey[700],
    },
  });

type OwnProps = {
  display: boolean;
  pointMergeableMyGifts: MyGiftBase[];
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  display,
  pointMergeableMyGifts,
}) => (
  <>
    {display && (
      <Box className={classes.pointBox}>
        <Container maxWidth="sm" className={classes.pointBoxContainer}>
          <PointCard />
        </Container>
        {pointMergeableMyGifts.length > 0 && (
          <Container
            maxWidth="sm"
            className={classes.pointMergeableMyGiftsContainer}
          >
            <Typography variant="body2" className={classes.caption}>
              ポイントに移行可能なギフト
            </Typography>
            <Grid
              container
              className={classes.pointMergeableMyGiftsScrollContainer}
            >
              {pointMergeableMyGifts.map((myGift) => (
                <div key={myGift.urlCode} className={classes.myGiftContainer}>
                  <div key={myGift.urlCode} className={classes.myGift}>
                    <MyGift myGift={myGift} />
                  </div>
                </div>
              ))}
            </Grid>
          </Container>
        )}
      </Box>
    )}
  </>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
