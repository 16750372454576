import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
} from '@material-ui/core';
import { DestinationEnum } from '/@/api/graphql/publicApi/types';
import { Modal } from '/@/components/shared/modal/Index';
import { LoginButton } from '/@/components/shared/loginButton/Index';

const styles = (theme: Theme) => {
  return createStyles({
    description: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    closeLink: {
      marginTop: theme.spacing(2),
      textDecoration: 'none',
    },
  });
};

type OwnProps = {
  modalOpen: boolean;
  handleClose: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, modalOpen, handleClose }) => (
  <>
    {modalOpen && (
      <Modal handleClose={handleClose}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body1">【お問い合わせに関して】</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" className={classes.description}>
              {`LINEログインすることで
            お客様の状況確認がスムーズに行え、
            より早く適切なご案内ができる場合があります。`}
            </Typography>
          </Grid>
          <LoginButton
            url={null}
            origin={null}
            referer={null}
            convertToPoint={null}
            destination={DestinationEnum.Inquiry}
            serialCode={null}
            data-gtm-click="inquiriesPage-newPage-loginModal-loginButton"
          >
            ログインする
          </LoginButton>
          <Typography
            variant="caption"
            onClick={handleClose}
            className={classes.closeLink}
            data-cy="closeButton"
            data-gtm-click="inquiriesPage-newPage-loginModal-closeButton"
          >
            このまま問い合わせる
          </Typography>
        </Grid>
      </Modal>
    )}
  </>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
