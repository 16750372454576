import React, { FormEvent, ChangeEvent } from 'react';
import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  Chip,
  Container,
} from '@material-ui/core';

import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { LoginModal } from './loginModal/Index';

import type { InquiriesNewPageInputState } from '/@/store/page/inquiriesPage/newPage';
import { TOPICS } from '/@/store/page/inquiriesPage/newPage';
import { LoadingButton } from '/@/components/shared/button/loadingButton/Index';
import { HeadingWithTitle } from '/@/components/shared/headingWithTitle/Index';

type Props = {
  name: InquiriesNewPageInputState['name'];
  email: InquiriesNewPageInputState['email'];
  topic: InquiriesNewPageInputState['topic'];
  content: InquiriesNewPageInputState['content'];
  isTermsAgreed: boolean;
  isReadyToSubmit: boolean;
  isLoading: boolean;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleChangeName: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeEmail: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeTopic: (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
  handleChangeContent: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  handleChangeIsTermsAgreed: (event: ChangeEvent<HTMLInputElement>) => void;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    background: {
      backgroundColor: theme.palette.background.contrast,
    },
    mainContainer: {
      padding: theme.spacing(2),
    },
    baseBox: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    cautionBox: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.hint,
      borderRadius: theme.spacing(),
    },
    gridItem: {
      marginBottom: theme.spacing(2),
    },
    submitButton: {
      padding: theme.spacing(),
      fontWeight: 'bold',
      borderRadius: '1.4rem',
    },
    formLabelContainer: {
      marginBottom: theme.spacing(),
    },
    formLabel: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    formLabelChip: {
      fontWeight: 'bold',
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.dark,
      marginRight: theme.spacing(),
      marginLeft: theme.spacing(),
    },
    inquiryContentDescription: {
      marginTop: theme.spacing(),
      color: theme.palette.secondary.dark,
    },
    privacyPolicyLink: {
      color: theme.palette.primary.main,
    },
  }),
);

const Presenter: React.VFC<Props> = ({
  name,
  email,
  topic,
  content,
  isTermsAgreed,
  isReadyToSubmit,
  isLoading,
  handleSubmit,
  handleChangeName,
  handleChangeEmail,
  handleChangeTopic,
  handleChangeContent,
  handleChangeIsTermsAgreed,
}) => {
  const classes = useStyles();

  return (
    <>
      <MainLayout>
        <div className={classes.background}>
          <Container maxWidth="sm" className={classes.mainContainer}>
            <HeadingWithTitle title="CONTACT" subTitle="お問い合わせ" />
            <form onSubmit={handleSubmit}>
              <Box className={classes.baseBox}>
                <Grid
                  container
                  direction="column"
                  alignItems="stretch"
                  spacing={2}
                >
                  <Grid item xs className={classes.gridItem}>
                    <FormControl fullWidth>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.formLabelContainer}
                      >
                        <FormLabel className={classes.formLabel}>
                          お名前
                        </FormLabel>
                        <Chip
                          label="必須"
                          color="primary"
                          size="small"
                          className={classes.formLabelChip}
                        />
                      </Grid>
                      <TextField
                        id="inquiry-name"
                        InputLabelProps={{ shrink: true }}
                        placeholder="山田太郎"
                        required
                        value={name}
                        autoComplete="name"
                        onChange={handleChangeName}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs className={classes.gridItem}>
                    <FormControl fullWidth>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.formLabelContainer}
                      >
                        <FormLabel className={classes.formLabel}>
                          メールアドレス
                        </FormLabel>
                        <Chip
                          label="必須"
                          color="primary"
                          size="small"
                          className={classes.formLabelChip}
                        />
                      </Grid>
                      <TextField
                        id="inquiry-email-address"
                        InputLabelProps={{ shrink: true }}
                        placeholder="giftwallet@example.com"
                        required
                        value={email}
                        autoComplete="email"
                        onChange={handleChangeEmail}
                        inputProps={{
                          type: 'email',
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs className={classes.gridItem}>
                    <FormControl fullWidth>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.formLabelContainer}
                      >
                        <FormLabel
                          id="inquiry-topic-label"
                          className={classes.formLabel}
                        >
                          お問い合わせの種類
                        </FormLabel>
                        <Chip
                          label="必須"
                          color="primary"
                          size="small"
                          className={classes.formLabelChip}
                        />
                      </Grid>
                      <Select
                        autoWidth
                        labelId="inquiry-topic-label"
                        id="inquiry-topic"
                        value={topic}
                        onChange={handleChangeTopic}
                      >
                        {Object.entries(TOPICS).map(([key, value]) => (
                          <MenuItem key={key} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs className={classes.gridItem}>
                    <FormControl fullWidth>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.formLabelContainer}
                      >
                        <FormLabel className={classes.formLabel}>
                          お問い合わせ内容
                        </FormLabel>
                        <Chip
                          label="必須"
                          color="primary"
                          size="small"
                          className={classes.formLabelChip}
                        />
                        <Typography
                          variant="body2"
                          className={classes.inquiryContentDescription}
                        >
                          できるだけ具体的に記入してください。より早く、より適切なお返事を送ることができます。
                        </Typography>
                      </Grid>
                      <TextField
                        id="inquiry-content"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        multiline
                        minRows={5}
                        required
                        variant="outlined"
                        value={content}
                        placeholder="お問い合わせ内容"
                        onChange={handleChangeContent}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs className={classes.gridItem}>
                    <Box className={classes.cautionBox}>
                      <Typography variant="body2" gutterBottom>
                        ※迷惑メールの対策、キャリアメール等、ドメイン指定を行っている場合、メールが受信できない場合がございます。「@giftee.co」を受信設定してください。
                      </Typography>
                      <Typography variant="body2">
                        ※メールの対応は平日のみとなっております。週末や祝日にいただいたお問い合わせは、翌営業日以降（平日）に確認させていただきますのでご了承ください。
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs className={classes.gridItem}>
                    <FormControlLabel
                      label={
                        <Typography variant="body2">
                          <a
                            href="https://giftee.com/privacy_policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                            data-cy="termsOfServiceLink"
                            className={classes.privacyPolicyLink}
                          >
                            「お問い合わせに関する個人情報のお取り扱いについて」
                          </a>
                          に同意する
                        </Typography>
                      }
                      control={
                        <Checkbox
                          name="agreeWithTheTreatmentOfPersonalData"
                          color="primary"
                          required
                          checked={isTermsAgreed}
                          onChange={handleChangeIsTermsAgreed}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs className={classes.gridItem}>
                    <LoadingButton
                      disableElevation
                      className={classes.submitButton}
                      data-cy="submitButton"
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                      disabled={!isReadyToSubmit}
                      loading={isLoading}
                    >
                      送信する
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Container>
        </div>
        <LoginModal />
      </MainLayout>
    </>
  );
};

export { Presenter };
