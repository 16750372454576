import { combineReducers, Reducer } from 'redux';
import { types } from './types';
import { STATUS } from '../constants';
import type { Status } from '../constants';
import type { RootAction } from '/@/store/actions';
import type { Error } from '../types';

const status: Reducer<Status, RootAction> = (state = STATUS.IDLE, action) => {
  switch (action.type) {
    case types.GET_MY_GIFT_START:
      return STATUS.LOADING;
    case types.GET_MY_GIFT_SUCCESS:
      return STATUS.SUCCESS;
    case types.GET_MY_GIFT_FAILURE:
      return STATUS.FAILURE;
    default:
      return state;
  }
};

const error: Reducer<Error, RootAction> = (state = null, action) => {
  switch (action.type) {
    case types.GET_MY_GIFT_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const getMyGift = combineReducers({
  status,
  error,
});

export { getMyGift };
