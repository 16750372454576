import { Reducer } from 'redux';
import { types } from './types';
import type { RootAction } from '/@/store/actions';
import { discardMyGiftsTypes } from '/@/store/api/discardMyGifts';

type MyGiftsDiscardConfirmationModalOpenState = boolean;

const initialState: MyGiftsDiscardConfirmationModalOpenState = false;

const myGiftsDiscardConfirmationModalOpen: Reducer<
  MyGiftsDiscardConfirmationModalOpenState,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_MY_GIFTS_DISCARD_CONFIRMATION_MODAL:
      return !state;
    case discardMyGiftsTypes.DISCARD_MY_GIFTS_IDLE:
      return false;
    default:
      return state;
  }
};

export { myGiftsDiscardConfirmationModalOpen };
export type { MyGiftsDiscardConfirmationModalOpenState };
