import { gql } from '@apollo/client';

export const giftConvertToPointRequestSubscriptionGql = gql`
  subscription GiftConvertToPointRequest($requestUid: String!) {
    giftConvertToPointCompletion(requestUid: $requestUid) {
      giftConvertToPointRequest {
        convertPointAmount
        errorMessage
        requestUid
      }
    }
  }
`;
