import { FetchResult } from '@apollo/client';
import { publicApiClient } from '/@/api/graphql/appApolloClient';
import { signInOrSignUpGql } from './index.gql';
import { SignInOrSignUpInput, SignInOrSignUpMutation } from '../types';

type SignInOrSignUpResponse = FetchResult<SignInOrSignUpMutation>;

const signInOrSignUp = (
  signInOrSignUpInput: SignInOrSignUpInput,
): Promise<SignInOrSignUpResponse> => {
  return publicApiClient.mutate({
    mutation: signInOrSignUpGql,
    variables: { signInOrSignUpInput },
  });
};

export { signInOrSignUp };
export type { SignInOrSignUpResponse };
