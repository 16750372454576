import { getPromotionSpots } from '/@/api/graphql/internalApi/getPromotionSpots';
import type { GetPromotionSpotsResponse } from '/@/api/graphql/internalApi/getPromotionSpots';
import type { AppThunk } from '/@/store';
import {
  getPromotionSpotsStart,
  getPromotionSpotsSuccess,
  getPromotionSpotsFailure,
} from './actions';
import type { PromotionSpotNameEnum } from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getPromotionSpotsThunk =
  (spotName: PromotionSpotNameEnum): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getPromotionSpotsStart());

    return getPromotionSpots(spotName)
      .then((response) => dispatch(getPromotionSpotsSuccessThunk(response)))
      .catch((error) => dispatch(getPromotionSpotsFailureThunk(error)));
  };

const getPromotionSpotsSuccessThunk =
  (response: GetPromotionSpotsResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { promotionSpots } = response.data!;
    dispatch(getPromotionSpotsSuccess(promotionSpots));
    return Promise.resolve();
  };

const getPromotionSpotsFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getPromotionSpotsFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getPromotionSpotsThunk };
