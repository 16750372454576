import { Reducer } from 'redux';
import { BrandBase } from '/@/store/app/shared/types';
import type { RootAction } from '/@/store/actions';
import { getBrandTypes } from '/@/store/api/getBrand';
import { types } from './types';

type BrandState = Brand | null;
type Brand = BrandBase;

const initialState: BrandState = null;

const brand: Reducer<BrandState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getBrandTypes.GET_BRAND_SUCCESS: {
      if (!action.brand) return null;
      return action.brand;
    }
    case types.INITIALISE_BRAND: {
      return initialState;
    }
    default:
      return state;
  }
};

export { brand };
export type { Brand, BrandState };
