import { Reducer } from 'redux';
import {
  GetMyGiftsConnectionQuery,
  MyGiftConnection,
} from '/@/api/graphql/internalApi/types';
import { getInitialExpiringMyGiftsConnectionTypes } from '/@/store/api/getInitialExpiringMyGiftsConnection';
import { getContinuousExpiringMyGiftsConnectionTypes } from '/@/store/api/getContinuousExpiringMyGiftsConnection';
import { MyGiftBase } from '/@/store/app/shared/types';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';

type ExpiringMyGiftsState = ExpiringMyGift[] | null;
type PageInfoState = MyGiftConnection['pageInfo'] | null;
type ExpiringMyGiftsConnectionState = {
  expiringMyGifts: ExpiringMyGiftsState;
  pageInfo: PageInfoState;
};
type ExpiringMyGift = MyGiftBase;

const initialState: ExpiringMyGiftsState = null;

const expiringMyGifts: Reducer<ExpiringMyGiftsState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getInitialExpiringMyGiftsConnectionTypes.GET_INITIAL_EXPIRING_MY_GIFTS_CONNECTION_SUCCESS:
      if (!action.expiringMyGiftsConnection.nodes) return null;
      return filterNodes(action.expiringMyGiftsConnection.nodes);
    case getContinuousExpiringMyGiftsConnectionTypes.GET_CONTINUOUS_EXPIRING_MY_GIFTS_CONNECTION_SUCCESS: {
      if (!action.expiringMyGiftsConnection.nodes) return state;
      const filteredNodes = filterNodes(action.expiringMyGiftsConnection.nodes);

      if (state === null) {
        return filteredNodes;
      } else {
        return [...state, ...filteredNodes];
      }
    }
    default:
      return state;
  }
};

const filterNodes = (
  expiringMyGiftsConnectionNodes: NonNullable<
    GetMyGiftsConnectionQuery['myGiftsConnection']['nodes']
  >,
): NonNullable<ExpiringMyGiftsState> =>
  expiringMyGiftsConnectionNodes.filter(
    (node): node is ExpiringMyGift => !!node,
  );

const pageInfoInitialState: PageInfoState = null;

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getInitialExpiringMyGiftsConnectionTypes.GET_INITIAL_EXPIRING_MY_GIFTS_CONNECTION_SUCCESS:
    case getContinuousExpiringMyGiftsConnectionTypes.GET_CONTINUOUS_EXPIRING_MY_GIFTS_CONNECTION_SUCCESS:
      return action.expiringMyGiftsConnection.pageInfo;
    default:
      return state;
  }
};

const expiringMyGiftsConnection = combineReducers({
  expiringMyGifts,
  pageInfo,
});

export { expiringMyGiftsConnection };
export type {
  ExpiringMyGiftsConnectionState,
  ExpiringMyGift,
  ExpiringMyGiftsState,
};
