import React from 'react';
import { Presenter } from './Presenter';
import { useAppSelector } from '/@/store';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

const Container: React.VFC<Record<string, never>> = () => {
  const myPoint = useAppSelector((state) => state.app.pointWallet?.point);
  const getPointWalletStatus = useAppSelector(
    (state) => state.api.getPointWallet.status,
  );
  const chargedPoint = useAppSelector(
    (state) => state.app.chargedPoint?.pointAmount,
  );
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate(PATHS.HOME);
  };

  return (
    <Presenter
      chargedPoint={chargedPoint}
      myPoint={myPoint}
      navigateToHome={navigateToHome}
      getPointWalletStatus={getPointWalletStatus}
    />
  );
};

export { Container as CompletionPage };
