export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
};

export type ChoosableGiftCardTicket = {
  expiredAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
  urlCode: Scalars['String']['output'];
};

/** Autogenerated input type of CreateOauthSession */
export type CreateOauthSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  destinationOption?: InputMaybe<DestinationOptionInput>;
  giftSavingOption?: InputMaybe<GiftSavingOptionInput>;
  pointSerialOption?: InputMaybe<PointSerialOptionInput>;
  userAuthenticatorUid: Scalars['String']['input'];
};

/** Autogenerated return type of CreateOauthSession. */
export type CreateOauthSessionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  oauthSession: CreatedOauthSession;
};

export type CreatedOauthSession = {
  authorizationUrl: Scalars['String']['output'];
  destinationOption: Maybe<DestinationOption>;
  giftSavingOption: Maybe<GiftSavingOption>;
  pointSerialOption: Maybe<PointSerialOption>;
};

export enum DestinationEnum {
  Gifts = 'gifts',
  Inquiry = 'inquiry',
  Promotions = 'promotions',
}

export type DestinationOption = {
  name: DestinationEnum;
};

export type DestinationOptionInput = {
  name: DestinationEnum;
};

export type Error = {
  extensions: ErrorExtensions;
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
};

export enum ErrorCodeEnumType {
  CampaignReachedLimitation = 'CAMPAIGN_REACHED_LIMITATION',
  DuplicatedGifteeContent = 'DUPLICATED_GIFTEE_CONTENT',
  ExchangeError = 'EXCHANGE_ERROR',
  ItemNotAvailable = 'ITEM_NOT_AVAILABLE',
  LineAccessTokenRevoked = 'LINE_ACCESS_TOKEN_REVOKED',
  LineAuthenticationFailed = 'LINE_AUTHENTICATION_FAILED',
  LineUnfriended = 'LINE_UNFRIENDED',
  PointBackCampaignNotAvailable = 'POINT_BACK_CAMPAIGN_NOT_AVAILABLE',
  PointWalletBalanceShortage = 'POINT_WALLET_BALANCE_SHORTAGE',
  SerialCodeNotFound = 'SERIAL_CODE_NOT_FOUND',
  SerialCodeOutOfPeriod = 'SERIAL_CODE_OUT_OF_PERIOD',
  SerialCodeUsed = 'SERIAL_CODE_USED',
}

export type ErrorExtensions = {
  code: Maybe<ErrorCodeEnumType>;
  status: Scalars['Int']['output'];
};

export type FixedGiftCardTicket = {
  expiredAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
  urlCode: Scalars['String']['output'];
};

export type GiftCard = {
  giftCardTickets: Array<GiftCardTicket>;
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  origin: Scalars['String']['output'];
  project: Project;
  url: Scalars['String']['output'];
  urlCode: Scalars['String']['output'];
};

export type GiftCardTicket = {
  ticket: GiftCardTicketUnion;
  urlCode: Scalars['String']['output'];
};

export type GiftCardTicketUnion = ChoosableGiftCardTicket | FixedGiftCardTicket;

export type GiftSavingOption = {
  convertToPoint: Scalars['Boolean']['output'];
  origin: OriginEnum;
  referer: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type GiftSavingOptionInput = {
  convertToPoint?: InputMaybe<Scalars['Boolean']['input']>;
  origin: OriginEnum;
  referer?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type GifteeBox = {
  expiredAt: Scalars['ISO8601DateTime']['output'];
  imageUrl: Scalars['String']['output'];
  initialPoint: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  project: Project;
  url: Scalars['String']['output'];
  urlCode: Scalars['String']['output'];
};

export type GifteeContent = {
  content: GifteeContentUnion;
};

export type GifteeContentUnion = GiftCard | GifteeBox;

export type Mutation = {
  createOauthSession: CreateOauthSessionPayload;
  notifyInquiry: NotifyInquiryPayload;
  signInOrSignUp: SignInOrSignUpPayload;
};

export type MutationCreateOauthSessionArgs = {
  input: CreateOauthSessionInput;
};

export type MutationNotifyInquiryArgs = {
  input: NotifyInquiryInput;
};

export type MutationSignInOrSignUpArgs = {
  input: SignInOrSignUpInput;
};

/** Autogenerated input type of NotifyInquiry */
export type NotifyInquiryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sessionToken?: InputMaybe<Scalars['String']['input']>;
  topic: Scalars['String']['input'];
};

/** Autogenerated return type of NotifyInquiry. */
export type NotifyInquiryPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type OauthSession = {
  destinationOption: Maybe<DestinationOption>;
  giftSavingOption: Maybe<GiftSavingOption>;
  pointSerialOption: Maybe<PointSerialOption>;
};

export enum OriginEnum {
  G4b = 'g4b',
  Gcp = 'gcp',
}

export type PointSerialOption = {
  serialCode: Scalars['String']['output'];
};

export type PointSerialOptionInput = {
  serialCode: Scalars['String']['input'];
};

export type Prefecture = {
  name: Scalars['String']['output'];
};

export type Project = {
  name: Scalars['String']['output'];
  uid: Scalars['String']['output'];
};

export type Query = {
  gifteeContent: GifteeContent;
  oauthSession: OauthSession;
  prefectures: Array<Prefecture>;
  userAuthenticators: Array<UserAuthenticator>;
};

export type QueryGifteeContentArgs = {
  origin: OriginEnum;
  url: Scalars['String']['input'];
};

export type QueryOauthSessionArgs = {
  state: Scalars['String']['input'];
};

/** Autogenerated input type of SignInOrSignUp */
export type SignInOrSignUpInput = {
  authorizationCode: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
};

/** Autogenerated return type of SignInOrSignUp. */
export type SignInOrSignUpPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  oauthSession: Maybe<OauthSession>;
  session: Maybe<UserSession>;
};

export type UserAuthenticator = {
  label: Scalars['String']['output'];
  uid: Scalars['String']['output'];
};

export type UserSession = {
  expiredAt: Scalars['ISO8601DateTime']['output'];
  token: Scalars['String']['output'];
};

export type CreateOauthSessionMutationVariables = Exact<{
  createOauthSessionInput: CreateOauthSessionInput;
}>;

export type CreateOauthSessionMutation = {
  createOauthSession: { oauthSession: { authorizationUrl: string } };
};

export type GetOauthSessionQueryVariables = Exact<{
  state: Scalars['String']['input'];
}>;

export type GetOauthSessionQuery = {
  oauthSession: {
    giftSavingOption: {
      url: string;
      origin: OriginEnum;
      referer: string | null;
      convertToPoint: boolean;
    } | null;
    destinationOption: { name: DestinationEnum } | null;
    pointSerialOption: { serialCode: string } | null;
  };
};

export type GetPrefecturesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPrefecturesQuery = { prefectures: Array<{ name: string }> };

export type GetGifteeContentQueryVariables = Exact<{
  url: Scalars['String']['input'];
  origin: OriginEnum;
}>;

export type GetGifteeContentQuery = {
  gifteeContent: {
    content:
      | {
          __typename: 'GiftCard';
          url: string;
          name: string;
          origin: string;
          giftCardTickets: Array<{
            ticket:
              | {
                  __typename: 'ChoosableGiftCardTicket';
                  url: string;
                  name: string;
                  expiredAt: string | null;
                  imageUrl: string;
                }
              | {
                  __typename: 'FixedGiftCardTicket';
                  url: string;
                  name: string;
                  expiredAt: string | null;
                  imageUrl: string;
                };
          }>;
        }
      | {
          __typename: 'GifteeBox';
          url: string;
          name: string;
          imageUrl: string;
          expiredAt: string;
          initialPoint: number;
        };
  };
};

export type GetUserAuthenticatorsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserAuthenticatorsQuery = {
  userAuthenticators: Array<{ label: string; uid: string }>;
};

export type NotifyInquiryMutationVariables = Exact<{
  input: NotifyInquiryInput;
}>;

export type NotifyInquiryMutation = {
  notifyInquiry: {
    errors: Array<{
      message: string;
      path: Array<string>;
      extensions: { status: number; code: ErrorCodeEnumType | null };
    }>;
  };
};

export type SignInOrSignUpMutationVariables = Exact<{
  signInOrSignUpInput: SignInOrSignUpInput;
}>;

export type SignInOrSignUpMutation = {
  signInOrSignUp: {
    session: { token: string; expiredAt: string } | null;
    oauthSession: {
      giftSavingOption: {
        url: string;
        origin: OriginEnum;
        referer: string | null;
      } | null;
      destinationOption: { name: DestinationEnum } | null;
      pointSerialOption: { serialCode: string } | null;
    } | null;
    errors: Array<{
      message: string;
      path: Array<string>;
      extensions: { status: number; code: ErrorCodeEnumType | null };
    }>;
  };
};
