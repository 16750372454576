import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

export const FOOTER_HEIGHT = 92;

const styles = (theme: Theme) => {
  return createStyles({
    footer: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      backgroundColor: theme.palette.background.hint,
      width: '100%',
      height: FOOTER_HEIGHT,
      minHeight: FOOTER_HEIGHT,
    },
    copyright: {
      textAlign: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      color: theme.palette.secondary.main,
    },
    itemGrid: {
      textAlign: 'center',
      padding: theme.spacing(),
    },
    link: {
      textDecoration: 'none',
    },
    linkTitle: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
  });
};

type OwnProps = Record<string, never>;

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes }) => (
  <footer className={classes.footer} data-cy="footer">
    <Grid container justifyContent="center" alignItems="center">
      <Grid item className={classes.itemGrid}>
        <a
          href="https://giftee.co.jp/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <Typography variant="caption" className={classes.linkTitle}>
            運営会社
          </Typography>
        </a>
      </Grid>
      <Grid item className={classes.itemGrid}>
        <Link to={PATHS.FREQUENTLY_ASKED_QUESTIONS} className={classes.link}>
          <Typography variant="caption" className={classes.linkTitle}>
            よくあるご質問
          </Typography>
        </Link>
      </Grid>
      <Grid item className={classes.itemGrid}>
        <Link to={PATHS.TERMS_OF_SERVICE} className={classes.link}>
          <Typography variant="caption" className={classes.linkTitle}>
            サービス利用規約
          </Typography>
        </Link>
      </Grid>
    </Grid>
    <Typography display="block" className={classes.copyright}>
      ©︎giftee, inc.
    </Typography>
  </footer>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
