import { types } from './types';
import type { GetItemsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getItemsConnectionStart = () =>
  ({
    type: types.GET_ITEMS_CONNECTION_START,
  }) as const;

export const getItemsConnectionSuccess = (
  itemsConnection: GetItemsConnectionQuery['items'],
) =>
  ({
    type: types.GET_ITEMS_CONNECTION_SUCCESS,
    itemsConnection,
  }) as const;

export const getItemsConnectionFailure = (error: ApiError) =>
  ({
    type: types.GET_ITEMS_CONNECTION_FAILURE,
    error,
  }) as const;
