type GenderCode = {
  code: number;
  name: string;
};

// According to ISO/IEC 5218:2004
// cf. https://www.iso.org/standard/36266.html
const genderCodes: GenderCode[] = [
  { code: 1, name: '男性' },
  { code: 2, name: '女性' },
  { code: 9, name: 'その他' },
];

type GenderCodes = typeof genderCodes;

export type { GenderCode, GenderCodes };
export { genderCodes };
