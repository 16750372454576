import React from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/core';
import { GifteeContentBase } from '/@/store/app/shared/types';
import { GiftCardGifteeContent } from './giftCardGifteeContent/Index';
import { GifteeBoxGifteeContent } from './gifteeBoxGifteeContent/Index';
import { typeGuards } from '/@/utils/typeGuards';
const styles = () => {
  return createStyles({});
};

export type GifteeContent = GifteeContentBase;

type OwnProps = {
  gifteeContent: GifteeContent;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ gifteeContent }) => {
  if (typeGuards.app.isGiftCardGifteeContent(gifteeContent))
    return (
      <div data-cy="gifteeContent">
        <GiftCardGifteeContent giftCardGifteeContent={gifteeContent} />
      </div>
    );
  if (typeGuards.app.isGifteeBoxGifteeContent(gifteeContent))
    return (
      <div data-cy="gifteeContent">
        <GifteeBoxGifteeContent gifteeBoxGifteeContent={gifteeContent} />
      </div>
    );
  return <></>;
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
