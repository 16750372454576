import React from 'react';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PointChargeSection } from './pointChargeSection/Index';
import { RecommendedGiftsSection } from './recommendedGiftsSection/Index';

type OwnProps = {
  headerTitle: string;
  skipConfirm?: boolean;
};

type Props = OwnProps;

const Presenter: React.VFC<Props> = ({ headerTitle, skipConfirm }) => (
  <MainLayout headerTitle={headerTitle}>
    <div>
      <PointChargeSection skipConfirm={skipConfirm} />
      {skipConfirm !== true && <RecommendedGiftsSection />}
    </div>
  </MainLayout>
);

export { Presenter };
