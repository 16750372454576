import { InMemoryCache, createHttpLink, DefaultOptions } from '@apollo/client';

import { getGiftWalletApiUri } from './uri';
import { getSessionTokenFromCookie } from '/@/utils/getSessionTokenFromCookie';
import { setContext } from '@apollo/client/link/context';
import { AppApolloClient } from './client';

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
  },
  mutate: {
    fetchPolicy: 'no-cache',
  },
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
};

const internalApiHttpLink = createHttpLink({
  uri: `${getGiftWalletApiUri()}/internal_api/graphql`,
});

const internalApiAddRequestHeaderLink = setContext((_, { headers }) => {
  const sessionToken = getSessionTokenFromCookie();
  return {
    headers: {
      ...headers,
      authorization:
        typeof sessionToken === 'string'
          ? `Basic ${btoa(`${sessionToken}:`)}`
          : '',
    },
  };
});

const internalApiClient = new AppApolloClient({
  link: internalApiAddRequestHeaderLink.concat(internalApiHttpLink),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions,
});

const publicApiClient = new AppApolloClient({
  uri: `${getGiftWalletApiUri()}/public_api/graphql`,
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions,
});

export { internalApiClient, publicApiClient };
