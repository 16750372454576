import React from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/core';
import { GifteeBoxBase } from '/@/store/app/shared/types';
import { TicketSkelton } from '/@/components/shared/gifteeContent/ticketSkelton/Index';

const styles = () => {
  return createStyles({});
};

export type GifteeBox = GifteeBoxBase;
type OwnProps = {
  gifteeBox: GifteeBox;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ gifteeBox }) => (
  <div data-cy="gifteeBox">
    <TicketSkelton
      name={gifteeBox.name}
      imageUrl={gifteeBox.imageUrl}
      url={gifteeBox.url}
      expiredAt={gifteeBox.expiredAt}
      expiredAtLabel="交換"
    />
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
