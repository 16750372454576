import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Card,
  CardActionArea,
  Typography,
  Grid,
} from '@material-ui/core';
import { PromotionSpotsState } from '/@/store/app/promotionSpots';

const styles = (theme: Theme) => {
  const REWARD_SIZE = 70;
  const PROMOTER_SIZE = 40;
  return createStyles({
    card: {
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    promotionWrapper: {
      marginBottom: theme.spacing(),
    },
    promotionTag: {
      display: 'inline-block',
      borderBottomRightRadius: theme.spacing(2),
      padding: theme.spacing(),
      backgroundColor: theme.palette.background.contrast,
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    rewardCardSection: {
      padding: theme.spacing(2),
    },
    rewardImage: {
      height: REWARD_SIZE,
      width: REWARD_SIZE,
      marginRight: theme.spacing(),
      borderRadius: theme.spacing(),
    },
    rewardName: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    promoterCardSection: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.hint,
    },
    promoterImage: {
      height: PROMOTER_SIZE,
      width: PROMOTER_SIZE,
      marginRight: theme.spacing(),
      borderRadius: theme.spacing(1),
    },
    promoterName: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    promotionDescription: {
      marginTop: theme.spacing(),
    },
  });
};

type OwnProps = {
  promotionSpot: NonNullable<PromotionSpotsState>[number];
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, promotionSpot }) => (
  <Card className={classes.card}>
    <CardActionArea
      href={promotionSpot.promotionPublishment.url}
      role="link"
      data-promotion-name={promotionSpot.promotionPublishment.promotion.name}
      data-promotion-spot-name={promotionSpot.name}
    >
      <Grid className={classes.promotionWrapper}>
        <Typography
          color="primary"
          variant="caption"
          display="inline"
          className={classes.promotionTag}
        >
          {promotionSpot.promotionPublishment.promotion.promotionTag.name}
        </Typography>
      </Grid>
      <Grid container wrap="nowrap" className={classes.rewardCardSection}>
        <img
          className={classes.rewardImage}
          src={promotionSpot.promotionPublishment.promotion.reward.imageUrl}
          alt={promotionSpot.promotionPublishment.promotion.reward.name}
        />
        <Typography variant="body1" className={classes.rewardName}>
          {promotionSpot.promotionPublishment.promotion.reward.name}
        </Typography>
      </Grid>
      <Grid container wrap="nowrap" className={classes.promoterCardSection}>
        <img
          className={classes.promoterImage}
          src={promotionSpot.promotionPublishment.promotion.promoter.imageUrl}
          alt={promotionSpot.promotionPublishment.promotion.promoter.name}
        />
        <Grid container direction="column">
          <Typography variant="body2" className={classes.promoterName}>
            {promotionSpot.promotionPublishment.promotion.name}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {promotionSpot.promotionPublishment.promotion.promoter.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
            className={classes.promotionDescription}
          >
            {promotionSpot.promotionPublishment.promotion.description}
          </Typography>
        </Grid>
      </Grid>
    </CardActionArea>
  </Card>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
