import { types } from './types';
import { ErrorCodeEnumType } from '/@/api/graphql/publicApi/types';

export const setPointChargeWithSerialCodeError = (
  errorCode: ErrorCodeEnumType,
) =>
  ({
    type: types.SET_POINT_CHARGE_WITH_SERIAL_CODE_ERROR,
    errorCode,
  }) as const;
