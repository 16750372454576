import { types } from './types';
import type { CreateGiftConvertToPointMutation } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const createGiftConvertToPointInit = () =>
  ({ type: types.CREATE_GIFT_CONVERT_TO_POINT_INIT }) as const;

export const createGiftConvertToPointStart = () =>
  ({ type: types.CREATE_GIFT_CONVERT_TO_POINT_START }) as const;

export const createGiftConvertToPointSuccess = (
  giftConvertToPointRequest: CreateGiftConvertToPointMutation['createGiftConvertToPointRequest']['giftConvertToPointRequest'],
) =>
  ({
    type: types.CREATE_GIFT_CONVERT_TO_POINT_SUCCESS,
    giftConvertToPointRequest,
  }) as const;

export const createGiftConvertToPointFailure = (error: ApiError) =>
  ({
    type: types.CREATE_GIFT_CONVERT_TO_POINT_FAILURE,
    error,
  }) as const;
