import { Error } from '/@/api/graphql/internalApi/types';

// MEMO: GraphQLのレスポンス内の独自で定義されたエラーコードを抽出
const filteringExtensionsErrorCode = (errors: Error[]) => {
  if (Array.isArray(errors) && errors.length > 0) {
    const errorCode = errors.find((error) => error.extensions.code)?.extensions
      .code;
    if (errorCode) return errorCode;
  }
  return null;
};

export { filteringExtensionsErrorCode };
