import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getMyGiftsConnectionGql } from './index.gql';
import {
  GetMyGiftsConnectionQuery,
  GetMyGiftsConnectionQueryVariables,
} from '../types';

type GetMyGiftsConnectionResponse = FetchResult<GetMyGiftsConnectionQuery>;

const getMyGiftsConnection = (
  variables?: GetMyGiftsConnectionQueryVariables,
): Promise<GetMyGiftsConnectionResponse> => {
  return internalApiClient.query({
    query: getMyGiftsConnectionGql,
    variables,
  });
};

export { getMyGiftsConnection };

export type { GetMyGiftsConnectionResponse };
