import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { createUserProfileGql } from './index.gql';
import { CreateUserProfileInput, CreateUserProfileMutation } from '../types';

type CreateUserProfileResponse = FetchResult<CreateUserProfileMutation>;

const createUserProfile = (
  input: CreateUserProfileInput,
): Promise<CreateUserProfileResponse> => {
  return internalApiClient.mutate({
    mutation: createUserProfileGql,
    variables: {
      input,
    },
  });
};

export { createUserProfile };

export type { CreateUserProfileResponse };
