import { gql } from '@apollo/client';

export const getSavingGifteeContentGql = gql`
  query GetGifteeContent($url: String!, $origin: OriginEnum!) {
    gifteeContent(url: $url, origin: $origin) {
      content {
        ... on GiftCard {
          __typename
          url
          name
          origin
          giftCardTickets {
            ticket {
              ... on FixedGiftCardTicket {
                __typename
                url
                name
                expiredAt
                imageUrl
              }
              ... on ChoosableGiftCardTicket {
                __typename
                url
                name
                expiredAt
                imageUrl
              }
            }
          }
        }
        ... on GifteeBox {
          __typename
          url
          name
          imageUrl
          expiredAt
          initialPoint
        }
      }
    }
  }
`;
