import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

const styles = () => {
  return createStyles({
    cautionText: {
      color: '#4E5B61',
      fontSize: '0.75rem',
      textAlign: 'left',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      paddingLeft: '0.75rem', // ※の位置調整
      textIndent: '-0.75rem', // ※の位置調整
    },
  });
};

type Props = WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes }) => (
  <>
    <Typography className={classes.cautionText}>
      ※Amazon、Amazon.co.jpおよびそれらのロゴは Amazon.com, Inc.
      またはその関連会社の商標です。
    </Typography>
    <Typography className={classes.cautionText}>
      ※本プログラムは株式会社ギフティによる提供です。本プログラムについてのお問い合わせは
      Amazonではお受けしておりません。 gift walletにログイン後の「
      <Link to={PATHS.INQUIRIES_NEW}>お問い合わせ</Link>」よりお願いいたします。
    </Typography>
    <Typography className={classes.cautionText}>
      ※GOおよび、GOチケットのロゴはGO株式会社の商標です。
    </Typography>
    <Typography className={classes.cautionText}>
      ※本プログラムは株式会社ギフティによる提供です。本プログラムについてのお問い合わせはGOではお受けしておりません。
      gift walletにログイン後の「
      <Link to={PATHS.INQUIRIES_NEW}>お問い合わせ</Link>」よりお願いいたします。
    </Typography>
    <Typography className={classes.cautionText}>
      ※Coke ONはThe Coca-Cola Companyの登録商標です。
    </Typography>
    <Typography className={classes.cautionText}>
      ※ © 2024 iTunes K.K. All rights reserved.
    </Typography>
    <Typography className={classes.cautionText}>
      ※Google Play は Google LLCの商標です。
    </Typography>
    <Typography className={classes.cautionText}>
      ※「au PAY」は、KDDI株式会社の商標です。
    </Typography>
    <Typography className={classes.cautionText}>
      ※PayPayポイントは出金、譲渡不可です。PayPay／PayPayカード公式ストアでも利用可能です。
    </Typography>
  </>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
