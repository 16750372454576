import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getBrandGql } from './index.gql';
import { GetBrandQueryVariables, GetBrandQuery } from '../types';

type GetBrandResponse = FetchResult<GetBrandQuery>;

const getBrand = (
  variables: GetBrandQueryVariables,
): Promise<GetBrandResponse> => {
  return internalApiClient.query({
    query: getBrandGql,
    variables,
  });
};

export { getBrand };

export type { GetBrandResponse };
