import React, { useEffect } from 'react';
import { Presenter } from './Presenter';
import { OriginEnum, DestinationEnum } from '/@/api/graphql/publicApi/types';
import { useAppDispatch, useAppSelector } from '/@/store';
import { STATUS } from '/@/store/api/constants';
import { createOauthSessionThunk } from '/@/store/api/createOauthSession';
import { getUserAuthenticatorsThunk } from '/@/store/api/getUserAuthenticators';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { PATHS, PathValues } from '/@/routes/paths';
import { createOauthSessionOption } from '/@/utils/createOauthSessionOption';
import { useLocation } from 'react-router-dom';

export type LoginOption = {
  url: string | null;
  origin: OriginEnum | null;
  referer: string | null;
  convertToPoint: boolean | null;
  destination: DestinationEnum | null;
  serialCode: string | null;
  flow?: PathValues;
};

type Props = LoginOption & {
  children: string;
};

const Container: React.VFC<Props> = ({
  convertToPoint,
  destination,
  serialCode,
  flow,
  children,
}) => {
  const dispatch = useAppDispatch();
  const { pushClickEvent } = useDataLayer();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  const createOauthSessionApiStatus = useAppSelector(
    (state) => state.api.createOauthSession.status,
  );

  const authorizationUrl = useAppSelector(
    (state) => state.page.signInPage.authorizationUrl,
  );

  const userAuthenticators = useAppSelector(
    (state) => state.app.userAuthenticators,
  );

  const getUserAuthenticatorsStatus = useAppSelector(
    (state) => state.api.getUserAuthenticators.status,
  );

  const handleClick = () => {
    // ログインボタンクリック時のデータレイヤー送信
    pushClickEvent(
      {
        clickItem:
          flow === PATHS.LP_CONVERT_TO_POINT
            ? 'handleLpConvertToPointButton'
            : 'handleLoginButton',
        customData: {
          clickItem:
            flow === PATHS.LP_CONVERT_TO_POINT
              ? 'handleLpConvertToPointButton'
              : 'handleLoginButton',
          convertToPoint: convertToPoint,
          destination: destination,
          withSerialCode: serialCode ? true : false,
        },
      },
      true,
    );
    if (userAuthenticators && userAuthenticators.length) {
      // 足元UserAuthenticatorは一つしか登録せず運用する
      // 複数にするタイミングでログインのUIが変わるので一旦最初のものを固定で取得
      const userAuthenticator = userAuthenticators[0];
      const { giftSavingOption, destinationOption, pointSerialOption } =
        createOauthSessionOption(urlSearchParams);

      return dispatch(
        createOauthSessionThunk({
          userAuthenticatorUid: userAuthenticator.uid,
          giftSavingOption: giftSavingOption,
          destinationOption: destinationOption,
          pointSerialOption: pointSerialOption,
        }),
      ).catch(() => {});
    }
  };

  useEffect(() => {
    if (
      createOauthSessionApiStatus === STATUS.SUCCESS &&
      authorizationUrl !== null
    ) {
      location.href = authorizationUrl;
    }
  }, [createOauthSessionApiStatus, authorizationUrl]);

  useEffect(() => {
    if (getUserAuthenticatorsStatus === STATUS.IDLE) {
      dispatch(getUserAuthenticatorsThunk()).catch(() => {});
    }
  }, [dispatch, getUserAuthenticatorsStatus]);

  return <Presenter onClick={handleClick}>{children}</Presenter>;
};

export { Container as LoginButton };
