import { CreatePointExchangeInputWithTypeBase } from '../shared/types';
import { types } from './types';

export const initialiseCreatePointExchangeInput = () =>
  ({
    type: types.INITIALISE_CREATE_POINT_EXCHANGE_INPUT,
  }) as const;

export const setCreatePointExchangeInput = (
  createPointExchangeInput: CreatePointExchangeInputWithTypeBase | null,
) =>
  ({
    type: types.SET_CREATE_POINT_EXCHANGE_INPUT,
    createPointExchangeInput,
  }) as const;
