import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { BrandBase } from '/@/store/app/shared/types';
import { Status } from '/@/store/api/constants';

const styles = (theme: Theme) => {
  return createStyles({
    menuListItem: {
      padding: theme.spacing(1.5),
    },
    menuListItemText: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
    noExchangeableBrandText: {
      fontSize: '0.875rem',
      lineHeight: '1.4',
      fontWeight: 'bold',
      margin: theme.spacing(2),
    },
  });
};

type OwnProps = {
  brands: BrandBase[] | null;
  selectedUid: string | null;
  setBrandCondition: (uid: string) => void;
  getBrandsConnectionStatus: Status;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  brands,
  selectedUid,
  setBrandCondition,
  getBrandsConnectionStatus,
}) => (
  <Box>
    <List
      data-cy="searchBrandList"
      component="nav"
      aria-label="menu-list"
      style={{ paddingTop: 0 }}
    >
      {brands?.map((brand, Index) => (
        <div key={Index}>
          <ListItem
            button
            className={classes.menuListItem}
            onClick={() => setBrandCondition(brand.uid)}
            data-gtm-click="templates-header-searchHeader-brandCondition-setBrandConditionButton"
          >
            <ListItemIcon>
              {brand.uid === selectedUid && <CheckIcon />}
            </ListItemIcon>
            <ListItemText
              primary={brand.name}
              classes={{ primary: classes.menuListItemText }}
            />
          </ListItem>
          <Divider component="li" />
        </div>
      ))}
      {brands?.length === 0 && getBrandsConnectionStatus !== 'loading' && (
        <Typography className={classes.noExchangeableBrandText}>
          選択可能なブランドはありません
        </Typography>
      )}
    </List>
  </Box>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
