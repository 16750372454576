import { gql } from '@apollo/client';

export const pointUpdatedSubscriptionGql = gql`
  subscription PointUpdated {
    pointUpdated {
      pointWallet {
        point
        nearestAvailableWithdrawableUntilAt
      }
    }
  }
`;
