import React from 'react';
import { Presenter } from './Presenter';
import type { GiftCardGifteeContent } from './Presenter';

type Props = {
  giftCardGifteeContent: GiftCardGifteeContent;
};

const Container: React.VFC<Props> = ({ giftCardGifteeContent }) => {
  const ticketsCountText =
    giftCardGifteeContent.content.giftCardTickets.length > 1
      ? `全${giftCardGifteeContent.content.giftCardTickets.length}件`
      : '';

  return (
    <Presenter
      giftCardGifteeContent={giftCardGifteeContent}
      ticketsCountText={ticketsCountText}
    />
  );
};

export { Container as GiftCardGifteeContent };
