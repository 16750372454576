import { RootState } from '/@/store';
import { Status, STATUS } from '/@/store/api/constants';
import { createSelector } from 'reselect';

export const selectCreateUserProfileStatus = (state: RootState): Status => {
  return state.api.createUserProfile.status;
};

export const selectIsCreateUserProfileLoading = createSelector(
  [selectCreateUserProfileStatus],
  (createUserProfileStatus): boolean => {
    return createUserProfileStatus === STATUS.LOADING;
  },
);

export const selectIsCreateUserProfileSuccess = createSelector(
  [selectCreateUserProfileStatus],
  (createUserProfileStatus): boolean => {
    return createUserProfileStatus === STATUS.SUCCESS;
  },
);

export const selectGenderCode = (state: RootState): number | null =>
  state.page.userProfileNewPage.genderCode;

export const selectBirthdayYear = (state: RootState): string =>
  state.page.userProfileNewPage.birthdayYear;

export const selectBirthdayMonth = (state: RootState): string =>
  state.page.userProfileNewPage.birthdayMonth;

export const selectBirthdayDay = (state: RootState): string =>
  state.page.userProfileNewPage.birthdayDay;

export const selectPrefectureCode = (state: RootState): number | null =>
  state.page.userProfileNewPage.prefectureCode;

const selectIsGenderSelected = createSelector(
  [selectGenderCode],
  (genderCode): boolean => !!genderCode,
);

const selectIsBirthdayYearEntered = createSelector(
  [selectBirthdayYear],
  (birthdayYear): boolean => !!birthdayYear,
);

const selectIsBirthdayMonthEntered = createSelector(
  [selectBirthdayMonth],
  (birthdayMonth): boolean => !!birthdayMonth,
);

const selectIsBirthdayDayEntered = createSelector(
  [selectBirthdayDay],
  (birthdayDay): boolean => !!birthdayDay,
);

const selectIsPrefectureSelected = createSelector(
  [selectPrefectureCode],
  (prefectureCode): boolean => !!prefectureCode,
);

export const selectIsReadyToSubmit = createSelector(
  [
    selectIsGenderSelected,
    selectIsBirthdayYearEntered,
    selectIsBirthdayMonthEntered,
    selectIsBirthdayDayEntered,
    selectIsPrefectureSelected,
  ],
  (
    isGenderSelected,
    isBirthdayYearEntered,
    isBirthdayMonthEntered,
    isBirthdayDayEntered,
    isPrefectureSelected,
  ): boolean => {
    return [
      isGenderSelected,
      isBirthdayYearEntered,
      isBirthdayMonthEntered,
      isBirthdayDayEntered,
      isPrefectureSelected,
    ].every(Boolean);
  },
);
