import type { AppThunk } from '/@/store';
import { ApiError } from '/@/api/graphql/client';
import { handleApolloErrorThunk } from '/@/store/api/handleError/handleApolloError';
import { typeGuards } from '/@/utils/typeGuards';
import { ApiErrors } from '/@/store/api/handleError/constants';
import { updatePageError } from '/@/store/api/handleError/actions';
import * as Sentry from '@sentry/browser';

const handleErrorThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    if (typeGuards.api.isApolloError(error)) {
      return dispatch(handleApolloErrorThunk(error));
    }

    Sentry.captureException(error);
    dispatch(
      updatePageError(
        ApiErrors.UNEXPECTED_ERROR.message,
        ApiErrors.UNEXPECTED_ERROR.status,
      ),
    );
    return Promise.reject(error);
  };

export { handleErrorThunk };
