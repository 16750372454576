import { getMyGiftsConnection } from '/@/api/graphql/internalApi/getMyGiftsConnection';
import type { GetMyGiftsConnectionResponse } from '/@/api/graphql/internalApi/getMyGiftsConnection';
import type { AppThunk } from '/@/store';
import {
  getInitialExpiringMyGiftsConnectionStart,
  getInitialExpiringMyGiftsConnectionSuccess,
  getInitialExpiringMyGiftsConnectionFailure,
} from './actions';
import dayjs from 'dayjs';
import {
  MyGiftType_GiftTypeEnum,
  MyGiftSortConditionTargetEnumType,
  MyGiftUsageStatusEnum,
  SortDirectionEnum,
} from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getInitialExpiringMyGiftsConnectionThunk =
  (): AppThunk<Promise<void>> => (dispatch) => {
    dispatch(getInitialExpiringMyGiftsConnectionStart());

    return getMyGiftsConnection({
      sortConditions: [
        {
          direction: SortDirectionEnum.Asc,
          target: MyGiftSortConditionTargetEnumType.ExpiredAt,
        },
      ],
      usageStatuses: [MyGiftUsageStatusEnum.Available],
      expiredAtGteq: dayjs().toISOString(),
      expiredAtLteq: dayjs().add(7, 'days').toISOString(),
      giftTypeIn: [
        MyGiftType_GiftTypeEnum.GiftCardTicketChoosableContent,
        MyGiftType_GiftTypeEnum.GiftCardTicketFixedContent,
        MyGiftType_GiftTypeEnum.GifteeBox,
        MyGiftType_GiftTypeEnum.SimpleGift,
      ],
      first: 20,
    })
      .then((response) =>
        dispatch(getInitialExpiringMyGiftsConnectionSuccessThunk(response)),
      )
      .catch((error) =>
        dispatch(getInitialExpiringMyGiftsConnectionFailureThunk(error)),
      );
  };

const getInitialExpiringMyGiftsConnectionSuccessThunk =
  (response: GetMyGiftsConnectionResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { myGiftsConnection } = response.data!;
    dispatch(getInitialExpiringMyGiftsConnectionSuccess(myGiftsConnection));
    return Promise.resolve();
  };

const getInitialExpiringMyGiftsConnectionFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getInitialExpiringMyGiftsConnectionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getInitialExpiringMyGiftsConnectionThunk };
