import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getPromotionSpotsGql } from './index.gql';
import { GetPromotionSpotsQuery, QueryPromotionSpotsArgs } from '../types';

type GetPromotionSpotsResponse = FetchResult<GetPromotionSpotsQuery>;

const getPromotionSpots = (
  name: QueryPromotionSpotsArgs['name'],
): Promise<GetPromotionSpotsResponse> => {
  return internalApiClient.query({
    query: getPromotionSpotsGql,
    variables: { name },
  });
};

export { getPromotionSpots };

export type { GetPromotionSpotsResponse };
