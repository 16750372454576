import { Reducer } from 'redux';
import { discardMyGiftsTypes } from '/@/store/api/discardMyGifts';
import type { RootAction } from '/@/store/actions';
import { MyGiftBase } from '/@/store/app/shared/types';
import { myGiftsEditPageTypes } from '/@/store/page/giftsPage/editPage';

type SelectedMyGiftsState = SelectedMyGift[];

type SelectedMyGift = MyGiftBase;

const initialState: SelectedMyGiftsState = [];

const selectedMyGifts: Reducer<SelectedMyGiftsState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case discardMyGiftsTypes.DISCARD_MY_GIFTS_SUCCESS:
      return [];
    case myGiftsEditPageTypes.ADD_EDITING_MY_GIFT:
      return state.concat(action.myGift);
    case myGiftsEditPageTypes.REMOVE_EDITING_MY_GIFT:
      return state.filter((myGift) => myGift.urlCode !== action.myGift.urlCode);
    default:
      return state;
  }
};

export { selectedMyGifts };
export type { SelectedMyGiftsState, SelectedMyGift };
