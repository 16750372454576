import { createPointChargeWithSerialCode } from '/@/api/graphql/internalApi/createPointChargeWithSerialCode/index';
import type { CreatePointChargeWithSerialCodeResponse } from '/@/api/graphql/internalApi/createPointChargeWithSerialCode/index';
import type { AppThunk } from '/@/store';
import {
  createPointChargeWithSerialCodeStart,
  createPointChargeWithSerialCodeSuccess,
  createPointChargeWithSerialCodeFailure,
} from './actions';
import { handleErrorThunk } from '/@/store/api/handleError';
import type { ApiError } from '/@/api/graphql/client';
import { CreatePointChargeWithSerialCodeMutationVariables } from '/@/api/graphql/internalApi/types';
import { setPointChargeWithSerialCodeError } from '../../app/pointChargeWithSerialCodeError/actions';
import { filteringExtensionsErrorCode } from '/@/utils/filteringExtensionsErrorCode';

const createPointChargeWithSerialCodeThunk =
  (
    input: CreatePointChargeWithSerialCodeMutationVariables,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(createPointChargeWithSerialCodeStart());
    return createPointChargeWithSerialCode(input)
      .then((response) => {
        dispatch(createPointChargeWithSerialCodeSuccessThunk(response));
      })
      .catch((errors) => {
        const errorCode = filteringExtensionsErrorCode(errors);
        if (errorCode) dispatch(setPointChargeWithSerialCodeError(errorCode));
        dispatch(createPointChargeWithSerialCodeFailureThunk(errors));
      });
  };

const createPointChargeWithSerialCodeSuccessThunk =
  (
    response: CreatePointChargeWithSerialCodeResponse,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    const giftConvertToPointRequest =
      response.data!.createPointChargeWithSerialCode;
    dispatch(createPointChargeWithSerialCodeSuccess(giftConvertToPointRequest));
    return Promise.resolve();
  };

const createPointChargeWithSerialCodeFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(createPointChargeWithSerialCodeFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { createPointChargeWithSerialCodeThunk };
