import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Box,
  Container,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { POINT_SECTION_WITH_BENEFIT_OF_POINT_CHARGE_HEIGHT } from '/@/components/shared/headingWithPoints/Presenter';
import { FOOTER_HEIGHT } from '/@/components/templates/footer/Presenter';
import { HeadingWithPoints } from '/@/components/shared/headingWithPoints/Index';
import { AvailablePointCharges } from './availablePointCharges/Index';
import { PointLogList } from './pointLogList/Index';
import { TAB_NAME } from './Index';

const styles = (theme: Theme) =>
  createStyles({
    background: {
      backgroundColor: theme.palette.background.contrast,
    },
    baseBox: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },
    },
    boxContainer: {
      [theme.breakpoints.up('sm')]: {
        borderRadius: theme.spacing(2),
      },
      backgroundColor: theme.palette.background.default,
      padding: 0,
    },
    title: {
      fontWeight: 'bold',
    },
    mainContainer: {
      minHeight: `calc(100vh - ${HEADER_HEIGHT}px - ${POINT_SECTION_WITH_BENEFIT_OF_POINT_CHARGE_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
    },
    tab: {
      height: '3.5rem',
      borderBottom: '2px solid #e0e0e0',
    },
    tabLabel: {
      fontWeight: 'bold',
    },
  });

type OwnProps = {
  headerTitle: string;
  activeTabStatus: TAB_NAME;
  setActiveTabStatus: (tag: TAB_NAME) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  headerTitle,
  activeTabStatus,
  setActiveTabStatus,
}) => (
  <MainLayout headerTitle={headerTitle}>
    <div className={classes.background}>
      <Box className={classes.baseBox}>
        <Container maxWidth="sm" className={classes.boxContainer}>
          <HeadingWithPoints benefitOfPointChargeModalVisible={true} />
          <div className={classes.mainContainer}>
            <Tabs
              value={activeTabStatus}
              onChange={(_, value) => setActiveTabStatus(value)}
              aria-label="ポイントの表示を切り替えるタブ"
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label={
                  <Typography className={classes.tabLabel} variant="button">
                    ポイント有効期限
                  </Typography>
                }
                value="availablePointLogs"
                className={classes.tab}
                data-cy="availablePointLogs"
              />
              <Tab
                label={
                  <Typography className={classes.tabLabel} variant="button">
                    ポイント履歴
                  </Typography>
                }
                value="logList"
                className={classes.tab}
                data-cy="logList"
              />
            </Tabs>
            {activeTabStatus === 'availablePointLogs' && (
              <AvailablePointCharges />
            )}
            {activeTabStatus === 'logList' && <PointLogList />}
          </div>
        </Container>
      </Box>
    </div>
  </MainLayout>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
