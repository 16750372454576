import { RootState } from '/@/store';
import { createSelector } from 'reselect';
import { STATUS, Status } from '/@/store/api/constants';

const selectGetGifteeContentStatus = (state: RootState): Status =>
  state.api.getGifteeContent.status;

export const selectIsGetGifteeContentSuccess = createSelector(
  [selectGetGifteeContentStatus],
  (getGifteeContentStatus): boolean =>
    getGifteeContentStatus === STATUS.SUCCESS,
);
