import type { AppThunk } from '/@/store';
import { ApiError } from '/@/api/graphql/client';
import { handleApolloErrorThunk } from '/@/store/api/handleError/handleApolloError';
import { typeGuards } from '/@/utils/typeGuards';
import { ApiErrors } from '/@/store/api/handleError/constants';
import { updatePageError } from '/@/store/api/handleError/actions';
import * as Sentry from '@sentry/browser';

// MEMO: ApiError or error_message(string)を受け取る
const handleErrorThunk =
  (error: ApiError | string): AppThunk<Promise<void>> =>
  (dispatch) => {
    if (typeof error !== 'string' && typeGuards.api.isApolloError(error)) {
      return dispatch(handleApolloErrorThunk(error));
    }

    // MEMO: error_message取得時はalertMessageを表示しエラーページへは遷移しない
    if (typeof error !== 'string') {
      Sentry.captureException(error);
      dispatch(
        updatePageError(
          ApiErrors.UNEXPECTED_ERROR.message,
          ApiErrors.UNEXPECTED_ERROR.status,
        ),
      );
    }
    return Promise.reject(error);
  };

export { handleErrorThunk };
