import { types } from './types';
import type { GetBrandsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getNonRecommendedBrandsConnectionStart = () =>
  ({
    type: types.GET_NONRECOMMENDED_BRANDS_CONNECTION_START,
  }) as const;

export const getNonRecommendedBrandsConnectionSuccess = (
  brandsConnection: GetBrandsConnectionQuery['brands'],
) =>
  ({
    type: types.GET_NONRECOMMENDED_BRANDS_CONNECTION_SUCCESS,
    brandsConnection,
  }) as const;

export const getNonRecommendedBrandsConnectionFailure = (error: ApiError) =>
  ({
    type: types.GET_NONRECOMMENDED_BRANDS_CONNECTION_FAILURE,
    error,
  }) as const;
