import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getCurrentUserThunk } from '/@/store/api/getCurrentUser';
import { STATUS } from '/@/store/api/constants';

export const AUTHENTICATION_STATUS = {
  AUTHENTICATED: 'AUTHENTICATED',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  IDLE: 'IDLE',
} as const;

type AuthenticationStatus =
  (typeof AUTHENTICATION_STATUS)[keyof typeof AUTHENTICATION_STATUS];

// TODO: 単体テストを書きたい
export const useAuth = (): AuthenticationStatus => {
  const [authenticationStatus, setAuthenticationStatus] =
    useState<AuthenticationStatus>(AUTHENTICATION_STATUS.IDLE);

  const dispatch = useAppDispatch();

  const currentUserApiStatus = useAppSelector(
    (state) => state.api.getCurrentUser.status,
  );

  const currentUser = useAppSelector((state) => state.app.currentUser);

  useEffect(() => {
    if (!currentUser) {
      dispatch(getCurrentUserThunk()).catch(() => {});
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (currentUser) {
      setAuthenticationStatus(AUTHENTICATION_STATUS.AUTHENTICATED);
    } else if (currentUserApiStatus === STATUS.FAILURE) {
      setAuthenticationStatus(AUTHENTICATION_STATUS.UNAUTHENTICATED);
    }
  }, [currentUserApiStatus, currentUser]);

  return authenticationStatus;
};
