import { types } from './types';
import type { GetBrandQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

type Brand = GetBrandQuery['brand'];

export const getBrandStart = () =>
  ({
    type: types.GET_BRAND_START,
  }) as const;

export const getBrandSuccess = (brand: Brand) =>
  ({
    type: types.GET_BRAND_SUCCESS,
    brand,
  }) as const;

export const getBrandFailure = (error: ApiError) =>
  ({
    type: types.GET_BRAND_FAILURE,
    error,
  }) as const;
