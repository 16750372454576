import { combineReducers, Reducer } from 'redux';
import { types } from './types';
import { STATUS } from '../constants';
import type { Status } from '../constants';
import type { RootAction } from '/@/store/actions';
import type { Error } from '../types';

const status: Reducer<Status, RootAction> = (state = STATUS.IDLE, action) => {
  switch (action.type) {
    case types.POINT_UPDATED_SUBSCRIPTION_START:
      return STATUS.LOADING;
    case types.POINT_UPDATED_SUBSCRIPTION_SUCCESS:
      return STATUS.SUCCESS;
    case types.POINT_UPDATED_SUBSCRIPTION_FAILURE:
      return STATUS.FAILURE;
    default:
      return state;
  }
};

const error: Reducer<Error, RootAction> = (state = null, action) => {
  switch (action.type) {
    case types.POINT_UPDATED_SUBSCRIPTION_START:
      return null;
    case types.POINT_UPDATED_SUBSCRIPTION_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const pointUpdatedSubscription = combineReducers({ status, error });

export { pointUpdatedSubscription };
