import { RootState } from '/@/store';
import { createSelector } from 'reselect';
import { typeGuards } from '/@/utils/typeGuards';
import { MutationError, MutationErrors } from '/@/api/graphql/client';
import { ErrorCodeEnumType } from '/@/api/graphql/publicApi/types';

type ErrorMessage = string | null;

const selectSignInOrSignUpError = (state: RootState) =>
  state.api.signInOrSignUp.error;

const selectMutationErrors = createSelector(
  [selectSignInOrSignUpError],
  (signInOrSignUpError): MutationErrors | null => {
    if (!signInOrSignUpError) {
      return null;
    }

    if (!typeGuards.api.isMutationErrors(signInOrSignUpError)) {
      return null;
    }
    return signInOrSignUpError;
  },
);

export const selectLineAuthenticationFailedMessage = createSelector(
  [selectMutationErrors],
  (mutationErrors): ErrorMessage => {
    if (!mutationErrors) return null;

    const lineAuthenticationFailedError = mutationErrors.find((error) =>
      isLineAuthenticationFailedError(error),
    );

    return lineAuthenticationFailedError
      ? lineAuthenticationFailedError.message
      : null;
  },
);

const isLineAuthenticationFailedError = (error: MutationError) =>
  error.extensions.code === ErrorCodeEnumType.LineAuthenticationFailed;
