export const ApolloClientErrors = {
  NETWORK_ERROR: { status: 500, message: 'ネットワークエラーが発生しました。' },
  AUTHENTICATION_ERROR: {
    status: 401,
    message: 'ログインから時間が経過したため、再度ログインしてください。',
  },
  CLIENT_ERROR: { status: 400, message: 'クライアントエラーが発生しました。' },
  NOT_FOUND_ERROR: {
    status: 404,
    message: 'お探しのページが見つかりませんでした。',
  },
  UNEXPECTED_ERROR: {
    status: 500,
    message: '予期しないエラーが発生しました。',
  },
} as const;

export const ApiErrors = {
  UNEXPECTED_ERROR: {
    status: 500,
    message: '予期しないエラーが発生しました。',
  },
} as const;

export const MutationErrors = {
  INTERNAL_SERVER_ERROR: {
    status: 500,
    message: 'サーバーエラーが発生しました。',
  },
} as const;

export type ApolloClientErrorTypes =
  (typeof ApolloClientErrors)[keyof typeof ApolloClientErrors];
