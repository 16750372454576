import { Reducer } from 'redux';
import { handleErrorTypes } from '/@/store/api/handleError';
import { changePageTypes } from '/@/store/app/changePage';
import type { RootAction } from '/@/store/actions';

type PageErrorState = PageError | null;

type PageError = {
  message: string;
  status: number;
};

const pageError: Reducer<PageErrorState, RootAction> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case handleErrorTypes.UPDATE_PAGE_ERROR:
      return {
        message: action.message,
        status: action.status,
      };
    case changePageTypes.CHANGE_PAGE:
      return null;
    default:
      return state;
  }
};

export { pageError };
export type { PageErrorState };
