import { types } from './types';
import type { CreateOauthSessionMutation } from '/@/api/graphql/publicApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const createOauthSessionStart = () =>
  ({ type: types.CREATE_OAUTH_SESSION_START }) as const;

export const createOauthSessionSuccess = (
  oauthSession: CreateOauthSessionMutation['createOauthSession']['oauthSession'],
) =>
  ({
    type: types.CREATE_OAUTH_SESSION_SUCCESS,
    oauthSession,
  }) as const;

export const createOauthSessionFailure = (error: ApiError) =>
  ({
    type: types.CREATE_OAUTH_SESSION_FAILURE,
    error,
  }) as const;
