import { ApolloError } from '@apollo/client';
import { ApiError, MutationErrors, MutationError } from '/@/api/graphql/client';

export const isApolloError = (error: ApiError): error is ApolloError =>
  error !== undefined &&
  Object.prototype.hasOwnProperty.call(error, 'graphQLErrors');

export const isMutationErrors = (error: ApiError): error is MutationErrors =>
  error !== undefined &&
  Array.isArray(error) &&
  error.length > 0 &&
  error.every(isMutationError);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isMutationError = (error: any): error is MutationError =>
  error !== undefined &&
  Object.prototype.hasOwnProperty.call(error, 'message') &&
  Object.prototype.hasOwnProperty.call(error, 'path') &&
  Object.prototype.hasOwnProperty.call(error, 'extensions');
