import React, { useEffect, useState } from 'react';
import { Presenter } from './Presenter';
import { useAuth, AUTHENTICATION_STATUS } from '/@/common/hooks/useAuth';

const Container: React.VFC<Record<string, never>> = () => {
  const authenticationStatus = useAuth();

  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    setModalOpen(authenticationStatus !== AUTHENTICATION_STATUS.AUTHENTICATED);
  }, [authenticationStatus]);

  const handleClose = () => {
    setModalOpen(false);
  };

  return <Presenter modalOpen={modalOpen} handleClose={handleClose} />;
};

export { Container as LoginModal };
