import React from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { Presenter } from './Presenter';
import { initialiseAlertMessage } from '/@/store/ui/alertMessage/actions';

const Container: React.VFC<Record<string, never>> = () => {
  const alertMessage = useAppSelector((state) => state.ui.alertMessage);
  const dispatch = useAppDispatch();

  const handleInit = () => {
    dispatch(initialiseAlertMessage());
  };

  return alertMessage ? (
    <Presenter alertMessage={alertMessage} handleInit={handleInit} />
  ) : null;
};

export { Container as AlertMessage };
