import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getAvailablePointChargesGql } from './index.gql';
import { GetAvailablePointChargesQuery } from '../types';

type GetAvailablePointChargesResponse =
  FetchResult<GetAvailablePointChargesQuery>;

const getAvailablePointCharges = (
  input: {
    after?: string;
    first?: number;
  } = {},
): Promise<GetAvailablePointChargesResponse> => {
  return internalApiClient.query({
    query: getAvailablePointChargesGql,
    variables: input,
  });
};

export { getAvailablePointCharges };

export type { GetAvailablePointChargesResponse };
