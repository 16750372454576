import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Card,
  Typography,
} from '@material-ui/core';
import { ItemBase } from '/@/store/app/shared/types';
import { Points } from '../../points/Index';
import '/@/assets/font/font.css';
import { DiscountPercentageLabel } from '/@/components/shared/label/discountPercentageLabel';

const styles = (theme: Theme) => {
  return createStyles({
    card: {
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1.5),
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.1)',
      width: '100%',
    },
    disabled: {
      backgroundColor: theme.palette.grey[300],
      opacity: 0.5,
    },
    logoAndTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
      height: '100%',
      width: '100%',
    },
    // logoContainerのwidthは4rem+rightContentのmarginLeft（12px）で調整
    logoContainer: {
      width: '4.75rem',
      height: '4rem',
      marginTop: 'auto',
      marginBottom: 'auto',
      borderRadius: theme.spacing(1),
      border: '1px solid #E6EBED',
    },
    logo: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    rightContent: {
      marginLeft: theme.spacing(1.5),
      width: 'calc(100% - 4.75rem - 12px)', // ロゴの幅とmarginLeftの合計値
    },
    brandName: {
      margin: 0,
      fontSize: '0.625rem',
      lineHeight: '0.625rem',
      color: theme.palette.grey.A700,
    },
    itemName: {
      marginTop: theme.spacing(1),
      fontSize: '0.875rem',
      lineHeight: '1.4rem',
      fontWeight: 'bold',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
    },
    endAt: {
      marginTop: theme.spacing(1),
      fontSize: '0.625rem',
      lineHeight: '0.625rem',
      color: theme.palette.grey.A700,
    },
    pointBackText: {
      fontSize: '0.625rem',
      lineHeight: '0.625rem',
      fontWeight: 'bold',
      color: theme.palette.error.dark,
    },
    pointDisplayArea: {
      marginTop: theme.spacing(1),
    },
    pointText: {
      fontSize: '0.625rem',
      lineHeight: '1rem',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    points: {
      fontFamily: 'mamelon',
      fontSize: '1.25rem',
      fontWeight: 400,
    },
  });
};

type OwnProps = {
  exchangeable: boolean;
  itemContent: ItemBase;
  selectItemDetail: (uid: string) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  exchangeable,
  itemContent,
  selectItemDetail,
}) => {
  return (
    <Card
      className={
        exchangeable
          ? classes.card
          : `${classes.card} ${!exchangeable ? classes.disabled : ''}`
      }
      variant="outlined"
      onClick={() => exchangeable && selectItemDetail(itemContent.uid)}
      data-cy="itemCard"
      data-gtm-click="shared-pointItemContents-itemCard-card"
    >
      <div className={classes.logoAndTitle}>
        <div className={classes.logoContainer}>
          <img
            className={classes.logo}
            src={itemContent.contentImageUrl}
            alt={itemContent.contentName}
          />
        </div>
        <div className={classes.rightContent}>
          <p className={classes.brandName}>{itemContent.brand.name}</p>
          <Typography className={classes.itemName}>
            {itemContent.contentName}
          </Typography>
          {itemContent.currentItemPointPhase && (
            <>
              <p className={classes.endAt}>
                {'有効期限 ' + itemContent.readableExpiration}
              </p>
              {itemContent.currentPointBackCampaign && (
                <DiscountPercentageLabel
                  discountPercentage={
                    itemContent.currentPointBackCampaign.discountPercentage
                  }
                />
              )}
              <div className={classes.pointDisplayArea}>
                {itemContent.currentItemPointPhase.variable ? (
                  <Typography className={classes.pointText}>
                    <span className={classes.points}>
                      {itemContent.currentItemPointPhase.variable.minPoint.toLocaleString(
                        'ja-JP',
                      )}
                      {' ~ '}
                      {itemContent.currentItemPointPhase.variable.maxPoint.toLocaleString(
                        'ja-JP',
                      )}
                    </span>
                    ポイント
                  </Typography>
                ) : (
                  <Points points={itemContent.currentItemPointPhase.point} />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
export type { Props as PresenterProps };
