import { types } from './types';

export const updatePrefectureCode = (prefectureCode: number) =>
  ({
    type: types.UPDATE_PREFECTURE_CODE,
    payload: prefectureCode,
  }) as const;

export const updateGenderCode = (genderCode: number) =>
  ({
    type: types.UPDATE_GENDER_CODE,
    payload: genderCode,
  }) as const;

export const updateBirthdayYear = (birthdayYear: string) =>
  ({
    type: types.UPDATE_BIRTHDAY_YEAR,
    payload: birthdayYear,
  }) as const;

export const updateBirthdayMonth = (birthdayMonth: string) =>
  ({
    type: types.UPDATE_BIRTHDAY_MONTH,
    payload: birthdayMonth,
  }) as const;

export const updateBirthdayDay = (birthdayDay: string) =>
  ({
    type: types.UPDATE_BIRTHDAY_DAY,
    payload: birthdayDay,
  }) as const;
