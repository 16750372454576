import React, { useEffect, useRef, useState } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getNonRecommendedBrandsConnectionThunk } from '/@/store/api/getNonRecommendedBrandsConnection';
import { useIntersectionObserver } from '/@/common/hooks/useIntersectionObserver';
import { STATUS } from '/@/store/api/constants';
import { NonRecommendedBrandsState } from '/@/store/app/nonRecommendedBrandsConnection';

const Container: React.VFC = () => {
  const dispatch = useAppDispatch();
  const myPoint = useAppSelector((state) => state.app.pointWallet?.point);
  const brands = useAppSelector(
    (state) => state.app.nonRecommendedBrandsConnection.brands,
  );
  const isDisplayOnlyExchangeableBrands = useAppSelector(
    (state) => state.app.displayOnlyExchangeableBrands,
  );
  const [displayBrands, setDisplayBrands] =
    useState<NonRecommendedBrandsState>(brands);
  const pageInfo = useAppSelector(
    (state) => state.app.nonRecommendedBrandsConnection.pageInfo,
  );
  const getNonRecommendedBrandsConnectionStatus = useAppSelector(
    (state) => state.api.getNonRecommendedBrandsConnection.status,
  );

  useEffect(() => {
    if (
      myPoint !== undefined &&
      getNonRecommendedBrandsConnectionStatus === STATUS.IDLE
    ) {
      dispatch(
        getNonRecommendedBrandsConnectionThunk({
          after: null,
        }),
      ).catch(() => {});
    }
  }, [dispatch, myPoint, getNonRecommendedBrandsConnectionStatus]);

  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);

  useEffect(() => {
    startObserving(true);

    return () => {
      startObserving(false);
    };
  }, [startObserving]);

  useEffect(() => {
    if (
      isIntersect &&
      getNonRecommendedBrandsConnectionStatus !== STATUS.LOADING &&
      pageInfo?.hasNextPage
    )
      dispatch(
        getNonRecommendedBrandsConnectionThunk({
          after: pageInfo.endCursor,
        }),
      ).catch(() => {});
  }, [
    dispatch,
    isIntersect,
    getNonRecommendedBrandsConnectionStatus,
    brands,
    pageInfo,
    myPoint,
  ]);

  useEffect(() => {
    if (isDisplayOnlyExchangeableBrands && brands && myPoint !== undefined) {
      const exchangeableBrands = brands.filter(
        (brand) => brand.minPoint && myPoint >= brand.minPoint,
      );
      setDisplayBrands(exchangeableBrands);
    } else {
      setDisplayBrands(brands);
    }
  }, [isDisplayOnlyExchangeableBrands, brands, myPoint]);

  return (
    <Presenter
      brands={displayBrands}
      infiniteScrollTarget={infiniteScrollTarget}
      getBrandsConnectionStatus={getNonRecommendedBrandsConnectionStatus}
    />
  );
};

export { Container as ExchangeableContents };
