import React, { useEffect, useState } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { ItemBase } from '/@/store/app/shared/types';

type OwnProps = {
  itemContent: ItemBase;
  selectItemDetail: (uid: string) => void;
};

const Container: React.VFC<OwnProps> = (props) => {
  const { itemContent } = props;
  const dispatch = useAppDispatch();

  const [exchangeable, setExchangeable] = useState<boolean>(false);

  const point = useAppSelector((state) => state.app.pointWallet?.point) || 0;

  useEffect(() => {
    setExchangeable(
      (itemContent.currentItemPointPhase &&
        itemContent.currentItemPointPhase.point <= point) ||
        false,
    );
  }, [dispatch, point, itemContent.currentItemPointPhase]);

  return <Presenter exchangeable={exchangeable} {...props} />;
};

export { Container as ItemCard };
