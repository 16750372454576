import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Chip,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme: Theme) =>
  createStyles({
    label: {
      padding: theme.spacing(0.25, 1),
      height: '1.4375rem',
      backgroundColor: '#F7E9E8',
      '& .MuiChip-label': {
        padding: '0',
        color: '#D93E17',
        fontSize: '0.75rem',
        lineHeight: '1.2',
        fontWeight: 'bold',
      },
    },
    notEmphased: {
      color: '#4E5B61',
      backgroundColor: '#E6EBED',
      '& .MuiChip-label': {
        color: '#4E5B61',
      },
    },
    small: {
      padding: theme.spacing(0.25, 0.75),
      height: '1rem',
      '& .MuiChip-label': {
        fontSize: '0.625rem',
        lineHeight: '1',
      },
    },
  });

type OwnProps = {
  discountPercentage: number;
  isEmphased: boolean;
  labelSize: 'small' | 'medium';
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  discountPercentage,
  isEmphased,
  labelSize,
}) => (
  <Chip
    label={`${discountPercentage}%還元`}
    className={clsx(
      classes.label,
      !isEmphased && classes.notEmphased,
      labelSize === 'small' && classes.small,
    )}
  />
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
