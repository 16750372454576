import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Grid,
  Container,
  Typography,
} from '@material-ui/core';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { Link } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

const styles = (theme: Theme) =>
  createStyles({
    pointChargeContainer: {
      backgroundColor: theme.palette.background.contrast,
      padding: theme.spacing(3),
    },
    pointChargeTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      marginBottom: theme.spacing(3),
    },
    cautionaryMessage: {
      fontSize: '0.75rem',
      textAlign: 'left',
      fontWeight: 'bold',
      color: theme.palette.grey[700],
      marginTop: theme.spacing(3),
    },
    inquiriesNewPageLink: {
      color: theme.palette.primary.main,
    },
  });

type Props = WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes }) => (
  <MainLayout>
    <Container maxWidth="sm" className={classes.pointChargeContainer}>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        direction="column"
      >
        <Typography className={classes.pointChargeTitle}>
          シリアルコードを読み込んでいます
        </Typography>
        <PartialLoader />
        <Typography className={classes.cautionaryMessage}>
          しばらく待っても画面が切り替わらない場合は、時間をおいて再度お試しください。
          <br />
          それでも解決しない場合は、
          <Link
            to={PATHS.INQUIRIES_NEW}
            className={classes.inquiriesNewPageLink}
            data-cy="inquiriesNewPageLink"
          >
            こちら
          </Link>
          からお問い合わせください。
        </Typography>
      </Grid>
    </Container>
  </MainLayout>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
