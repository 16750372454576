import { gql } from '@apollo/client';

export const getCurrentUserGql = gql`
  query GetCurrentUser {
    currentUser {
      uid
      lastSignInAt
      profile {
        birthday
        gender {
          code
          name
        }
        prefecture {
          code
          name
        }
      }
    }
  }
`;
