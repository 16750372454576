import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { setItemSearchCondition } from '/@/store/app/itemSearchCondition/actions';
import { BrandBase } from '/@/store/app/shared/types';

type OwnProps = {
  index: number;
  brand: BrandBase;
  isRecommended: boolean;
  isLastItem?: boolean;
};

const Container: React.VFC<OwnProps> = ({
  index,
  brand,
  isRecommended,
  isLastItem,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isDisplayOnlyExchangeableBrands = useAppSelector(
    (state) => state.app.displayOnlyExchangeableBrands,
  );
  const myPoint = useAppSelector((state) => state.app.pointWallet?.point) || 0;

  const navigateItemPage = useCallback(() => {
    if (brand.uid) {
      dispatch(
        setItemSearchCondition({
          brandUid: brand.uid,
          ...(isDisplayOnlyExchangeableBrands && { minPoint: 1 }),
          ...(isDisplayOnlyExchangeableBrands && { maxPoint: myPoint }),
        }),
      );
      return navigate(
        `/point/brands/${brand.uid}/items${
          isDisplayOnlyExchangeableBrands
            ? '?minPoint=1&maxPoint=' + myPoint.toString()
            : ''
        }`,
      );
    }
  }, [dispatch, navigate, brand, isDisplayOnlyExchangeableBrands, myPoint]);

  return (
    <Presenter
      index={index}
      brand={brand}
      isRecommended={isRecommended}
      isLastItem={isLastItem}
      navigateItemPage={navigateItemPage}
    />
  );
};

export { Container as BrandCard };
