import React from 'react';
import { Presenter } from './Presenter';

export type Detail = { key: string; value: string };
type Props = {
  points: number | null | undefined;
  fontSize?: 'md' | 'lg' | 'xl';
  isMinus?: boolean;
};

const Container: React.VFC<Props> = ({
  points,
  fontSize = 'md',
  isMinus = false,
}) => {
  return <Presenter points={points} fontSize={fontSize} isMinus={isMinus} />;
};

export { Container as Points };
