import { types } from './types';
import { MyGiftBase } from '/@/store/app/shared/types';

export const addEditingMyGift = (myGift: MyGiftBase) =>
  ({
    type: types.ADD_EDITING_MY_GIFT,
    myGift,
  }) as const;

export const removeEditingMyGift = (myGift: MyGiftBase) =>
  ({
    type: types.REMOVE_EDITING_MY_GIFT,
    myGift,
  }) as const;
