import { getSavingGifteeContent } from '/@/api/graphql/publicApi/getSavingGifteeContent';
import type { GetSavingGifteeContentResponse } from '/@/api/graphql/publicApi/getSavingGifteeContent';
import type { AppThunk } from '/@/store';
import {
  getSavingGifteeContentStart,
  getSavingGifteeContentSuccess,
  getSavingGifteeContentFailure,
} from './actions';
import { GetGifteeContentQueryVariables } from '/@/api/graphql/publicApi/types';
import { handleErrorThunk } from '../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getSavingGifteeContentThunk =
  (variables: GetGifteeContentQueryVariables): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getSavingGifteeContentStart());
    return getSavingGifteeContent(variables)
      .then((response) =>
        dispatch(getSavingGifteeContentSuccessThunk(response)),
      )
      .catch((error) => dispatch(getSavingGifteeContentFailureThunk(error)));
  };

const getSavingGifteeContentSuccessThunk =
  (response: GetSavingGifteeContentResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getSavingGifteeContentSuccess(response.data!.gifteeContent));
    return Promise.resolve();
  };

const getSavingGifteeContentFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getSavingGifteeContentFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getSavingGifteeContentThunk };
