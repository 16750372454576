import { gql } from '@apollo/client';
import { FRAGMENT_FIXED_GIFT_CARD_TICKET } from './fixedGiftCardTicket.gql';
import { FRAGMENT_CHOOSABLE_GIFT_CARD_TICKET } from './choosableGiftCardTicket.gql';

export const FRAGMENT_GIFT_CARD_TICKET = gql`
  ${FRAGMENT_FIXED_GIFT_CARD_TICKET}
  ${FRAGMENT_CHOOSABLE_GIFT_CARD_TICKET}
  fragment GiftCardTicketFields on GiftCardTicket {
    urlCode
    ticket {
      __typename
      ... on FixedGiftCardTicket {
        ...FixedGiftCardTicketFields
      }
      ... on ChoosableGiftCardTicket {
        ...ChoosableGiftCardTicketFields
      }
    }
  }
`;
