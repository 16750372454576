import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
} from '@material-ui/core';
import { Promotion } from '/@/components/shared/promotion/Index';
import { PromotionSpotsState } from '/@/store/app/promotionSpots';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { Status, STATUS } from '/@/store/api/constants';
const styles = (theme: Theme) =>
  createStyles({
    titleContainer: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(),
    },
    title: {
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    titleDecoration: {
      color: theme.palette.gifteeCoral.main,
      fontWeight: 'bold',
      paddingRight: theme.spacing(),
      paddingLeft: theme.spacing(),
    },
  });

type OwnProps = {
  promotionSpots: PromotionSpotsState;
  getPromotionSpotsStatus: Status;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  promotionSpots,
  getPromotionSpotsStatus,
}) => (
  <div data-cy="promotions">
    {getPromotionSpotsStatus === STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      promotionSpots &&
      promotionSpots.length > 0 && (
        <>
          <Grid
            container
            justifyContent="center"
            className={classes.titleContainer}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.titleDecoration}
            >
              ＼
            </Typography>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.title}
            >
              さらにギフトをもらっちゃおう
            </Typography>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.titleDecoration}
            >
              ／
            </Typography>
          </Grid>
          <Grid>
            {promotionSpots?.map((promotionSpot) => (
              <Promotion
                key={`${promotionSpot.name}${promotionSpot.order}`}
                promotionSpot={promotionSpot}
              />
            ))}
          </Grid>
        </>
      )
    )}
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
