import { combineReducers } from 'redux';
import {
  giftsAvailabilityTabActions,
  selectedGiftsAvailabilityTab,
} from './selectedGiftsAvailabilityTab';
import {
  myGiftsDiscardConfirmationModalOpenActions,
  myGiftsDiscardConfirmationModalOpen,
} from './myGiftsDiscardConfirmationModalOpen';
import { alertMessage, alertMessageActions } from './alertMessage';

export const uiReducer = combineReducers({
  selectedGiftsAvailabilityTab,
  myGiftsDiscardConfirmationModalOpen,
  alertMessage,
});

export const uiActions = {
  ...giftsAvailabilityTabActions,
  ...myGiftsDiscardConfirmationModalOpenActions,
  ...alertMessageActions,
};
