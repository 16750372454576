import { types } from './types';
import type { GetMyGiftsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getInitialUnavailableMyGiftsConnectionStart = () =>
  ({
    type: types.GET_INITIAL_UNAVAILABLE_MY_GIFTS_CONNECTION_START,
  }) as const;

export const getInitialUnavailableMyGiftsConnectionSuccess = (
  unavailableMyGiftsConnection: GetMyGiftsConnectionQuery['myGiftsConnection'],
) =>
  ({
    type: types.GET_INITIAL_UNAVAILABLE_MY_GIFTS_CONNECTION_SUCCESS,
    unavailableMyGiftsConnection,
  }) as const;

export const getInitialUnavailableMyGiftsConnectionFailure = (
  error: ApiError,
) =>
  ({
    type: types.GET_INITIAL_UNAVAILABLE_MY_GIFTS_CONNECTION_FAILURE,
    error,
  }) as const;
