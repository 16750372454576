import { Reducer } from 'redux';
import {
  GetBrandsConnectionQuery,
  BrandConnection,
} from '/@/api/graphql/internalApi/types';
import { getBrandsConnectionTypes } from '/@/store/api/getBrandsConnection/index';
import { BrandsConnectionBase } from '/@/store/app/shared/types';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';

type BrandsState = Brand[] | null;
type PageInfoState = BrandConnection['pageInfo'] | null;
type BrandsConnectionState = {
  brands: BrandsState;
  pageInfo: PageInfoState;
};
type Brand = BrandsConnectionBase;

const initialState: BrandsState = null;

const brands: Reducer<BrandsState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getBrandsConnectionTypes.GET_BRANDS_CONNECTION_SUCCESS: {
      if (!action.brandsConnection?.nodes) return null;
      const filteredNodes = filterNodes(action.brandsConnection.nodes);

      if (state === null) {
        return filteredNodes;
      } else {
        const uniqueBrands = Array.from(
          new Map(
            [...state, ...filteredNodes].map((brand) => [brand.uid, brand]),
          ).values(),
        );
        return uniqueBrands;
      }
    }
    default:
      return state;
  }
};

const filterNodes = (
  brandsConnectionNodes: NonNullable<
    GetBrandsConnectionQuery['brands']['nodes']
  >,
): NonNullable<BrandsState> =>
  brandsConnectionNodes.filter((node) => !!node) as Brand[];

const pageInfoInitialState: PageInfoState = null;

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getBrandsConnectionTypes.GET_BRANDS_CONNECTION_SUCCESS:
      return action.brandsConnection.pageInfo;
    default:
      return state;
  }
};

const brandsConnection = combineReducers({
  brands,
  pageInfo,
});

export { brandsConnection };
export type { BrandsConnectionState, Brand, BrandsState };
