import { gql } from '@apollo/client';

export const getUserAUthenticatorsGql = gql`
  query GetUserAuthenticators {
    userAuthenticators {
      label
      uid
    }
  }
`;
