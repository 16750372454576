import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Box,
  Container,
} from '@material-ui/core';
import { Promotion } from '/@/components/shared/promotion/Index';
import { PromotionSpotsState } from '/@/store/app/promotionSpots';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PartialLoader } from '../../shared/loader/partialLoader/Index';
import { STATUS } from '/@/store/api/constants';
import type { Status } from '/@/store/api/constants';
import getGiftHeading from '/@/assets/get_gift_heading.svg';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    headingImage: {
      backgroundColor: theme.palette.background.contrast,
      lineHeight: 0,
    },
    headingTitle: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      color: theme.palette.primary.dark,
      fontWeight: 'bold',
    },
    title: {
      marginBottom: theme.spacing(2),
      color: theme.palette.primary.dark,
      fontWeight: 'bold',
    },
    loaderContainer: {
      position: 'fixed',
      inset: 0,
    },
  });

type OwnProps = {
  promotionSpots: PromotionSpotsState;
  getPromotionSpotsStatus: Status;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  promotionSpots,
  getPromotionSpotsStatus,
}) => (
  <>
    <MainLayout>
      <Box className={classes.headingImage}>
        <img
          src={getGiftHeading}
          alt="ギフトをもらう"
          width="100%"
          height="100"
          data-cy="getGiftHeading"
        />
      </Box>
      <Container maxWidth="sm" className={classes.container}>
        <Grid container direction="column">
          <Typography variant="subtitle1" className={classes.title}>
            もらえるギフト
          </Typography>
          {getPromotionSpotsStatus === STATUS.LOADING ? (
            <Grid
              container
              alignItems="center"
              className={classes.loaderContainer}
            >
              <PartialLoader />
            </Grid>
          ) : (
            <Grid>
              {promotionSpots?.length ? (
                promotionSpots.map((promotionSpot) => (
                  <Promotion
                    key={`${promotionSpot.name}${promotionSpot.order}`}
                    promotionSpot={promotionSpot}
                  />
                ))
              ) : (
                <Typography variant="body2">
                  現在応募可能なキャンペーンはありません。
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </MainLayout>
  </>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
