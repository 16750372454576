import { types } from './types';
import type { GetMyGiftsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getContinuousExpiringMyGiftsConnectionStart = () =>
  ({
    type: types.GET_CONTINUOUS_EXPIRING_MY_GIFTS_CONNECTION_START,
  }) as const;

export const getContinuousExpiringMyGiftsConnectionSuccess = (
  expiringMyGiftsConnection: GetMyGiftsConnectionQuery['myGiftsConnection'],
) =>
  ({
    type: types.GET_CONTINUOUS_EXPIRING_MY_GIFTS_CONNECTION_SUCCESS,
    expiringMyGiftsConnection,
  }) as const;

export const getContinuousExpiringMyGiftsConnectionFailure = (
  error: ApiError,
) =>
  ({
    type: types.GET_CONTINUOUS_EXPIRING_MY_GIFTS_CONNECTION_FAILURE,
    error,
  }) as const;
