import { getMyGiftsConnection } from '/@/api/graphql/internalApi/getMyGiftsConnection';
import type { GetMyGiftsConnectionResponse } from '/@/api/graphql/internalApi/getMyGiftsConnection';
import type { AppThunk } from '/@/store';
import {
  getContinuousUnavailableMyGiftsConnectionStart,
  getContinuousUnavailableMyGiftsConnectionSuccess,
  getContinuousUnavailableMyGiftsConnectionFailure,
} from './actions';
import type { PageInfo } from '/@/api/graphql/internalApi/types';
import {
  MyGiftType_GiftTypeEnum,
  MyGiftUsageStatusEnum,
  SortDirectionEnum,
  MyGiftSortConditionTargetEnumType,
} from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getContinuousUnavailableMyGiftsConnectionThunk =
  (after: PageInfo['endCursor']): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getContinuousUnavailableMyGiftsConnectionStart());

    return getMyGiftsConnection({
      sortConditions: [
        {
          direction: SortDirectionEnum.Desc,
          target: MyGiftSortConditionTargetEnumType.UnavailableAt,
        },
      ],
      usageStatuses: [
        MyGiftUsageStatusEnum.Discarded,
        MyGiftUsageStatusEnum.Expired,
      ],
      first: 20,
      after,
      giftTypeIn: [
        MyGiftType_GiftTypeEnum.GiftCardTicketChoosableContent,
        MyGiftType_GiftTypeEnum.GiftCardTicketFixedContent,
        MyGiftType_GiftTypeEnum.GifteeBox,
        MyGiftType_GiftTypeEnum.SimpleGift,
      ],
    })
      .then((response) =>
        dispatch(
          getContinuousUnavailableMyGiftsConnectionSuccessThunk(response),
        ),
      )
      .catch((error) =>
        dispatch(getContinuousUnavailableMyGiftsConnectionFailureThunk(error)),
      );
  };

const getContinuousUnavailableMyGiftsConnectionSuccessThunk =
  (response: GetMyGiftsConnectionResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { myGiftsConnection } = response.data!;
    dispatch(
      getContinuousUnavailableMyGiftsConnectionSuccess(myGiftsConnection),
    );
    return Promise.resolve();
  };

const getContinuousUnavailableMyGiftsConnectionFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getContinuousUnavailableMyGiftsConnectionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getContinuousUnavailableMyGiftsConnectionThunk };
