import { signInOrSignUp } from '/@/api/graphql/publicApi/signInOrSignUp';
import type { SignInOrSignUpResponse } from '/@/api/graphql/publicApi/signInOrSignUp';
import type { AppThunk } from '/@/store';
import {
  signInOrSignUpStart,
  signInOrSignUpSuccess,
  signInOrSignUpFailure,
} from './actions';
import type { SignInOrSignUpInput } from '/@/api/graphql/publicApi/types';
import { handleErrorThunk } from './handleError';
import type { ApiError } from '/@/api/graphql/client';

const signInOrSignUpThunk =
  (input: SignInOrSignUpInput): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(signInOrSignUpStart());
    return signInOrSignUp(input)
      .then((response) => dispatch(signInOrSignUpSuccessThunk(response)))
      .catch((error) => dispatch(signInOrSignUpFailureThunk(error)));
  };

const signInOrSignUpSuccessThunk =
  (response: SignInOrSignUpResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { session } = response.data!.signInOrSignUp;
    if (session) dispatch(signInOrSignUpSuccess(session));
    return Promise.resolve();
  };

const signInOrSignUpFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(signInOrSignUpFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { signInOrSignUpThunk };
