import { combineReducers } from 'redux';
import {
  getPromotionSpots,
  getPromotionSpotsActions,
} from './getPromotionSpots';
import {
  getUserAuthenticators,
  getUserAuthenticatorsActions,
} from './getUserAuthenticators';
import {
  saveGifteeContent,
  saveGifteeContentActions,
} from './saveGifteeContent';
import { getGifteeContent, getGifteeContentActions } from './getGifteeContent';
import {
  getInitialExpiringMyGiftsConnection,
  getInitialExpiringMyGiftsConnectionActions,
} from './getInitialExpiringMyGiftsConnection';
import {
  getContinuousExpiringMyGiftsConnection,
  getContinuousExpiringMyGiftsConnectionActions,
} from './getContinuousExpiringMyGiftsConnection';
import {
  getInitialAvailableMyGiftsConnection,
  getInitialAvailableMyGiftsConnectionActions,
} from './getInitialAvailableMyGiftsConnection';
import {
  getContinuousAvailableMyGiftsConnection,
  getContinuousAvailableMyGiftsConnectionActions,
} from './getContinuousAvailableMyGiftsConnection';
import {
  getInitialUnavailableMyGiftsConnection,
  getInitialUnavailableMyGiftsConnectionActions,
} from './getInitialUnavailableMyGiftsConnection';
import {
  getContinuousUnavailableMyGiftsConnection,
  getContinuousUnavailableMyGiftsConnectionActions,
} from './getContinuousUnavailableMyGiftsConnection';
import { getPointWallet, getPointWalletActions } from './getPointWallet';
import {
  getAvailablePointCharges,
  getAvailablePointChargesActions,
} from './getAvailablePointCharges';
import { getPointLogs, getPointLogsActions } from './getPointLogs';
import { notifyInquiry, notifyInquiryActions } from './notifyInquiry';
import {
  createOauthSession,
  createOauthSessionActions,
} from './createOauthSession';
import { signInOrSignUp, signInOrSignUpActions } from './signInOrSignUp';
import { getOauthSession, getOauthSessionActions } from './getOauthSession';
import {
  getSavingGifteeContent,
  getSavingGifteeContentActions,
} from './getSavingGifteeContent';
import {
  createUserProfile,
  createUserProfileActions,
} from './createUserProfile';
import { handleErrorActions } from './handleError';
import { getCurrentUser, getCurrentUserActions } from './getCurrentUser';
import { discardMyGifts, discardMyGiftsActions } from './discardMyGifts';
import {
  getBrandsConnection,
  getBrandsConnectionActions,
} from './getBrandsConnection';
import {
  getNonRecommendedBrandsConnection,
  getNonRecommendedBrandsConnectionActions,
} from './getNonRecommendedBrandsConnection';
import {
  getRecommendedBrandsConnection,
  getRecommendedBrandsConnectionActions,
} from './getRecommendedBrandsConnection';
import {
  getItemsConnection,
  getItemsConnectionActions,
} from './getItemsConnection';
import { getItemDetail, getItemDetailActions } from './getItemDetail';
import {
  createPointExchange,
  createPointExchangeActions,
} from './createPointExchange';
import {
  pointUpdatedSubscription,
  pointUpdatedSubscriptionActions,
} from './pointUpdatedSubscription';
import { getMyGift, getMyGiftActions } from './getMyGift';
import {
  createGiftConvertToPoint,
  createGiftConvertToPointActions,
} from './createGiftConvertToPoint';
import {
  giftConvertToPointRequestSubscription,
  giftConvertToPointRequestSubscriptionActions,
} from './giftConvertToPointRequestSubscription';
import { getBrand, getBrandActions } from './getBrand';
import { refreshMyGift, refreshMyGiftActions } from './refreshMyGift';
import {
  createPointChargeWithSerialCode,
  createPointChargeWithSerialCodeActions,
} from './createPointChargeWithSerialCode';

export const apiReducer = combineReducers({
  getPromotionSpots,
  getUserAuthenticators,
  saveGifteeContent,
  getGifteeContent,
  getInitialExpiringMyGiftsConnection,
  getContinuousExpiringMyGiftsConnection,
  getInitialAvailableMyGiftsConnection,
  getContinuousAvailableMyGiftsConnection,
  getInitialUnavailableMyGiftsConnection,
  getContinuousUnavailableMyGiftsConnection,
  getPointWallet,
  getAvailablePointCharges,
  getPointLogs,
  notifyInquiry,
  createOauthSession,
  signInOrSignUp,
  getOauthSession,
  getSavingGifteeContent,
  createUserProfile,
  getCurrentUser,
  discardMyGifts,
  getBrandsConnection,
  getNonRecommendedBrandsConnection,
  getRecommendedBrandsConnection,
  getItemsConnection,
  getItemDetail,
  createPointExchange,
  pointUpdatedSubscription,
  getMyGift,
  createGiftConvertToPoint,
  giftConvertToPointRequestSubscription,
  getBrand,
  refreshMyGift,
  createPointChargeWithSerialCode,
});

export const apiActions = {
  ...getPromotionSpotsActions,
  ...getUserAuthenticatorsActions,
  ...saveGifteeContentActions,
  ...getGifteeContentActions,
  ...getInitialExpiringMyGiftsConnectionActions,
  ...getContinuousExpiringMyGiftsConnectionActions,
  ...getInitialAvailableMyGiftsConnectionActions,
  ...getContinuousAvailableMyGiftsConnectionActions,
  ...getInitialUnavailableMyGiftsConnectionActions,
  ...getContinuousUnavailableMyGiftsConnectionActions,
  ...getPointWalletActions,
  ...getAvailablePointChargesActions,
  ...getPointLogsActions,
  ...notifyInquiryActions,
  ...createOauthSessionActions,
  ...signInOrSignUpActions,
  ...getOauthSessionActions,
  ...getSavingGifteeContentActions,
  ...createUserProfileActions,
  ...handleErrorActions,
  ...getCurrentUserActions,
  ...discardMyGiftsActions,
  ...getBrandsConnectionActions,
  ...getNonRecommendedBrandsConnectionActions,
  ...getRecommendedBrandsConnectionActions,
  ...getItemsConnectionActions,
  ...getItemDetailActions,
  ...createPointExchangeActions,
  ...pointUpdatedSubscriptionActions,
  ...getMyGiftActions,
  ...createGiftConvertToPointActions,
  ...giftConvertToPointRequestSubscriptionActions,
  ...getBrandActions,
  ...refreshMyGiftActions,
  ...createPointChargeWithSerialCodeActions,
};
