import { FetchResult, Observable } from '@apollo/client';
import { internalApiAppSubscriptionClient } from '/@/api/graphql/appApolloSubscription';
import { pointUpdatedSubscriptionGql } from './index.gql';
import { PointUpdatedSubscription } from '../types';

type PointUpdatedSubscriptionResponse = FetchResult<PointUpdatedSubscription>;

const pointUpdatedSubscriptionObserver = ():
  | Observable<PointUpdatedSubscriptionResponse>
  | undefined => {
  return internalApiAppSubscriptionClient()?.subscribe({
    query: pointUpdatedSubscriptionGql,
  });
};

export { pointUpdatedSubscriptionObserver };

export type { PointUpdatedSubscriptionResponse };
