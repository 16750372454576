import { gql } from '@apollo/client';

export const notifyInquiryGql = gql`
  mutation notifyInquiry($input: NotifyInquiryInput!) {
    notifyInquiry(input: $input) {
      errors {
        message
        path
        extensions {
          status
          code
        }
      }
    }
  }
`;
