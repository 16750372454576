import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
} from '@material-ui/core';
import { STATUS, Status } from '/@/store/api/constants';
import { BrandCard } from '/@/components/shared/brandContents/brandCard/Index';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { BrandsConnectionBase } from '/@/store/app/shared/types';

const styles = (theme: Theme) => {
  return createStyles({
    sectionContents: {
      marginTop: theme.spacing(2),
    },
    grid: {
      justifyContent: 'center',
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(2),
    },
  });
};

type OwnProps = {
  brands: BrandsConnectionBase[] | null;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getBrandsConnectionStatus: Status;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  brands,
  infiniteScrollTarget,
  getBrandsConnectionStatus,
}) => {
  return (
    <>
      <div className={classes.sectionContents}>
        {brands && brands.length > 0 ? (
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            data-cy="exchangeableBrands"
            className={classes.grid}
          >
            {brands.map((brand, index) => (
              <BrandCard
                key={index}
                index={index}
                brand={brand}
                isRecommended={false}
                isLastItem={index === brands.length - 1}
              />
            ))}
            {getBrandsConnectionStatus === STATUS.LOADING && <PartialLoader />}
          </Grid>
        ) : getBrandsConnectionStatus === STATUS.LOADING ||
          getBrandsConnectionStatus === STATUS.IDLE ? (
          <PartialLoader />
        ) : (
          getBrandsConnectionStatus === STATUS.SUCCESS && (
            <Typography> 現在交換可能なチケットはありません</Typography>
          )
        )}
      </div>
      <div ref={infiniteScrollTarget} />
    </>
  );
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
