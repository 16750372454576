import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getInitialUnavailableMyGiftsConnectionThunk } from '/@/store/api/getInitialUnavailableMyGiftsConnection';
import { getContinuousUnavailableMyGiftsConnectionThunk } from '/@/store/api/getContinuousUnavailableMyGiftsConnection';
import { Presenter } from './Presenter';
import { useIntersectionObserver } from '/@/common/hooks/useIntersectionObserver';
import { STATUS } from '/@/store/api/constants';
import { selectUnavailableAtGroupedUnavailableMyGifts } from '/@/store/app/unavailableMyGiftsConnection/selectors';

const Container: React.VFC<Record<string, never>> = () => {
  const groupedUnavailableMyGifts = useAppSelector(
    selectUnavailableAtGroupedUnavailableMyGifts,
  );
  const unavailableMyGiftsConnectionPageInfo = useAppSelector(
    (state) => state.app.unavailableMyGiftsConnection.pageInfo,
  );
  const getContinuousUnavailableMyGiftsConnectionStatus = useAppSelector(
    (state) => state.api.getContinuousUnavailableMyGiftsConnection.status,
  );
  const getInitialUnavailableMyGiftsConnectionStatus = useAppSelector(
    (state) => state.api.getInitialUnavailableMyGiftsConnection.status,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getInitialUnavailableMyGiftsConnectionThunk()).catch(() => {});
  }, [dispatch]);

  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);

  useEffect(() => {
    startObserving(true);

    return () => {
      startObserving(false);
    };
  }, [startObserving]);

  useEffect(() => {
    if (
      isIntersect &&
      getContinuousUnavailableMyGiftsConnectionStatus !== STATUS.LOADING &&
      unavailableMyGiftsConnectionPageInfo?.hasNextPage
    ) {
      dispatch(
        getContinuousUnavailableMyGiftsConnectionThunk(
          unavailableMyGiftsConnectionPageInfo.endCursor,
        ),
      ).catch(() => {});
    }
  }, [
    dispatch,
    isIntersect,
    getContinuousUnavailableMyGiftsConnectionStatus,
    groupedUnavailableMyGifts,
    unavailableMyGiftsConnectionPageInfo,
  ]);

  return (
    <Presenter
      groupedUnavailableMyGifts={groupedUnavailableMyGifts}
      infiniteScrollTarget={infiniteScrollTarget}
      getInitialUnavailableMyGiftsConnectionStatus={
        getInitialUnavailableMyGiftsConnectionStatus
      }
      getContinuousUnavailableMyGiftsConnectionStatus={
        getContinuousUnavailableMyGiftsConnectionStatus
      }
    />
  );
};

export { Container as UnavailableMyGifts };
