import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ErrorMessage } from '/@/store/api/saveGifteeContent/selectors';
import { SavingGift } from './savingGift/Index';
import {
  LoginButton,
  LoginOption,
} from '/@/components/shared/loginButton/Index';
import { FriendRequest } from './friendRequest/Index';

const styles = (theme: Theme) => {
  return createStyles({
    loginButtonContainer: {
      paddingTop: theme.spacing(4),
    },
    friendRequestContainer: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    authenticationErrorMessage: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.warning.main,
      padding: theme.spacing(3),
    },
    serialCodeOutOfPeriodMessage: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.warning.main,
    },
  });
};

type OwnProps = LoginOption & {
  lineUnfriendedErrorMessage: ErrorMessage;
  authenticationErrorMessage: ErrorMessage;
  isSerialCodeOutOfPeriod: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  url,
  origin,
  convertToPoint,
  destination,
  serialCode,
  lineUnfriendedErrorMessage,
  authenticationErrorMessage,
  isSerialCodeOutOfPeriod,
}) => {
  const buttonText = serialCode
    ? 'ログインしてポイントをチャージ'
    : url && origin
      ? 'ログインしてギフトを保存する'
      : 'ログインする';
  return (
    <div data-cy="signInPageLoginSection">
      {authenticationErrorMessage && (
        <Typography
          variant="body2"
          className={classes.authenticationErrorMessage}
        >
          {authenticationErrorMessage}
        </Typography>
      )}
      {isSerialCodeOutOfPeriod && (
        <Typography
          variant="body2"
          className={classes.serialCodeOutOfPeriodMessage}
        >
          {`シリアルコードの有効期限が切れています`}
        </Typography>
      )}
      {lineUnfriendedErrorMessage && (
        <div className={classes.friendRequestContainer}>
          <FriendRequest message={lineUnfriendedErrorMessage} />
        </div>
      )}
      {url && origin && (
        <div>
          <SavingGift url={url} origin={origin} />
        </div>
      )}
      <div className={classes.loginButtonContainer}>
        <LoginButton
          url={null}
          origin={null}
          referer={null}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={serialCode}
        >
          {buttonText}
        </LoginButton>
      </div>
    </div>
  );
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
