import {
  GifteeContentBase,
  GiftCardGifteeContentBase,
  GifteeBoxGifteeContentBase,
  GiftCardTicketBase,
  GiftCardTicketFixedBase,
  GiftCardTicketChoosableBase,
} from '/@/store/app/shared/types';

type GifteeContent = GifteeContentBase;
type GiftCardGifteeContent = GiftCardGifteeContentBase;
type GifteeBoxGifteeContent = GifteeBoxGifteeContentBase;
type GiftCardTicket = GiftCardTicketBase;
type GiftCardTicketFixed = GiftCardTicketFixedBase;
type GiftCardTicketChoosable = GiftCardTicketChoosableBase;

export const isGiftCardGifteeContent = (
  gifteeContent: GifteeContent,
): gifteeContent is GiftCardGifteeContent =>
  gifteeContent.content.__typename === 'GiftCard';

export const isGifteeBoxGifteeContent = (
  gifteeContent: GifteeContent,
): gifteeContent is GifteeBoxGifteeContent =>
  gifteeContent.content.__typename === 'GifteeBox';

export const isFixedGiftCardTicket = (
  giftCardTicket: GiftCardTicket,
): giftCardTicket is GiftCardTicketFixed =>
  giftCardTicket.ticket.__typename === 'FixedGiftCardTicket';

export const isChoosableGiftCardTicket = (
  giftCardTicket: GiftCardTicket,
): giftCardTicket is GiftCardTicketChoosable =>
  giftCardTicket.ticket.__typename === 'ChoosableGiftCardTicket';
