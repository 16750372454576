import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Card,
  Typography,
} from '@material-ui/core';
import { BrandBase } from '/@/store/app/shared/types';
import { DiscountPercentageLabel } from '/@/components/shared/label/discountPercentageLabel';

const styles = (theme: Theme) => {
  return createStyles({
    card: {
      padding: theme.spacing(1),
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.1)',
      minWidth: '10rem',
      textAlign: 'left',
      borderRadius: theme.spacing(1),
      [theme.breakpoints.down(375)]: {
        width: '100%',
      },
      [theme.breakpoints.between(375, 600)]: {
        width: 'calc((100% - 1rem) / 2)',
      },
      [theme.breakpoints.up(600)]: {
        width: 'calc((100% - 2rem) / 3)',
      },
    },
    logoAndTitle: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    logoContainer: {
      width: '3rem',
      height: '3rem',
      padding: theme.spacing(0.5),
      borderRadius: theme.spacing(1),
      border: '1px solid #E6EBED',
    },
    logo: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    rightContent: {
      marginLeft: theme.spacing(1),
      width: 'calc(100% - 3rem - 8px)', // ロゴの幅とmarginLeftの合計値
    },
    itemTitle: {
      marginBottom: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      fontSize: '0.75rem',
      lineHeight: '1',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
    },
    discountTexts: {
      fontSize: '0.675rem',
      lineHeight: '1',
      fontWeight: 900,
    },
    description: {
      marginTop: theme.spacing(1),
      fontSize: '0.625rem',
      lineHeight: '1.5',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
    },
  });
};

type OwnProps = {
  index: number;
  brand: BrandBase;
  isRecommended: boolean;
  isLastItem?: boolean;
  navigateItemPage: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  index,
  brand,
  isRecommended,
  isLastItem,
  navigateItemPage,
}) => {
  return (
    <>
      <Card
        key={index}
        className={classes.card}
        variant="outlined"
        style={{
          height: isRecommended ? '6.625rem' : '4rem',
          marginRight: isLastItem ? 'auto' : 0,
        }}
        component="button"
        onClick={navigateItemPage}
        data-cy="brandCard"
        data-gtm-click="shared-brandContents-brandCard-card"
      >
        <div className={classes.logoAndTitle}>
          <div className={classes.logoContainer}>
            <img
              className={classes.logo}
              src={brand.iconUrl}
              alt={brand.name}
            />
          </div>
          <div className={classes.rightContent}>
            <Typography className={classes.itemTitle}>{brand.name}</Typography>
            <div className={classes.discountTexts}>
              {isRecommended ? (
                <>
                  {brand.currentPointBackCampaign?.discountPercentage && (
                    <DiscountPercentageLabel
                      discountPercentage={
                        brand.currentPointBackCampaign?.discountPercentage
                      }
                      labelSize="small"
                    />
                  )}
                </>
              ) : (
                brand.currentPointBackCampaign?.discountPercentage && (
                  <DiscountPercentageLabel
                    discountPercentage={
                      brand.currentPointBackCampaign?.discountPercentage
                    }
                    isEmphased={false}
                    labelSize="small"
                  />
                )
              )}
            </div>
          </div>
        </div>
        {isRecommended && brand.currentPointBackCampaign?.description && (
          <Typography className={classes.description}>
            {brand.currentPointBackCampaign.description}
          </Typography>
        )}
      </Card>
    </>
  );
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
