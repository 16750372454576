import { getBrandsConnection } from '/@/api/graphql/internalApi/getBrandsConnection/index';
import type { GetBrandsConnectionResponse } from '/@/api/graphql/internalApi/getBrandsConnection/index';
import type { AppThunk } from '/@/store';
import {
  getRecommendedBrandsConnectionStart,
  getRecommendedBrandsConnectionSuccess,
  getRecommendedBrandsConnectionFailure,
} from './actions';
import type { GetBrandsConnectionQueryVariables } from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getRecommendedBrandsConnectionThunk =
  (variables: GetBrandsConnectionQueryVariables): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getRecommendedBrandsConnectionStart());

    const recommendedVariables = {
      ...variables,
      recommendEq: true,
    };

    return getBrandsConnection(recommendedVariables)
      .then((response) =>
        dispatch(getRecommendedBrandsConnectionSuccessThunk(response)),
      )
      .catch((error) =>
        dispatch(getRecommendedBrandsConnectionFailureThunk(error)),
      );
  };

const getRecommendedBrandsConnectionSuccessThunk =
  (response: GetBrandsConnectionResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { brands } = response.data!;
    dispatch(getRecommendedBrandsConnectionSuccess(brands));
    return Promise.resolve();
  };

const getRecommendedBrandsConnectionFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getRecommendedBrandsConnectionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getRecommendedBrandsConnectionThunk };
