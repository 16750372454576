import React from 'react';
import { LoginOption } from '/@/components/shared/loginButton/Index';
import { Presenter } from './Presenter';

type Props = LoginOption;

const Container: React.VFC<Props> = ({
  url,
  origin,
  convertToPoint,
  destination,
  serialCode,
}) => {
  return (
    <Presenter
      url={url}
      origin={origin}
      referer={null}
      convertToPoint={convertToPoint}
      destination={destination}
      serialCode={serialCode}
    />
  );
};

export { Container as SummarySection };
