import {
  ApolloClient,
  FetchResult,
  ApolloQueryResult,
  MutationOptions,
  OperationVariables,
  QueryOptions,
  ApolloError,
} from '@apollo/client';
import { GraphQLError } from 'graphql';
import { OperationDefinitionNode, NameNode } from 'graphql/language/ast';
import { ErrorCodeEnumType } from './internalApi/types';

export type Response =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | FetchResult<{ [key: string]: any }>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ApolloQueryResult<{ [key: string]: any }>;

export type ApiError =
  | ApolloError
  | ReadonlyArray<GraphQLError>
  | MutationErrors;

export type MutationErrors = MutationError[];
export type MutationError = {
  message: string;
  path: Paths;
  extensions: Extensions;
};
type Extensions = {
  status: Status;
  code?: Code;
};
type Status = number;
type Paths = Path[];
type Path = string;
type Code = ErrorCodeEnumType;

type MutationName = NameNode['value'];

export class AppApolloClient<TCacheShape> extends ApolloClient<TCacheShape> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutate<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>,
  ): Promise<FetchResult<T>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutate<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>,
  ): Promise<FetchResult<T> | ApiError>;

  public mutate<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T = any,
    TVariables extends OperationVariables = OperationVariables,
  >(
    options: MutationOptions<T, TVariables>,
  ): Promise<FetchResult<T> | ApiError> {
    return super.mutate(options).then(this.rejectErrorIfNeeded(options));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query<T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables>,
  ): Promise<ApolloQueryResult<T>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query<T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables>,
  ): Promise<ApolloQueryResult<T> | ApiError>;

  public query<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T = any,
    TVariables extends OperationVariables = OperationVariables,
  >(
    options: QueryOptions<TVariables>,
  ): Promise<ApolloQueryResult<T> | ApiError> {
    return super
      .query(options)
      .then(this.rejectGraphqlErrorIfNeeded<ApolloQueryResult<T>>());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private rejectErrorIfNeeded<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>,
  ): (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: FetchResult<any>,
  ) => Promise<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FetchResult<any> | MutationErrors | ReadonlyArray<GraphQLError>
  > {
    return (response) =>
      this.rejectMutationErrorIfNeeded(options)(response).then(
        this.rejectGraphqlErrorIfNeeded<Response | MutationErrors>(),
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private rejectMutationErrorIfNeeded<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>,
  ): (response: Response) => Promise<Response | MutationErrors> {
    return (response) => {
      const mutationName = this.getMutationName(options);
      if (this.hasMutationError(mutationName, response)) {
        const mutationErrors: MutationErrors =
          !!response.data && response.data[mutationName].errors;
        return Promise.reject(mutationErrors);
      }
      return Promise.resolve(response);
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getMutationName<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>,
  ): MutationName {
    const definition = options.mutation.definitions.find(
      (definition) => definition.kind === 'OperationDefinition',
    ) as OperationDefinitionNode;
    const mutationName = definition?.name ? definition.name.value : '';
    const convertedMutationName =
      this.convertFirstLetterToLowercase(mutationName);
    return convertedMutationName;
  }

  private convertFirstLetterToLowercase = (string: string): string =>
    string.charAt(0).toLowerCase() + string.slice(1);

  private hasMutationError(
    mutationName: MutationName,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: FetchResult<{ [key: string]: any }>,
  ): boolean {
    const { data } = response;
    const mutationNameObject = data ? data[mutationName] : undefined;
    const errors = mutationNameObject
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (mutationNameObject.errors as Array<any>)
      : undefined;
    return !!data && !!errors && errors.length > 0;
  }

  private rejectGraphqlErrorIfNeeded<TResult>(): (
    response: TResult,
  ) => Promise<TResult | ReadonlyArray<GraphQLError>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (response: any) => {
      if (this.hasGraphqlError(response as Response)) {
        return Promise.reject((response as Response).errors);
      }
      return Promise.resolve(response);
    };
  }

  private hasGraphqlError(response: Response): boolean {
    return response.errors !== undefined;
  }
}
