const types = {
  GET_NONRECOMMENDED_BRANDS_CONNECTION_START:
    'api/GET_NONRECOMMENDED_BRANDS_CONNECTION_START',
  GET_NONRECOMMENDED_BRANDS_CONNECTION_SUCCESS:
    'api/GET_NONRECOMMENDED_BRANDS_CONNECTION_SUCCESS',
  GET_NONRECOMMENDED_BRANDS_CONNECTION_FAILURE:
    'api/GET_NONRECOMMENDED_BRANDS_CONNECTION_FAILURE',
} as const;

export { types };
