import { gql } from '@apollo/client';
import { FRAGMENT_MY_GIFT } from '/@/api/graphql/internalApi/fragments/myGift.gql';
import { FRAGMENT_ERRORS } from '/@/api/graphql/internalApi/fragments/errors.gql';

export const refreshMyGiftGql = gql`
  ${FRAGMENT_MY_GIFT}
  ${FRAGMENT_ERRORS}
  mutation RefreshMyGift($clientMutationId: String, $urlCode: ID!) {
    refreshMyGift(
      input: { clientMutationId: $clientMutationId, myGiftUrlCode: $urlCode }
    ) {
      errors {
        ...ErrorsFields
      }
      myGift {
        ...MyGiftFields
      }
    }
  }
`;
