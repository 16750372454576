import React from 'react';
import { Presenter } from './Presenter';
import { useAppSelector } from '/@/store';
import { selectDuplicatedGifteeContentErrorMessage } from '/@/store/api/saveGifteeContent/selectors';

const Container: React.VFC<Record<string, never>> = () => {
  const duplicatedGifteeContentErrorMessage = useAppSelector(
    selectDuplicatedGifteeContentErrorMessage,
  );

  return (
    <Presenter
      duplicatedGifteeContentErrorMessage={duplicatedGifteeContentErrorMessage}
    />
  );
};

export { Container as GiftShowPage };
