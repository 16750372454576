import { ItemSearchConditionBase } from '../shared/types';
import { types } from './types';

export const setItemSearchCondition = (
  itemSearchCondition: ItemSearchConditionBase | null,
) =>
  ({
    type: types.SET_ITEM_SEARCH_CONDITION,
    itemSearchCondition,
  }) as const;
