import { gql } from '@apollo/client';

export const getPointLogsGql = gql`
  query GetPointLogs(
    $first: Int
    $after: String
    $statusIn: [PointLogType_StatusEnumType!]
    $loggedAtGteq: ISO8601DateTime
    $loggedAtLteq: ISO8601DateTime
    $logTypeEq: PointWalletType_pointLogs_LogTypeEnumType
  ) {
    pointWallet {
      pointLogs(
        first: $first
        after: $after
        statusIn: $statusIn
        loggedAtGteq: $loggedAtGteq
        loggedAtLteq: $loggedAtLteq
        logTypeEq: $logTypeEq
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        nodes {
          title
          status
          loggedAt
          statement {
            __typename
            ... on PointCharge {
              pointAmount
              withdrawableUntilAt
            }
            ... on PointExchange {
              pointAmount
              myGifts {
                contentName
                imageUrl
              }
            }
            ... on PointExpired {
              expiredPoint
            }
          }
        }
      }
    }
  }
`;
