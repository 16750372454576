import React from 'react';
import { Presenter } from './Presenter';
import { useLocation } from 'react-router-dom';

const Container: React.VFC<Record<string, never>> = () => {
  const location = useLocation();
  const pointLogStatus = location?.state?.pointLogStatus || null;

  return <Presenter pointLogStatus={pointLogStatus} />;
};

export { Container as CompletionPage };
