import React, { useEffect } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getBrandsConnectionThunk } from '/@/store/api/getBrandsConnection';
import { STATUS } from '/@/store/api/constants';
import { ItemSearchConditionState } from '/@/store/app/itemSearchCondition/reducers';

type OwnProps = {
  handleClose: () => void;
  conditions: ItemSearchConditionState;
  setConditions: (conditions: ItemSearchConditionState) => void;
};

const Container: React.VFC<OwnProps> = ({
  handleClose,
  conditions,
  setConditions,
}) => {
  const dispatch = useAppDispatch();
  const brands = useAppSelector((state) => state.app.brandsConnection.brands);
  const point = useAppSelector((state) => state.app.pointWallet?.point);
  const pageInfo = useAppSelector(
    (state) => state.app.brandsConnection.pageInfo,
  );
  const getBrandsConnectionStatus = useAppSelector(
    (state) => state.api.getBrandsConnection.status,
  );

  const setBrandCondition = (uid: string): void => {
    setConditions({
      ...conditions,
      brandUid: uid,
    });
    handleClose();
  };

  useEffect(() => {
    if (brands === null && point !== undefined) {
      dispatch(
        getBrandsConnectionThunk({
          after: null,
          withPointsLteq: point,
        }),
      ).catch(() => {});
    }
  }, [brands, dispatch, point]);

  useEffect(() => {
    if (getBrandsConnectionStatus !== STATUS.LOADING && pageInfo?.hasNextPage) {
      dispatch(
        getBrandsConnectionThunk({
          after: pageInfo.endCursor,
          withPointsLteq: point,
        }),
      ).catch(() => {});
    }
  }, [dispatch, getBrandsConnectionStatus, brands, pageInfo, point]);

  return (
    <Presenter
      brands={brands}
      selectedUid={conditions?.brandUid || null}
      setBrandCondition={setBrandCondition}
      getBrandsConnectionStatus={getBrandsConnectionStatus}
    />
  );
};
export { Container as BrandCondition };
