import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Container,
  Button,
} from '@material-ui/core';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import errorIcon from '/@/assets/triangle-exclamation.svg';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { FOOTER_HEIGHT } from '/@/components/templates/footer/Presenter';

const styles = (theme: Theme) =>
  createStyles({
    pointChargeErrorContainer: {
      height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
      backgroundColor: '#F6F7F8',
      padding: theme.spacing(3),
    },
    errorIcon: { margin: 'auto' },
    pointChargeErrorTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#D93E17',
      marginTop: theme.spacing(3),
    },
    errorText: {
      fontSize: '0.875rem',
      textAlign: 'left',
      marginTop: theme.spacing(3),
    },
    navigateToHomeButton: {
      width: '100%',
      fontWeight: 'bold',
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(4),
    },
  });

type OwnProps = {
  navigateToHome: () => void;
  errorMessage: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  navigateToHome,
  errorMessage,
}) => (
  <MainLayout>
    <Container maxWidth="sm" className={classes.pointChargeErrorContainer}>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        direction="column"
      >
        <img
          src={errorIcon}
          alt="error-icon"
          className={classes.errorIcon}
          width="100"
          height="100"
          data-cy="errorIcon"
        />
        <Typography className={classes.pointChargeErrorTitle}>
          ポイント獲得に失敗しました
        </Typography>
        <Typography className={classes.errorText}>{errorMessage}</Typography>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classes.navigateToHomeButton}
          onClick={navigateToHome}
          data-cy="navigateToHomeButton"
          data-gtm-click="pointPage-chargePage-completionPage-navigateToHomeButton"
        >
          ホームへ戻る
        </Button>
      </Grid>
    </Container>
  </MainLayout>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
