import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
  PreloadedState,
} from 'redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { useAppDispatch, useAppSelector } from './hooks';
import { RootAction } from './actions';
import { apiReducer } from './api';
import { appReducer } from './app';
import { uiReducer } from './ui';
import { pageReducer } from './page';

const rootReducer = combineReducers({
  api: apiReducer,
  app: appReducer,
  ui: uiReducer,
  page: pageReducer,
});

const configureStore = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preloadedState: PreloadedState<any> | undefined = undefined,
) => {
  const enhancer = applyMiddleware(thunk);
  const composedEnhancers = composeWithDevTools(enhancer);

  return createStore(rootReducer, preloadedState, composedEnhancers);
};

const store = configureStore();

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = ThunkDispatch<RootState, unknown, RootAction>;
type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  RootAction
>;

export { store, useAppDispatch, useAppSelector, configureStore };
export type { RootState, AppDispatch, AppThunk };
