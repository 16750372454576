import { createSelector } from 'reselect';
import { RootState } from '/@/store';
import { formatToEndMonthJa } from '/@/utils/formatDate';
import { AvailablePointCharge } from '/@/api/graphql/internalApi/types';

export type GroupedAvailablePointCharges = {
  [key: string]: AvailablePointCharge[];
};

const availablePointCharges = (state: RootState) =>
  state.page.pointLogsPage.availablePointChargesPage.availablePointCharges;

export const groupedAvailablePointCharges = createSelector(
  [availablePointCharges],
  (availablePointCharges): GroupedAvailablePointCharges =>
    availablePointCharges?.reduce((currentValue, obj) => {
      const formattedDate = formatToEndMonthJa(obj.withdrawableUntilAt);
      return {
        ...currentValue,
        [formattedDate]: [...(currentValue[formattedDate] || []), obj],
      };
    }, {} as GroupedAvailablePointCharges) || {},
);
