import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getBrandsConnectionGql } from './index.gql';
import { GetBrandsConnectionQuery, QueryBrandsArgs } from '../types';

type GetBrandsConnectionResponse = FetchResult<GetBrandsConnectionQuery>;

const getBrandsConnection = (
  variables: QueryBrandsArgs,
): Promise<GetBrandsConnectionResponse> => {
  return internalApiClient.query({
    query: getBrandsConnectionGql,
    variables,
  });
};

export { getBrandsConnection };

export type { GetBrandsConnectionResponse };
