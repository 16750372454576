import React from 'react';

import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import notFoundIcon from '/@/assets/not_found_icon.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    subtitle: {
      paddingBottom: theme.spacing(2),
      fontWeight: 'bold',
    },
    topPageLink: {
      textDecoration: 'none',
      margin: theme.spacing(4),
    },
    topPageLinkText: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  }),
);

const Presenter: React.VFC<Record<string, never>> = () => {
  const classes = useStyles();

  return (
    <MainLayout>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        data-cy="notFoundPage"
      >
        <img
          src={notFoundIcon}
          alt="404 not found"
          width="100%"
          height="250"
          data-cy="notFoundIcon"
        />
        <Typography variant="body2" className={classes.subtitle}>
          お探しのページが見つかりませんでした。
        </Typography>
        <Typography variant="caption">URLをご確認いただくか、</Typography>
        <Typography variant="caption">
          サービストップよりページをお探しください。
        </Typography>
        <Link to={PATHS.ROOT} className={classes.topPageLink}>
          <Typography variant="body2" className={classes.topPageLinkText}>
            ← トップページへ戻る
          </Typography>
        </Link>
      </Grid>
    </MainLayout>
  );
};

export { Presenter };
