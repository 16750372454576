import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Typography,
  Theme,
  Grid,
} from '@material-ui/core';
import topImage from '/@/assets/signin-hero-gift-wallet-top-image.png';
import { LoginButton } from '/@/components/shared/loginButton/Index';
import { PATHS } from '/@/routes/paths';
import { DestinationEnum, OriginEnum } from '/@/api/graphql/publicApi/types';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      width: '100%',
      padding: theme.spacing(4, 3),
    },
    subTitle: {
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: '1',
      marginBottom: theme.spacing(0.5),
      textAlign: 'center',
    },
    mainTitle: {
      color: '#003F65',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: '1.3',
      textAlign: 'center',
    },
    topImage: {
      display: 'block',
      width: '85%',
      margin: `${theme.spacing(3)}px auto 0`,
    },
    mainText: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      lineHeight: '1.4',
      textAlign: 'left',
    },
    emphasisText: {
      background:
        'linear-gradient(transparent 50%, rgba(235, 113, 82, 0.4) 0%)',
    },
    loginButton: {
      marginTop: theme.spacing(2),
    },
    convertToPointNote: {
      color: '#4E5B61',
      display: 'inline-block',
      width: '100%',
      marginTop: theme.spacing(2),
      textAlign: 'center',
      fontSize: '0.75rem',
    },
  });
};

type OwnProps = {
  url: string | null;
  origin: OriginEnum | null;
  referer: string | null;
  convertToPoint: boolean | null;
  destination: DestinationEnum | null;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  url,
  origin,
  referer,
  convertToPoint,
  destination,
}) => (
  <div data-cy="signInPageHeroSection" className={classes.container}>
    <Grid container direction="column" alignContent="center">
      <Grid item xs>
        <Typography variant="h3" className={classes.subTitle}>
          えらべるPayは
        </Typography>
        <Typography variant="h1" className={classes.mainTitle}>
          gift wallet ポイントへの
          <br />
          移行がおトク！
        </Typography>
      </Grid>
      <Grid item xs>
        <img
          src={topImage}
          alt="giftWalletTopimage"
          className={classes.topImage}
        />
        <Typography variant="body1" className={classes.mainText}>
          gift wallet に移行で、ポイントの還元やポイントのまとめて利用など、
          <span className={classes.emphasisText}>今よりもさらにおトク</span>
          にポイントが活用できます。
        </Typography>
      </Grid>
      <Grid item xs>
        <div className={classes.loginButton}>
          <LoginButton
            url={url}
            origin={origin}
            referer={referer}
            convertToPoint={convertToPoint}
            destination={destination}
            serialCode={null}
            flow={PATHS.LP_CONVERT_TO_POINT}
          >
            ギフトをポイントに移行する
          </LoginButton>
          <Typography variant="caption" className={classes.convertToPointNote}>
            ※ポイントは残高全てが移行されます。ご注意ください。
          </Typography>
        </div>
      </Grid>
    </Grid>
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
