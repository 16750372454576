import { types } from './types';
import type { GetMyGiftsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getInitialExpiringMyGiftsConnectionStart = () =>
  ({
    type: types.GET_INITIAL_EXPIRING_MY_GIFTS_CONNECTION_START,
  }) as const;

export const getInitialExpiringMyGiftsConnectionSuccess = (
  expiringMyGiftsConnection: GetMyGiftsConnectionQuery['myGiftsConnection'],
) =>
  ({
    type: types.GET_INITIAL_EXPIRING_MY_GIFTS_CONNECTION_SUCCESS,
    expiringMyGiftsConnection,
  }) as const;

export const getInitialExpiringMyGiftsConnectionFailure = (error: ApiError) =>
  ({
    type: types.GET_INITIAL_EXPIRING_MY_GIFTS_CONNECTION_FAILURE,
    error,
  }) as const;
