import React, { useEffect, useState } from 'react';
import { Presenter } from './Presenter';
import { useAppSelector } from '/@/store';
import { useAppDispatch } from '/@/store/hooks';
import { getPointWalletThunk } from '/@/store/api/getPointWallet';
import { getMyGiftsConnection } from '/@/api/graphql/internalApi/getMyGiftsConnection';
import { MyGiftUsageStatusEnum } from '/@/api/graphql/internalApi/types';
import type { MyGiftBase } from '/@/store/app/shared/types';
import { isPointsCharged } from '/@/api/graphql/internalApi/getPointLogs';

const Container: React.VFC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPointWalletThunk()).catch(() => {});
  }, [dispatch]);

  const point = useAppSelector((state) => state.app.pointWallet?.point);
  const [display, setDisplay] = useState<boolean>(false);
  const [pointMergeableMyGifts, setPointMergeableMyGifts] = useState<
    MyGiftBase[]
  >([]);

  useEffect(() => {
    if (display) return;

    if (
      (!!point && point > 0) ||
      (!!pointMergeableMyGifts && pointMergeableMyGifts.length > 0)
    )
      setDisplay(true);

    if (point === 0)
      // ポイントロードしたがポイントが0の場合にチャージ実績を確認する
      isPointsCharged(setDisplay);
  }, [dispatch, display, point, pointMergeableMyGifts]);

  useEffect(() => {
    getMyGiftsConnection({
      pointMergeableEq: true,
      usageStatuses: [MyGiftUsageStatusEnum.Available],
    }).then((response) => {
      const filteredNodes = response.data?.myGiftsConnection?.nodes
        ?.filter((node): node is MyGiftBase => !!node)
        .map((node) => {
          return {
            ...node,
            contentName: `${node.contentName}(${node.remainingPoint.toLocaleString('ja-JP')}ポイント)`,
          };
        });
      setPointMergeableMyGifts(filteredNodes || []);
    });
  }, [dispatch]);

  return (
    <Presenter
      display={display}
      pointMergeableMyGifts={pointMergeableMyGifts}
    />
  );
};

export { Container as PointSection };
