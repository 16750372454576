import { Reducer } from 'redux';
import type {
  GetMyGiftsConnectionQuery,
  MyGiftConnection,
} from '/@/api/graphql/internalApi/types';
import { getInitialAvailableMyGiftsConnectionTypes } from '/@/store/api/getInitialAvailableMyGiftsConnection';
import { getContinuousAvailableMyGiftsConnectionTypes } from '/@/store/api/getContinuousAvailableMyGiftsConnection';
import { MyGiftBase } from '/@/store/app/shared/types';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';

type AvailableMyGiftsState = AvailableMyGift[] | null;
type PageInfoState = MyGiftConnection['pageInfo'] | null;
type AvailableMyGiftsConnectionState = {
  availableMyGifts: AvailableMyGiftsState;
  pageInfo: PageInfoState;
};
type AvailableMyGift = MyGiftBase;

const availableMyGiftsInitialState: AvailableMyGiftsState = null;

const availableMyGifts: Reducer<AvailableMyGiftsState, RootAction> = (
  state = availableMyGiftsInitialState,
  action,
) => {
  switch (action.type) {
    case getInitialAvailableMyGiftsConnectionTypes.GET_INITIAL_AVAILABLE_MY_GIFTS_CONNECTION_SUCCESS:
      if (!action.availableMyGiftsConnection.nodes) return null;
      return filterNodes(action.availableMyGiftsConnection.nodes);
    case getContinuousAvailableMyGiftsConnectionTypes.GET_CONTINUOUS_AVAILABLE_MY_GIFTS_CONNECTION_SUCCESS: {
      if (!action.availableMyGiftsConnection.nodes) return state;
      const filteredNodes = filterNodes(
        action.availableMyGiftsConnection.nodes,
      );

      if (state === null) {
        return filteredNodes;
      } else {
        const uniqueItems = Array.from(
          new Map(
            [...state, ...filteredNodes].map((item) => [item.urlCode, item]),
          ).values(),
        );
        return uniqueItems;
      }
    }
    default:
      return state;
  }
};

const filterNodes = (
  availableMyGiftsConnectionNodes: NonNullable<
    GetMyGiftsConnectionQuery['myGiftsConnection']['nodes']
  >,
): NonNullable<AvailableMyGiftsState> =>
  availableMyGiftsConnectionNodes.filter(
    (node): node is AvailableMyGift => !!node,
  );

const pageInfoInitialState: PageInfoState = null;

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getInitialAvailableMyGiftsConnectionTypes.GET_INITIAL_AVAILABLE_MY_GIFTS_CONNECTION_SUCCESS:
    case getContinuousAvailableMyGiftsConnectionTypes.GET_CONTINUOUS_AVAILABLE_MY_GIFTS_CONNECTION_SUCCESS:
      return action.availableMyGiftsConnection.pageInfo;
    default:
      return state;
  }
};

const availableMyGiftsConnection = combineReducers({
  availableMyGifts,
  pageInfo,
});

export { availableMyGiftsConnection };
export type {
  AvailableMyGiftsConnectionState,
  AvailableMyGift,
  AvailableMyGiftsState,
};
