import { types } from './types';
import type { ApiError } from '/@/api/graphql/client';

export const notifyInquiryIdle = () =>
  ({
    type: types.NOTIFY_INQUIRY_IDLE,
  }) as const;

export const notifyInquiryStart = () =>
  ({
    type: types.NOTIFY_INQUIRY_START,
  }) as const;

export const notifyInquirySuccess = () =>
  ({
    type: types.NOTIFY_INQUIRY_SUCCESS,
  }) as const;

export const notifyInquiryFailure = (error: ApiError) =>
  ({
    type: types.NOTIFY_INQUIRY_FAILURE,
    error,
  }) as const;
