import { ErrorCodeEnumType } from '/@/api/graphql/internalApi/types';
import { PATHS } from '/@/routes/paths';

// 必要に応じて SEVERITY の種別を増やす ref: https://mui.com/components/alert/
export const SEVERITY = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
} as const;

export type Severity = (typeof SEVERITY)[keyof typeof SEVERITY];

export const EXCHAGE_ERROR_PATTERN = {
  [ErrorCodeEnumType.ItemNotAvailable]: {
    severity: SEVERITY.ERROR,
    message:
      '対象ギフトの交換が終了しているため交換できません。マイギフトページより交換をやりなおしてください。',
    link: {
      path: PATHS.HOME,
      text: 'マイギフトへもどる',
    },
  },
  [ErrorCodeEnumType.PointBackCampaignNotAvailable]: {
    severity: SEVERITY.ERROR,
    message:
      '還元キャンペーンが終了しているため交換できません。マイギフトページより交換をやりなおしてください。',
    link: {
      path: PATHS.HOME,
      text: 'マイギフトへもどる',
    },
  },
  [ErrorCodeEnumType.CampaignReachedLimitation]: {
    severity: SEVERITY.ERROR,
    message:
      '還元キャンペーンが終了しているため交換できません。マイギフトページより交換をやりなおしてください。',
    link: {
      path: PATHS.HOME,
      text: 'マイギフトへもどる',
    },
  },
  [ErrorCodeEnumType.PointWalletBalanceShortage]: {
    severity: SEVERITY.ERROR,
    message: '所持ポイント不足により交換できません。',
    link: {
      path: PATHS.HOME,
      text: 'マイギフトへもどる',
    },
  },
  [ErrorCodeEnumType.ExchangeError]: {
    severity: SEVERITY.ERROR,
    message:
      '交換時にエラーが発生しました。マイギフトページより交換をやりなおしてください。',
    link: {
      path: PATHS.HOME,
      text: 'マイギフトへもどる',
    },
  },
} as const;

export type ExchangeErrorPatternType = keyof typeof EXCHAGE_ERROR_PATTERN;

export const isExchangeErrorPatternTypeMatch = (
  value: ErrorCodeEnumType,
): value is ErrorCodeEnumType => {
  return Object.keys(EXCHAGE_ERROR_PATTERN).includes(value);
};
