import { ErrorCodeEnumType } from '/@/api/graphql/publicApi/types';

export const POINT_CHARGE_WITH_SERIAL_CODE_ERROR_PATTERN = {
  [ErrorCodeEnumType.SerialCodeOutOfPeriod]: {
    message: 'URLの期限が切れているためチャージできませんでした。',
  },
  [ErrorCodeEnumType.SerialCodeUsed]: {
    message: 'このURLはすでに利用済みです。',
  },
  [ErrorCodeEnumType.SerialCodeNotFound]: {
    message:
      'アクセスされたURLは間違っています。URLを確認して再度アクセスしてください。',
  },
} as const;

export type PointChargeWithSerialCodeErrorPatternType =
  keyof typeof POINT_CHARGE_WITH_SERIAL_CODE_ERROR_PATTERN;

export const isPointChargeWithSerialCodeErrorPatternTypeMatch = (
  value: string,
): value is PointChargeWithSerialCodeErrorPatternType =>
  Object.keys(POINT_CHARGE_WITH_SERIAL_CODE_ERROR_PATTERN).includes(value);
