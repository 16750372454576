import React from 'react';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PageLoader } from '/@/components/shared/loader/pageLoader/Index';

const Presenter: React.VFC<Record<string, never>> = () => (
  <>
    <MainLayout>
      <PageLoader />
    </MainLayout>
  </>
);

export { Presenter };
