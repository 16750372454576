import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Container,
  Box,
  Divider,
  Grid,
  Button,
} from '@material-ui/core';
import { AvailableMyGifts } from './availableMyGifts/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import { SelectedMyGiftsState } from '/@/store/app/selectedMyGifts';
import { DiscardConfirmationModal } from './discardConfirmationModal/Index';

const styles = (theme: Theme) =>
  createStyles({
    background: {
      backgroundColor: theme.palette.background.contrast,
    },
    baseBox: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },
    },
    container: {
      [theme.breakpoints.up('sm')]: {
        borderRadius: theme.spacing(2),
      },
      backgroundColor: theme.palette.background.default,
      padding: 0,
    },
    subtitle: {
      color: theme.palette.primary.dark,
      padding: theme.spacing(2),
      fontWeight: 'bold',
      lineHeight: '1',
    },
    myGiftButton: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      margin: theme.spacing(2),
      backgroundColor: theme.palette.background.hint,
    },
    myGiftButtonLabel: {
      color: theme.palette.primary.dark,
      fontWeight: 'bold',
    },
    discardGrid: {
      padding: theme.spacing(2),
    },
    selectedCount: { color: theme.palette.primary.main, fontWeight: 'bold' },
    discardButtonLabel: {
      fontWeight: 'bold',
    },
    discardButtonIcon: {
      paddingLeft: theme.spacing(),
    },
    sortGrid: {
      padding: theme.spacing(2),
    },
    sortLabel: {
      paddingLeft: theme.spacing(2),
      color: theme.palette.secondary.dark,
      fontWeight: 'bold',
    },
  });

type OwnProps = {
  selectedMyGifts: SelectedMyGiftsState;
  handleMyGiftsDiscardConfirmationModalOpen: () => void;
  isDiscardButtonDisabled: boolean;
};
type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  selectedMyGifts,
  handleMyGiftsDiscardConfirmationModalOpen,
  isDiscardButtonDisabled,
}) => (
  <>
    <MainLayout>
      <div className={classes.background}>
        <Box className={classes.baseBox}>
          <Container maxWidth="sm" className={classes.container}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" className={classes.subtitle}>
                マイギフト選択
              </Typography>
              <Button
                variant="contained"
                disableElevation
                className={classes.myGiftButton}
                component={Link}
                to={PATHS.HOME}
                data-cy="giftsPageButton"
              >
                <Typography
                  variant="caption"
                  className={classes.myGiftButtonLabel}
                >
                  マイギフトに戻る
                </Typography>
              </Button>
            </Grid>
            <Divider />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              className={classes.discardGrid}
            >
              <Grid item>
                <Grid container alignItems="center">
                  <Typography variant="caption">選択中：</Typography>
                  <Typography
                    data-cy="selectedCount"
                    variant="subtitle1"
                    className={classes.selectedCount}
                  >
                    {selectedMyGifts.length}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleMyGiftsDiscardConfirmationModalOpen}
                  disableElevation
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={isDiscardButtonDisabled}
                  data-cy="discardButton"
                  data-gtm-click="giftsPage-editPage-discardButton"
                >
                  <Grid container alignItems="center">
                    <Typography
                      variant="caption"
                      className={classes.discardButtonLabel}
                    >
                      利用済みにする
                    </Typography>
                    <ArrowForwardIosIcon
                      className={classes.discardButtonIcon}
                    />
                  </Grid>
                </Button>
              </Grid>
            </Grid>
            <Divider />
            {/* <Grid container alignItems='center' className={classes.sortGrid}>
              <SortIcon color='primary' />
              <Typography variant='caption' className={classes.sortLabel}>
                保存日の新しい順
              </Typography>
            </Grid> */}
            <AvailableMyGifts />
          </Container>
        </Box>
      </div>
      <DiscardConfirmationModal
        handleMyGiftsDiscardConfirmationModalOpen={
          handleMyGiftsDiscardConfirmationModalOpen
        }
      />
    </MainLayout>
  </>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
