import { gql } from '@apollo/client';

export const getPointWalletGql = gql`
  query GetPointWallet {
    pointWallet {
      point
      nearestAvailableWithdrawableUntilAt
    }
  }
`;
