import { gql } from '@apollo/client';

export const createOauthSessionGql = gql`
  mutation CreateOauthSession(
    $createOauthSessionInput: CreateOauthSessionInput!
  ) {
    createOauthSession(input: $createOauthSessionInput) {
      oauthSession {
        authorizationUrl
      }
    }
  }
`;
