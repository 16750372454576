const types = {
  CREATE_POINT_CHARGE_WITH_SERIAL_CODE_START:
    'api/CREATE_POINT_CHARGE_WITH_SERIAL_CODE_START',
  CREATE_POINT_CHARGE_WITH_SERIAL_CODE_SUCCESS:
    'api/CREATE_POINT_CHARGE_WITH_SERIAL_CODE_SUCCESS',
  CREATE_POINT_CHARGE_WITH_SERIAL_CODE_FAILURE:
    'api/CREATE_POINT_CHARGE_WITH_SERIAL_CODE_FAILURE',
} as const;

export { types };
