import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import {
  WithStyles,
  withStyles,
  createStyles,
  Button,
  Typography,
  Theme,
  Link,
} from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      textAlign: 'center',
    },
    loginButton: {
      fontWeight: 'bold',
      width: '100%',
      marginTop: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderRadius: theme.spacing(4),
    },
  });
};

type OwnProps = {
  children: string;
  onClick: () => Promise<void> | undefined;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ children, onClick, classes }) => {
  return (
    <div className={classes.container} data-cy="signInPageLoginButton">
      <div>
        <Typography variant="caption">
          <Link
            data-cy="termsOfServiceLink"
            component={RouterLink}
            to={PATHS.TERMS_OF_SERVICE}
          >
            サービス利用規約
          </Link>
          に同意したものとみなします
        </Typography>
      </div>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        className={classes.loginButton}
        onClick={onClick}
        data-gtm-click="shared-loginButton-loginButton"
      >
        {children}
      </Button>
    </div>
  );
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
