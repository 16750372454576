import { RootState } from '/@/store';
import { SelectedMyGiftsState } from './reducers';
import { createSelector } from 'reselect';

const selectedMyGifts = (state: RootState) => state.app.selectedMyGifts;

export const MAX_TYPICAL_SELECTED_MY_GIFTS_COUNT = 4;

export const selectTypicalSelectedMyGifts = createSelector(
  [selectedMyGifts],
  (selectedMyGifts): SelectedMyGiftsState =>
    selectedMyGifts.slice(0, MAX_TYPICAL_SELECTED_MY_GIFTS_COUNT),
);

export const selectOtherSelectedMyGifts = createSelector(
  [selectedMyGifts],
  (selectedMyGifts): SelectedMyGiftsState =>
    selectedMyGifts.slice(MAX_TYPICAL_SELECTED_MY_GIFTS_COUNT),
);

export const selectIsDiscardButtonDisabled = createSelector(
  [selectedMyGifts],
  (selectedMyGifts): boolean => selectedMyGifts.length === 0,
);
