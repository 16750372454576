import { gql } from '@apollo/client';

export const FRAGMENT_CHOOSABLE_GIFT_CARD_TICKET = gql`
  fragment ChoosableGiftCardTicketFields on ChoosableGiftCardTicket {
    name
    imageUrl
    expiredAt
    url
  }
`;
