import { gql } from '@apollo/client';
import { FRAGMENT_POINT_WALLET } from '/@/api/graphql/internalApi/fragments/pointWallet.gql';
import { FRAGMENT_ERRORS } from '/@/api/graphql/internalApi/fragments/errors.gql';

export const createPointChargeWithSerialCodeGql = gql`
  ${FRAGMENT_POINT_WALLET}
  ${FRAGMENT_ERRORS}
  mutation createPointChargeWithSerialCode(
    $clientMutationId: String
    $serialCode: String!
  ) {
    createPointChargeWithSerialCode(
      input: { clientMutationId: $clientMutationId, serialCode: $serialCode }
    ) {
      clientMutationId
      pointCharge {
        pointAmount
        withdrawableUntilAt
      }
      pointWallet {
        ...PointWalletFields
      }
      errors {
        ...ErrorsFields
      }
    }
  }
`;
