import React, { ReactNode } from 'react';
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles,
  Typography,
} from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    validationMessage: {
      marginTop: theme.spacing(0.5),
      color: theme.palette.error.main,
      fontSize: '0.75rem',
      lineHeight: '1',
    },
  });

type OwnProps = {
  children: ReactNode;
  validationMessage: string | null;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  children,
  validationMessage,
}) => (
  <div>
    {children}
    {validationMessage && (
      <Typography className={classes.validationMessage}>
        {validationMessage}
      </Typography>
    )}
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
