import { types } from './types';
import type { GetGifteeContentQuery } from '/@/api/graphql/publicApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getSavingGifteeContentStart = () =>
  ({
    type: types.GET_SAVING_GIFTEE_CONTENT_START,
  }) as const;

export const getSavingGifteeContentSuccess = (
  savingGifteeContent: GetGifteeContentQuery['gifteeContent'],
) =>
  ({
    type: types.GET_SAVING_GIFTEE_CONTENT_SUCCESS,
    savingGifteeContent,
  }) as const;

export const getSavingGifteeContentFailure = (error: ApiError) =>
  ({
    type: types.GET_SAVING_GIFTEE_CONTENT_FAILURE,
    error,
  }) as const;
