import { getBrandsConnection } from '/@/api/graphql/internalApi/getBrandsConnection/index';
import type { GetBrandsConnectionResponse } from '/@/api/graphql/internalApi/getBrandsConnection/index';
import type { AppThunk } from '/@/store';
import {
  getBrandsConnectionStart,
  getBrandsConnectionSuccess,
  getBrandsConnectionFailure,
} from './actions';
import type { GetBrandsConnectionQueryVariables } from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getBrandsConnectionThunk =
  (variables: GetBrandsConnectionQueryVariables): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getBrandsConnectionStart());

    return getBrandsConnection(variables)
      .then((response) => dispatch(getBrandsConnectionSuccessThunk(response)))
      .catch((error) => dispatch(getBrandsConnectionFailureThunk(error)));
  };

const getBrandsConnectionSuccessThunk =
  (response: GetBrandsConnectionResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { brands } = response.data!;
    dispatch(getBrandsConnectionSuccess(brands));
    return Promise.resolve();
  };

const getBrandsConnectionFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getBrandsConnectionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getBrandsConnectionThunk };
