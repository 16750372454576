import React from 'react';
import {
  Grid,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { ItemCard } from './ItemCard/Index';
import { ItemsState } from '/@/store/app/itemsConnection';
import { STATUS, Status } from '/@/store/api/constants';
import { PartialLoader } from '../loader/partialLoader/Index';
import { ItemDetailModal } from './itemDetailModal/Index';

const styles = (theme: Theme) =>
  createStyles({
    grid: {
      rowGap: theme.spacing(2),
    },
  });

type OwnProps = {
  itemContents: ItemsState;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getItemsConnectionStatus: Status;
  selectItemDetail: (uid: string) => void;
};
type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  itemContents,
  infiniteScrollTarget,
  getItemsConnectionStatus,
  selectItemDetail,
}) => {
  return (
    <>
      {itemContents && itemContents.length > 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          data-cy="itemContents"
          className={classes.grid}
        >
          {itemContents.map((itemContent, index) => (
            <ItemCard
              key={index}
              itemContent={itemContent}
              selectItemDetail={selectItemDetail}
            />
          ))}
          {getItemsConnectionStatus === STATUS.LOADING && <PartialLoader />}
        </Grid>
      ) : getItemsConnectionStatus === STATUS.LOADING ? (
        <PartialLoader />
      ) : (
        <Typography>現在交換可能なチケットはありません</Typography>
      )}
      <div ref={infiniteScrollTarget} />
      <ItemDetailModal />
    </>
  );
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
