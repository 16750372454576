import { gql } from '@apollo/client';
import { FRAGMENT_GIFTEE_CONTENT } from '/@/api/graphql/internalApi/fragments/gifteeContent.gql';
import { FRAGMENT_ERRORS } from '/@/api/graphql/internalApi/fragments/errors.gql';

export const saveGifteeContentGql = gql`
  ${FRAGMENT_GIFTEE_CONTENT}
  ${FRAGMENT_ERRORS}
  mutation SaveGifteeContent(
    $url: String!
    $origin: OriginEnum!
    $referer: String
    $notifyTarget: Boolean
  ) {
    saveGifteeContent(
      input: {
        url: $url
        origin: $origin
        referer: $referer
        notifyTarget: $notifyTarget
      }
    ) {
      gifteeContent {
        ...GifteeContentFields
      }
      errors {
        ...ErrorsFields
      }
    }
  }
`;
