import { gql } from '@apollo/client';
import { FRAGMENT_GIFT_CARD_TICKET } from './giftCardTicket.gql';

export const FRAGMENT_GIFT_CARD = gql`
  ${FRAGMENT_GIFT_CARD_TICKET}
  fragment GiftCardFields on GiftCard {
    urlCode
    url
    name
    origin
    giftCardTickets {
      ...GiftCardTicketFields
    }
  }
`;
