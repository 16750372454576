import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getItemsConnectionGql } from './index.gql';
import {
  GetItemsConnectionQuery,
  GetItemsConnectionQueryVariables,
} from '../types';

type GetItemsConnectionResponse = FetchResult<GetItemsConnectionQuery>;

const getItemsConnection = (
  variables: GetItemsConnectionQueryVariables,
): Promise<GetItemsConnectionResponse> => {
  return internalApiClient.query({
    query: getItemsConnectionGql,
    variables,
  });
};

export { getItemsConnection };

export type { GetItemsConnectionResponse };
