import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Container,
  Button,
} from '@material-ui/core';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import pointChargeIcon from '/@/assets/point-charge.svg';
import { ItemDetailModal } from '/@/components/shared/pointItemContents/itemDetailModal/Index';
import { Points } from '/@/components/shared/points/Index';
import { Status } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';

const styles = (theme: Theme) =>
  createStyles({
    pointChargedContainer: {
      backgroundColor: theme.palette.background.contrast,
      padding: theme.spacing(3),
    },
    pointChargeIcon: { margin: 'auto' },
    pointChargedTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      marginTop: theme.spacing(3),
    },
    pointBalanceText: {
      fontSize: '0.75rem',
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.grey[700],
      marginTop: theme.spacing(3),
    },
    pointDisplayArea: {
      marginTop: theme.spacing(1),
      textAlign: 'center',
    },
    navigateToHomeButton: {
      width: '100%',
      fontWeight: 'bold',
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(4),
    },
    populerItemsSection: {
      padding: theme.spacing(2),
    },
  });

type OwnProps = {
  myPoint: number | undefined;
  navigateToHome: () => void;
  getPointWalletStatus: Status;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  myPoint,
  navigateToHome,
  getPointWalletStatus,
}) => (
  <MainLayout>
    <Container maxWidth="sm" className={classes.pointChargedContainer}>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        direction="column"
      >
        <img
          src={pointChargeIcon}
          alt="ギフトを移行しました"
          className={classes.pointChargeIcon}
          width="100"
          height="100"
          data-cy="pointChargeIcon"
        />
        <Typography className={classes.pointChargedTitle}>
          ギフトを移行しました
        </Typography>
        <Typography className={classes.pointBalanceText}>
          ポイント残高
        </Typography>
        <div className={classes.pointDisplayArea}>
          {getPointWalletStatus === 'loading' ? (
            <PartialLoader />
          ) : (
            <Points points={myPoint} fontSize="xl" />
          )}
        </div>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classes.navigateToHomeButton}
          onClick={navigateToHome}
          data-cy="navigateToHomeButton"
          data-gtm-click="pointPage-chargePage-completionPage-navigateToHomeButton"
        >
          マイギフトへ戻る
        </Button>
      </Grid>
    </Container>
    {/* データが貯まるまで一旦非表示. cf: https://giftee-inc.slack.com/archives/C06E76T0J86/p1724049106246419?thread_ts=1723773614.435139&cid=C06E76T0J86 */}
    {/* <Container maxWidth='sm' className={classes.populerItemsSection}>
      <PopulerItemsSection />
    </Container> */}
    <ItemDetailModal />
  </MainLayout>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
