import type { AppThunk } from '/@/store';
import { ApiError } from '/@/api/graphql/client';
import { handleApolloErrorThunk } from './handleApolloError';
import { handleMutationErrorsThunk } from './handleMutationErrors';
import { typeGuards } from '/@/utils/typeGuards';
import { ApiErrors } from './constants';
import { updatePageError } from './actions';
import * as Sentry from '@sentry/browser';

// TODO: GraphQL エラーを捕捉する
const handleErrorThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    if (typeGuards.api.isApolloError(error)) {
      return dispatch(handleApolloErrorThunk(error));
    }
    if (typeGuards.api.isMutationErrors(error)) {
      return dispatch(handleMutationErrorsThunk(error));
    }

    Sentry.captureException(error);
    dispatch(
      updatePageError(
        ApiErrors.UNEXPECTED_ERROR.message,
        ApiErrors.UNEXPECTED_ERROR.status,
      ),
    );
    return Promise.reject(error);
  };

export { handleErrorThunk };
