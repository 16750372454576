import {
  GiftCardGifteeContentBase,
  GifteeBoxGifteeContentBase,
} from '/@/store/app/shared/types';
import { GetGifteeContentQuery } from '/@/api/graphql/internalApi/types';
import { GetGifteeContentQuery as GetSavingGifteeContentQuery } from '/@/api/graphql/publicApi/types';

type GiftCardGifteeContent = GiftCardGifteeContentBase;
type GifteeBoxGifteeContent = GifteeBoxGifteeContentBase;

export const isGiftCardGifteeContent = (
  gifteeContent:
    | GetGifteeContentQuery['gifteeContent']
    | GetSavingGifteeContentQuery['gifteeContent'],
): gifteeContent is GiftCardGifteeContent =>
  gifteeContent.content?.__typename === 'GiftCard';

export const isGifteeBoxGifteeContent = (
  gifteeContent:
    | GetGifteeContentQuery['gifteeContent']
    | GetSavingGifteeContentQuery['gifteeContent'],
): gifteeContent is GifteeBoxGifteeContent =>
  gifteeContent.content?.__typename === 'GifteeBox';

export const isGifteeContent = (
  gifteeContent:
    | GetGifteeContentQuery['gifteeContent']
    | GetSavingGifteeContentQuery['gifteeContent'],
): gifteeContent is GifteeBoxGifteeContent | GiftCardGifteeContent =>
  isGifteeBoxGifteeContent(gifteeContent) ||
  isGiftCardGifteeContent(gifteeContent);
