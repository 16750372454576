import React from 'react';
import { Presenter } from './Presenter';
import dayjs from 'dayjs';
import { formatToEndDateJa } from '/@/utils/formatDate';

type Props = {
  url: string;
  name: string;
  imageUrl: string;
  expiredAtLabel: string;
  expiredAt: string | null;
};

const Container: React.VFC<Props> = ({
  url,
  name,
  imageUrl,
  expiredAtLabel,
  expiredAt,
}) => {
  const isExpiring = dayjs(expiredAt).isBefore(dayjs().add(1, 'week'));
  const expirationText = expiredAt
    ? dayjs(expiredAt).isBefore(dayjs())
      ? `${expiredAtLabel}期限切れ`
      : `${expiredAtLabel}期限 ${formatToEndDateJa(expiredAt)}`
    : `${expiredAtLabel}期限 取得中...`;

  return (
    <Presenter
      url={url}
      name={name}
      imageUrl={imageUrl}
      expirationText={expirationText}
      isExpiring={isExpiring}
    />
  );
};

export { Container as TicketSkelton };
