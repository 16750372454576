import { types } from './types';
import type { GetPointWalletQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getPointWalletStart = () =>
  ({
    type: types.GET_POINT_WALLET_START,
  }) as const;

export const getPointWalletSuccess = (
  pointWallet: GetPointWalletQuery['pointWallet'],
) =>
  ({
    type: types.GET_POINT_WALLET_SUCCESS,
    pointWallet,
  }) as const;

export const getPointWalletFailure = (error: ApiError) =>
  ({
    type: types.GET_POINT_WALLET_FAILURE,
    error,
  }) as const;
