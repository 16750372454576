import { MutationErrors } from '/@/api/graphql/client';
import { updatePageError } from './actions';
import type { AppThunk } from '/@/store';
import { MutationErrors as mutationErrors } from './constants';

export const handleMutationErrorsThunk =
  (errors: MutationErrors): AppThunk<Promise<void>> =>
  (dispatch) => {
    // NOTE: 現状、単に 500 ページに飛ばすだけなので、個別のエラーメッセージ、コードの値を利用していない。
    // そのため、全てを INTERNAL_SERVER_ERROR として扱う。
    dispatch(
      updatePageError(
        mutationErrors.INTERNAL_SERVER_ERROR.message,
        mutationErrors.INTERNAL_SERVER_ERROR.status,
      ),
    );
    return Promise.reject(errors);
  };
