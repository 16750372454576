import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Divider,
} from '@material-ui/core';
import { GiftCardBase } from '/@/store/app/shared/types';
import { GiftCardTicket } from './giftCardTicket/Index';

const styles = () => {
  return createStyles({});
};

export type GiftCard = GiftCardBase;
type OwnProps = {
  giftCard: GiftCard;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ giftCard }) => (
  <div data-cy="giftCard">
    {giftCard.giftCardTickets.map((giftCardTicket) => (
      <React.Fragment key={giftCardTicket.ticket.url}>
        <GiftCardTicket giftCardTicket={giftCardTicket} />
        <Divider />
      </React.Fragment>
    ))}
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
