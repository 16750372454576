import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
} from '@material-ui/core';
import { BenefitOfPointChargeModalWithButton } from '/@/components/shared/benefitOfPointCharge/modalWithButton/Index';
import { Points } from '../points/Index';
import clsx from 'clsx';

export const POINT_SECTION_HEIGHT = 48;
export const POINT_SECTION_WITH_BENEFIT_OF_POINT_CHARGE_HEIGHT = 95;

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      padding: theme.spacing(1.5, 2),
      height: POINT_SECTION_HEIGHT,
    },
    withBenefitOfPointCharge: {
      height: POINT_SECTION_WITH_BENEFIT_OF_POINT_CHARGE_HEIGHT,
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
    },
    benefitOfPointCharge: {
      display: 'flex',
      justifyContent: 'end',
      marginTop: theme.spacing(1.5),
    },
    title: {
      fontWeight: 'bold',
    },
    hr: {
      marginBottom: 0,
    },
  });
};

type OwnProps = {
  points: number | undefined;
  benefitOfPointChargeModalVisible: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  points,
  benefitOfPointChargeModalVisible,
}) => (
  <div
    className={clsx(
      classes.container,
      benefitOfPointChargeModalVisible && classes.withBenefitOfPointCharge,
    )}
  >
    <Grid container alignItems="center" className={classes.heading}>
      <Typography className={classes.title}>所持ポイント</Typography>
      <Points points={points} fontSize="lg" />
    </Grid>
    {benefitOfPointChargeModalVisible && (
      <Grid container className={classes.benefitOfPointCharge}>
        <BenefitOfPointChargeModalWithButton />
      </Grid>
    )}
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
