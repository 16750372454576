import React from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  Container,
} from '@material-ui/core';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PATHS } from '/@/routes/paths';
import { HeadingWithTitle } from '/@/components/shared/headingWithTitle/Index';

const useStyles = makeStyles((theme) =>
  createStyles({
    mainContainer: {
      padding: theme.spacing(2),
      minHeight: '100vh',
    },
    baseBox: {
      textAlign: 'center',
      backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderRadius: theme.spacing(2),
    },
    serviceTopButton: {
      fontWeight: 'bold',
      padding: theme.spacing(),
      borderRadius: '1.4rem',
    },
    background: {
      backgroundColor: theme.palette.background.contrast,
    },
  }),
);

const Presenter: React.VFC<Record<string, never>> = () => {
  const classes = useStyles();

  return (
    <MainLayout>
      <div className={classes.background}>
        <Container maxWidth="sm" className={classes.mainContainer}>
          <HeadingWithTitle title="Thank You" subTitle="送信しました" />

          <Box className={classes.baseBox}>
            <Grid container direction="column" alignItems="stretch" spacing={2}>
              <Grid item xs>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs>
                    <Typography variant="body2" gutterBottom>
                      お問い合わせいただき、ありがとうございます。
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" gutterBottom>
                      土日または祝日に送られた場合、返信は翌営業日以降になりますのでご了承ください。
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" gutterBottom>
                      平日の場合は、順に返信しますので、
                      <br />
                      しばらくお待ちください。
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Button
                  className={classes.serviceTopButton}
                  disableElevation
                  variant="contained"
                  color="primary"
                  fullWidth
                  component={Link}
                  to={PATHS.ROOT}
                >
                  サービスTOPに戻る
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </MainLayout>
  );
};

export { Presenter };
