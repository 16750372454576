import { Reducer } from 'redux';
import type { RootAction } from '/@/store/actions';
import { ItemSearchConditionBase } from '/@/store/app/shared/types';
import { types } from './types';

type ItemSearchConditionState = ItemSearchConditionBase | null;

const initialState: ItemSearchConditionState = null;

const itemSearchCondition: Reducer<ItemSearchConditionState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.SET_ITEM_SEARCH_CONDITION:
      return action.itemSearchCondition;
    default:
      return state;
  }
};

export { itemSearchCondition };
export type { ItemSearchConditionState };
