import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  CardActionArea,
} from '@material-ui/core';

const styles = (theme: Theme) => {
  const TICKET_IMAGE_SIZE = 70;
  return createStyles({
    container: { padding: theme.spacing(2) },
    cardContent: { minWidth: 0 },
    ticketImage: {
      marginRight: theme.spacing(2),
      height: TICKET_IMAGE_SIZE,
      width: TICKET_IMAGE_SIZE,
      borderRadius: theme.spacing(),
    },
    ticketName: {
      maxWidth: '100%',
      fontWeight: 'bold',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: theme.palette.primary.dark,
    },
    ticketExpiredAtSecondary: {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
    ticketExpiredAtWarning: {
      fontWeight: 'bold',
      color: theme.palette.warning.main,
    },
  });
};

export type OwnProps = {
  url: string;
  name: string;
  imageUrl: string;
  expirationText: string;
  isExpiring: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  url,
  name,
  imageUrl,
  expirationText,
  isExpiring,
}) => (
  <CardActionArea
    href={url}
    role="link"
    data-cy={`ticketSkelton-${url}`}
    className={classes.container}
  >
    <Grid container wrap="nowrap" direction="row">
      <img className={classes.ticketImage} src={imageUrl} alt={name} />
      <Grid
        container
        justifyContent="center"
        direction="column"
        className={classes.cardContent}
      >
        <Typography variant="body1" className={classes.ticketName}>
          {name}
        </Typography>
        <Typography
          data-cy="expirationText"
          variant="caption"
          className={
            isExpiring
              ? classes.ticketExpiredAtWarning
              : classes.ticketExpiredAtSecondary
          }
        >
          {expirationText}
        </Typography>
      </Grid>
    </Grid>
  </CardActionArea>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
