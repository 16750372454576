import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Container,
} from '@material-ui/core';
import { ExchangedGifts } from './exchangedGifts/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import savedGiftIcon from '/@/assets/saved_gift_icon.svg';
import { ItemDetailModal } from '/@/components/shared/pointItemContents/itemDetailModal/Index';
import { PointLogType_StatusEnumType } from '/@/api/graphql/internalApi/types';
import { Link } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

const styles = (theme: Theme) =>
  createStyles({
    exchangedGiftsTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      padding: theme.spacing(3),
    },
    duplicatedExchangedGiftsErrorMessage: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.warning.main,
      padding: theme.spacing(3),
    },
    exchangedGiftsContainer: {
      padding: theme.spacing(4, 2),
    },
    exchangedGifts: {
      minWidth: '100%',
      maxWidth: '100%',
    },
    alertMessage: {
      textAlign: 'center',
      color: theme.palette.primary.dark,
      padding: theme.spacing(3),
    },
    savedGiftIcon: { margin: 'auto' },
    exchangedGiftsBackground: {
      backgroundColor: theme.palette.background.contrast,
    },
    myGiftsLinkText: {
      marginTop: theme.spacing(3),
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '0.675rem',
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    populerItemsSection: {
      padding: theme.spacing(2),
    },
  });

type OwnProps = {
  pointLogStatus?: PointLogType_StatusEnumType;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, pointLogStatus }) => (
  <MainLayout>
    <div className={classes.exchangedGiftsBackground}>
      <Container maxWidth="sm" className={classes.exchangedGiftsContainer}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          direction="column"
        >
          <img
            src={savedGiftIcon}
            alt="ギフトを交換しました"
            className={classes.savedGiftIcon}
            width="100"
            height="100"
            data-cy="savedGiftIcon"
          />
          <Typography className={classes.exchangedGiftsTitle}>
            {pointLogStatus === PointLogType_StatusEnumType.Retrying
              ? 'ギフト発行中に問題が発生しました'
              : 'ギフトを交換しました'}
          </Typography>
          <div className={classes.exchangedGifts}>
            {pointLogStatus === PointLogType_StatusEnumType.Retrying ? (
              <Typography className={classes.alertMessage}>
                ギフト発行中に問題が発生しました。
                <br />
                少し時間をおいてから、マイギフトページより再度実行してください。
              </Typography>
            ) : (
              <ExchangedGifts />
            )}
          </div>
        </Grid>
        <Typography className={classes.myGiftsLinkText}>
          獲得したギフトは
          <Link to={PATHS.HOME} className={classes.link} data-cy="homePageLink">
            マイギフト
          </Link>
          より確認できます
        </Typography>
      </Container>
    </div>
    {/* データが貯まるまで一旦非表示. cf: https://giftee-inc.slack.com/archives/C06E76T0J86/p1724049106246419?thread_ts=1723773614.435139&cid=C06E76T0J86 */}
    {/* <Container maxWidth='sm' className={classes.populerItemsSection}>
      <PopulerItemsSection />
    </Container> */}
    <ItemDetailModal />
  </MainLayout>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
