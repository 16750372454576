import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { discardMyGiftsGql } from './index.gql';
import { DiscardMyGiftsMutation, DiscardMyGiftsInput } from '../types';

type DiscardMyGiftsResponse = FetchResult<DiscardMyGiftsMutation>;

const discardMyGifts = (
  input: DiscardMyGiftsInput,
): Promise<DiscardMyGiftsResponse> => {
  return internalApiClient.mutate({
    mutation: discardMyGiftsGql,
    variables: {
      myGiftUrlCodes: input['myGiftUrlCodes'],
    },
  });
};

export { discardMyGifts };

export type { DiscardMyGiftsResponse };
