import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Box,
  Card,
} from '@material-ui/core';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { ExpiringMyGiftsState } from '/@/store/app/expiringMyGiftsConnection';
import { MyGift } from '/@/components/shared/myGift/Index';
const styles = (theme: Theme) =>
  createStyles({
    rootContainer: {
      [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.background.default,
      },
      backgroundColor: theme.palette.background.contrast,
    },
    contentBox: { padding: theme.spacing(1) },
    caption: {
      paddingLeft: theme.spacing(),
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    myGiftsContainer: {
      padding: theme.spacing(1),
      overflowX: 'auto',
      flexWrap: 'nowrap',
    },
    myGiftContainer: {
      marginRight: theme.spacing(1),
      maxWidth: '90%',
    },
    myGift: {
      borderRadius: theme.spacing(),
    },
  });

type OwnProps = {
  expiredMyGifts: ExpiringMyGiftsState;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getInitialExpiringMyGiftsConnectionStatus: Status;
  getContinuousExpiringMyGiftsConnectionStatus: Status;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  expiredMyGifts,
  infiniteScrollTarget,
  getInitialExpiringMyGiftsConnectionStatus,
  getContinuousExpiringMyGiftsConnectionStatus,
}) => (
  <>
    {getInitialExpiringMyGiftsConnectionStatus === STATUS.LOADING ? (
      <PartialLoader />
    ) : expiredMyGifts?.length ? (
      <Grid className={classes.rootContainer} data-cy="expiringMyGifts">
        <Box className={classes.contentBox}>
          <Typography variant="caption" className={classes.caption}>
            有効期限が近づいています
          </Typography>
          <Grid container className={classes.myGiftsContainer}>
            {expiredMyGifts.map((myGift) => (
              <div key={myGift.urlCode} className={classes.myGiftContainer}>
                <Card key={myGift.urlCode} className={classes.myGift}>
                  <MyGift myGift={myGift} />
                </Card>
              </div>
            ))}
          </Grid>
        </Box>
      </Grid>
    ) : (
      <></>
    )}
    {getContinuousExpiringMyGiftsConnectionStatus === STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <></>
    )}
    <div ref={infiniteScrollTarget} />
  </>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
