import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Button,
  Theme,
  Card,
  Grid,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { formatToEndDateJa } from '/@/utils/formatDate';
import { PointChargeModal } from '/@/components/shared/modal/pointChargeModal/Index';
import { GetMyGiftQuery } from '/@/api/graphql/internalApi/types';
import clsx from 'clsx';

const styles = (theme: Theme) =>
  createStyles({
    myGiftCard: {
      padding: theme.spacing(3, 2),
      borderRadius: theme.spacing(2),
    },
    logoAndTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
      height: '100%',
      width: '100%',
    },
    // logoContainerのwidthは64px+rightContentのmarginLeft（8px）で調整
    logoContainer: {
      width: '4.5rem',
      height: '4rem',
      marginBottom: 'auto',
      borderRadius: theme.spacing(1),
    },
    logo: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    rightContent: {
      marginLeft: theme.spacing(1),
      width: '-webkit-fill-available',
    },
    pointChargeableText: {
      fontSize: '0.625rem',
      lineHeight: '0.625rem',
      fontWeight: 'bold',
      color: theme.palette.success.main,
    },
    notChargeableText: {
      fontSize: '0.625rem',
      lineHeight: '0.625rem',
      fontWeight: 'bold',
      color: '#A6ADAF',
    },
    itemName: {
      marginTop: theme.spacing(1),
      fontSize: '0.875rem',
      lineHeight: '1.4rem',
      fontWeight: 'bold',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
    },
    remainingPoint: {
      fontWeight: 'normal',
      fontSize: '0.75rem',
      lineHeight: '1.2rem',
    },
    endAt: {
      marginTop: theme.spacing(1),
      fontSize: '0.625rem',
      lineHeight: '0.625rem',
      fontWeight: 'bold',
      color: theme.palette.grey.A700,
    },
    buttonsContainer: {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    pointChargeButton: {
      width: '100%',
      fontSize: '0.875rem',
      lineHeight: '0.875rem',
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2, 4),
      borderRadius: theme.spacing(4),
      boxShadow: 'none',
    },
    notChargeableButton: {
      color: theme.palette.common.white + ' !important',
      backgroundColor: '#A6ADAF' + ' !important',
    },
    usingGiftButton: {
      width: '100%',
      fontSize: '0.875rem',
      lineHeight: '0.875rem',
      fontWeight: 'bold',
      padding: theme.spacing(2, 4),
      borderRadius: theme.spacing(4),
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: 'none',
    },
  });

type OwnProps = {
  isOpenModal: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  myGift: GetMyGiftQuery['myGift'];
  expiredAtLabel: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  isOpenModal,
  handleOpenModal,
  handleCloseModal,
  myGift,
  expiredAtLabel,
}) => (
  <Card variant="outlined" className={classes.myGiftCard}>
    <div className={classes.logoAndTitle}>
      <div className={classes.logoContainer}>
        <img
          className={classes.logo}
          src={myGift.imageUrl}
          alt={myGift.contentName}
        />
      </div>
      <div className={classes.rightContent}>
        {myGift.pointMergeable ? (
          <Typography className={classes.pointChargeableText}>
            ポイントに移行可能なギフト
          </Typography>
        ) : (
          <Typography className={classes.notChargeableText}>
            ポイント移行不可
          </Typography>
        )}
        <Typography className={classes.itemName}>
          {myGift.contentName}
          {myGift.remainingPoint ? (
            <span
              className={classes.remainingPoint}
            >{`（${myGift.remainingPoint.toLocaleString('ja-JP')}ポイント）`}</span>
          ) : (
            ''
          )}
        </Typography>
        <Typography className={classes.endAt}>
          {expiredAtLabel +
            '期限 ' +
            (myGift.expiredAt
              ? formatToEndDateJa(myGift.expiredAt)
              : '取得中...')}
        </Typography>
      </div>
    </div>
    <Grid
      container
      direction="column"
      alignContent="center"
      className={classes.buttonsContainer}
    >
      <Button
        variant="contained"
        color="primary"
        disableElevation
        className={clsx(
          classes.pointChargeButton,
          myGift.pointMergeable ? '' : classes.notChargeableButton,
        )}
        onClick={handleOpenModal}
        disabled={myGift.remainingPoint === 0}
        data-cy="pointChargeButton"
        data-gtm-click="pointPage-chargePage-pointChargeSection-chargeableGift-pointChargeButton"
      >
        {myGift.pointMergeable && myGift.remainingPoint > 0
          ? 'ギフトをポイントに移行する'
          : 'ポイント移行不可'}
      </Button>
      <Button
        variant="contained"
        color="default"
        disableElevation
        className={classes.usingGiftButton}
        component={Link}
        to={myGift.giftUrl}
        data-cy="usingGiftButton"
      >
        ギフトを利用する
      </Button>
    </Grid>
    {isOpenModal && <PointChargeModal handleClose={handleCloseModal} />}
  </Card>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
