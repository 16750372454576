import { gql } from '@apollo/client';

export const signInOrSignUpGql = gql`
  mutation SignInOrSignUp($signInOrSignUpInput: SignInOrSignUpInput!) {
    signInOrSignUp(input: $signInOrSignUpInput) {
      session {
        token
        expiredAt
      }
      oauthSession {
        giftSavingOption {
          url
          origin
          referer
        }
        destinationOption {
          name
        }
        pointSerialOption {
          serialCode
        }
      }
      errors {
        message
        path
        extensions {
          status
          code
        }
      }
    }
  }
`;
