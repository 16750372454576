import { getMyGiftsConnection } from '/@/api/graphql/internalApi/getMyGiftsConnection';
import type { GetMyGiftsConnectionResponse } from '/@/api/graphql/internalApi/getMyGiftsConnection';
import type { AppThunk } from '/@/store';
import {
  getContinuousAvailableMyGiftsConnectionStart,
  getContinuousAvailableMyGiftsConnectionSuccess,
  getContinuousAvailableMyGiftsConnectionFailure,
} from './actions';
import type { PageInfo } from '/@/api/graphql/internalApi/types';
import {
  MyGiftType_GiftTypeEnum,
  MyGiftUsageStatusEnum,
} from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getContinuousAvailableMyGiftsConnectionThunk =
  (after: PageInfo['endCursor']): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getContinuousAvailableMyGiftsConnectionStart());

    return getMyGiftsConnection({
      usageStatuses: [MyGiftUsageStatusEnum.Available],
      giftTypeIn: [
        MyGiftType_GiftTypeEnum.GiftCardTicketChoosableContent,
        MyGiftType_GiftTypeEnum.GiftCardTicketFixedContent,
        MyGiftType_GiftTypeEnum.GifteeBox,
        MyGiftType_GiftTypeEnum.SimpleGift,
      ],
      first: 20,
      after,
    })
      .then((response) =>
        dispatch(getContinuousAvailableMyGiftsConnectionSuccessThunk(response)),
      )
      .catch((error) =>
        dispatch(getContinuousAvailableMyGiftsConnectionFailureThunk(error)),
      );
  };

const getContinuousAvailableMyGiftsConnectionSuccessThunk =
  (response: GetMyGiftsConnectionResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { myGiftsConnection } = response.data!;
    dispatch(getContinuousAvailableMyGiftsConnectionSuccess(myGiftsConnection));
    return Promise.resolve();
  };

const getContinuousAvailableMyGiftsConnectionFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getContinuousAvailableMyGiftsConnectionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getContinuousAvailableMyGiftsConnectionThunk };
