import { Reducer } from 'redux';
import { getCurrentUserTypes } from '/@/store/api/getCurrentUser';
import type { RootAction } from '/@/store/actions';

type Gender = {
  code: number;
  name: string;
};

type Prefecture = {
  code: number;
  name: string;
};

type Profile = {
  birthday: string;
  gender: Gender;
  prefecture: Prefecture;
};

type CurrentUser = {
  uid: string;
  lastSignInAt: string;
  profile?: Profile | null;
};

type CurrentUserState = CurrentUser | null;

const currentUser: Reducer<CurrentUserState, RootAction> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case getCurrentUserTypes.GET_CURRENT_USER_SUCCESS:
      return action.currentUser;
    default:
      return state;
  }
};

export { currentUser };
export type { CurrentUserState, CurrentUser };
