import { types } from './types';
import { ApiError } from '/@/api/graphql/client';
import { GiftConvertToPointRequestSubscription } from '/@/api/graphql/internalApi/types';

export const initialiseGiftConvertToPointRequestSubscriptionStatus = () =>
  ({
    type: types.INITIALISE_GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_STATUS,
  }) as const;

export const giftConvertToPointRequestSubscriptionStart = () =>
  ({
    type: types.GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_START,
  }) as const;

export const giftConvertToPointRequestSubscriptionSuccess = (
  responce: NonNullable<
    GiftConvertToPointRequestSubscription['giftConvertToPointCompletion']['giftConvertToPointRequest']
  >,
) =>
  ({
    type: types.GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_SUCCESS,
    responce,
  }) as const;

// MEMO: ポイント移行が非同期のため、エラーメッセージをフィールドで受け取っているためStringを許容
export const giftConvertToPointRequestSubscriptionFailure = (
  error: ApiError | string,
) =>
  ({
    type: types.GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_FAILURE,
    error,
  }) as const;
