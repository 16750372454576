import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Card,
  Divider,
} from '@material-ui/core';
import { GifteeBoxGifteeContentBase } from '/@/store/app/shared/types';
import { GifteeBox } from './gifteeBox/Index';
import { formatToDateJa } from '/@/utils/formatDate';

const styles = (theme: Theme) => {
  return createStyles({
    card: {
      border: 'none',
      borderRadius: theme.spacing(2),
    },
    content: {
      padding: theme.spacing(2),
    },
    contentExpiredAt: {
      color: theme.palette.secondary.main,
    },
    contentName: {
      overflowWrap: 'break-word',
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
    contentCount: {
      color: theme.palette.secondary.main,
    },
  });
};

export type GifteeBoxGifteeContent = GifteeBoxGifteeContentBase;

type OwnProps = {
  gifteeBoxGifteeContent: GifteeBoxGifteeContent;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, gifteeBoxGifteeContent }) => (
  <Card
    data-cy="gifteeBoxGifteeContent"
    variant="outlined"
    className={classes.card}
  >
    <Grid className={classes.content}>
      {gifteeBoxGifteeContent.savedAt && (
        <Typography
          variant="caption"
          className={classes.contentExpiredAt}
          data-cy="gifteeBoxGifteeContentSavedAt"
        >
          保存日時：{formatToDateJa(gifteeBoxGifteeContent.savedAt)}
        </Typography>
      )}
      <Typography variant="body1" className={classes.contentName}>
        {gifteeBoxGifteeContent.content.name}
      </Typography>
    </Grid>
    <Divider />
    <GifteeBox gifteeBox={gifteeBoxGifteeContent.content} />
  </Card>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
