import { types } from './types';
import type { GetGifteeContentQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getGifteeContentStart = () =>
  ({
    type: types.GET_GIFTEE_CONTENT_START,
  }) as const;

export const getGifteeContentSuccess = (
  gifteeContent: GetGifteeContentQuery['gifteeContent'],
) =>
  ({
    type: types.GET_GIFTEE_CONTENT_SUCCESS,
    gifteeContent,
  }) as const;

export const getGifteeContentFailure = (error: ApiError) =>
  ({
    type: types.GET_GIFTEE_CONTENT_FAILURE,
    error,
  }) as const;
