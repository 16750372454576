import { createUserProfile } from '/@/api/graphql/internalApi/createUserProfile';
import {
  createUserProfileStart,
  createUserProfileSuccess,
  createUserProfileFailure,
} from './actions';
import type { CreateUserProfileInput } from '/@/api/graphql/internalApi/types';
import type { AppThunk } from '/@/store';
import { handleErrorThunk } from '/@/store/api/handleError';
import type { ApiError } from '/@/api/graphql/client';

const createUserProfileThunk =
  (
    input: CreateUserProfileInput,
  ): // eslint-disable-next-line @typescript-eslint/no-explicit-any
  AppThunk<Promise<void | any>> =>
  (dispatch) => {
    dispatch(createUserProfileStart());

    return createUserProfile(input)
      .then(() => dispatch(createUserProfileSuccessThunk()))
      .catch((error) => dispatch(createUserProfileFailureThunk(error)));
  };

const createUserProfileSuccessThunk =
  (): AppThunk<Promise<void>> => (dispatch) => {
    dispatch(createUserProfileSuccess());
    return Promise.resolve();
  };

const createUserProfileFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(createUserProfileFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { createUserProfileThunk };
