import React, { useEffect, useRef } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getItemsConnectionThunk } from '/@/store/api/getItemsConnection';
import { STATUS } from '/@/store/api/constants';
import { useIntersectionObserver } from '/@/common/hooks/useIntersectionObserver';
import { getItemDetailThunk } from '/@/store/api/getItemDetail';
import { initialiseItemsConnection } from '/@/store/app/itemsConnection/actions';

const Container: React.VFC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();
  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);
  const items = useAppSelector((state) => state.app.itemsConnection.items);
  const itemSearchCondition = useAppSelector(
    (state) => state.app.itemSearchCondition,
  );
  const pageInfo = useAppSelector(
    (state) => state.app.itemsConnection.pageInfo,
  );
  const getItemsConnectionStatus = useAppSelector(
    (state) => state.api.getItemsConnection.status,
  );
  const selectItemDetail = (uid: string) =>
    dispatch(getItemDetailThunk({ uid: uid })).catch(() => {});

  useEffect(() => {
    startObserving(true);

    return () => {
      startObserving(false);
    };
  }, [startObserving]);

  useEffect(() => {
    if (itemSearchCondition) {
      dispatch(initialiseItemsConnection());
      dispatch(
        getItemsConnectionThunk({
          after: null,
        }),
      ).catch(() => {});
    }
  }, [dispatch, itemSearchCondition]);

  useEffect(() => {
    if (
      isIntersect &&
      getItemsConnectionStatus !== STATUS.LOADING &&
      pageInfo?.hasNextPage
    ) {
      dispatch(
        getItemsConnectionThunk({
          after: pageInfo?.endCursor,
        }),
      ).catch(() => {});
    }
  }, [isIntersect, getItemsConnectionStatus, pageInfo, dispatch]);

  return (
    <Presenter
      itemContents={items}
      infiniteScrollTarget={infiniteScrollTarget}
      getItemsConnectionStatus={getItemsConnectionStatus}
      selectItemDetail={selectItemDetail}
    />
  );
};

export { Container as PointItemContents };
