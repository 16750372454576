import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
} from '@material-ui/core';
import { BrandCard } from '../brandCard/Index';
import { BrandsConnectionBase } from '/@/store/app/shared/types';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { STATUS, Status } from '/@/store/api/constants';

const styles = (theme: Theme) => {
  return createStyles({
    sectionContents: {
      marginTop: theme.spacing(2),
    },
    grid: {
      justifyContent: 'center',
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(2),
    },
  });
};

type OwnProps = {
  brands: BrandsConnectionBase[] | null;
  getRecommendedBrandsStatus: Status;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  brands,
  getRecommendedBrandsStatus,
}) => {
  return (
    <>
      <div className={classes.sectionContents}>
        {brands && brands.length > 0 ? (
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            data-cy="recommendedBrands"
            className={classes.grid}
          >
            {brands.map((brand, index) => (
              <BrandCard
                key={index}
                index={index}
                brand={brand}
                isRecommended={true}
                isLastItem={index === brands.length - 1}
              />
            ))}
          </Grid>
        ) : getRecommendedBrandsStatus === STATUS.LOADING ||
          getRecommendedBrandsStatus === STATUS.IDLE ? (
          <PartialLoader />
        ) : (
          getRecommendedBrandsStatus === STATUS.SUCCESS && (
            <Typography> 現在おトクなチケットはありません</Typography>
          )
        )}
      </div>
    </>
  );
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
