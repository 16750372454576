import { types } from './types';
import type { GetBrandsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getBrandsConnectionStart = () =>
  ({
    type: types.GET_BRANDS_CONNECTION_START,
  }) as const;

export const getBrandsConnectionSuccess = (
  brandsConnection: GetBrandsConnectionQuery['brands'],
) =>
  ({
    type: types.GET_BRANDS_CONNECTION_SUCCESS,
    brandsConnection,
  }) as const;

export const getBrandsConnectionFailure = (error: ApiError) =>
  ({
    type: types.GET_BRANDS_CONNECTION_FAILURE,
    error,
  }) as const;
