import { Reducer } from 'redux';
import { getPromotionSpotsTypes } from '/@/store/api/getPromotionSpots';
import type { RootAction } from '/@/store/actions';

type PromotionSpotsState = Array<PromotionSpot> | null;

type PromotionSpot = {
  name: string;
  order: number;
  promotionPublishment: PromotionPublishment;
};

type PromotionPublishment = {
  promotion: Promotion;
  url: string;
};

type Promotion = {
  description: string;
  name: string;
  promoter: Promoter;
  promotionTag: PromotionTag;
  reward: Reward;
};

type Promoter = {
  imageUrl: string;
  name: string;
};

type PromotionTag = {
  name: string;
};

type Reward = {
  imageUrl: string;
  name: string;
};

const initialState: PromotionSpotsState = null;

const promotionSpots: Reducer<PromotionSpotsState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getPromotionSpotsTypes.GET_PROMOTION_SPOTS_SUCCESS:
      return action.promotionSpots;
    default:
      return state;
  }
};

export { promotionSpots };
export type { PromotionSpotsState };
