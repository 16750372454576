import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Container,
  Typography,
} from '@material-ui/core';
import pointChargeImage from '/@/assets/point_charge_image.png';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      textAlign: 'center',
    },
    title: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      textAlign: 'center',
    },
    text_part1: {
      fontSize: '0.75rem',
      fontHeight: '1.2rem',
      marginTop: theme.spacing(2),
      color: '#4E5B61',
    },
    text_part2: {
      fontSize: '0.75rem',
      fontHeight: '1.2rem',
      fontWeight: 'bold',
      color: theme.palette.warning.main,
    },
    attention: {
      fontSize: '0.75rem',
      fontHeight: '1.2rem',
      color: theme.palette.secondary.light,
      marginTop: theme.spacing(2),
    },
    imageConteiner: {
      marginTop: theme.spacing(2),
    },
    image: {
      display: 'block',
      margin: 'auto',
      width: '80%',
    },
  });

type Props = WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes }) => (
  <Container
    maxWidth="sm"
    className={classes.container}
    data-cy="benefitOfPointChargeIllustration"
  >
    <Typography className={classes.title}>
      ポイントに移行するとさらにお得
    </Typography>
    <Typography className={classes.text_part1}>
      ポイントへ移行することで
      <br />
      多くの商品と交換ができるのはもちろんのこと、
    </Typography>
    <Typography className={classes.text_part2}>
      還元やポイント追加付与など
      <br />
      さらにお得にご利用することができます！
    </Typography>
    <Typography className={classes.attention}>
      ※ 獲得したポイント毎に有効期限が異なります。
      <br />※ 有効期限の近いポイントから利用されます。
    </Typography>
    <div className={classes.imageConteiner}>
      <img
        src={pointChargeImage}
        alt="ポイント移行イメージ"
        className={classes.image}
        data-cy="pointChargeImage"
      />
    </div>
  </Container>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
