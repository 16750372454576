import { types } from './types';
import { SelectedGiftsAvailabilityTabState } from './reducers';

export const selectgiftsAvailabilityTab = (
  selectedGiftsAvailabilityTab: SelectedGiftsAvailabilityTabState,
) =>
  ({
    type: types.SELECT_GIFTS_AVAILABILITY_TAB,
    selectedGiftsAvailabilityTab,
  }) as const;
