import { types } from './types';
import type { GetMyGiftsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getInitialAvailableMyGiftsConnectionStart = () =>
  ({
    type: types.GET_INITIAL_AVAILABLE_MY_GIFTS_CONNECTION_START,
  }) as const;

export const getInitialAvailableMyGiftsConnectionSuccess = (
  availableMyGiftsConnection: GetMyGiftsConnectionQuery['myGiftsConnection'],
) =>
  ({
    type: types.GET_INITIAL_AVAILABLE_MY_GIFTS_CONNECTION_SUCCESS,
    availableMyGiftsConnection,
  }) as const;

export const getInitialAvailableMyGiftsConnectionFailure = (error: ApiError) =>
  ({
    type: types.GET_INITIAL_AVAILABLE_MY_GIFTS_CONNECTION_FAILURE,
    error,
  }) as const;
