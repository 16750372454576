import { types } from './types';
import type { DiscardMyGiftsMutation } from '/@/api/graphql/internalApi/types';
import { ApiError } from '/@/api/graphql/client';

export const discardMyGiftsIdle = () =>
  ({
    type: types.DISCARD_MY_GIFTS_IDLE,
  }) as const;

export const discardMyGiftsStart = () =>
  ({
    type: types.DISCARD_MY_GIFTS_START,
  }) as const;

export const discardMyGiftsSuccess = (
  myGifts: NonNullable<DiscardMyGiftsMutation['discardMyGifts']['myGifts']>,
  message: string,
) =>
  ({
    type: types.DISCARD_MY_GIFTS_SUCCESS,
    myGifts,
    message,
  }) as const;

export const discardMyGiftsFailure = (error: ApiError) =>
  ({
    type: types.DISCARD_MY_GIFTS_FAILURE,
    error,
  }) as const;
