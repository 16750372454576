import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { MyGift } from '/@/components/shared/myGift/Index';
import { GroupedAvailableMyGifts } from '/@/store/app/availableMyGiftsConnection/selectors';
import { MyGiftBase } from '/@/store/app/shared/types';

const styles = (theme: Theme) =>
  createStyles({
    editGiftsContainer: {
      padding: theme.spacing(0, 2),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.hint,
    },
    groupedLabelText: {
      display: 'inline-block',
      marginTop: theme.spacing(3),
      fontWeight: 'bold',
      lineHeight: '1',
      color: '#4E5B61',
    },
    availableMyGiftsNotFoundContainer: {
      padding: theme.spacing(2),
    },
    availableMyGiftsNotFoundTextContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    availableMyGiftsNotFoundText: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    promotionsPageButton: {
      fontWeight: 'bold',
      borderRadius: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
    editableMyGiftForm: {
      marginTop: theme.spacing(2),
      borderRadius: theme.spacing(1.5),
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    },
    editableMyGiftLabel: {
      minWidth: '0px',
      width: '100%',
      pointerEvents: 'none',
    },
    editableMyGiftRoot: {
      width: '100%',
      margin: '0px',
      padding: 0,
      backgroundColor: theme.palette.common.white,
    },
  });

type OwnProps = {
  groupedAvailableMyGifts: GroupedAvailableMyGifts;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getInitialAvailableMyGiftsConnectionStatus: Status;
  getContinuousAvailableMyGiftsConnectionStatus: Status;
  handleEditingMyGift: (myGift: MyGiftBase, checked: boolean) => void;
  isSelectedMyGift: (myGift: MyGiftBase) => boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  groupedAvailableMyGifts,
  infiniteScrollTarget,
  getInitialAvailableMyGiftsConnectionStatus,
  getContinuousAvailableMyGiftsConnectionStatus,
  handleEditingMyGift,
  isSelectedMyGift,
}) => (
  <Grid data-cy="availableMyGifts">
    {getInitialAvailableMyGiftsConnectionStatus === STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <div className={classes.editGiftsContainer}>
        {Object.entries(groupedAvailableMyGifts).map(([date, myGifts]) => (
          <React.Fragment key={date}>
            <Typography variant="caption" className={classes.groupedLabelText}>
              {date}
            </Typography>
            <FormGroup>
              {myGifts.map((myGift) => (
                <div
                  data-cy={`availableMyGift-${myGift.urlCode}`}
                  key={myGift.urlCode}
                  className={classes.editableMyGiftForm}
                >
                  <FormControlLabel
                    classes={{
                      root: classes.editableMyGiftRoot,
                      label: classes.editableMyGiftLabel,
                    }}
                    control={
                      <Checkbox
                        checked={isSelectedMyGift(myGift)}
                        data-cy={`checkBox-${myGift.urlCode}`}
                        color="primary"
                        onChange={(_, checked) =>
                          handleEditingMyGift(myGift, checked)
                        }
                        style={{ padding: 0, marginLeft: 20 }}
                      />
                    }
                    label={<MyGift myGift={myGift} />}
                  />
                </div>
              ))}
            </FormGroup>
          </React.Fragment>
        ))}
      </div>
    )}
    {getContinuousAvailableMyGiftsConnectionStatus === STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <></>
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
