import { CurrentUser } from './reducers';
import { RootState } from '/@/store';

export const selectCurrentUserProfile = (
  state: RootState,
): CurrentUser['profile'] => {
  return state.app.currentUser?.profile;
};

export const selectIsUserProfilePresent = (state: RootState): boolean =>
  !!state.app.currentUser?.profile;
