import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Container,
  Grid,
} from '@material-ui/core';
import { BenefitOfPointCharge } from '/@/components/shared/benefitOfPointCharge/illustration/Index';
import { ChargeableGift } from './chargeableGift/Index';
import { BenefitOfPointChargeModalWithButton } from '/@/components/shared/benefitOfPointCharge/modalWithButton/Index';
import { PageLoader } from '/@/components/shared/loader/pageLoader/Index';
import { PointChargeModal } from '/@/components/shared/modal/pointChargeModal/Index';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.contrast,
      padding: theme.spacing(2),
    },
    benefitOfPointChargeSection: {
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
    benefitOfPointChargeModal: {
      marginTop: theme.spacing(2),
    },
  });

type OwnProps = {
  hasCharged: boolean | null;
  skipConfirm?: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, hasCharged, skipConfirm }) => (
  <Container maxWidth="sm" className={classes.container}>
    {skipConfirm === true ? (
      <>
        <PageLoader />
        {/* skipConfirmがtrueの時、モーダル非表示で自動的にチャージ処理が実行される */}
        <PointChargeModal handleClose={() => {}} skipConfirm={skipConfirm} />
      </>
    ) : (
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        direction="column"
      >
        {hasCharged === false && (
          <div
            className={classes.benefitOfPointChargeSection}
            data-cy="benefitOfPointCharge"
          >
            <BenefitOfPointCharge />
          </div>
        )}
        <ChargeableGift />
        {hasCharged === true && (
          <Grid
            container
            alignContent="center"
            justifyContent="center"
            className={classes.benefitOfPointChargeModal}
          >
            <BenefitOfPointChargeModalWithButton />
          </Grid>
        )}
      </Grid>
    )}
  </Container>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
