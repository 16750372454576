import { gql } from '@apollo/client';

export const getBrandGql = gql`
  query GetBrand($uid: String!) {
    brand(uid: $uid) {
      currentPointBackCampaign {
        beginAt
        description
        discountPercentage
        discountRate
        endAt
        normalDiscountPercentage
        normalDiscountRate
        uid
      }
      description
      iconUrl
      name
      uid
    }
  }
`;
