import { gql } from '@apollo/client';
import { FRAGMENT_MY_GIFT } from '/@/api/graphql/internalApi/fragments/myGift.gql';
import { FRAGMENT_ERRORS } from '/@/api/graphql/internalApi/fragments/errors.gql';

export const discardMyGiftsGql = gql`
  ${FRAGMENT_MY_GIFT}
  ${FRAGMENT_ERRORS}
  mutation DiscardMyGifts($myGiftUrlCodes: [ID!]!) {
    discardMyGifts(input: { myGiftUrlCodes: $myGiftUrlCodes }) {
      myGifts {
        ...MyGiftFields
      }
      errors {
        ...ErrorsFields
      }
    }
  }
`;
