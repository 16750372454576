import React from 'react';
import { Presenter } from './Presenter';
import { DestinationEnum, OriginEnum } from '/@/api/graphql/publicApi/types';

type OwnProps = {
  url: string | null;
  origin: OriginEnum | null;
  referer: string | null;
  convertToPoint: boolean | null;
  destination: DestinationEnum | null;
};

const Container: React.VFC<OwnProps> = ({
  url,
  origin,
  referer,
  convertToPoint,
  destination,
}) => {
  return (
    <Presenter
      url={url}
      origin={origin}
      referer={referer}
      convertToPoint={convertToPoint}
      destination={destination}
    />
  );
};

export { Container as HeroSection };
