import * as Sentry from '@sentry/react';

const initSentry = (): void => {
  Sentry.init({
    dsn: process.env.VITE_SENTRY_DSN,
    environment: process.env.VITE_SENTRY_ENVIRONMENT,
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (hint) {
        // ref: https://docs.sentry.io/platforms/javascript/configuration/filtering/#hints-for-breadcrumbs
        if (breadcrumb.data && hint.input && hint.input[1]?.body) {
          // when breadcrumb is created from HTTP requests by fetch API, it captures request query.
          // hint.input is tuple, ["request url", "request body"]
          breadcrumb.data.requestQuery = JSON.stringify(hint.input[1].body);
        }
      }
      return breadcrumb;
    },
  });
};

export { initSentry };
