import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getItemDetailGql } from './index.gql';
import { GetItemDetailQuery, GetItemDetailQueryVariables } from '../types';

type GetItemDetailResponse = FetchResult<GetItemDetailQuery>;

const getItemDetail = (
  variables: GetItemDetailQueryVariables,
): Promise<GetItemDetailResponse> => {
  return internalApiClient.query({
    query: getItemDetailGql,
    variables,
  });
};

export { getItemDetail };

export type { GetItemDetailResponse };
