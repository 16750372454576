import React from 'react';
import { Presenter } from './Presenter';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { useAppDispatch, useAppSelector } from '/@/store';
import { switchDisplayOnlyExchangeableBrands } from '/@/store/app/displayOnlyExchangeableBrands/actions';

const Container: React.VFC<Record<string, never>> = () => {
  const { pushClickEvent } = useDataLayer();
  const isDisplayOnlyExchangeableBrands = useAppSelector(
    (state) => state.app.displayOnlyExchangeableBrands,
  );
  const dispatch = useAppDispatch();

  const handleDisplayOnlyExchangeable = () => {
    dispatch(switchDisplayOnlyExchangeableBrands());
    pushClickEvent({
      clickItem: 'displayOnlyExchangeableCheckbox',
      customData: {
        clickItem: 'displayOnlyExchangeableCheckbox',
        displayOnlyExchangeable: !isDisplayOnlyExchangeableBrands,
      },
    });
  };

  return (
    <Presenter
      isDisplayOnlyExchangeableBrands={isDisplayOnlyExchangeableBrands}
      handleDisplayOnlyExchangeable={handleDisplayOnlyExchangeable}
    />
  );
};

export { Container as BrandContents };
