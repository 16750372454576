import React, { ChangeEvent, FormEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  createStyles,
  FormControl,
  FormLabel,
  Grid,
  Link,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  ButtonGroup,
  Button,
  Container,
} from '@material-ui/core';

import { Header } from '/@/components/templates/header/Index';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { LoadingButton } from '/@/components/shared/button/loadingButton/Index';
import { PATHS } from '/@/routes/paths';
import createUserProfileIcon from '/@/assets/create_user_profile_icon.svg';
import { genderCodes } from '/@/utils/genderCodeMapping';
import { prefectureCodes } from '/@/utils/prefectureCodeMapping';
import { UserProfileNewPageInputState } from '/@/store/page/userPage/profilePage/newPage';

const useStyles = makeStyles((theme) =>
  createStyles({
    baseBox: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(5) + HEADER_HEIGHT,
      marginBottom: theme.spacing(5),
    },
    createUserProfileIcon: {
      display: 'block',
      margin: 'auto',
    },
    titleText: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    captionText: {
      display: 'block',
      textAlign: 'center',
      color: theme.palette.secondary.main,
    },
    dateText: {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
    formContainer: {
      marginTop: theme.spacing(2),
    },
    formGroup: {
      marginTop: theme.spacing(),
    },
    genderLabel: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    birthdayLabel: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    prefectureLabel: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    genderSelectionButtonUnselected: {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
    genderSelectionButtonSelected: {
      fontWeight: 'bold',
      color: theme.palette.background.highContrast,
      backgroundColor: theme.palette.background.contrast,
    },
    submitButton: {
      borderRadius: theme.spacing(4),
      padding: theme.spacing(2),
      fontWeight: 'bold',
    },
  }),
);

type Props = {
  genderCode: UserProfileNewPageInputState['genderCode'] | null;
  prefectureCode: UserProfileNewPageInputState['prefectureCode'] | null;
  isLoading: boolean;
  isReadyToSubmit: boolean;
  pushDatalayer: () => void;
  handleChangeGender: (selectedGenderCode: number) => void;
  handleChangeBirthdayYear: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeBirthdayMonth: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeBirthdayDay: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangePrefecture: (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

const Presenter: React.VFC<Props> = ({
  genderCode,
  prefectureCode,
  isLoading,
  isReadyToSubmit,
  pushDatalayer,
  handleChangeGender,
  handleChangeBirthdayYear,
  handleChangeBirthdayMonth,
  handleChangeBirthdayDay,
  handleChangePrefecture,
  handleSubmit,
}) => {
  const classes = useStyles();

  return (
    <>
      <Header isMenuVisible={false} isLogoClickable={false} />
      <Container maxWidth="sm">
        <Box className={classes.baseBox}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item xs>
              <img
                data-cy="createUserProfileIcon"
                alt="プロフィール作成アイコン"
                src={createUserProfileIcon}
                className={classes.createUserProfileIcon}
              />
            </Grid>
            <Grid item xs>
              <Grid
                container
                direction="column"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item xs>
                  <Typography
                    className={classes.titleText}
                    variant="subtitle1"
                    gutterBottom
                  >
                    プロフィールを登録してギフトを保存
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography
                    className={classes.captionText}
                    variant="caption"
                    gutterBottom
                  >
                    誕生日や住んでいる地域など、情報を登録すると、あなたに合った限定ギフトやクーポンが届くかも！
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs>
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  direction="column"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs className={classes.formContainer}>
                    <FormControl component="fieldset">
                      <FormLabel
                        className={classes.genderLabel}
                        component="legend"
                      >
                        性別
                      </FormLabel>
                      <Box mt={2}>
                        <ButtonGroup variant="outlined" disableElevation>
                          {genderCodes.map((genderCodeMaster) => (
                            <Button
                              key={genderCodeMaster.code}
                              className={
                                genderCode === genderCodeMaster.code
                                  ? classes.genderSelectionButtonSelected
                                  : classes.genderSelectionButtonUnselected
                              }
                              onClick={() =>
                                handleChangeGender(genderCodeMaster.code)
                              }
                              data-gtm-click="userPage-profilePage-newPage-genderSelectionButton"
                            >
                              {genderCodeMaster.name}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs className={classes.formContainer}>
                    <FormControl component="fieldset">
                      <FormLabel
                        className={classes.birthdayLabel}
                        component="legend"
                      >
                        生年月日
                      </FormLabel>
                      <Grid
                        container
                        className={classes.formGroup}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item xs>
                          <TextField
                            id="user-profile-birth-year"
                            variant="outlined"
                            size="small"
                            placeholder="1980"
                            required
                            value={null}
                            autoComplete="bday-year"
                            onChange={handleChangeBirthdayYear}
                            inputProps={{
                              inputMode: 'numeric',
                              style: { textAlign: 'center' },
                              maxLength: 4,
                              minLength: 4,
                              pattern: '^(19|20)\\d{2}$',
                            }}
                          />
                        </Grid>
                        <Typography
                          className={classes.dateText}
                          variant="caption"
                        >
                          年
                        </Typography>
                        <Grid item xs>
                          <TextField
                            id="user-profile-birth-month"
                            variant="outlined"
                            size="small"
                            placeholder="01"
                            required
                            value={null}
                            autoComplete="bday-month"
                            onChange={handleChangeBirthdayMonth}
                            inputProps={{
                              inputMode: 'numeric',
                              style: { textAlign: 'center' },
                              maxLength: 2,
                              minLength: 1,
                              pattern: '^(0?[1-9]|1[012])$',
                            }}
                          />
                        </Grid>
                        <Typography
                          className={classes.dateText}
                          variant="caption"
                        >
                          月
                        </Typography>
                        <Grid item xs>
                          <TextField
                            id="user-profile-birth-day"
                            variant="outlined"
                            size="small"
                            placeholder="01"
                            required
                            value={null}
                            autoComplete="bday-day"
                            onChange={handleChangeBirthdayDay}
                            inputProps={{
                              inputMode: 'numeric',
                              style: { textAlign: 'center' },
                              maxLength: 2,
                              minLength: 1,
                              pattern: '^(0?[1-9]|[12]\\d|3[01])$',
                            }}
                          />
                        </Grid>
                        <Typography
                          className={classes.dateText}
                          variant="caption"
                        >
                          日
                        </Typography>
                      </Grid>
                    </FormControl>
                  </Grid>
                  <Grid item xs className={classes.formContainer}>
                    <FormControl fullWidth>
                      <FormLabel
                        className={classes.prefectureLabel}
                        id="user-profile-prefecture-label"
                      >
                        お住まいの地域
                      </FormLabel>
                      <Select
                        required
                        labelId="user-profile-prefecture-label"
                        id="user-profile-prefecture"
                        value={prefectureCode}
                        onChange={handleChangePrefecture}
                      >
                        {prefectureCodes.map((prefectureCodeMaster) => (
                          <MenuItem
                            key={prefectureCodeMaster.code}
                            value={prefectureCodeMaster.code}
                          >
                            {prefectureCodeMaster.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      className={classes.captionText}
                      variant="caption"
                      gutterBottom
                    >
                      <Link
                        data-cy="termsOfServiceLink"
                        component={RouterLink}
                        to={PATHS.TERMS_OF_SERVICE}
                      >
                        サービス利用規約
                      </Link>
                      に同意したこととなります
                    </Typography>
                    <LoadingButton
                      disableElevation
                      data-cy="submitButton"
                      className={classes.submitButton}
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                      disabled={!isReadyToSubmit}
                      loading={isLoading}
                      onClick={pushDatalayer}
                    >
                      登録
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export { Presenter };
