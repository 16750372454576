import { getMyGiftsConnection } from '/@/api/graphql/internalApi/getMyGiftsConnection';
import type { GetMyGiftsConnectionResponse } from '/@/api/graphql/internalApi/getMyGiftsConnection';
import type { AppThunk } from '/@/store';
import {
  getInitialAvailableMyGiftsConnectionStart,
  getInitialAvailableMyGiftsConnectionSuccess,
  getInitialAvailableMyGiftsConnectionFailure,
} from './actions';
import {
  MyGiftType_GiftTypeEnum,
  MyGiftUsageStatusEnum,
} from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getInitialAvailableMyGiftsConnectionThunk =
  (): AppThunk<Promise<void>> => (dispatch) => {
    dispatch(getInitialAvailableMyGiftsConnectionStart());

    return getMyGiftsConnection({
      usageStatuses: [MyGiftUsageStatusEnum.Available],
      first: 20,
      giftTypeIn: [
        MyGiftType_GiftTypeEnum.GiftCardTicketChoosableContent,
        MyGiftType_GiftTypeEnum.GiftCardTicketFixedContent,
        MyGiftType_GiftTypeEnum.GifteeBox,
        MyGiftType_GiftTypeEnum.SimpleGift,
      ],
    })
      .then((response) =>
        dispatch(getInitialAvailableMyGiftsConnectionSuccessThunk(response)),
      )
      .catch((error) =>
        dispatch(getInitialAvailableMyGiftsConnectionFailureThunk(error)),
      );
  };

const getInitialAvailableMyGiftsConnectionSuccessThunk =
  (response: GetMyGiftsConnectionResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { myGiftsConnection } = response.data!;
    dispatch(getInitialAvailableMyGiftsConnectionSuccess(myGiftsConnection));
    return Promise.resolve();
  };

const getInitialAvailableMyGiftsConnectionFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getInitialAvailableMyGiftsConnectionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getInitialAvailableMyGiftsConnectionThunk };
