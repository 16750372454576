import { GIFT_WALLET_FRONT_URI } from '/@/common/giftWalletFrontUri';

const LINE_BOT_FRIEND_URL = {
  DEVELOPMENT: 'http://localhost:8080',
  STAGING: 'https://line.me/R/ti/p/@380uyjzk',
  PRODUCTION: 'https://line.me/R/ti/p/@943qavdo',
} as const;

type LineBotFriendUrl =
  (typeof LINE_BOT_FRIEND_URL)[keyof typeof LINE_BOT_FRIEND_URL];

const getLineBotFriendUrl = (): LineBotFriendUrl => {
  const { hostname } = location;
  if (hostname === GIFT_WALLET_FRONT_URI.PRODUCTION) {
    return LINE_BOT_FRIEND_URL.PRODUCTION;
  } else if (hostname === GIFT_WALLET_FRONT_URI.STAGING) {
    return LINE_BOT_FRIEND_URL.STAGING;
  }
  return LINE_BOT_FRIEND_URL.DEVELOPMENT;
};

export { getLineBotFriendUrl };
