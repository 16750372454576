import { RootState } from '/@/store';
import { Status, STATUS } from '/@/store/api/constants';
import { createSelector } from 'reselect';

export const selectGetCurrentUserStatus = (state: RootState): Status =>
  state.api.getCurrentUser.status;

export const selectIsGetCurrentUserSuccess = createSelector(
  [selectGetCurrentUserStatus],
  (selectGetCurrentUserStatus): boolean =>
    selectGetCurrentUserStatus === STATUS.SUCCESS,
);
