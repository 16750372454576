import { types } from './types';
import type { GetMyGiftsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getContinuousUnavailableMyGiftsConnectionStart = () =>
  ({
    type: types.GET_CONTINUOUS_UNAVAILABLE_MY_GIFTS_CONNECTION_START,
  }) as const;

export const getContinuousUnavailableMyGiftsConnectionSuccess = (
  unavailableMyGiftsConnection: GetMyGiftsConnectionQuery['myGiftsConnection'],
) =>
  ({
    type: types.GET_CONTINUOUS_UNAVAILABLE_MY_GIFTS_CONNECTION_SUCCESS,
    unavailableMyGiftsConnection,
  }) as const;

export const getContinuousUnavailableMyGiftsConnectionFailure = (
  error: ApiError,
) =>
  ({
    type: types.GET_CONTINUOUS_UNAVAILABLE_MY_GIFTS_CONNECTION_FAILURE,
    error,
  }) as const;
