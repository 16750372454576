import { Reducer } from 'redux';
import {
  GetBrandsConnectionQuery,
  BrandConnection,
} from '/@/api/graphql/internalApi/types';
import { getRecommendedBrandsConnectionTypes } from '/@/store/api/getRecommendedBrandsConnection/index';
import { BrandsConnectionBase } from '/@/store/app/shared/types';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';

type RecommendedBrandsState = RecommendedBrand[] | null;
type PageInfoState = BrandConnection['pageInfo'] | null;
type RecommendedBrandsConnectionState = {
  brands: RecommendedBrandsState;
  pageInfo: PageInfoState;
};
type RecommendedBrand = BrandsConnectionBase;

const initialState: RecommendedBrandsState = null;

const brands: Reducer<RecommendedBrandsState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getRecommendedBrandsConnectionTypes.GET_RECOMMENDED_BRANDS_CONNECTION_SUCCESS: {
      if (!action.brandsConnection?.nodes) return null;
      const filteredNodes = filterNodes(action.brandsConnection.nodes);

      // 現状おすすめブランドはページネーションしていないため置き換える
      return filteredNodes;
    }
    default:
      return state;
  }
};

const filterNodes = (
  recommendedBrandsConnectionNodes: NonNullable<
    GetBrandsConnectionQuery['brands']['nodes']
  >,
): NonNullable<RecommendedBrandsState> =>
  recommendedBrandsConnectionNodes.filter(
    (node) => !!node,
  ) as RecommendedBrand[];

const pageInfoInitialState: PageInfoState = null;

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getRecommendedBrandsConnectionTypes.GET_RECOMMENDED_BRANDS_CONNECTION_SUCCESS:
      return action.brandsConnection.pageInfo;
    default:
      return state;
  }
};

const recommendedBrandsConnection = combineReducers({
  brands,
  pageInfo,
});

export { recommendedBrandsConnection };
export type {
  RecommendedBrandsConnectionState,
  RecommendedBrand,
  RecommendedBrandsState,
};
