import { gql } from '@apollo/client';
import { FRAGMENT_MY_GIFT } from '/@/api/graphql/internalApi/fragments/myGift.gql';

export const getMyGiftGql = gql`
  ${FRAGMENT_MY_GIFT}
  query GetMyGift($urlCode: ID!) {
    myGift(urlCode: $urlCode) {
      ...MyGiftFields
    }
  }
`;
