import React from 'react';
import { Presenter } from './Presenter';
import { useAppSelector } from '/@/store/hooks';

type OwnProps = {
  benefitOfPointChargeModalVisible: boolean;
};

const Container: React.VFC<OwnProps> = ({
  benefitOfPointChargeModalVisible,
}) => {
  const pointWallet = useAppSelector((state) => state.app.pointWallet);

  return (
    <Presenter
      points={pointWallet?.point}
      benefitOfPointChargeModalVisible={benefitOfPointChargeModalVisible}
    />
  );
};

export { Container as HeadingWithPoints };
