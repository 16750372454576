import { Reducer } from 'redux';
import type { RootAction } from '/@/store/actions';
import { ItemDetailBase } from '/@/store/app/shared/types';
import { types } from './types';
import { getItemDetailTypes } from '/@/store/api/getItemDetail';

type ItemDetailState = ItemDetailBase | null;

const initialState: ItemDetailState = null;

const itemDetail: Reducer<ItemDetailState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getItemDetailTypes.GET_ITEM_DETAIL_SUCCESS:
      if (!action.item) return null;
      return action.item;
    case types.INITIALISE_ITEM_DETAIL:
      return initialState;
    default:
      return state;
  }
};

export { itemDetail };
export type { ItemDetailState };
