import React from 'react';

import {
  Card,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  Button,
  Container,
} from '@material-ui/core';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import { HeadingWithTitle } from '/@/components/shared/headingWithTitle/Index';

const useStyles = makeStyles((theme) =>
  createStyles({
    background: {
      backgroundColor: theme.palette.background.contrast,
    },
    QAContainer: {
      padding: theme.spacing(2),
    },
    QACard: {
      marginBottom: theme.spacing(3),
      border: 'none',
      borderRadius: theme.spacing(2),
      minWidth: '100%',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    questionCardSection: {
      backgroundColor: theme.palette.background.highContrast,
      padding: theme.spacing(2),
      color: theme.palette.text.disabled,
    },
    questionText: {
      fontWeight: 'bold',
      color: 'white',
    },
    answerCardSection: {
      padding: theme.spacing(2),
    },
    answerMark: {
      fontWeight: 'bold',
      color: theme.palette.primary.light,
      paddingRight: theme.spacing(0.5),
    },
    homeLink: {
      textDecoration: 'none',
      margin: theme.spacing(2),
    },
    homeLinkText: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    inquiryContainer: {
      padding: theme.spacing(4),
    },
    inquiryButton: {
      marginTop: theme.spacing(3),
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
      paddingRight: theme.spacing(6.75),
      paddingLeft: theme.spacing(6.75),
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '0.5rem',
    },
    inquiriesNewPageLink: {
      color: theme.palette.primary.main,
    },
  }),
);

const Presenter: React.VFC<Record<string, never>> = () => {
  const classes = useStyles();

  return (
    <>
      <MainLayout>
        <div className={classes.background}>
          <Container maxWidth="sm" className={classes.QAContainer}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              data-cy="frequentlyAskedQuestions"
            >
              <HeadingWithTitle title="Q&A" subTitle="よくあるご質問" />
              <Card variant="outlined" className={classes.QACard}>
                <Grid
                  container
                  wrap="nowrap"
                  className={classes.questionCardSection}
                >
                  <Typography variant="body1" className={classes.questionText}>
                    Q. 保存したギフトに戻れません
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignItems="baseline"
                  className={classes.answerCardSection}
                >
                  <Grid container wrap="nowrap">
                    <Typography variant="body1" className={classes.answerMark}>
                      A.
                    </Typography>
                    <Typography variant="body2">
                      マイギフトページで、保存されているギフトを確認してみてください。
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="flex-end">
                    <Link to={PATHS.HOME} className={classes.homeLink}>
                      <Typography
                        variant="body2"
                        className={classes.homeLinkText}
                      >
                        マイギフトを確認する →
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Card>
              <Card variant="outlined" className={classes.QACard}>
                <Grid
                  container
                  wrap="nowrap"
                  className={classes.questionCardSection}
                >
                  <Typography className={classes.questionText} variant="body1">
                    Q. ギフトが保存できません
                  </Typography>
                </Grid>
                <Grid
                  container
                  wrap="nowrap"
                  alignItems="baseline"
                  className={classes.answerCardSection}
                >
                  <Typography variant="body1" className={classes.answerMark}>
                    A.
                  </Typography>
                  <Typography variant="body2">
                    申し訳ありません。時間をおいて再度お試しください。それでも解決しない場合は、
                    <Link
                      to={PATHS.INQUIRIES_NEW}
                      className={classes.inquiriesNewPageLink}
                      data-cy="inquiriesNewPageLink"
                    >
                      こちら
                    </Link>
                    からお問い合わせください。
                  </Typography>
                </Grid>
              </Card>
              <Card variant="outlined" className={classes.QACard}>
                <Grid
                  container
                  wrap="nowrap"
                  className={classes.questionCardSection}
                >
                  <Typography className={classes.questionText} variant="body1">
                    Q. ギフトの使い方がわかりません
                  </Typography>
                </Grid>
                <Grid
                  container
                  wrap="nowrap"
                  alignItems="baseline"
                  className={classes.answerCardSection}
                >
                  <Typography variant="body1" className={classes.answerMark}>
                    A.
                  </Typography>
                  <Typography variant="body2">
                    ギフトによって使い方が異なります。各ギフトのチケットページ内に記載されている利用方法を確認してみてください。
                  </Typography>
                </Grid>
              </Card>
            </Grid>
          </Container>
        </div>
        <Container maxWidth="sm" className={classes.inquiryContainer}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="body2" style={{ textAlign: 'center' }}>
              問題が解決しない場合やその他のご質問は
              <br />
              こちらからお問い合わせください。
            </Typography>
            <Button
              variant="contained"
              disableElevation
              className={classes.inquiryButton}
              component={Link}
              to={PATHS.INQUIRIES_NEW}
              data-cy="inquiriesNewPageButton"
            >
              お問い合わせ
            </Button>
          </Grid>
        </Container>
      </MainLayout>
    </>
  );
};

export { Presenter };
