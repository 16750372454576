import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
} from '@material-ui/core';
import type { Detail } from './Index';

const styles = (theme: Theme) => {
  const MY_GIFT_IMAGE_SIZE = 70;
  return createStyles({
    card: { padding: theme.spacing(2) },
    cardContent: { minWidth: 0 },
    myGiftImage: {
      marginRight: theme.spacing(2),
      height: MY_GIFT_IMAGE_SIZE,
      width: MY_GIFT_IMAGE_SIZE,
      borderRadius: theme.spacing(),
    },
    title: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      color: theme.palette.secondary.main,
      fontSize: '0.875rem',
      lineHeight: '1',
    },
    details: {
      color: theme.palette.secondary.main,
      fontSize: '0.875rem',
      lineHeight: '1',
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
    point: {
      fontSize: '1rem',
      lineHeight: '1.6',
      fontWeight: 'bold',
    },
  });
};

type OwnProps = {
  title: string;
  details?: Detail[];
  point: number;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, title, details, point }) => (
  <div className={classes.card} data-cy="pointLog">
    <Grid container wrap="nowrap" direction="row">
      <Grid
        item
        xs={9}
        container
        justifyContent="center"
        direction="column"
        className={classes.cardContent}
      >
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        {details && (
          <Grid
            container
            alignItems="center"
            direction="row"
            style={{ marginTop: '0.75rem' }}
          >
            {details.map((detail, index) => (
              <Typography
                key={index}
                variant="subtitle2"
                className={classes.details}
              >
                {detail.key} : {detail.value}
              </Typography>
            ))}
          </Grid>
        )}
      </Grid>
      <Grid item xs={3} container justifyContent="flex-end" alignItems="center">
        <Typography variant="subtitle1" className={classes.point}>
          {point.toLocaleString('ja-JP')}
        </Typography>
      </Grid>
    </Grid>
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
