import { types } from './types';
import type { CreatePointExchangeMutation } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const createPointExchangeStart = () =>
  ({ type: types.CREATE_POINT_EXCHANGE_START }) as const;

export const createPointExchangeSuccess = (
  pointExchange: CreatePointExchangeMutation['createPointExchange']['pointExchange'],
  pointWallet: CreatePointExchangeMutation['createPointExchange']['pointWallet'],
) =>
  ({
    type: types.CREATE_POINT_EXCHANGE_SUCCESS,
    pointExchange,
    pointWallet,
  }) as const;

export const createPointExchangeFailure = (error: ApiError) =>
  ({
    type: types.CREATE_POINT_EXCHANGE_FAILURE,
    error,
  }) as const;
