import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Modal,
  Box,
  Button,
  Grid,
} from '@material-ui/core';
import { MyGiftsDiscardConfirmationModalOpenState } from '/@/store/ui/myGiftsDiscardConfirmationModalOpen';
import { LoadingButton } from '/@/components/shared/button/loadingButton/Index';
import { SelectedMyGiftsState } from '/@/store/app/selectedMyGifts';

const styles = (theme: Theme) =>
  createStyles({
    box: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(),
      padding: theme.spacing(3),
      outline: 'none',
    },
    confirmation: {
      marginTop: theme.spacing(),
    },
    discardingList: {
      padding: theme.spacing(2),
      margin: 0,
    },
    discardingMyGift: {
      fontWeight: 'bold',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    description: {
      color: theme.palette.secondary.dark,
      display: 'inline-block',
      textAlign: 'center',
    },
    buttonGroup: {},
    confirmButton: {
      padding: '0.9375rem',
      marginTop: theme.spacing(3),
      fontWeight: 'bold',
      lineHeight: '1',
      borderRadius: '1.375rem',
      boxShadow: 'none',
      width: '100%',
    },
    cancelButton: {
      marginTop: theme.spacing(2),
      width: '100%',
      fontWeight: 'bold',
      borderRadius: '1.375rem',
      padding: '0.8125rem',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      lineHeight: '1',
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: 'none',
    },
  });

type OwnProps = {
  myGiftsDiscardConfirmationModalOpen: MyGiftsDiscardConfirmationModalOpenState;
  handleMyGiftsDiscardConfirmationModalOpen: () => void;
  typicalSelectedMyGifts: SelectedMyGiftsState;
  otherSelectedMyGifts: SelectedMyGiftsState;
  handleDiscardMyGifts: () => void;
  isDiscardMyGiftsLoading: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  myGiftsDiscardConfirmationModalOpen,
  handleMyGiftsDiscardConfirmationModalOpen,
  typicalSelectedMyGifts,
  otherSelectedMyGifts,
  handleDiscardMyGifts,
  isDiscardMyGiftsLoading,
}) => (
  <Modal
    data-cy="myGiftsDiscardConfirmationModal"
    open={myGiftsDiscardConfirmationModalOpen}
    onClose={handleMyGiftsDiscardConfirmationModalOpen}
  >
    <Box className={classes.box}>
      <Typography variant="body2" className={classes.confirmation}>
        「利用済み」にするとマイギフトへは戻せなくなります。以下のギフトを「利用済み」にしますか？
      </Typography>
      <ul className={classes.discardingList}>
        {typicalSelectedMyGifts.map((myGift) => (
          <li key={myGift.urlCode}>
            <Typography
              variant="body2"
              className={classes.discardingMyGift}
              key={myGift.urlCode}
            >
              {myGift.contentName}
            </Typography>
          </li>
        ))}
        {otherSelectedMyGifts.length ? (
          <li>
            <Typography variant="body2" className={classes.discardingMyGift}>
              その他 {otherSelectedMyGifts.length}件
            </Typography>
          </li>
        ) : null}
      </ul>
      <Typography variant="caption" className={classes.description}>
        「利用済み」にしたギフトはマイギフトの「利用済み・期限切れ」タブで確認できます。
      </Typography>
      <Grid container justifyContent="center" className={classes.buttonGroup}>
        <LoadingButton
          onClick={() => handleDiscardMyGifts()}
          className={classes.confirmButton}
          disableElevation
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          disabled={false}
          loading={isDiscardMyGiftsLoading}
          data-cy="submitButton"
          data-gtm-click="giftsPage-editPage-discardConfirmationModal-submitButton"
        >
          利用済みにする
        </LoadingButton>
        <Button
          onClick={() => handleMyGiftsDiscardConfirmationModalOpen()}
          variant="outlined"
          disableElevation
          className={classes.cancelButton}
          data-cy="cancelButton"
          data-gtm-click="giftsPage-editPage-discardConfirmationModal-cancelButton"
        >
          キャンセル
        </Button>
      </Grid>
    </Box>
  </Modal>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
