import { Reducer, combineReducers } from 'redux';

import { types } from './types';
import { RootAction } from '/@/store/actions';

import { PrefectureCode } from '/@/utils/prefectureCodeMapping';
import { GenderCode } from '/@/utils/genderCodeMapping';

type UserProfileNewPage = {
  input: UserProfileNewPageInputState;
};

type UserProfileNewPageInputState = {
  prefectureCode: PrefectureCode['code'];
  genderCode: GenderCode['code'];
  birthdayYear: string;
  birthdayMonth: string;
  birthdayDay: string;
};

const prefectureCode: Reducer<number | null, RootAction> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case types.UPDATE_PREFECTURE_CODE:
      return action.payload;
    default:
      return state;
  }
};

const genderCode: Reducer<number | null, RootAction> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case types.UPDATE_GENDER_CODE:
      return action.payload;
    default:
      return state;
  }
};

const birthdayYear: Reducer<string, RootAction> = (state = '', action) => {
  switch (action.type) {
    case types.UPDATE_BIRTHDAY_YEAR:
      return action.payload;
    default:
      return state;
  }
};

const birthdayMonth: Reducer<string, RootAction> = (state = '', action) => {
  switch (action.type) {
    case types.UPDATE_BIRTHDAY_MONTH:
      return action.payload;
    default:
      return state;
  }
};

const birthdayDay: Reducer<string, RootAction> = (state = '', action) => {
  switch (action.type) {
    case types.UPDATE_BIRTHDAY_DAY:
      return action.payload;
    default:
      return state;
  }
};

const userProfileNewPage = combineReducers({
  prefectureCode,
  genderCode,
  birthdayYear,
  birthdayMonth,
  birthdayDay,
});

export type { UserProfileNewPage, UserProfileNewPageInputState };
export { userProfileNewPage };
