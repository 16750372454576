import { types } from './types';
import type { SignInOrSignUpMutation } from '/@/api/graphql/publicApi/types';
import { ApiError } from '/@/api/graphql/client';

export const signInOrSignUpStart = () =>
  ({ type: types.SIGN_IN_OR_SIGN_UP_WITH_LINE_START }) as const;

export const signInOrSignUpSuccess = (
  session: NonNullable<SignInOrSignUpMutation['signInOrSignUp']['session']>,
) =>
  ({
    type: types.SIGN_IN_OR_SIGN_UP_WITH_LINE_SUCCESS,
    session,
  }) as const;

export const signInOrSignUpFailure = (error: ApiError) =>
  ({
    type: types.SIGN_IN_OR_SIGN_UP_WITH_LINE_FAILURE,
    error,
  }) as const;
