import { gql } from '@apollo/client';
import { FRAGMENT_ERRORS } from '/@/api/graphql/internalApi/fragments/errors.gql';

export const createUserProfileGql = gql`
  ${FRAGMENT_ERRORS}
  mutation CreateUserProfile($input: CreateUserProfileInput!) {
    createUserProfile(input: $input) {
      errors {
        ...ErrorsFields
      }
    }
  }
`;
