import React from 'react';
import { Presenter } from './Presenter';

type Props = {
  discountPercentage: number;
  isEmphased?: boolean;
  labelSize?: 'small' | 'medium';
};

const Container: React.FC<Props> = ({
  discountPercentage,
  isEmphased = true,
  labelSize = 'medium',
}) => {
  return (
    <Presenter
      discountPercentage={discountPercentage}
      isEmphased={isEmphased}
      labelSize={labelSize}
    />
  );
};

export { Container as DiscountPercentageLabel };
