import React, { ChangeEvent, FormEvent, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Presenter } from './Presenter';
import { CreateUserProfileInput } from '/@/api/graphql/internalApi/types';
import { useAppDispatch, useAppSelector } from '/@/store';
import { createUserProfileThunk } from '/@/store/api/createUserProfile';
import {
  selectBirthdayDay,
  selectBirthdayMonth,
  selectBirthdayYear,
  selectGenderCode,
  selectIsCreateUserProfileLoading,
  selectIsCreateUserProfileSuccess,
  selectIsReadyToSubmit,
  selectPrefectureCode,
} from '/@/store/api/createUserProfile/selectors';
import { getCurrentUserThunk } from '/@/store/api/getCurrentUser';
import {
  updateGenderCode,
  updateBirthdayYear,
  updateBirthdayMonth,
  updateBirthdayDay,
  updatePrefectureCode,
} from '/@/store/page/userPage/profilePage/newPage/actions';
import { selectIsGetCurrentUserSuccess } from '/@/store/api/getCurrentUser/selectors';
import { selectCurrentUserProfile } from '/@/store/app/currentUser/selectors';
import { prefectureCodes } from '/@/utils/prefectureCodeMapping';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { genderCodes } from '/@/utils/genderCodeMapping';
import { decideGoForwardLocation } from '/@/utils/decideLocationAfterOauth';
import { createOauthSessionOption } from '/@/utils/createOauthSessionOption';

const Container: React.VFC<Record<string, never>> = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  const genderCode = useAppSelector(selectGenderCode);
  const birthdayYear = useAppSelector(selectBirthdayYear);
  const birthdayMonth = useAppSelector(selectBirthdayMonth);
  const birthdayDay = useAppSelector(selectBirthdayDay);
  const prefectureCode = useAppSelector(selectPrefectureCode);

  const isLoading = useAppSelector(selectIsCreateUserProfileLoading);
  const isCreateUserProfileSuccess = useAppSelector(
    selectIsCreateUserProfileSuccess,
  );
  const isReadyToSubmit = useAppSelector(selectIsReadyToSubmit);

  const dispatch = useAppDispatch();
  const { pushRegisterProfileEvent } = useDataLayer();

  const pushDatalayer = () => {
    pushRegisterProfileEvent({
      gender: genderCodes.find((g) => g.code === genderCode)?.name,
      birthday: `${birthdayYear}/${birthdayMonth}/${birthdayDay}`,
      birthYear: birthdayYear ?? undefined,
      prefecture: prefectureCodes.find((p) => p.code === prefectureCode)?.name,
      customData: {
        gender: genderCodes.find((g) => g.code === genderCode)?.name,
        birthday: `${birthdayYear}/${birthdayMonth}/${birthdayDay}`,
        birthYear: birthdayYear ?? undefined,
        prefecture: prefectureCodes.find((p) => p.code === prefectureCode)
          ?.name,
      },
    });
  };

  const handleChangeGender = (selectedGenderCode: number) => {
    if (selectedGenderCode !== genderCode) {
      dispatch(updateGenderCode(selectedGenderCode));
    }
  };

  const handleChangeBirthdayYear = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(updateBirthdayYear(event.target.value));
  };

  const handleChangeBirthdayMonth = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(updateBirthdayMonth(event.target.value));
  };

  const handleChangeBirthdayDay = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(updateBirthdayDay(event.target.value));
  };

  const handleChangePrefecture = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    dispatch(updatePrefectureCode(event.target.value as number));
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const input: CreateUserProfileInput = {
      prefectureCode: prefectureCode as number,
      genderCode: genderCode as number,
      year: birthdayYear,
      month: birthdayMonth,
      day: birthdayDay,
    };

    dispatch(createUserProfileThunk(input)).catch(() => {});
  };

  // Ensure the user can create profile only once
  const currentUserProfile = useAppSelector(selectCurrentUserProfile);
  const isGetCurrentUserSuccess = useAppSelector(selectIsGetCurrentUserSuccess);

  useEffect(() => {
    dispatch(getCurrentUserThunk()).catch(() => {});
  }, [dispatch]);

  useEffect(() => {
    const { giftSavingOption, pointSerialOption } =
      createOauthSessionOption(urlSearchParams);

    if (
      (isGetCurrentUserSuccess && currentUserProfile) ||
      isCreateUserProfileSuccess
    ) {
      navigate(
        decideGoForwardLocation({ giftSavingOption, pointSerialOption }),
      );
    }
  }, [
    urlSearchParams,
    isGetCurrentUserSuccess,
    currentUserProfile,
    isCreateUserProfileSuccess,
    navigate,
  ]);

  return (
    <Presenter
      isLoading={isLoading}
      genderCode={genderCode}
      prefectureCode={prefectureCode}
      isReadyToSubmit={isReadyToSubmit}
      pushDatalayer={pushDatalayer}
      handleChangeGender={handleChangeGender}
      handleChangeBirthdayYear={handleChangeBirthdayYear}
      handleChangeBirthdayMonth={handleChangeBirthdayMonth}
      handleChangeBirthdayDay={handleChangeBirthdayDay}
      handleChangePrefecture={handleChangePrefecture}
      handleSubmit={handleSubmit}
    />
  );
};

export { Container as UserProfileNewPage };
