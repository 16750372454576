import { types } from './types';
import type { GetItemDetailQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getItemDetailStart = () =>
  ({
    type: types.GET_ITEM_DETAIL_START,
  }) as const;

export const getItemDetailSuccess = (item: GetItemDetailQuery['item']) =>
  ({
    type: types.GET_ITEM_DETAIL_SUCCESS,
    item,
  }) as const;

export const getItemDetailFailure = (error: ApiError) =>
  ({
    type: types.GET_ITEM_DETAIL_FAILURE,
    error,
  }) as const;
