import { Reducer } from 'redux';
import type { RootAction } from '/@/store/actions';
import { types } from './types';

type DisplayOnlyExchangeableBrandsState = boolean;

const initialState: DisplayOnlyExchangeableBrandsState = false;

const displayOnlyExchangeableBrands: Reducer<
  DisplayOnlyExchangeableBrandsState,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case types.INITIALISE_DISPLAY_ONLY_EXCHANGEABLE_BRANDS:
      return initialState;
    case types.SWITCH_DISPLAY_ONLY_EXCHANGEABLE_BRANDS:
      return !state;
    default:
      return state;
  }
};

export { displayOnlyExchangeableBrands };
export type { DisplayOnlyExchangeableBrandsState };
