import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { refreshMyGiftGql } from './index.gql';
import {
  RefreshMyGiftMutationVariables,
  RefreshMyGiftMutation,
} from '../types';

type RefreshMyGiftResponse = FetchResult<RefreshMyGiftMutation>;

const refreshMyGift = (
  variables?: RefreshMyGiftMutationVariables,
): Promise<RefreshMyGiftResponse> => {
  return internalApiClient.query({
    query: refreshMyGiftGql,
    variables,
  });
};

export { refreshMyGift };

export type { RefreshMyGiftResponse };
