import React from 'react';

import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import internalServerErrorIcon from '/@/assets/internal_server_error_icon.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    subtitle: {
      paddingBottom: theme.spacing(2),
      fontWeight: 'bold',
    },
    myGiftsLink: {
      textDecoration: 'none',
      margin: theme.spacing(4),
    },
    myGiftsLinkText: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    container: {
      marginTop: theme.spacing(3),
    },
  }),
);

const Presenter: React.VFC<Record<string, never>> = () => {
  const classes = useStyles();

  return (
    <MainLayout>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        data-cy="internalServerErrorPage"
        className={classes.container}
      >
        <img
          src={internalServerErrorIcon}
          alt="500 intenal server error"
          width="100%"
          height="250"
          data-cy="internalServerErrorIcon"
        />
        <Typography variant="body2" className={classes.subtitle}>
          システムエラーが発生しました。
        </Typography>
        <Typography variant="caption">
          復旧作業中のため、アクセスができなくなっています。
        </Typography>
        <Typography variant="caption">
          時間をおいて、もう一度試してみてください。
        </Typography>
      </Grid>
    </MainLayout>
  );
};

export { Presenter };
