import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
} from '@material-ui/core';

export type Detail = {
  number: number;
  title: string;
  description: string;
  imgTitle?: string;
  img?: string;
};

type OwnProps = {
  title: string;
  subTitle: string;
  details: Detail[];
  attention?: string;
};

const styles = (theme: Theme) => {
  return createStyles({
    section: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    sectionTitle: {
      fontWeight: 'bold',
      color: '#003F65',
    },
    sectionSubTitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&::before': {
        borderTop: '3px solid',
        borderColor: theme.palette.primary.light,
        content: '""',
        width: '12px',
        marginRight: theme.spacing(1),
      },
      '&::after': {
        borderTop: '3px solid',
        borderColor: theme.palette.primary.light,
        content: '""',
        width: '12px',
        marginLeft: theme.spacing(1),
      },
    },
    detail: {},
    detailNumber: {
      position: 'relative',
      top: '3.25rem',
      marginTop: '-3.75rem',
      fontSize: '2.375rem',
      color: '#5097C4',
      marginLeft: theme.spacing(6),
    },
    detailText: {
      margin: theme.spacing(3),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderTopLeftRadius: theme.spacing(0.25),
      borderTopRightRadius: theme.spacing(0.25),
      borderBottomLeftRadius: theme.spacing(3),
      borderBottomRightRadius: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
    },
    detailTitle: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    detailDescription: {
      marginTop: theme.spacing(2),
      display: 'inline-block',
      fontWeight: 'normal',
      color: '#4E5B61',
    },
    detailImageContainer: {
      marginTop: theme.spacing(2),
    },
    detailImageTitle: {
      marginBottom: theme.spacing(0.625),
      display: 'inline-block',
      color: '#A6ADAF',
    },
    detailImage: {
      width: '-webkit-fill-available',
    },
    attention: {
      marginBottom: theme.spacing(3),
      color: '#4E5B61',
      textAlign: 'left',
      margin: theme.spacing(0, 3),
    },
  });
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  title,
  subTitle,
  details,
  attention,
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      className={classes.section}
    >
      <Grid item xs={12}>
        <Typography
          align="center"
          variant="h5"
          className={classes.sectionTitle}
        >
          {title}
        </Typography>
        <Typography
          align="center"
          variant="body2"
          className={classes.sectionSubTitle}
        >
          {subTitle}
        </Typography>
      </Grid>

      {details.map((detail) => (
        <Grid item xs={12} key={detail.number} className={classes.detail}>
          <Typography variant="body1" className={classes.detailNumber}>
            {detail.number.toString().padStart(2, '0')}
          </Typography>
          <div className={classes.detailText}>
            <Typography variant="body1" className={classes.detailTitle}>
              {detail.title}
            </Typography>
            <Typography variant="caption" className={classes.detailDescription}>
              {detail.description}
            </Typography>
            {detail.img && (
              <div className={classes.detailImageContainer}>
                {detail.imgTitle && (
                  <Typography
                    variant="caption"
                    className={classes.detailImageTitle}
                  >
                    {detail.imgTitle}
                  </Typography>
                )}
                {detail.img && (
                  <img
                    src={detail.img}
                    alt={detail.title}
                    className={classes.detailImage}
                  />
                )}
              </div>
            )}
          </div>
        </Grid>
      ))}

      {attention && (
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="body2"
            className={classes.attention}
          >
            {attention}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
