import { types } from './types';
import type { GetUserAuthenticatorsQuery } from '/@/api/graphql/publicApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getUserAuthenticatorsStart = () =>
  ({
    type: types.GET_USER_AUTHENTICATORS_START,
  }) as const;

export const getUserAuthenticatorsSuccess = (
  userAuthenticators: GetUserAuthenticatorsQuery['userAuthenticators'],
) =>
  ({
    type: types.GET_USER_AUTHENTICATORS_SUCCESS,
    userAuthenticators,
  }) as const;

export const getUserAuthenticatorsFailure = (error: ApiError) =>
  ({
    type: types.GET_USER_AUTHENTICATORS_FAILURE,
    error,
  }) as const;
