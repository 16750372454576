import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Container,
  Typography,
} from '@material-ui/core';
import { RecommendedContents } from '/@/components/shared/brandContents/recommendedContents/Index';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(2),
      borderRadius: theme.spacing(2),
    },
    title: {
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: '1.6rem',
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
  });

type Props = WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes }) => (
  <Container maxWidth="sm" className={classes.container}>
    <Typography className={classes.title}>
      今おトクに交換できるギフト
    </Typography>
    <RecommendedContents />
  </Container>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
