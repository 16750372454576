import React from 'react';
import {
  Tab,
  Tabs,
  WithStyles,
  withStyles,
  createStyles,
  Typography,
} from '@material-ui/core';
import {
  SelectedGiftsAvailabilityTabState,
  GIFTS_AVAILABILITY,
} from '/@/store/ui/selectedGiftsAvailabilityTab';
import { AvailableMyGifts } from '/@/components/page/giftsPage/availableMyGifts/Index';
import { UnavailableMyGifts } from '/@/components/page/giftsPage/unavailableMyGifts/Index';

const styles = () =>
  createStyles({
    tab: {
      height: '3.5rem',
      borderBottom: '2px solid #e0e0e0',
    },
    tabLabel: {
      fontWeight: 'bold',
    },
  });

type OwnProps = {
  selectedGiftsAvailabilityTab: SelectedGiftsAvailabilityTabState;
  handleGiftsAvailabilityTab: (
    selectedGiftsAvailabilityTab: SelectedGiftsAvailabilityTabState,
  ) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  selectedGiftsAvailabilityTab,
  handleGiftsAvailabilityTab,
}) => (
  <>
    <Tabs
      value={selectedGiftsAvailabilityTab}
      onChange={(_, value) => handleGiftsAvailabilityTab(value)}
      aria-label="ギフトの表示を切り替えるタブ"
      variant="fullWidth"
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab
        label={
          <Typography className={classes.tabLabel} variant="button">
            利用可能
          </Typography>
        }
        value={GIFTS_AVAILABILITY.AVAILABLE}
        data-cy="selectAvailableGiftsTab"
        className={classes.tab}
      />
      <Tab
        label={
          <Typography className={classes.tabLabel} variant="button">
            利用済み・期限切れ
          </Typography>
        }
        value={GIFTS_AVAILABILITY.UNAVAILABLE}
        data-cy="selectUnavailableGiftsTab"
        className={classes.tab}
      />
    </Tabs>
    {selectedGiftsAvailabilityTab === GIFTS_AVAILABILITY.AVAILABLE ? (
      <AvailableMyGifts />
    ) : (
      <UnavailableMyGifts />
    )}
  </>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
