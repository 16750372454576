import React from 'react';
import { Grid, Divider } from '@material-ui/core';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { PointLog } from '/@/components/shared/pointLog/Index';
import type { PointLogWithDetail } from '/@/store/page/pointLogsPage/listPage/selectors';

type OwnProps = {
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  pointLogsWithDetail: PointLogWithDetail[];
  getPointLogsStatus: Status;
};

const Presenter: React.VFC<OwnProps> = ({
  infiniteScrollTarget,
  pointLogsWithDetail,
  getPointLogsStatus,
}) => (
  <Grid data-cy="availablePointCharges">
    {getPointLogsStatus == STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <>
        {pointLogsWithDetail.map((pointLogWithDetail, index) => (
          <Grid key={index}>
            <PointLog
              title={pointLogWithDetail.title}
              details={pointLogWithDetail.details}
              point={pointLogWithDetail.point}
            />
            <Divider />
          </Grid>
        ))}
      </>
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

export { Presenter };
