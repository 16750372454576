import { gql } from '@apollo/client';

export const getBrandsConnectionGql = gql`
  query GetBrandsConnection(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $withPointsGteq: Int
    $withPointsLteq: Int
    $recommendEq: Boolean
  ) {
    brands(
      after: $after
      before: $before
      first: $first
      last: $last
      withPointsGteq: $withPointsGteq
      withPointsLteq: $withPointsLteq
      recommendEq: $recommendEq
    ) {
      nodes {
        uid
        name
        iconUrl
        description
        currentPointBackCampaign {
          discountPercentage
          normalDiscountPercentage
          description
        }
        minPoint
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
