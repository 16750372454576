import {
  AppBar,
  Box,
  Button,
  IconButton,
  Slide,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import React, { useRef } from 'react';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ItemDetailBase, exchangeItemType } from '/@/store/app/shared/types';
import { ItemExchangeModal } from '/@/components/shared/modal/ItemExchangeModal/Index';
import { getHtmlString } from '/@/utils/getHtmlString';
import { FormFieldWithValidation } from '/@/components/shared/FormFieldWithValidation/Index';
import { Points } from '/@/components/shared/points/Index';
import '/@/assets/font/font.css';
import clsx from 'clsx';
import { DiscountPercentageLabel } from '/@/components/shared/label/discountPercentageLabel';
import { AlertMessage } from '/@/components/shared/alertMessage/Index';

const styles = (theme: Theme) => {
  return createStyles({
    modalAppBar: {
      zIndex: 1300,
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.paper,
      alignItems: 'center',
      overflowY: 'auto',
    },
    ModalContent: {
      textAlign: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      paddingTop: HEADER_HEIGHT + theme.spacing(0.5),
      paddingBottom: theme.spacing(10),
    },
    header: {
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      textAlign: 'center',
      height: HEADER_HEIGHT,
      backgroundColor: theme.palette.background.paper,
      zIndex: 1,
    },
    arrowBackIcon: {
      padding: 0,
      position: 'absolute',
      left: theme.spacing(2),
      top: theme.spacing(1.5),
    },
    title: {
      marginTop: theme.spacing(1.5),
      fontWeight: 'bold',
      width: '100%',
    },
    logo: {
      width: '100%',
      maxWidth: '30rem',
    },
    overview: {
      marginTop: theme.spacing(2),
      textAlign: 'left',
    },
    brandName: {
      margin: 0,
      fontSize: '0.875rem',
      color: theme.palette.grey.A700,
    },
    itemName: {
      marginTop: theme.spacing(1),
      fontSize: '1.125rem',
      lineHeight: '1.4375rem',
      fontWeight: 'bold',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
    },
    endAt: {
      marginTop: theme.spacing(2),
      fontSize: '0.75rem',
      color: theme.palette.grey.A700,
    },
    pointBackLabel: {
      marginTop: theme.spacing(2),
    },
    pointText: {
      marginTop: theme.spacing(1),
      fontSize: '0.875rem',
      lineHeight: '1.6',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    variablePointInput: {
      marginRight: theme.spacing(0.5),
      width: '7.5rem',
      height: '2.5rem',
      fontFamily: 'mamelon',
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
      color: theme.palette.primary.dark,
      textAlign: 'center',
      border: '1px solid #E6EBED',
      borderRadius: '0.375rem',
    },
    variablePointInputWithError: {
      border: 'none',
      outline: '2px solid #FF0000',
    },
    detailTitle: {
      fontSize: '1rem',
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      textAlign: 'left',
    },
    detailText: {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      marginTop: theme.spacing(1),
      textAlign: 'left',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    cautionTitle: {
      fontSize: '1rem',
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      textAlign: 'left',
    },
    cautionText: {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      marginTop: theme.spacing(1),
      textAlign: 'left',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
    pointCautionTitle: {
      fontSize: '1rem',
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      textAlign: 'left',
    },
    pointCautionText: {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      marginTop: theme.spacing(1),
      textAlign: 'left',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
    exchangeButton: {
      position: 'fixed',
      bottom: theme.spacing(2),
      left: '50%',
      transform: 'translateX(-50%)',
      width: 'calc(100% - 3rem)',
      maxWidth: '480px',
      height: theme.spacing(6),
      borderRadius: '1.375rem',
    },
    buttonText: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
  });
};

type OwnProps = {
  onClose: () => void;
  itemDetail: ItemDetailBase | null;
  isOpenItemExchangeModal: boolean;
  handleSwitchItemExchangeModal: () => void;
  exchangeItemType: exchangeItemType;
  variablePoint: number;
  handleChangeVariablePoint: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabledChargeButton: boolean;
  validationMessage: string | null;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  onClose,
  itemDetail,
  isOpenItemExchangeModal,
  handleSwitchItemExchangeModal,
  exchangeItemType,
  variablePoint,
  handleChangeVariablePoint,
  isDisabledChargeButton,
  validationMessage,
}) => {
  if (!itemDetail) {
    return null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const modalRef = useRef<HTMLDivElement>(null);

  // MEMO: 交換時エラーのalertMessage表示時用
  const scrollToTop = () => {
    if (modalRef.current) modalRef.current.scrollTop = 0;
  };

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <AppBar
        ref={modalRef}
        className={classes.modalAppBar}
        variant="outlined"
        color="default"
        data-cy="header"
      >
        <div className={classes.ModalContent}>
          <div className={classes.header}>
            <IconButton
              color="secondary"
              onClick={onClose}
              component="button"
              className={classes.arrowBackIcon}
              data-gtm-click="shared-pointItemContents-itemDetailModal-closeButton"
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Typography className={classes.title}>ギフト詳細</Typography>
          </div>
          <div style={{ marginLeft: '-1rem', marginRight: '-1rem' }}>
            <AlertMessage />
          </div>
          <img
            className={classes.logo}
            src={itemDetail.contentImageUrl}
            alt={itemDetail.contentName}
          />
          <div className={classes.overview}>
            <p className={classes.brandName}>{itemDetail.brand.name}</p>
            <Typography className={classes.itemName}>
              {itemDetail.contentName}
            </Typography>
            {itemDetail.currentItemPointPhase && (
              <>
                <p className={classes.endAt}>
                  {'有効期限 ' + itemDetail.readableExpiration}
                </p>
                {itemDetail.currentPointBackCampaign && (
                  <div className={classes.pointBackLabel}>
                    <DiscountPercentageLabel
                      discountPercentage={
                        itemDetail.currentPointBackCampaign.discountPercentage
                      }
                    />
                  </div>
                )}
                <FormFieldWithValidation validationMessage={validationMessage}>
                  <Typography className={classes.pointText}>
                    {exchangeItemType === 'fixedAmount' ? (
                      <Points
                        points={itemDetail.currentItemPointPhase.point}
                        fontSize="lg"
                      />
                    ) : (
                      <>
                        <input
                          type="text"
                          value={variablePoint.toLocaleString('ja-JP')}
                          onChange={handleChangeVariablePoint}
                          inputMode="numeric"
                          pattern="[0-9]*"
                          className={clsx(
                            classes.variablePointInput,
                            !!validationMessage &&
                              classes.variablePointInputWithError,
                          )}
                          min={1}
                          max={999999}
                        />
                        {'ポイント'}
                      </>
                    )}
                  </Typography>
                </FormFieldWithValidation>
              </>
            )}
          </div>
          <Typography className={classes.detailTitle}>ギフト詳細</Typography>
          <Typography
            className={classes.detailText}
            dangerouslySetInnerHTML={{
              __html: getHtmlString(itemDetail.description),
            }}
          />
          <Typography className={classes.cautionTitle}>
            ご利用上の注意事項
          </Typography>
          <Typography
            className={classes.cautionText}
            dangerouslySetInnerHTML={{
              __html: getHtmlString(itemDetail.caution),
            }}
          />
          <Typography className={classes.pointCautionTitle}>
            ポイントに関する注意事項
          </Typography>
          <Typography
            className={classes.pointCautionText}
            dangerouslySetInnerHTML={{
              __html: getHtmlString(itemDetail.brand.pointCaution),
            }}
          />
          <Button
            color="primary"
            variant="contained"
            className={classes.exchangeButton}
            disabled={isDisabledChargeButton}
            onClick={handleSwitchItemExchangeModal}
            data-cy="exchangeButton"
            data-gtm-click="shared-pointItemContents-itemDetailModal-exchangeButton"
          >
            <Box ml={1}>
              <Typography className={classes.buttonText}>交換に進む</Typography>
            </Box>
          </Button>
        </div>
        {isOpenItemExchangeModal && (
          <ItemExchangeModal
            handleClose={handleSwitchItemExchangeModal}
            scrollToTop={scrollToTop}
          />
        )}
      </AppBar>
    </Slide>
  );
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
