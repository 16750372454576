import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  CircularProgress,
  Grid,
} from '@material-ui/core';

const styles = () => {
  return createStyles({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
    },
  });
};

type Props = WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes }) => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    className={classes.container}
    data-cy="pageLoader"
  >
    <CircularProgress size="3rem" color="primary" />
  </Grid>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
