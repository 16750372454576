import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Grid,
  Button,
} from '@material-ui/core';
import { Modal } from '/@/components/shared/modal/Index';
import { BenefitOfPointCharge } from '../illustration/Index';

const styles = (theme: Theme) => {
  return createStyles({
    handleCloseModalButton: {
      marginTop: theme.spacing(2),
      padding: '0.875rem',
      fontSize: '0.875rem',
      fontWeight: 'bold',
      lineHeight: '1',
      borderRadius: '1.375rem',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: 'none',
    },
  });
};

type OwnProps = {
  handleClose: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, handleClose }) => (
  <Modal handleClose={handleClose}>
    <Grid
      container
      direction="column"
      justifyContent="center"
      data-cy="benefitOfPointChargeModal"
    >
      <BenefitOfPointCharge />
      <Button
        className={classes.handleCloseModalButton}
        variant="contained"
        onClick={handleClose}
        data-cy="benefitOfPointChargeModalCloseButton"
        data-gtm-click="shared-benefitOfPointCharge-modal-closeButton"
      >
        閉じる
      </Button>
    </Grid>
  </Modal>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
