import { types } from './types';
import type { GetMyGiftsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getContinuousAvailableMyGiftsConnectionStart = () =>
  ({
    type: types.GET_CONTINUOUS_AVAILABLE_MY_GIFTS_CONNECTION_START,
  }) as const;

export const getContinuousAvailableMyGiftsConnectionSuccess = (
  availableMyGiftsConnection: GetMyGiftsConnectionQuery['myGiftsConnection'],
) =>
  ({
    type: types.GET_CONTINUOUS_AVAILABLE_MY_GIFTS_CONNECTION_SUCCESS,
    availableMyGiftsConnection,
  }) as const;

export const getContinuousAvailableMyGiftsConnectionFailure = (
  error: ApiError,
) =>
  ({
    type: types.GET_CONTINUOUS_AVAILABLE_MY_GIFTS_CONNECTION_FAILURE,
    error,
  }) as const;
