import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Container,
  Box,
  Grid,
  Button,
} from '@material-ui/core';
import { GiftsAvailabilityTabs } from './giftsAvailablityTabs/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { ExpiringMyGifts } from './expiringMyGifts/Index';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';

const styles = (theme: Theme) =>
  createStyles({
    background: {
      backgroundColor: theme.palette.background.contrast,
    },
    baseBox: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },
    },
    container: {
      [theme.breakpoints.up('sm')]: {
        borderRadius: theme.spacing(2),
      },
      backgroundColor: theme.palette.background.default,
      padding: 0,
    },
    subtitle: {
      color: theme.palette.primary.dark,
      padding: theme.spacing(2),
      fontWeight: 'bold',
      lineHeight: '1',
    },
    giftsEditPageButton: {
      minWidth: '0px',
      margin: theme.spacing(2),
      backgroundColor: theme.palette.background.hint,
      color: theme.palette.primary.dark,
    },
    giftsEditPageDisabledButton: {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.background.hint,
        color: theme.palette.secondary.main,
      },
    },
    giftsEditPageButtonLabel: {
      fontWeight: 'bold',
    },
  });

type OwnProps = {
  isGiftsEditPageButtonDisabled: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  isGiftsEditPageButtonDisabled,
}) => (
  <>
    <MainLayout>
      <div className={classes.background}>
        <Box className={classes.baseBox}>
          <Container maxWidth="sm" className={classes.container}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" className={classes.subtitle}>
                マイギフト
              </Typography>
              <Button
                classes={{
                  contained: classes.giftsEditPageDisabledButton,
                }}
                disabled={isGiftsEditPageButtonDisabled}
                variant="contained"
                disableElevation
                className={classes.giftsEditPageButton}
                component={Link}
                to={PATHS.GIFTS_EDIT}
                data-cy="giftsEditPageButton"
              >
                <Typography
                  color="inherit"
                  variant="caption"
                  className={classes.giftsEditPageButtonLabel}
                >
                  利用済みにする
                </Typography>
              </Button>
            </Grid>
            <ExpiringMyGifts />
            <GiftsAvailabilityTabs />
          </Container>
        </Box>
      </div>
    </MainLayout>
  </>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
