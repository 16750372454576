import { types } from './types';
import type { ApiError } from '/@/api/graphql/client';

export const createUserProfileStart = () =>
  ({
    type: types.CREATE_USER_PROFILE_START,
  }) as const;

export const createUserProfileSuccess = () =>
  ({
    type: types.CREATE_USER_PROFILE_SUCCESS,
  }) as const;

export const createUserProfileFailure = (error: ApiError) =>
  ({
    type: types.CREATE_USER_PROFILE_FAILURE,
    error,
  }) as const;
