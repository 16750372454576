import { RootState } from '/@/store';
import type { Status } from '/@/store/api/constants';
import { STATUS } from '/@/store/api/constants';

export const selectIsLoading = (state: RootState): boolean => {
  const apiStatus = state.api.notifyInquiry.status;

  return apiStatus === STATUS.LOADING;
};

export const selectApiStatus = (state: RootState): Status =>
  state.api.notifyInquiry.status;
