import { createOauthSession } from '/@/api/graphql/publicApi/createOauthSession';
import type { CreateOauthSessionResponse } from '/@/api/graphql/publicApi/createOauthSession';
import type { AppThunk } from '/@/store';
import {
  createOauthSessionStart,
  createOauthSessionSuccess,
  createOauthSessionFailure,
} from './actions';
import type { CreateOauthSessionInput } from '/@/api/graphql/publicApi/types';
import { handleErrorThunk } from '/@/store/api/handleError';
import type { ApiError } from '/@/api/graphql/client';

const createOauthSessionThunk =
  (input: CreateOauthSessionInput): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(createOauthSessionStart());
    return createOauthSession(input)
      .then((response) => dispatch(createOauthSessionSuccessThunk(response)))
      .catch((error) => dispatch(createOauthSessionFailureThunk(error)));
  };

const createOauthSessionSuccessThunk =
  (response: CreateOauthSessionResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(
      createOauthSessionSuccess(response.data!.createOauthSession.oauthSession),
    );
    return Promise.resolve();
  };

const createOauthSessionFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(createOauthSessionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { createOauthSessionThunk };
