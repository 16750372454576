import { getMyGiftsConnection } from '/@/api/graphql/internalApi/getMyGiftsConnection';
import type { GetMyGiftsConnectionResponse } from '/@/api/graphql/internalApi/getMyGiftsConnection';
import type { AppThunk } from '/@/store';
import {
  getInitialUnavailableMyGiftsConnectionStart,
  getInitialUnavailableMyGiftsConnectionSuccess,
  getInitialUnavailableMyGiftsConnectionFailure,
} from './actions';
import {
  MyGiftType_GiftTypeEnum,
  MyGiftUsageStatusEnum,
  SortDirectionEnum,
  MyGiftSortConditionTargetEnumType,
} from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getInitialUnavailableMyGiftsConnectionThunk =
  (): AppThunk<Promise<void>> => (dispatch) => {
    dispatch(getInitialUnavailableMyGiftsConnectionStart());

    return getMyGiftsConnection({
      sortConditions: [
        {
          direction: SortDirectionEnum.Desc,
          target: MyGiftSortConditionTargetEnumType.UnavailableAt,
        },
      ],
      usageStatuses: [
        MyGiftUsageStatusEnum.Discarded,
        MyGiftUsageStatusEnum.Expired,
      ],
      first: 20,
      giftTypeIn: [
        MyGiftType_GiftTypeEnum.GiftCardTicketChoosableContent,
        MyGiftType_GiftTypeEnum.GiftCardTicketFixedContent,
        MyGiftType_GiftTypeEnum.GifteeBox,
        MyGiftType_GiftTypeEnum.SimpleGift,
      ],
    })
      .then((response) =>
        dispatch(getInitialUnavailableMyGiftsConnectionSuccessThunk(response)),
      )
      .catch((error) =>
        dispatch(getInitialUnavailableMyGiftsConnectionFailureThunk(error)),
      );
  };

const getInitialUnavailableMyGiftsConnectionSuccessThunk =
  (response: GetMyGiftsConnectionResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { myGiftsConnection } = response.data!;
    dispatch(getInitialUnavailableMyGiftsConnectionSuccess(myGiftsConnection));
    return Promise.resolve();
  };

const getInitialUnavailableMyGiftsConnectionFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getInitialUnavailableMyGiftsConnectionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getInitialUnavailableMyGiftsConnectionThunk };
