import React from 'react';
import {
  Theme,
  WithStyles,
  withStyles,
  createStyles,
  Grid,
  Typography,
} from '@material-ui/core';
import decoration from '/@/assets/signin-how-to-use-decoration.svg';

const styles = (theme: Theme) => {
  return createStyles({
    section: {
      padding: theme.spacing(4, 3),
    },
    sectionTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      color: '#003F65',
    },
    sectionText: {
      fontSize: '1rem',
      lineHeight: '1.6',
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    detail: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(4),
    },
    detailTitle: {
      lineHeight: '1',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    decorationImage: {
      display: 'block',
      margin: 'auto',
      width: '100%',
      marginTop: theme.spacing(3),
    },
    detailDescription: {
      display: 'inline-block',
      marginTop: theme.spacing(2),
      color: '#4E5B61',
      fontWeight: 'normal',
    },
  });
};

type Props = WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes }) => {
  const details = [
    {
      title: 'もらったギフトを一覧で管理できる',
      description:
        '「マイギフト」ページから確認できるので、ギフトの期限切れ、使い忘れを減らせます。',
    },
    {
      title: '保存したチケットをすぐに表示できる',
      description:
        'gift walletのLINEアカウントを開いて、チケットをタップするだけ。',
    },
    {
      title: 'ギフトはLINEから確認できる',
      description:
        'ギフトがgift walletに保存されると、LINEアカウントに通知されます。',
    },
  ];

  return (
    <div data-cy="signInPageAboutSection">
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={classes.section}
      >
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="h5"
            className={classes.sectionTitle}
          >
            gift wallet の利用方法
          </Typography>
          <Typography variant="body1" className={classes.sectionText}>
            gift wallet は、
            <br />
            せっかくのギフトを使い忘れなく、
            <br />
            有効活用するためのお財布サービスです。
          </Typography>
          <img
            src={decoration}
            alt="decoration"
            className={classes.decorationImage}
          />
        </Grid>

        {details.map((detail, index) => (
          <Grid item xs={12} key={index} className={classes.detail}>
            <Typography variant="body1" className={classes.detailTitle}>
              {detail.title}
            </Typography>
            <Typography variant="caption" className={classes.detailDescription}>
              {detail.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
