import React from 'react';
import { useLocation } from 'react-router-dom';
import { DestinationEnum, OriginEnum } from '/@/api/graphql/publicApi/types';
import { Presenter } from './Presenter';

const Container: React.VFC<Record<string, never>> = () => {
  const { search } = useLocation();

  const urlSearchParams = new URLSearchParams(search);
  const url = urlSearchParams.get('gift_url');
  const origin = urlSearchParams.get('gift_origin') as OriginEnum | null;
  const referer = urlSearchParams.get('referer');
  const convertToPoint = true; // LP経由の場合は固定
  const destination = urlSearchParams.get(
    'destination',
  ) as DestinationEnum | null;

  return (
    <Presenter
      url={url}
      origin={origin}
      referer={referer}
      convertToPoint={convertToPoint}
      destination={destination}
      serialCode={null}
    />
  );
};

export { Container as LpConvertToPointPage };
