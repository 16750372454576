import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
} from '@material-ui/core';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { MyGift } from '/@/components/shared/myGift/Index';
import { GroupedUnavailableMyGifts } from '/@/store/app/unavailableMyGiftsConnection/selectors';

const styles = (theme: Theme) =>
  createStyles({
    unavailableGiftsContainer: {
      padding: theme.spacing(0, 2),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.hint,
    },
    groupedLabelText: {
      display: 'inline-block',
      marginTop: theme.spacing(3),
      fontWeight: 'bold',
      lineHeight: '1',
      color: '#4E5B61',
    },
    myGiftCard: {
      marginTop: theme.spacing(2),
      borderRadius: theme.spacing(1.5),
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    },
    unavailableMyGiftsNotFoundContainer: {
      padding: theme.spacing(2),
    },
    unavailableMyGiftsNotFoundText: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      marginTop: theme.spacing(4),
    },
    sortGrid: {
      padding: theme.spacing(2),
    },
    sortLabel: {
      paddingLeft: theme.spacing(2),
      color: theme.palette.secondary.dark,
      fontWeight: 'bold',
    },
  });

type OwnProps = {
  groupedUnavailableMyGifts: GroupedUnavailableMyGifts;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getInitialUnavailableMyGiftsConnectionStatus: Status;
  getContinuousUnavailableMyGiftsConnectionStatus: Status;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  groupedUnavailableMyGifts,
  infiniteScrollTarget,
  getInitialUnavailableMyGiftsConnectionStatus,
  getContinuousUnavailableMyGiftsConnectionStatus,
}) => (
  <Grid data-cy="unavailableMyGifts">
    {getInitialUnavailableMyGiftsConnectionStatus === STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <>
        {Object.keys(groupedUnavailableMyGifts).length ? (
          <div className={classes.unavailableGiftsContainer}>
            {Object.keys(groupedUnavailableMyGifts).map((date) => (
              <React.Fragment key={date}>
                <Typography
                  variant="caption"
                  className={classes.groupedLabelText}
                >
                  {date}
                </Typography>
                {groupedUnavailableMyGifts[date].map((myGift) => (
                  <div key={myGift.urlCode} className={classes.myGiftCard}>
                    <MyGift myGift={myGift} />
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        ) : (
          <Grid
            container
            justifyContent="center"
            direction="column"
            className={classes.unavailableMyGiftsNotFoundContainer}
          >
            <Typography
              variant="body2"
              className={classes.unavailableMyGiftsNotFoundText}
            >
              現在利用済み・期限切れのギフトはありません。
            </Typography>
          </Grid>
        )}
      </>
    )}
    {getContinuousUnavailableMyGiftsConnectionStatus === STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <></>
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
