import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Grid,
  Typography,
  Theme,
} from '@material-ui/core';
import exchangeImage from '/@/assets/signin-about-gift-wallet.png';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      width: '100%',
      padding: theme.spacing(4, 2),
    },
    subTitle: {
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: '1',
      marginBottom: theme.spacing(0.5),
      textAlign: 'center',
    },
    mainTitle: {
      color: '#003F65',
      fontSize: '1.375rem',
      fontWeight: 'bold',
      lineHeight: '1.6',
      textAlign: 'center',
    },
    mainText: {
      fontSize: '0.875rem',
      lineHeight: '1.4',
      textAlign: 'left',
      marginTop: theme.spacing(2),
    },
    exchangeImage: {
      display: 'block',
      margin: 'auto',
      width: '100%',
      marginTop: theme.spacing(3),
    },
  });
};

type OwnProps = Record<string, never>;

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes }) => (
  <div data-cy="signInPageAboutSection" className={classes.container}>
    <Grid container direction="column" alignContent="center">
      <Grid item xs>
        <Typography variant="h3" className={classes.subTitle}>
          ポイント移行をすると
        </Typography>
        <Typography variant="h1" className={classes.mainTitle}>
          まとめてポイントが使える
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="body1" align="center" className={classes.mainText}>
          ポイントを移行すると、移行したポイントに加えてgift wallet
          に貯蓄済みのポイントも合わせて利用することができます。
        </Typography>
      </Grid>
      <Grid item xs>
        <img
          src={exchangeImage}
          alt="exchangeImage"
          className={classes.exchangeImage}
        />
      </Grid>
    </Grid>
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
