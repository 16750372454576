import React, { useEffect } from 'react';
import { Presenter } from './Presenter';
import { useAppSelector } from '/@/store';
import { useDataLayer } from '/@/common/hooks/useDataLayer';

const Container: React.VFC<Record<string, never>> = () => {
  const exchangedGifts = useAppSelector((state) => state.app.exchangedGifts);
  const { pushPointExchangeEvent } = useDataLayer();

  useEffect(() => {
    if (exchangedGifts) {
      pushPointExchangeEvent({
        point: exchangedGifts?.pointAmount,
        itemUid: exchangedGifts?.item.uid,
        itemName: exchangedGifts?.item.contentName,
        brandUid: exchangedGifts?.item.brand.uid,
        brandName: exchangedGifts?.item.brand.name,
        customData: {
          point: exchangedGifts?.pointAmount,
          itemUid: exchangedGifts?.item.uid,
          itemName: exchangedGifts?.item.contentName,
          brandUid: exchangedGifts?.item.brand.uid,
          brandName: exchangedGifts?.item.brand.name,
        },
      });
    }
  }, [pushPointExchangeEvent, exchangedGifts]);

  return <Presenter exchangedGifts={exchangedGifts} />;
};
export { Container as ExchangedGifts };
