import React, { useEffect, useState } from 'react';
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles,
  Collapse,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { StyleRules } from '@material-ui/core/styles';
import clsx from 'clsx';
import { AlertMessageState, SEVERITY } from '/@/store/ui/alertMessage';
import successIcon from '/@/assets/alert_message_success_icon.svg';
import errorIcon from '/@/assets/alert_message_error_icon.svg';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { Link } from 'react-router-dom';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    alert: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      fontWeight: 'bold',
      borderRadius: '0.5rem',
      transition: 'opacity 0.5s linear',
      textAlign: 'left',
    },
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
    withSuccess: {
      width: `calc(100vw - ${theme.spacing(4)}px)`,
      position: 'fixed',
      top: HEADER_HEIGHT + theme.spacing(2), // ヘッダーの高さ+余白
      left: theme.spacing(2),
      margin: 0,
      color: theme.palette.grey[700],
      border: '1px solid #32CD32',
      backgroundColor: '#F2FAF1',
      zIndex: 1000,
    },
    withError: {
      color: '#D93E17',
      border: '1px solid #EB2300',
      backgroundColor: '#FDF3F2',
    },
    message: {
      fontWeight: 'bold',
      fontSize: '0.875rem',
      lineHeight: '1.2',
    },
    link: {
      display: 'inline-block',
      textDecoration: 'none',
      marginTop: theme.spacing(1),
    },
    linkText: {
      color: theme.palette.primary.main,
      fontSize: '0.875rem',
      lineHeight: '1.2',
    },
  });

type OwnProps = {
  alertMessage: NonNullable<AlertMessageState>;
  handleInit: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, alertMessage, handleInit }) => {
  const [isDisplay, setIsDisplay] = useState(true);

  useEffect(() => {
    // success時の表示時間
    if (alertMessage.severity === SEVERITY.SUCCESS) {
      const timer = setTimeout(() => {
        setIsDisplay(false);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alertMessage.severity]);

  const icon = { src: '', alt: '' };

  switch (alertMessage.severity) {
    case SEVERITY.SUCCESS:
      icon.src = successIcon;
      icon.alt = 'successIcon';
      break;
    case SEVERITY.ERROR:
      icon.src = errorIcon;
      icon.alt = 'errorIcon';
      break;
    default:
      break;
  }

  return (
    <Collapse in={isDisplay}>
      <Alert
        icon={<img src={icon.src} alt={icon.alt} width="20" height="20" />}
        severity={alertMessage.severity}
        className={clsx(
          classes.alert,
          isDisplay ? classes.visible : classes.hidden,
          alertMessage.severity === SEVERITY.SUCCESS && classes.withSuccess,
          alertMessage.severity === SEVERITY.ERROR && classes.withError,
        )}
        data-cy="alertMessage"
      >
        <Typography variant="body2" className={classes.message}>
          {alertMessage.message}
        </Typography>
        {alertMessage.link?.path && alertMessage.link?.text && (
          <Link
            to={alertMessage.link.path}
            onClick={handleInit}
            className={classes.link}
            data-gtm-click="shared-alertMessage-link"
          >
            <Typography variant="body2" className={classes.linkText}>
              {alertMessage.link.text}
            </Typography>
          </Link>
        )}
      </Alert>
    </Collapse>
  );
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
