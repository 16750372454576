import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Button,
  Theme,
  Card,
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CreatePointExchangeMutation } from '/@/api/graphql/internalApi/types';
import pointBackIcon from '/@/assets/point_back_icon.svg';
import { Points } from '/@/components/shared/points/Index';
const styles = (theme: Theme) =>
  createStyles({
    myGiftCard: {
      padding: theme.spacing(3, 2),
      borderRadius: theme.spacing(2),
    },
    logoAndTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
      height: '100%',
      width: '100%',
    },
    // logoContainerのwidthは4rem+rightContentのmarginLeft（0.5rem）で調整
    logoContainer: {
      width: '4.5rem',
      height: '4rem',
      marginTop: 'auto',
      marginBottom: 'auto',
      borderRadius: theme.spacing(1),
      border: '1px solid #E6EBED',
    },
    logo: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    rightContent: {
      marginLeft: theme.spacing(1),
      width: 'calc(100% - 4.5rem - 0.5rem)', // ロゴの幅とmarginLeftの合計値
    },
    brandName: {
      margin: 0,
      fontSize: '0.625rem',
      lineHeight: '0.625rem',
      color: theme.palette.grey.A700,
    },
    itemName: {
      marginTop: theme.spacing(1),
      fontSize: '0.875rem',
      lineHeight: '1.4rem',
      fontWeight: 'bold',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    pointBackContent: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1, 2),
    },
    pointBackIcon: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    pointBackRightContent: {
      marginLeft: theme.spacing(2),
    },
    pointBackTitle: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      color: theme.palette.grey.A700,
    },
    pointText: {
      marginTop: theme.spacing(1),
      display: 'inline-flex',
    },
    pointBackText: {
      marginTop: 'auto',
      marginLeft: theme.spacing(0.5),
      fontSize: '0.625rem',
      lineHeight: '1rem',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    usingGiftButton: {
      width: '100%',
      fontWeight: 'bold',
      lineHeight: '1',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2, 4),
      borderRadius: theme.spacing(4),
    },
    homePageButton: {
      width: '100%',
      fontWeight: 'bold',
      lineHeight: '1',
      padding: theme.spacing(1.5, 4),
      borderRadius: theme.spacing(4),
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
    },
  });

type OwnProps = {
  exchangedGifts: CreatePointExchangeMutation['createPointExchange']['pointExchange'];
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, exchangedGifts }) => (
  <Card variant="outlined" className={classes.myGiftCard}>
    {exchangedGifts?.myGifts.map((myGift, index) => (
      <div key={index}>
        {index > 0 && <Divider className={classes.divider} />}
        <div className={classes.logoAndTitle}>
          <div className={classes.logoContainer}>
            <img
              className={classes.logo}
              src={myGift.imageUrl}
              alt={myGift.contentName}
            />
          </div>
          <div className={classes.rightContent}>
            <p className={classes.brandName}>
              {exchangedGifts.item.brand.name}
            </p>
            <Typography className={classes.itemName}>
              {myGift.contentName}
            </Typography>
          </div>
        </div>
      </div>
    ))}
    {exchangedGifts?.pointBackCampaignApply && (
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        className={classes.pointBackContent}
      >
        <img
          src={pointBackIcon}
          alt="pointBackIcon"
          className={classes.pointBackIcon}
          width="40"
          height="40"
          data-cy="pointBackIcon"
        />
        <div className={classes.pointBackRightContent}>
          <Typography className={classes.pointBackTitle}>
            獲得した還元ポイント
          </Typography>
          <div className={classes.pointText}>
            <Points points={exchangedGifts.pointBackCampaignApply.backPoint} />
            <Typography className={classes.pointBackText}>
              {`(${
                exchangedGifts.pointBackCampaignApply.pointBackCampaign
                  .discountPercentage
              }%還元)`}
            </Typography>
          </div>
        </div>
      </Grid>
    )}
    <Grid container direction="column" alignContent="center">
      {exchangedGifts?.myGifts[0].giftUrl && (
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classes.usingGiftButton}
          component={Link}
          to={exchangedGifts.myGifts[0].giftUrl}
          data-cy="usingGiftButton"
        >
          ギフトを使用する
        </Button>
      )}
    </Grid>
  </Card>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
