import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Grid,
  Container,
} from '@material-ui/core';
import { HeroSection } from './heroSection/Index';
import { LoginSection } from './loginSection/Index';
import { AboutSection } from './aboutSection/Index';
import { FeatureSection } from './featureSection/Index';
import { UsageSection } from './usageSection/Index';
import { SummarySection } from './summarySection/Index';
import { Footer } from '/@/components/templates/footer/Index';
import { LoginOption } from '/@/components/shared/loginButton/Index';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      padding: 0,
    },
    loginSectionContainer: {
      width: '100%',
      padding: theme.spacing(2),
    },
    aboutSectionContainer: {
      margin: theme.spacing(2),
    },
    featureSectionContainer: {
      marginTop: theme.spacing(4),
    },
    usageSectionContainer: {
      marginTop: theme.spacing(2),
      width: '100%',
      backgroundColor: theme.palette.background.hint,
    },
    summarySectionContainer: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%',
      backgroundColor: theme.palette.background.contrast,
    },
  });
};

type OwnProps = LoginOption & {
  isSerialCodeOutOfPeriod: boolean;
  errorMessage: string | null;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  url,
  origin,
  convertToPoint,
  destination,
  serialCode,
  isSerialCodeOutOfPeriod,
  errorMessage,
}) => (
  <Grid container direction="column" alignContent="center">
    <section>
      <Container maxWidth="sm" className={classes.container}>
        <HeroSection />
      </Container>
    </section>
    <section className={classes.loginSectionContainer}>
      <Container maxWidth="sm" className={classes.container}>
        <LoginSection
          url={url}
          origin={origin}
          referer={null}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={serialCode}
          isSerialCodeOutOfPeriod={isSerialCodeOutOfPeriod}
          errorMessage={errorMessage}
        />
      </Container>
    </section>
    <section className={classes.aboutSectionContainer}>
      <Container maxWidth="sm" className={classes.container}>
        <AboutSection />
      </Container>
    </section>
    <section className={classes.featureSectionContainer}>
      <Container maxWidth="sm" className={classes.container}>
        <FeatureSection />
      </Container>
    </section>
    <section className={classes.usageSectionContainer}>
      <Container maxWidth="sm" className={classes.container}>
        <UsageSection />
      </Container>
    </section>
    <section className={classes.summarySectionContainer}>
      <Container maxWidth="sm" className={classes.container}>
        <SummarySection
          url={url}
          origin={origin}
          referer={null}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={serialCode}
        />
      </Container>
    </section>
    <Footer />
  </Grid>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
