import React from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/core';
import { FixedGiftCardTicketBase } from '/@/store/app/shared/types';
import { TicketSkelton } from '/@/components/shared/gifteeContent/ticketSkelton/Index';

const styles = () => {
  return createStyles({});
};

export type FixedGiftCardTicket = FixedGiftCardTicketBase;

type OwnProps = {
  fixedGiftCardTicket: FixedGiftCardTicket;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ fixedGiftCardTicket }) => (
  <div data-cy={`fixedGiftCardTicket-${fixedGiftCardTicket.url}`}>
    <TicketSkelton
      name={fixedGiftCardTicket.name}
      imageUrl={fixedGiftCardTicket.imageUrl}
      url={fixedGiftCardTicket.url}
      expiredAt={fixedGiftCardTicket.expiredAt}
      expiredAtLabel="有効"
    />
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
