import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  AppBar,
  Toolbar,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
} from '@material-ui/core';
import menuIcon from '/@/assets/menu_icon.svg';
import giftWalletLogo from '/@/assets/gift_wallet_logo.svg';

import { Link as RouterLink } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

export const HEADER_HEIGHT = 48;

const styles = (theme: Theme) => {
  return createStyles({
    appBar: {
      backgroundColor: theme.palette.background.default,
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
    },
    toolBar: {
      height: HEADER_HEIGHT,
      minHeight: HEADER_HEIGHT,
      padding: theme.spacing(1, 2),
    },
    menuIconButton: {
      padding: 0,
    },
    giftWalletLogo: {
      margin: 'auto',
      marginLeft: 0,
      height: '1.5rem',
    },
    menuDrawer: {
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: '30%',
      },
      [theme.breakpoints.up('md')]: {
        width: '10%',
      },
    },
    menuListSubheader: {
      fontSize: '0.75rem',
    },
    menuDivider: {},
    menuListItemText: {
      fontSize: '0.875rem',
    },
  });
};

type OwnProps = {
  isLogoClickable: boolean;
  isMenuVisible: boolean;
  isMenuOpened: boolean;
  handleToggleMenu: () => void;
  handleClickGlobalMenu: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  isLogoClickable,
  isMenuVisible,
  isMenuOpened,
  handleToggleMenu,
  handleClickGlobalMenu,
}) => (
  <>
    <AppBar
      position="fixed"
      className={classes.appBar}
      variant="outlined"
      color="default"
      data-cy="header"
    >
      <Toolbar className={classes.toolBar}>
        {isLogoClickable ? (
          <RouterLink
            to={PATHS.ROOT}
            className={classes.giftWalletLogo}
            data-cy="clickableGiftWalletLogo"
          >
            <img height={24} src={giftWalletLogo} alt="ロゴ" />
          </RouterLink>
        ) : (
          <img
            height={24}
            className={classes.giftWalletLogo}
            src={giftWalletLogo}
            alt="ロゴ"
            data-cy="unclickableGiftWalletLogo"
          />
        )}
        {isMenuVisible && (
          <IconButton
            color="primary"
            className={classes.menuIconButton}
            aria-label="menu-icon-button"
            onClick={() => {
              handleToggleMenu();
              handleClickGlobalMenu();
            }}
            edge="start"
            data-cy="menuIcon"
            data-gtm-click="templates-header-globalHeader-menuButton"
          >
            <img height={18} width={16} src={menuIcon} alt="メニューアイコン" />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>

    <SwipeableDrawer
      classes={{
        paper: classes.menuDrawer,
      }}
      anchor="right"
      open={isMenuOpened}
      onOpen={() => {}} // Do nothing
      onClose={handleToggleMenu}
    >
      <List data-cy="menuList" component="nav" aria-label="menu-list">
        <ListSubheader classes={{ root: classes.menuListSubheader }}>
          ギフト管理
        </ListSubheader>
        <ListItem
          data-cy="listItemHome"
          button
          component={RouterLink}
          to={PATHS.HOME}
        >
          <ListItemText
            primary="マイギフト"
            classes={{ primary: classes.menuListItemText }}
          />
        </ListItem>
        <ListItem
          data-cy="listItemGetGift"
          button
          component={RouterLink}
          to={PATHS.PROMOTIONS}
        >
          <ListItemText
            primary="もらえるギフト"
            classes={{ primary: classes.menuListItemText }}
          />
        </ListItem>

        <Divider className={classes.menuDivider} component="li" />

        <ListSubheader classes={{ root: classes.menuListSubheader }}>
          ヘルプ
        </ListSubheader>
        <ListItem
          button
          component={RouterLink}
          to={PATHS.FREQUENTLY_ASKED_QUESTIONS}
        >
          <ListItemText
            data-cy="listItemFrequentlyAskedQuestions"
            primary="よくあるご質問"
            classes={{ primary: classes.menuListItemText }}
          />
        </ListItem>
        <ListItem button component={RouterLink} to={PATHS.INQUIRIES_NEW}>
          <ListItemText
            data-cy="listItemInquires"
            primary="お問い合わせ"
            classes={{ primary: classes.menuListItemText }}
          />
        </ListItem>
        <ListItem button component={RouterLink} to={PATHS.TERMS_OF_SERVICE}>
          <ListItemText
            data-cy="listItemTermsOfServices"
            primary="サービス利用規約"
            classes={{ primary: classes.menuListItemText }}
          />
        </ListItem>
      </List>
    </SwipeableDrawer>
  </>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
