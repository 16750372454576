import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Button,
  Container,
} from '@material-ui/core';
import { Promotions } from './promotions/Index';
import { GifteeContent } from './gifteeContent/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import savedGiftIcon from '/@/assets/saved_gift_icon.svg';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import { ErrorMessage } from '/@/store/api/saveGifteeContent/selectors';

const styles = (theme: Theme) =>
  createStyles({
    promotionsContainer: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    gifteeContentTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      padding: theme.spacing(3),
    },
    duplicatedGifteeContentErrorMessage: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.warning.main,
      padding: theme.spacing(3),
    },
    gifteeContentContainer: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    gifteeContent: {
      minWidth: '100%',
      maxWidth: '100%',
    },
    savedGiftIcon: { margin: 'auto' },
    homePageButton: {
      fontWeight: 'bold',
      marginTop: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderRadius: theme.spacing(4),
    },
    gifteeContentBackground: {
      backgroundColor: theme.palette.background.contrast,
    },
  });

type OwnProps = { duplicatedGifteeContentErrorMessage: ErrorMessage };

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  duplicatedGifteeContentErrorMessage,
}) => (
  <>
    <MainLayout>
      <div className={classes.gifteeContentBackground}>
        <Container maxWidth="sm" className={classes.gifteeContentContainer}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            direction="column"
          >
            <img
              src={savedGiftIcon}
              alt="ギフトを保存しました"
              className={classes.savedGiftIcon}
              width="100"
              height="100"
              data-cy="savedGiftIcon"
            />
            {duplicatedGifteeContentErrorMessage ? (
              <Typography
                className={classes.duplicatedGifteeContentErrorMessage}
              >
                {duplicatedGifteeContentErrorMessage}
              </Typography>
            ) : (
              <Typography className={classes.gifteeContentTitle}>
                ギフトを保存しました
              </Typography>
            )}
            <div className={classes.gifteeContent}>
              <GifteeContent />
            </div>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.homePageButton}
              component={Link}
              to={PATHS.HOME}
              data-cy="homePageButton"
            >
              マイギフトを確認する
            </Button>
          </Grid>
        </Container>
      </div>
      <Container maxWidth="sm" className={classes.promotionsContainer}>
        <Promotions />
      </Container>
    </MainLayout>
  </>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
