import { gql } from '@apollo/client';

export const FRAGMENT_ERRORS = gql`
  fragment ErrorsFields on Error {
    message
    path
    extensions {
      status
      code
    }
  }
`;
