import { Reducer } from 'redux';
import type { RootAction } from '/@/store/actions';
import { CreatePointExchangeInputWithTypeBase } from '/@/store/app/shared/types';
import { types } from './types';

type CreatePointExchangeInputState =
  CreatePointExchangeInputWithTypeBase | null;

const initialState: CreatePointExchangeInputState = null;

const createPointExchangeInput: Reducer<
  CreatePointExchangeInputState,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CREATE_POINT_EXCHANGE_INPUT:
      if (!action.createPointExchangeInput) return null;
      return action.createPointExchangeInput;
    case types.INITIALISE_CREATE_POINT_EXCHANGE_INPUT:
      return initialState;
    default:
      return state;
  }
};

export { createPointExchangeInput };
export type { CreatePointExchangeInputState };
