import React from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/core';
import {
  LoginButton,
  LoginOption,
} from '/@/components/shared/loginButton/Index';

const styles = () => {
  return createStyles({});
};

type OwnProps = LoginOption;

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  convertToPoint,
  destination,
  serialCode,
}) => {
  const buttonText = serialCode
    ? 'ログインしてポイントをチャージ'
    : 'さっそく使ってみる';

  return (
    <div data-cy="signInPageSummarySection">
      <div>
        <LoginButton
          url={null}
          origin={null}
          referer={null}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={serialCode}
        >
          {buttonText}
        </LoginButton>
      </div>
    </div>
  );
};

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
