import React from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { POINT_SECTION_WITH_BENEFIT_OF_POINT_CHARGE_HEIGHT } from '/@/components/shared/headingWithPoints/Presenter';
import { FOOTER_HEIGHT } from '/@/components/templates/footer/Presenter';
import { HeadingWithPoints } from '/@/components/shared/headingWithPoints/Index';
import { BrandContents } from '/@/components/shared/brandContents/Index';
import { CautionText } from '/@/components/shared/cautionText/Index';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
    },
    ticketContainer: {
      backgroundColor: theme.palette.background.hint,
      minHeight: `calc(100vh - ${HEADER_HEIGHT}px - ${POINT_SECTION_WITH_BENEFIT_OF_POINT_CHARGE_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
      padding: theme.spacing(3, 2),
      paddingTop: theme.spacing(2),
    },
    content: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    cautionTextContainer: {
      marginTop: theme.spacing(2),
    },
  });

type OwnProps = {
  headerTitle: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({ classes, headerTitle }) => (
  <MainLayout headerTitle={headerTitle}>
    <HeadingWithPoints benefitOfPointChargeModalVisible={true} />
    <div className={classes.ticketContainer}>
      <BrandContents />
      <div className={classes.cautionTextContainer}>
        <CautionText />
      </div>
    </div>
  </MainLayout>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
