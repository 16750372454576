import { sessionTokenCookieName } from './setSessionTokenToCookie';

const getSessionTokenFromCookie = (): string | undefined => {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith(sessionTokenCookieName))
    ?.split('=')[1];
};

export { getSessionTokenFromCookie };
