import { Reducer } from 'redux';
import {
  GetAvailablePointChargesQuery,
  AvailablePointCharge,
} from '/@/api/graphql/internalApi/types';
import { getAvailablePointChargesTypes } from '/@/store/api/getAvailablePointCharges';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';

type AvailablePointChargesState = AvailablePointCharge[] | null;
type PageInfoState =
  | GetAvailablePointChargesQuery['pointWallet']['availablePointCharges']['pageInfo']
  | null;
type PointLogsPageState = {
  availablePointCharges: AvailablePointChargesState;
  pageInfo: PageInfoState;
};

const initialAvailablePointChargesState: AvailablePointChargesState = null;

const availablePointCharges: Reducer<AvailablePointChargesState, RootAction> = (
  state = initialAvailablePointChargesState,
  action,
) => {
  switch (action.type) {
    case getAvailablePointChargesTypes.GET_AVAILABLE_POINT_CHARGES_SUCCESS: {
      if (!action.availablePointChargesResponse.nodes) return state;

      const filteredNodes = filterNodes(
        action.availablePointChargesResponse.nodes,
      );
      if (state) {
        return [...state, ...filteredNodes];
      } else {
        return [...filteredNodes];
      }
    }
    default:
      return state;
  }
};

const filterNodes = (
  availablePointCharges: NonNullable<
    GetAvailablePointChargesQuery['pointWallet']['availablePointCharges']['nodes']
  >,
): NonNullable<AvailablePointChargesState> =>
  availablePointCharges.filter((node): node is AvailablePointCharge => !!node);

const pageInfoInitialState: PageInfoState = null;

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getAvailablePointChargesTypes.GET_AVAILABLE_POINT_CHARGES_SUCCESS:
      return action.availablePointChargesResponse.pageInfo;
    default:
      return state;
  }
};

const availablePointChargesPage = combineReducers({
  availablePointCharges,
  pageInfo,
});

export { availablePointChargesPage };
export type { PointLogsPageState, AvailablePointChargesState };
