import { gql } from '@apollo/client';

export const getAvailablePointChargesGql = gql`
  query GetAvailablePointCharges($first: Int, $after: String) {
    pointWallet {
      availablePointCharges(first: $first, after: $after) {
        nodes {
          uid
          pointAmount
          remainingAmount
          withdrawableUntilAt
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;
