import { TopicValues } from './';
import { types } from './types';

export const updateName = (name: string) =>
  ({
    type: types.UPDATE_NAME,
    payload: name,
  }) as const;

export const updateEmail = (email: string) =>
  ({
    type: types.UPDATE_EMAIL,
    payload: email,
  }) as const;

export const updateContent = (content: string) =>
  ({
    type: types.UPDATE_CONTENT,
    payload: content,
  }) as const;

export const updateTopic = (topic: TopicValues | '') =>
  ({
    type: types.UPDATE_TOPIC,
    payload: topic,
  }) as const;

export const updateIsTermsAgreed = (isTermsAgreed: boolean) =>
  ({
    type: types.UPDATE_IS_TERMS_AGREED,
    payload: isTermsAgreed,
  }) as const;
