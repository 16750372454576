import React from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/core';
import { SignInPageState } from '/@/store/page/signInPage';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { GifteeContent } from '/@/components/shared/gifteeContent/Index';

const styles = () => {
  return createStyles({
    savingGift: {
      borderRadius: 16,
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
    },
    image: {
      width: 80,
      borderRadius: 16,
    },
    name: {
      fontWeight: 'bold',
    },
  });
};

type OwnProps = {
  getSavingGifteeContentStatus: Status;
  savingGifteeContent: SignInPageState['savingGifteeContent'];
};

type Props = OwnProps & WithStyles<typeof styles>;

const Presenter: React.VFC<Props> = ({
  classes,
  getSavingGifteeContentStatus,
  savingGifteeContent,
}) => (
  <div data-cy="signInPageSavingGift">
    {getSavingGifteeContentStatus === STATUS.LOADING || !savingGifteeContent ? (
      <PartialLoader />
    ) : (
      <div className={classes.savingGift}>
        <GifteeContent gifteeContent={savingGifteeContent} />
      </div>
    )}
  </div>
);

const WithStyled = withStyles(styles)(Presenter);

export { WithStyled as Presenter };
